import VisualBox from "common/frontend/components/visuals/VisualBox"
import { useEventId, visualsState } from "common/frontend/state"
import * as React from "react"
import { useRecoilValue } from "recoil"
import { StoriesDefinition } from "./StoriesPage"

const stories: StoriesDefinition = {
    name: 'VisualBox',
    stories: [
        {
            name: 'Different widths',
            component: () => {
                const eventId = useEventId()
                const visuals = useRecoilValue(visualsState)
                const widths = [
                    100,
                    150,
                    200,
                    300,
                    500,
                    800
                ]
                return (
                    <>
                        {widths.map((width, index) => (
                            <div
                                key={width}
                            >
                                <div style={{ marginBottom: 5 }}>{width}px wide</div>
                                <div
                                    style={{
                                        width,
                                        marginBottom: 15
                                    }}
                                >
                                    <VisualBox
                                        eventId={eventId}
                                        visual={visuals[index % visuals.length]}
                                    />
                                </div>
                            </div>            
                        ))}
                    </>
                )
            }
        },
        {
            name: 'In a grid',
            component: () => {
                const eventId = useEventId()
                const visuals = useRecoilValue(visualsState)
                return (
                    <div
                        className="visuals-grid"
                        style={{
                            maxWidth: 500
                        }}
                    >
                        {visuals.map(visual => (
                            <VisualBox
                                key={visual.id}
                                eventId={eventId}
                                visual={visual}
                            />
                        ))}
                    </div>
                )
            }
        }
    ]
}

export default stories