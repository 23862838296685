import { AudioUploadJob } from 'common/types/commonTypes'
import * as React from 'react'
import { Suspense, useState } from 'react'
import { useAudioUploadJobs, useEvent, useRefreshAudioUploadJobs } from '../state'
import Cover from './Cover'
import DraggableNewTracks, { NewTrackTarget } from './DraggableNewTracks'
import Modal from './Modal'
import MusicUpload from './MusicUpload'
import { DefaultTrackImage } from './TrackBox'
import { startCase } from 'lodash'

export default function MusicUploaded({ target }: { target: NewTrackTarget }) {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const refresh = useRefreshAudioUploadJobs()
    const event = useEvent()
    function onDone() {
        refresh()
        setModalIsOpen(false)
    }
    return (
        <div>
            <button className="btn" onClick={() => setModalIsOpen(true)} style={{ marginTop: 10 }}>Upload</button>
            {event?.userTrackInfos?.length > 0 && (
                <>
                    <h4>Uploaded music</h4>
                    <DraggableNewTracks trackInfos={event.userTrackInfos} target={target} />
                </>
            )}
            <Suspense
                fallback={<Cover center translucent loading />}
            >
                <AudioUploadJobs />
            </Suspense>
            <Modal opened={modalIsOpen} onClose={() => setModalIsOpen(false)}>
                <MusicUpload
                    onCancel={() => setModalIsOpen(false)}
                    onDone={() => onDone()}
                />
            </Modal>
        </div>
    )
}

function AudioUploadJobs() {
    const jobs = useAudioUploadJobs()
    const refresh = useRefreshAudioUploadJobs()
    return (
        <>
            {jobs.length > 0 && (
                <>
                    <div className="cols cols--center">
                        <h4>Your requests</h4>
                        <button
                            className="action action--refresh cols__right"
                            onClick={() => refresh()}
                            title="Refresh"
                        />
                    </div>
                    {jobs.map((job, index) => (
                        <AudioUploadJob
                            key={index}
                            job={job}
                        />
                    ))}
                </>
            )}
        </>
    )
}

function AudioUploadJob({ job }: { job: AudioUploadJob }) {
    return (
        <div className="info-box" style={{ marginBottom: 10, padding: 5, paddingRight: 20 }}>
            <DefaultTrackImage />
            <div>{job.audioUpload.name}</div>
            <div style={{ marginLeft: 'auto' }}><em>{startCase(job.status)}</em></div>
        </div>
    )
}