import { BDuration, BDurationJson } from '@busby/esb'
import { parseDateString } from 'common/universal/universalUtils'

export function formatDate(dateString: string): string {
    const date = parseDateString(dateString).setZone("Europe/London")
    return date.toFormat("dd-MM-yyyy")
}

export function formatTime(dateString: string): string {
    return parseDateString(dateString).setZone("Europe/London").toFormat("hh:mm a")
}

export function formatDateFull(dateString: string) {
    return parseDateString(dateString).setZone("Europe/London").toLocaleString({ dateStyle: 'full' })
}

export function formatDateTimeFull(dateString: string) {
    return parseDateString(dateString).setZone("Europe/London").toLocaleString({
        dateStyle: 'full',
        timeStyle: 'short'
    })
}

export function formatDurationAsMinutesAndSeconds(duration: BDurationJson): string {
    return formatSecondsAsMinutesAndSeconds(BDuration.fromJson(duration).getSeconds())
}

export function formatMillisAsMinutesAndSeconds(millis: number): string {
    return formatSecondsAsMinutesAndSeconds(Math.floor(millis / 1000))
}

export function formatSecondsAsMinutesAndSeconds(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60
    const pad = (value: number) => String(value).padStart(2, '0')
    if (hours > 0) {
        return [hours, pad(minutes), pad(seconds)].join(':')
    }
    else {
        return [minutes, pad(seconds)].join(':')
    }
}

export function formatSectionDuration(duration: BDurationJson) {
    const totalMinutes = BDuration.fromJson(duration).getMinutes()
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    const parts = []
    // TODO: need proper pluralization approach for i18n
    if (hours > 0) {
        if (hours === 1) {
            parts.push('1 hour')
        } else {
            parts.push(`${hours} hours`)
        }
    }
    if (minutes > 0) {
        parts.push(minutes)
    }
    // if (minutes > 0) {
    //     if (minutes === 1) {
    //         parts.push('1 minute')
    //     } else {
    //         parts.push(`${minutes} minutes`)
    //     }
    // }
    return parts.join(' ')
}