import { times } from 'lodash'
import * as React from 'react'
import { Suspense, useMemo } from 'react'
import { useEvent, useRefreshTrackRequestJobs, useTrackRequestJobs } from '../state'
import { makeWesleyTrackInfo } from '../utils'
import Cover from './Cover'
import DraggableNewTracks, { NewTrackTarget } from './DraggableNewTracks'
import TrackRequestBox from './TrackRequestBox'

export default function MusicRequests({ target }: { target: NewTrackTarget }) {
    const event = useEvent()

    return (
        <>
            {event?.requestedTrackInfos?.length > 0 && (
                <>
                    <h4>Your requested tracks</h4>
                    <DraggableNewTracks trackInfos={event.requestedTrackInfos} target={target} />
                </>
            )}
            <Suspense
                fallback={<Cover center translucent loading />}
            >
                <TrackRequestJobs />
            </Suspense>
        </>
    )
}

function TrackRequestJobs() {
    const trackRequestJobs = useTrackRequestJobs()
    const refresh = useRefreshTrackRequestJobs()
    return (
        <>
            {trackRequestJobs.length > 0 && (
                <>
                    <div className="cols cols--center">
                        <h4>Your requests</h4>
                        <button
                            className="action action--refresh cols__right"
                            onClick={() => refresh()}
                            title="Refresh"
                        />
                    </div>
                    {trackRequestJobs.map((job, index) => (
                        <TrackRequestBox
                            key={index}
                            trackRequest={job.trackRequest}
                            trackInfo={job.trackInfo}
                            status={job.status}
                        />
                    ))}
                </>
            )}
        </>
    )
}