import RjsfCore from "@rjsf/core";
import { FieldTemplateProps, getUiOptions } from "@rjsf/utils";
import * as React from 'react';
import ajvValidator from '@rjsf/validator-ajv8';

function getFieldTemplate(props: FieldTemplateProps) {
    const { classNames, label, children, uiSchema, schema, required, help } = props;
    const uiOptions = getUiOptions(uiSchema as any)
    if (uiSchema?.["ui:widget"] === "hidden") {
        return null;
    }
    const title = label || uiOptions.title
    return (
        <div className={classNames}>
            {
                !uiOptions.hideTitle && title && <label className="control-label">
                    {title}{required && <span className="required">*</span>}
                </label>
            }
            {children}
            {uiOptions.help && help}
        </div>
    );
}

export const Form = React.forwardRef<any, any>((props: React.ComponentProps<typeof RjsfCore> & { theme: string, jsonSchema: any, value: string }, forwardedRef) => {
    const { theme, ...rest } = props

    return <div className={`form-input form-input--${theme}`}>
        <RjsfCore
            {...rest}
            ref={forwardedRef}
            templates={{ FieldTemplate: getFieldTemplate }}
            validator={ajvValidator}
            schema={props.jsonSchema}
            formData={props.value}
        >
            {
                props.children || <div/>
            }
        </RjsfCore>
    </div>
})