import { produce } from 'immer';
import * as React from 'react';
import { useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { highlightedElementsState } from './Search';

export interface SearchHighlightProps {
    content: string
}

/**
 * A single search-highlighted bit of text content
 * 
 * Other than formatting the highlight, the main purpose of this
 * is to keep track of the HTML elements that are highlighted so
 * we can jump to them...
 * 
 */
export function SearchHighlight({ content }: SearchHighlightProps) {
    const setElements = useSetRecoilState(highlightedElementsState);
    const ref = useRef<HTMLElement>();

    // This will be called with our element when mounted
    // ... and called again with null when unmounted ...
    //
    // ... but we need a reference to the element when unmounting
    // so we can go and find it to remove from the state array
    //
    // that's what the ref is for
    function setElement(el: HTMLElement) {
        if (el) {
            // mounted element
            setElements(produce(draft => {
                draft.push(el as any) // TODO: what is wrong with the typing?
                ref.current = el
            }));
        } else {
            // unmounted element
            setElements(produce((draft) => {
                const idx = draft.indexOf(ref.current as any)  // TODO: what is wrong with the typing?
                if (idx !== -1) {
                    draft.splice(idx, 1);
                }
                ref.current = el;
            }));
        }
    }
    return (
        <mark
            className="search-highlight"
            ref={el => setElement(el)}
        >
            {content}
        </mark>
    );
}
