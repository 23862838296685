import * as classnames from 'classnames'
import * as React from 'react'
import { forwardRef, HTMLAttributes } from 'react'
import { SimpleEvents } from 'common/types/eventService'
import { bem, getBemClasses } from 'common/frontend/utils'
import { formatSecondsAsMinutesAndSeconds } from 'common/frontend/formatters'
import './LiveEventBox.scss'
import { OnRemove, ActionDisabled } from '../model'
import Action from './Action'
import Icon from './Icon'
import { BDuration } from '@busby/esb'

export interface LiveEventBoxProps extends HTMLAttributes<HTMLDivElement> {
    liveEvent: SimpleEvents.LiveEvent
    onEdit?: () => void
    onRemove?: OnRemove
}

export function getIconPath(data: any) {
    if (data && typeof data === 'object' && 'iconLocation' in data && 'iconFilename' in data) {
        return `/resources/${data.iconLocation}/liveEventTypeIcons/${data.iconFilename}`
    }
}

export function getIconStyle(data: any) {
    const iconPath = getIconPath(data)
    if (iconPath) {
        return {
            '--icon-path': `url(${iconPath})`
        } as React.CSSProperties
    }
    return {}
}

const LiveEventBox = forwardRef<HTMLDivElement, LiveEventBoxProps>(({
    liveEvent,
    onEdit,
    onRemove,
    className,
    style,
    ...props
}, ref) => {
    const block = bem('live-event-box')
    const hasType = Boolean(liveEvent.type)
    const durationSeconds = BDuration.fromJson(liveEvent.duration)?.getSeconds()
    return (
        <div
            ref={ref}
            {...props}
            className={classnames([
                block.mod({ 'has-type': hasType }),
                className
            ])}
            style={{
                ...getIconStyle(liveEvent.type),
                ...style
            }}
        >
            {!hasType && <Icon name='Edit' className={block.el('icon')}/>}
            <div className={block.el('name')}>
                {liveEvent.name}
            </div>
            {Boolean(durationSeconds && durationSeconds > 0) && (
                <div className={block.el('duration')}>
                    {formatSecondsAsMinutesAndSeconds(durationSeconds)}
                </div>
            )}
            {onEdit && (
                <Action
                    icon="Edit"
                    onClick={() => onEdit?.()}
                />
            )}
            {onRemove && (
                <button
                    className={getBemClasses('action', {
                        trash: true,
                        disabled: onRemove === ActionDisabled
                    })}
                    onClick={onRemove === ActionDisabled ? () => {} : () => onRemove()}
                />
            )}
        </div>
    )
})

export default LiveEventBox
