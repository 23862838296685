import { Form } from "common/frontend/components/Form";
import { DateTimeBlurWidget, TextBlurWidget } from "common/frontend/components/customWidgets";
import { useConfig } from "common/frontend/state";
import { EventServiceSubscriber } from "common/types/eventService";
import { resultOrThrow } from "common/universal/universalUtils";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useRecoilValue } from "recoil";
import { proResourceWidget } from "../components/EventForm";
import { digitalSignageState, useSelectedOrganisation, useUserOrganisations } from "../state";
import "./DigitalSignageAddPage.scss";
const schema: any = {
    type: "object",
    required: ["name", "date", "room"],
    properties: {
        name: {
            type: "string",
        },
        date: {
            type: "string",
        },
        room: {
            type: "object",
            properties: {
                id: {
                    type: "string"
                }
            }
        },
        note: {
            type: "string"
        }
    }
}



export function DigitalSignageAddPage() {
    const [formData, setFormData] = useState({})
    const formRef = useRef<any>()
    const config = useConfig()
    const state = useRecoilValue(digitalSignageState)
    const navigate = useNavigate()
    // const organisations = useUserOrganisations()

    useEffect(() => {
        if (!state.selectedSign) {
            navigate("/digital-signage")
        }
    }, [])

    if (!state.selectedSign) {
        return null;
    } else {
        const add = async () => {
            if (formRef.current && !formRef.current.validateForm()) return

            try {
                const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                const { name, date, room: { id: roomId }, note } = formData as any
                await resultOrThrow(subscriber.control.digitalSignageUpdateEvent({ event: { name, date, roomId, note }, signId: state.selectedSign.id }))
                navigate("/digital-signage")
                toast('Saved', {
                    autoClose: 500,
                    closeButton: false,
                    position: 'bottom-center',
                    className: 'small-toast'
                })
            } catch (e) {
                toast.error(e.message || "Error saving", {
                    autoClose: 5000,
                    closeButton: false,
                    position: 'top-center'
                })
            }
        }

        const uiSchema = {
            name: {
                "ui:title": "Name",
                "ui:widget": TextBlurWidget,
            },
            date: {
                "ui:title": "Date",
                "ui:widget": DateTimeBlurWidget
            },
            room: {
                "ui:title": 'Room',
                "ui:field": 'StringField',
                "ui:widget": proResourceWidget(
                    state.selectedOrganisation.id,
                    'room',
                    {
                        query: {
                            field: 'venue._resourceId',
                            comparator: 'eq',
                            value: state.selectedVenue.id
                        },
                    }
                )
            },
            note: {
                "ui:title": "Note",
                "ui:widget": TextBlurWidget,
            }
        }

        return <div className="digital-signage-add-page relative">
            {/* <Cover center translucent loading enabled={saveEventIsPending} /> */}
            <div style={{ maxWidth: 500, marginBottom: 40 }}>
                <h2>Add event to {state.selectedSign.name}</h2>
                <p>Enter in the details of the event below.</p>
            </div>
            <div className="digital-signage-add-page__form">
                <Form
                    jsonSchema={schema}
                    uiSchema={uiSchema}
                    value={formData}
                    onChange={value => setFormData(value.formData)}
                    theme="wesley"
                    ref={formRef}
                />
                <div className="cols cols--spaced cols--center">
                    <button
                        className="btn"
                        type="submit"
                        onClick={add}
                    >
                        Add
                    </button>
                    <Link to="/digital-signage">
                        <button className='btn btn--secondary'>Cancel</button>
                    </Link>
                </div>
            </div>
        </div>
    }
}