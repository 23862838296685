import * as classnames from 'classnames'
import * as React from 'react'
import {Modal as MantineModal} from "@mantine/core"
import Cover from './Cover'
import './Modal.scss'
import { wesleyDebugNamespace } from 'common/frontend/utils'
import {ModalProps as MantineModalProps} from "@mantine/core"


const debug = wesleyDebugNamespace.extend('modal')

export interface ModalProps extends MantineModalProps {
    isPending?: boolean
    modalBodyClassName?: string
}

export default function Modal({ isPending = false, ...props }: ModalProps) {

    const { className } = props
    let classNameFromProp: string = undefined
    if (typeof className === 'string') {
        classNameFromProp = className
    }
    // set the duration programmatically, as we need to sync closeTimeoutMS
    // with the value used in CSS
    const transitionDuration = 200
    const setOverlayRef = (el: HTMLElement) => {
        if (el) {
            el.style.setProperty('--transition-duration', `${transitionDuration}ms`)
        }
    }

    function afterClose() {
        // We occasionally had the issue that this class would remain on the body even
        // after the modal had been closed. It causes the body to become unscrollable.
        // I couldn't find out exactly why it was happening, but added this potential fix.
        // See https://trello.com/c/pKZunRSx/233-scroll-issue-after-opening-modal
        const bodyClass = 'ReactModal__Body--open'
        if (document.body.classList.contains(bodyClass)) {
            debug('Removing modal open body class manually')
            document.body.classList.remove(bodyClass)
        }
    }

    function onClose() {
        /**
         * Escape key detection implementation in react-modal is not quite right, as it looks at event.code not event.key
         * See https://github.com/reactjs/react-modal/blob/e7c4a63eb5f19dd85d03b83cb25566fafe7b85ae/src/components/ModalPortal.js#L26
         * 
         * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code says:
         *   "To determine what character corresponds with the key event, use the KeyboardEvent.key property instead."
         * 
         * Reported at https://github.com/reactjs/react-modal/issues/1030
         */
        if ('key' in event) {
            if (event.key === 'Escape') {
                props.onClose()
            }
        } else {
            props.onClose()
        }
    }

    return (
        <MantineModal
            {...props}
            p={0}
            withCloseButton={false}
            centered
            onClose={onClose}
            size={"auto"}
            miw={500}
            styles={{
                overlay: {
                    zIndex: 1000
                },
                inner: {
                    zIndex: 1000
                },
                content: {
                    padding: 0,
                    minWidth: 500
                }
            }}
        >
            <MantineModal.Body className={props.modalBodyClassName}>
                <button className="action action--remove" style={{position: "absolute", top: 6, right: 6, zIndex: 100}} onClick={(e) => props.onClose()}/>
                <Cover center translucent loading enabled={isPending} />
                {props.children}
            </MantineModal.Body>
        </MantineModal>
    )
}