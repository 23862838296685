import ProgressSteps from 'common/frontend/components/ProgressSteps'
import * as React from 'react'
import Streaming from 'common/frontend/components/Streaming'
import { usePageTitle, useScreenSize } from 'common/frontend/hooks'
import { useStreamingViewers } from '../state'
import StreamingViewer from '../components/StreamingViewer'

export default function StreamingPage() {
    usePageTitle('Webcast')
    const { isSmallScreen } = useScreenSize()
    return (
        <div className="streaming-page">
            <section className="progress-navigation">
                <ProgressSteps headerOnly/>
            </section>
            <section className="section--grey container-padding">
                {isSmallScreen ? <SmallScreenStreamingList /> : <Streaming />}
            </section>
        </div>
    )
}

export function SmallScreenStreamingList() {
    const viewers = useStreamingViewers()
    return (
        <div className="info-box-list">
            {viewers.map(viewer => (
                <StreamingViewer
                    key={viewer.id}
                    viewer={viewer}
                />
            ))}
        </div>
    )
}