
import AuthSection from 'common/frontend/components/AuthSection'
import Cover from 'common/frontend/components/Cover'
import * as React from 'react'
import { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Form } from 'common/frontend/components/Form'
import { useRequestPasswordResetAction } from '../actions'
import { defaultBackgroundImages } from '../images'

const schema = {
    type: 'object',
    required: [
        'email'
    ],
    properties: {
        email: {
            type: "string",
            format: "email",
            title: "Email"
        },
    }
}

const initialData = {
    email: '',
    password: '',
}

export default function RequestPasswordResetPage() {
    return (
        <AuthSection images={defaultBackgroundImages}>
            <ErrorBoundary FallbackComponent={RequestResetPasswordError}>
                <RequestPasswordResetForm />
            </ErrorBoundary>
        </AuthSection>
    )
}

function RequestResetPasswordError({ resetErrorBoundary }) {
    return (
        <div>
            <h3>Sorry, we couldn't reset your password</h3>
            <button className="btn" onClick={() => resetErrorBoundary()}>OK</button>
        </div>
    )
}

function RequestPasswordResetForm() {
    const [data, setData] = useState(initialData)
    const [submitted, setSubmitted] = useState(false)
    const [requestPasswordReset, isPending] = useRequestPasswordResetAction()

    async function request({ email }) {
        await requestPasswordReset({ email })
        setSubmitted(true)
    }

    return (
        <div className="forgot-password">
            {/* we display it over the top of the old message to keep the size of the box */}
            {submitted && (
                <div className="forgot-password__submitted-message cover">
                    <h1>Check your email</h1>
                    <p>We have sent an email with a link to reset your password.</p>
                    <p className="emphasis">
                        Didn’t receive a link? <a onClick={() => request(data)}>Click here to resend</a>
                    </p>
                </div>
            )}
            <Cover center translucent loading enabled={isPending} />
            <h1>Reset password</h1>
            <p>Enter the email associated with your account and we’ll send an email with instructions to reset your password.</p>
            <Form
                theme="wesley-auth"
                jsonSchema={schema}
                value={data}
                onChange={data => setData(data.formData)}
                onSubmit={() => request(data)}
            >
                <button type="submit" className="btn">Submit</button>
            </Form>
        </div>
    )
}