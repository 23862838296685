import 'common/frontend/index.scss'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { App } from './App'
import './index.scss'
import { MantineProvider } from '@mantine/core'

const setup = () => {
    ReactDOM.render(
        <React.StrictMode>
            <MantineProvider theme={{fontFamily: "Work Sans", lineHeights: {md: "normal"}, fontSizes: {md: "16px"}}}>
                <App />
            </MantineProvider>
        </React.StrictMode>,
        document.getElementById('root')
    )
}
setup()

