import { Filters, ResourceOrderBy, ResourceQuery } from '@busby/esb'
import { UiSchema, WidgetProps } from '@rjsf/utils'
import EventUserAdd from 'common/frontend/components/EventUserAdd'
import { Form } from 'common/frontend/components/Form'
import Modal from 'common/frontend/components/Modal'
import { SelectLoader } from 'common/frontend/components/Select'
import { DateTimeBlurWidget, TextBlurWidget } from 'common/frontend/components/customWidgets'
import { useConfig, useEventFeatures, useEventSource, useIsAdmin, useIsEventSubmitted } from 'common/frontend/state'
import { wesleyDebugNamespace } from 'common/frontend/utils'
import { EventUserInfo, SimpleEvents } from 'common/types/eventService'
import { events } from 'common/types/events'
import { OptionalIdDeep } from 'common/types/typeUtils'
import { JSONSchema7 } from 'json-schema'
import { orderBy, startCase, uniq } from 'lodash'
import { DateTime } from 'luxon'
import * as React from 'react'
import { Suspense, forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { EventParams } from '../model'
import { useMinEventDate, useOrganisationIdForQuery, useProResource } from '../state'
import './EventForm.scss'
import { EventOptionsForm } from './EventOptionsForm'
import OrganisationSelect from './OrganisationSelect'
import ProResourceSelect from './ProResourceSelect'

const debug = wesleyDebugNamespace.extend('event-edit')

export interface ProResourceWidgetOptions<RT extends keyof events> {
    orderBy?: ResourceOrderBy<events[RT]>
    query?: ResourceQuery<events[RT], events, RT>
    filters?: Partial<Filters<events[RT], events>>
}

export function proResourceWidget<RT extends keyof events>(
    organisationId: string,
    resourceType: RT,
    options?: ProResourceWidgetOptions<RT>
) {
    return (props: WidgetProps) => {
        const { id, title, required: requiredOption } = props.options
        const { value, disabled, onChange, required: requiredProp } = props
        const required = Boolean(requiredOption || requiredProp)
        debug('proResourceWidget props', resourceType, props)
        return (
            <Suspense fallback={<SelectLoader />}>
                <ProResourceSelect
                    organisationId={organisationId}
                    resourceType={resourceType}
                    orderBy={options?.orderBy}
                    query={options?.query}
                    filters={options?.filters}
                    value={value}
                    disabled={disabled}
                    required={required}
                    onChange={onChange}
                />
            </Suspense>
        )
    }
}

function OrganisationWidget(props: WidgetProps) {
    const { id, title, required: requiredOption } = props.options
    const { value, disabled, onChange, required: requiredProp } = props
    const required = Boolean(requiredOption || requiredProp)
    return (
        <Suspense fallback={<SelectLoader />}>
            <OrganisationSelect
                value={value}
                onChange={organisation => onChange(organisation)}
                required={required}
                disabled={disabled}
            />
        </Suspense>
    )
}

export interface EventFormProps extends EventParams {
    hideAdminOptions?: boolean
    liveValidate?: boolean
    onChange: (params: EventParams) => Promise<void>
}

const EventForm = forwardRef<void, EventFormProps>(({ event, eventUserInfos, removeEventUsersById = [], liveValidate = false, onChange, hideAdminOptions }, forwardedRef) => {
    const organisationIdForQuery = useOrganisationIdForQuery(event)

    const [addFamilyUsersModalIsOpen, setAddFamilyUsersModalIsOpen] = useState(false)
    const eventFormRef = useRef(null)
    const eventSubmitted = useIsEventSubmitted()
    const eventSource = useEventSource()
    const readOnly = eventSource === "oldSystem" || eventSubmitted
    const { hasStreaming } = useEventFeatures()
    const isAdmin = useIsAdmin()
    const streamingViewerCount = hasStreaming ? event.streamingViewers?.length ?? 0 : 0
    const eventOptionFormRef = useRef<any>()
    const validate = useEventFormValidator(event, organisationIdForQuery, eventSubmitted)
    const config = useConfig()
    const minEventDate = useMinEventDate()
    useImperativeHandle(forwardedRef, () => ({
        checkValidity(): boolean {
            try {
                // This is the underlying HTML validity checker, relies on rsjf having set that stuff..
                // Newer versions of rjsf have eventFormRef.current.formRef.current.validateForm()
                const isValid = eventFormRef.current.validateForm() && eventOptionFormRef.current.checkValidity()

                // const { validate: formValidate } = eventFormRef.current?.props
                // const { formData } = eventFormRef.current?.state
                // if (formValidate && formData) {
                //     // NAUGHTY BIT!
                //     // We peek into the internal state, and use the internal "validate()" function
                //     const { errors } = formValidate(formData)
                //     if (errors.length > 0) {
                //         return false
                //     }
                // }
                // return true
                return Boolean(isValid)
            } catch (err) {
                console.error('error encountered whilst checking validity, assuming invalid', err)
                return false
            }
        },
    }))

    async function setEvent(updatedEvent: OptionalIdDeep<SimpleEvents.Event>) {
        await onChange({
            event: updatedEvent,
            eventUserInfos: eventUserInfos,
            removeEventUsersById
        })
    }

    const [addIsPending, setAddIsPending] = useState(false)

    async function onAdd(newUser: EventUserInfo) {
        setAddIsPending(true)
        try {
            await onChange({
                event,
                eventUserInfos: [...eventUserInfos, newUser],
                removeEventUsersById
            })
            toast("Added user")
        } finally {
            setAddIsPending(false)
        }
    }

    async function onRemove(email: string) {
        // we need to remove ones that already saved on the server
        const userIds: string[] = uniq([
            ...removeEventUsersById,
            ...eventUserInfos
                .filter(eventUserInfo => 'id' in eventUserInfo && eventUserInfo.email === email)
                .map(eventUser => eventUser.id)
        ])
        debug('removing %s', email, userIds)
        await onChange({
            event,
            eventUserInfos: eventUserInfos.filter(eventUserInfo => eventUserInfo.email !== email),
            removeEventUsersById: userIds
        })
    }

    const venue = event?.venue

    const eventDetailsSchema: JSONSchema7 = {
        type: "object",
        required: [
            "organisation",
            "name",
            "date",
            "venue",
            "room",
        ],
        properties: {
            organisation: {
                type: "object",
                properties: {
                    id: {
                        type: "string"
                    }
                }
            },
            name: {
                type: "string"
            },
            venue: {
                type: "object",
                properties: {
                    id: {
                        type: "string"
                    }
                }
            },
            // don't show room field until we have venue selected
            ...(venue ? {
                room: {
                    type: "object",
                    properties: {
                        id: {
                            type: "string"
                        }
                    }
                },
            } : {}),
            date: {
                type: "string",
            },
            ...(!isAdmin && event?.cutOff ? {
                cutOff: {
                    type: "string"
                }
            } : {})
        }
    }

    const eventDetailsUiSchema = useMemo<UiSchema>(() => {
        return {
            organisation: {
                "ui:title": 'Organisation',
                "ui:field": 'StringField',
                "ui:widget": OrganisationWidget,
                "ui:disabled": readOnly,
                "ui:readonly": readOnly,
            },
            name: {
                "ui:title": "Event Name",
                "ui:widget": TextBlurWidget,
                "ui:disabled": readOnly,
                "ui:readonly": readOnly
            },
            date: {
                "ui:title": "Date",
                "ui:widget": DateTimeBlurWidget,
                "ui:disabled": readOnly,
                "ui:readonly": readOnly,
                "ui:options": {
                    min: DateTime.fromISO(minEventDate, { zone: "utc" }).setZone("Europe/London").toFormat("yyyy-MM-dd'T'HH:mm")
                },
                ...(streamingViewerCount > 0 ? {
                    'ui:help': `Email notifications will be sent out to all webcast viewers if the date or time is changed`
                } : {}),
            },
            cutOff: {
                "ui:title": "Cut off",
                "ui:widget": DateTimeBlurWidget,
                "ui:disabled": true,
                "ui:readonly": true
            },
            venue: {
                "ui:title": 'Venue',
                "ui:field": 'StringField',
                // Without this hack it won't update the resource widget when organisation changes...
                "ui:options": { "__hack__": organisationIdForQuery },
                "ui:widget": proResourceWidget(organisationIdForQuery, 'venue', { orderBy: { field: 'name', order: 'asc' } }),
                "ui:disabled": readOnly || !event?.organisation,
                "ui:readonly": readOnly || !event?.organisation,
            },
            ...(venue ? {
                room: {
                    "ui:title": 'Room',

                    // Without this hack it won't update the resource widget when venue changes...
                    "ui:options": { "__hack__": venue.id },

                    "ui:field": 'StringField',
                    "ui:widget": proResourceWidget(
                        organisationIdForQuery,
                        'room',
                        {
                            orderBy: { field: 'name', order: 'asc' },
                            query: {
                                field: 'venue._resourceId',
                                comparator: 'eq',
                                value: venue.id
                            },
                            filters: {
                                resolveLinkTypes: ['capability']
                            },
                        },
                    ),
                    "ui:disabled": readOnly,
                    "ui:readonly": readOnly,
                }
            } : {}),
        }
    }, [venue, organisationIdForQuery, eventSubmitted, streamingViewerCount])

    const adminOptionsSchema = {
        type: "object",
        properties: {
            uiOptions: {
                type: "object",
                properties: {
                    visualTribute: {
                        type: "object",
                        title: "Visual tribute",
                        properties: {
                            serviceUserMode: {
                                type: "string",
                                title: "Service user mode",
                                enum: ["restricted", "full"],
                                enumNames: ["Restricted", "Full"],
                                default: "restricted"
                            },
                            professionalUserMode: {
                                type: "string",
                                title: "Professional user mode",
                                enum: ["restricted", "full"],
                                enumNames: ["Restricted", "Full"],
                                default: "restricted"
                            }
                        }
                    }
                }
            },
            cutOff: {
                title: "Event cut off",
                type: "string",
                format: "date-time",
            }
        }
    }

    const adminUiOptions = {
        uiOptions: {
            "ui:options": {
                "hideTitle": true
            },
            "ui:disabled": readOnly,
            "ui:readonly": readOnly,
        },
        cutOff: {
            "ui:disabled": readOnly,
            "ui:readonly": readOnly,
        }
    }

    const onAdminFormChange = ({ formData }) => {
        console.log("onAdminFormChange")
        setEvent({ ...event, uiOptions: formData.uiOptions, cutOff: formData.cutOff })
    }

    return (
        <div className="event-form">
            {
                !config.disableAddFamilyOption &&
                <div>
                    <h4>Family Users</h4>
                    {orderBy(eventUserInfos, 'createdAt').map((eventUserInfo, index) => (
                        <div
                            key={index}
                            className="info-box"
                            style={{ marginBottom: 20, maxWidth: 500 }}
                        >
                            <div>
                                <div><strong>{eventUserInfo.firstName} {eventUserInfo.lastName}</strong></div>
                                <div>{eventUserInfo.email}</div>
                                {
                                    eventUserInfo.phoneNumber && <div>{eventUserInfo.phoneNumber}</div>
                                }
                            </div>
                            <button className="action action--trash" onClick={() => onRemove(eventUserInfo.email)} />
                        </div>
                    ))}
                    <button
                        className="btn btn--secondary"
                        style={{ width: '100%', maxWidth: 500, marginBottom: 10 }}
                        onClick={() => setAddFamilyUsersModalIsOpen(true)}
                        disabled={readOnly}
                    >
                        Add Family User
                    </button>
                    <Modal
                        opened={addFamilyUsersModalIsOpen}
                        isPending={addIsPending}
                        onClose={() => setAddFamilyUsersModalIsOpen(false)}
                        // isPending={addIsPending || isInviting}
                        closeOnClickOutside={false}
                        closeOnEscape={false}
                    >
                        <EventUserAdd
                            onSave={user => onAdd(user)}
                            onDone={() => setAddFamilyUsersModalIsOpen(false)}
                            existingUserEmails={eventUserInfos.map(user => user.email)}
                            addButtonText={event && 'id' in event ? 'Invite user' : 'Add'}
                        />
                    </Modal>

                </div>
            }
            <div className="cols">
                <div>
                    <h4>Event details</h4>
                    <Form
                        readOnly={readOnly}
                        ref={eventFormRef}
                        jsonSchema={eventDetailsSchema}
                        uiSchema={{
                            ...eventDetailsUiSchema,
                        }}
                        liveValidate={liveValidate}
                        transformErrors={errors => transformErrors(eventDetailsSchema, errors)}
                        value={event}
                        customValidate={validate}
                        onChange={value => setEvent(value.formData)}
                        theme="wesley"
                    />
                    {
                        isAdmin && !hideAdminOptions && <div style={{ marginTop: 50 }}>
                            <h4>Admin options</h4>
                            <Form
                                jsonSchema={adminOptionsSchema}
                                value={{ uiOptions: event.uiOptions, cutOff: event.cutOff }}
                                onChange={onAdminFormChange}
                                uiSchema={adminUiOptions}
                                theme="wesley"
                            />
                        </div>
                    }
                </div>
                <div>
                    <EventOptionsForm
                        ref={eventOptionFormRef}
                        event={event}
                        onChange={event => { console.log("onEventOptionsFOrmChanged"); setEvent(event) }}
                        readOnly={readOnly}
                        liveValidate={liveValidate}
                    />
                </div>
            </div>
        </div>
    )
})

export default EventForm

/**
 * 
 * Create a form validator to do our custom validation
 * 
 * @returns validator function you can pass into FormInput validate prop
 */
function useEventFormValidator(prevFormData: any, organisationId: string, eventSubmitted: boolean) {
    const venueId = prevFormData?.venue?.id

    // ALL the rooms, with embedded venue info
    const rooms = useProResource(organisationId, 'room', {
        query: venueId ? {
            field: 'venue._resourceId',
            comparator: 'eq',
            value: venueId,
        } : {},
        filters: {
            resolveLinkTypes: ['venue']
        }
    })

    /**
     * 
     * Validates if the selected room is in the selected venue
     * This can be briefly mismatched when you change the venue, and the room hasn't updated yet
     * By validating this we prevent an attempt to save the event with an invalid combination
     * 
     * @param formData 
     * @param errors 
     */
    function validateRoomHasCorrectVenue(formData, errors) {
        const roomId = formData?.room?.id
        const venueId = formData?.venue?.id
        const room = roomId ? (rooms?.find(room => roomId === room.id) as SimpleEvents.Room) : null
        const roomVenueId = room?.venue?.id

        if (roomId && venueId !== roomVenueId) {
            errors.room.addError('Invalid room')
        }
    }

    function eventDateInFuture(formData, errors) {
        if (formData.date) {
            const prevDate = DateTime.fromISO(prevFormData.date, { zone: "UTC" })

            const date = DateTime.fromISO(formData.date, { zone: "UTC" })
            const now = DateTime.utc()

            if (date < now && !eventSubmitted && !prevDate.equals(date)) {
                errors.date.addError('Date must be in the future')
            }
        }
    }

    return (formData, errors) => {
        validateRoomHasCorrectVenue(formData, errors)
        eventDateInFuture(formData, errors)
        return errors
    }
}

function transformErrors(schema: JSONSchema7, errors) {
    const newErrors = errors.map(error => {
        if (error.message === 'should be object') {
            // 'should be object' isn't really an error... could just be null
            // doesn't handle object types very well I think...
            const key = error.property.replace(/^\./, '')
            if (schema.required.includes(key)) {
                // it's a required prop
                error.stack = `${startCase(key)} is required`
                return error
            }
            // it's not required
            return {}
        } else if (error.message?.endsWith('must be object')) {
            const key = error.property.replace(/^\./, '')
            if (schema.required.includes(key)) {
                // it's a required prop
                error.stack = `${startCase(key)} is required`
                return error
            }
            // it's not required
            return {}
        } else if (error.stack === 'is a required property') {
            error.stack = 'is required'
        }
        return error
    })
    return newErrors
}
