import * as React from 'react'
import { ILoremIpsumParams, loremIpsum } from 'lorem-ipsum'
import { useMemo } from 'react'

export default function LoremIpsum(props: ILoremIpsumParams) {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: loremIpsum({ ...props, format: 'html' })
            }}
        />
    )
}