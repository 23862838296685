import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { useEventPathTo } from '../eventPaths'
import { progressValue, useEvent, useEventFeatures, useEventSource } from '../state'
import Steps from './Steps'

interface ProgressStepsProps {
    headerOnly?: boolean
}

export default function ProgressSteps({ headerOnly = false }: ProgressStepsProps) {
    const event = useEvent()
    const progress = useRecoilValue(progressValue)
    const to = useEventPathTo()
    const eventSource = useEventSource()
    const {
        hasMusic,
        hasVisuals,
        hasStreaming,
    } = useEventFeatures()

    if (!event) return null

    return (
        <Steps headerOnly={headerOnly}>
            {hasMusic && (
                <Steps.Step
                    title="Music"
                    to={to('music')}
                    done={progress.music.done}
                >
                    <p>Search and schedule music from our vast and versatile library.</p>
                </Steps.Step>
            )}
            {hasVisuals && (
                <Steps.Step
                    title="Visuals"
                    to={to('visuals')}
                    done={progress.visuals.done}
                >
                    <p>Easily create sleek and stylish visuals with your own photos and videos.</p>
                </Steps.Step>
            )}
            {
                eventSource === "newSystem" && <Steps.Step
                    title="Scheduling"
                    to={to('scheduling')}
                    done={progress.scheduling.done}
                >
                    <p>Schedule the media for your service.</p>
                </Steps.Step>
            }
            {hasStreaming && eventSource === "newSystem" && (
                <Steps.Step
                    title="Webcast"
                    to={to('webcast')}
                    done={progress.streaming.done}
                >
                    <p>Share your special day with loved ones who aren't able to attend in person.</p>
                </Steps.Step>
            )}
            <Steps.Step
                title="Review"
                to={to('review')}
                done={progress.review.done}
            >
                <p>View your plans in full, before sending to your venue.</p>
            </Steps.Step>
        </Steps>
    )
}