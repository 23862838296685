import * as React from 'react'

export default function ProgressBar({ loaded, total }: { loaded: number, total: number }) {
    const percentage = loaded * 100 / total
    return (
        <div
            className="progress-bar"
        >
            <div
                className="progress-bar__progress"
                style={{ width: `${percentage}%` }}
            />
        </div>
    )
}