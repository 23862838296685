import { useEventBasePath } from './state'

function to(base: string, path: string) {
    const value = [base, path].filter(Boolean).join('/')
    return value[0] !== '/' ? `/${value}` : value
}

export function useEventPathTo() {
    const base = useEventBasePath()
    return (path: string) => to(base, path)
}