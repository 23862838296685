import * as React from 'react'
import Footer from '../components/Footer'
import { StoriesDefinition } from './StoriesPage'

export default {
    name: 'Footer',
    stories: [
        {
            name: 'Basic',
            component: () => <Footer />
        }
    ]
} as StoriesDefinition