import { Form } from 'common/frontend/components/Form'
import AuthSection from 'common/frontend/components/AuthSection'
import Cover from 'common/frontend/components/Cover'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Link, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { usePasswordResetAction } from '../actions'
import { defaultBackgroundImages } from '../images'

const schema = {
    type: 'object',
    required: [
        'password',
        'confirmPassword',
    ],
    properties: {
        password: {
            type: "string",
            format: "password",
            title: "Password"
        },
        confirmPassword: {
            type: "string",
            format: "password",
            title: "Confirm password",
        },
    }
}

const initialData = {
    password: '',
    confirmPassword: ''
}

export default function ResetPasswordPage() {

    const [searchParams] = useSearchParams()

    const { email, token } = useMemo(() => {
        return {
            email: searchParams.get('email'),
            token: searchParams.get('token')
        }
    }, [searchParams])

    const validLink = Boolean(email && token)

    return (
        <AuthSection images={defaultBackgroundImages}>
            {!validLink ? (
                <div>
                    <h3>Sorry, this link is invalid</h3>
                    <Link to="/forgot-password">Request password reset</Link>
                </div>
            ) : (
                <ErrorBoundary FallbackComponent={ResetPasswordError}>
                    <ResetPasswordForm email={email} token={token} />
                </ErrorBoundary>
            )}
        </AuthSection>
    )
}

function ResetPasswordError() {
    return (
        <div>
            <h3>Sorry, we couldn't reset your password</h3>
            <p>The link may have expired</p>
            <Link to="/forgot-password">Request password reset</Link>
        </div>
    )
}

interface ResetPasswordProps {
    email: string
    token: string
}

function ResetPasswordForm({ email, token }: ResetPasswordProps) {
    const [data, setData] = useState(initialData)

    const [resetPassword, isPending] = usePasswordResetAction()

    function validate(formData, errors) {
        if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword.addError('should match password')
        }
        return errors
    }

    function onSubmit() {
        const { password } = data
        if (!token || !email) {
            toast('Invalid link')
            return
        }
        resetPassword({
            token,
            email,
            newPassword: password
        })
    }
    return (
        <div className="reset-password">
            <h1>Create new password</h1>
            <Form
                theme="wesley-auth"
                jsonSchema={schema}
                value={data}
                onChange={data => setData(data.formData)}
                onSubmit={() => onSubmit()}
                customValidate={validate}
            >
                <button type="submit" className="btn">Reset password</button>
            </Form>
            <Cover center translucent loading enabled={isPending} />
        </div>
    )
}