import ProgressSteps from 'common/frontend/components/ProgressSteps'
import SchedulerViewOnly from 'common/frontend/components/SchedulerViewOnly'
import { usePageTitle } from 'common/frontend/hooks'
import { currentUserState, useEvent, useIsClientPortal } from 'common/frontend/state'
import * as React from 'react'
import { useRecoilValue } from 'recoil'

export default function LandingPage() {
    usePageTitle('Home')
    const currentUser = useRecoilValue(currentUserState)
    const event = useEvent()
    if (!event) return null
    if (!currentUser) return null

    return (
        <div className="landing-page">
            <section>
                <div className="landing-page__welcome">
                    <h1>{event.name}</h1>
                    <div className="cols">
                        <p>Use this site to schedule music and video, arrange webcasts and create visual tributes.</p>
                    </div>
                </div>
                <ProgressSteps />
            </section>
            <section>
                <div style={{ maxWidth: 1000, margin: '0 auto' }}>
                    <SchedulerViewOnly
                        includeTimings
                    />
                </div>
            </section>
        </div>
    )
}