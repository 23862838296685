import { Mutex } from 'async-mutex'
import { useConfig, useEventId, useRefetchEvent, useResetCurrentUser, useResetEvent, useSetCurrentUser, useSetLastSaved } from 'common/frontend/state'
import { TrackInfo, TrackRequest, VisualTributeRequest } from 'common/types/commonTypes'
import { EventServiceSubscriber } from 'common/types/eventService'
import { resultOrThrow } from 'common/universal/universalUtils'
import { useMemo, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { axios } from './utils'

export async function sleep(ms) {
    return new Promise(resolve => {
        setTimeout(() => resolve(null), ms)
    })
}

export function useLogoutAction() {
    const resetCurrentUser = useResetCurrentUser()
    const navigate = useNavigate()
    return useAction(async () => {
        await axios.post('/auth/logout')
        resetCurrentUser()
        navigate('/logout')
        // do a proper reload to clear browser state
        location.pathname = '/login'
    })
}

interface ActionOptions {
    onError?: (error: Error) => void
}

export interface LoginAction {
    email: string
    password: string
}

export function useAddAction() {
    return useEventResourceAction('add')
}

export function useUpdateAction() {
    return useEventResourceAction('update')
}

export function useDeleteAction() {
    return useEventResourceAction('delete')
}

export function useDeleteMultipleAction() {
    return useEventResourceAction('deleteMultiple')
}

export function useLoginAction({ admin }: { admin?: boolean } = {}) {
    const {set} = useSetCurrentUser()
    const resetEvent = useResetEvent()
    return useAction(async ({ email, password }: { email: string, password: string }) => {
        const { user } = (await axios.post('/auth/login', { email, password, admin })).data
        if (user) {
            set(user)
        } else {
            set(null)
        }
        resetEvent() // not sure if we need this or not :)
    }, {
        onError: (e) => {
            console.error(e)
            toast('Login failed')
        }
    })
}

// This was the dummy data version...
// export async function searchMusic({ query }: { query: string }): Promise<WesleyTrackInfo[]> {
//     await sleep(1000); // just to simulate a request
//     const lowercaseQuery = query.toLowerCase()
//     return exampleSongs.songs
//         .filter(song => [song.artist, song.title].some(value => value.toLowerCase().includes(lowercaseQuery)))
//         .slice(0, 30)
//         .map(({ artist, title }) => ({
//             ...makeWesleyTrackInfo(),
//             artist,
//             title
//         }))
// }

export interface RequestPasswordResetParams {
    email: string
}

export function useRequestPasswordResetAction() {
    return useAction(async ({ email }: RequestPasswordResetParams) => {
        await axios.post('/auth/password/request-reset', { email })
    })
}

export interface ChangePasswordParams {
    currentPassword: string
    newPassword: string
}

export function useChangePasswordAction() {
    return useAction(async ({ currentPassword, newPassword }: ChangePasswordParams) => {
        await axios.post('/auth/password/change', { currentPassword, newPassword })
        toast('Your password was changed!')
        // TODO: should we log out and make them log in again?
    }, {
        onError: () => toast('Change password failed')
    })
}

export interface PasswordResetParams {
    email: string
    token: string
    newPassword: string
}

export function usePasswordResetAction() {
    const navigate = useNavigate()
    return useAction(async ({ email, token, newPassword }: PasswordResetParams) => {
        await axios.post('/auth/password/reset', { email, token, newPassword })
        toast('Password was changed!')
        navigate('/login')
    })
}

export interface AcceptInviteParams {
    firstName: string
    lastName: string
    email: string
    password: string
    inviteToken: string
}

export function useAcceptInviteAction() {
    const {set} = useSetCurrentUser()
    const navigate = useNavigate()
    return useAction(async (params: AcceptInviteParams) => {
        const { user } = (await axios.post('/auth/invite/accept', params)).data
        toast('Success!')
        // navigate('/login')
        if (user) {
            set(user)
        } else {
            set(null)
        }
    })
}

/**
 * A library function that can be used to wrap an async action with pending state and error handling
 */
 export function useAction<T, V>(handler: (params: T) => Promise<V>, { onError }: ActionOptions = {}): [(params: T) => Promise<V>, boolean] {
    const [isPending, setIsPending] = useState(false)
    async function action(params: T): Promise<V> {
        try {
            setIsPending(true)
            return await handler(params)
        } catch (error) {
            if (onError) {
                onError(error)
            } else {
                throw error
            }
        } finally {
            // calling this can try to scare us with:
            //   "Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application."
            // But it's wrong in this case, and newer react versions understand better.
            // See https://github.com/facebook/react/pull/22114
            setIsPending(false)
        }
    }
    return [action, isPending]
}

type DropFirst<T extends (...args: any[]) => any> = Parameters<T> extends [any, ...infer U] ? (...args: U) => ReturnType<T> : never
type DropFirstInObject<T extends { [key in string]: (...args: any[]) => any }> = {
    [k in keyof T & string]: DropFirst<T[k]>
}

// keep it simpler by only doing one update at a time
// also ensures the isPending works when doing interleaved modifications
const mutex = new Mutex()

function useEventResourceAction<
    T extends keyof EventServiceSubscriber['eventResource'],
    R = DropFirstInObject<EventServiceSubscriber['eventResource'][T]>
>(
    actionName: T
): [R, boolean] {
    const config = useConfig()
    const eventId = useEventId()
    const handleErrorUsingErrorBoundary = useErrorHandler()
    const [isPending, setIsPending] = useState(false)
    const setLastSaved = useSetLastSaved()

    const action = useMemo(() => new Proxy({}, {
        get: ({ }, prop) => {
            return async (...args: any[]) => {
                try {
                    setIsPending(true)
                    return await mutex.runExclusive(async () => {
                        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                        const result = await subscriber.eventResource[actionName][prop](eventId, ...args)
                        // Note: this does not record which event was saved, which is fine for our current use case
                        setLastSaved(new Date())
                        return result
                    })
                } catch (error) {
                    // TODO: not sure how to do the error handling here right now...
                    // handleErrorUsingErrorBoundary(error)
                    throw error
                } finally {
                    // calling this can try to scare us with:
                    //   "Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application."
                    // But it's wrong in this case, and newer react versions understand better.
                    // See https://github.com/facebook/react/pull/22114
                    setIsPending(false)
                }
            }
        }
    }) as R, [eventId])

    return [action, isPending]
}

export function useRequestTrackAction() {
    const config = useConfig()
    const eventId = useEventId()
    return useAction(async ({ trackRequest, trackInfo }: { trackRequest: TrackRequest, trackInfo?: TrackInfo }) => {
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        return await resultOrThrow(subscriber.control.requestTrack({ eventId, trackRequest, trackInfo }))
    })
}

export function useRequestVisualTributeAction() {
    const config = useConfig()
    const eventId = useEventId()
    return useAction(async ({ visualTributeRequest, visualId }: { visualTributeRequest: VisualTributeRequest, visualId: string }) => {
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        return await resultOrThrow(subscriber.control.requestVisualTribute({ eventId, visualTributeRequest, visualId }))
    })
}

export function useSubmitEventAction() {
    const config = useConfig()
    const eventId = useEventId()
    const refetchEvent = useRefetchEvent()
    return useAction(async () => {
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        await resultOrThrow(subscriber.control.submitEvent({ eventId }))
        refetchEvent()
    })
}

export function useChangeProLogoAction() {
    const config = useConfig()
    return useAction(async ({organisationId, logoFile}: {organisationId: string, logoFile: string}) => {
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        await resultOrThrow(subscriber.control.proSetLogo({ organisationId, logoFile }))
    })
}