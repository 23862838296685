import { useDndMonitor } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import * as classnames from 'classnames'
import { TrackInfo } from 'common/types/commonTypes'
import { SimpleEvents } from 'common/types/eventService'
import * as React from 'react'
import { useResetAudio } from '../audio'
import { getBemClasses, wesleyDebugNamespace } from '../utils'
import { NewItem, useNewItems } from './Scheduler'
import TrackBox from './TrackBox'

export type NewTrackTarget = 'visualMusicItem' | 'scheduleItem'

/**
 *  This is for when you want a list of tracks to drag into something.
 * 
 *  The "something" can be the list of music for a visual, or a schedule.
 */
export default function DraggableNewTracks({ trackInfos, target, enableTrackRequest }: { trackInfos: TrackInfo[], target: NewTrackTarget, enableTrackRequest?: boolean }) {
    const items = useNewItems<TrackInfo, SimpleEvents.ScheduleItemData | SimpleEvents.VisualMusicItemData>(trackInfos, trackInfo => {
        if (target === 'visualMusicItem') {
            return {
		        order: null,
                inTimecode: null,
                outTimecode: null,
                trackInfo,
            }
        } else if (target === 'scheduleItem') {
            return {
                type: 'audio',
                order: null,
                inTimecode: null,
                outTimecode: null,
                loop: false,
                trackInfo,
            }
        } else {
            throw new Error(`unknown target type ${target}`)
        }
    })

    return (
        <>
            {items.map(item => (
                <DraggableNewTrackItem
                    key={item.id}
                    item={item}
                    enableTrackRequest
                />
            ))}
        </>
    )
}

export interface DraggableNewTrackItemProps {
    item: NewItem & (SimpleEvents.ScheduleItemData | SimpleEvents.VisualMusicItemData)
    enableTrackRequest?: boolean
}

function DraggableNewTrackItem({ item, enableTrackRequest }: DraggableNewTrackItemProps) {
    const resetAudio = useResetAudio()
    const {
        attributes,
        listeners,
        setNodeRef,
    } = useSortable({
        id: item.id,
        data: {
            item
        }
    })

    useDndMonitor({
        onDragStart({ active }) {
            if (active.id === item.id) {
                // stop the audio when we start dragging a new track
                // otherwise the audio will keep playing, but become detached
                // from any track box on the page (as the ids are tmp for new items)
                resetAudio(active.id)
            }
        }
    })

    return (
        <TrackBox
            id={item.id}
            ref={setNodeRef}
            track={item.trackInfo}
            enableTrackRequest={enableTrackRequest}
            {...listeners}
            {...attributes}
            className={classnames([
                getBemClasses('track-box', {
                    draggable: true
                })
            ])}
        />
    )
}
