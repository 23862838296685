import * as React from 'react'
import { useEffect, useState } from 'react'
import { useProResourceProperties } from '../state'
import { Select } from 'common/frontend/components/Select'
import { DebounceInput } from 'react-debounce-input'
import { Events } from 'common/types/events'
import { useIsAdminPortal } from 'common/frontend/state'
import ProResourceSelect from './ProResourceSelect'
import OrganisationSelect from './OrganisationSelect'
import { StringResourcePropertyType } from '@busby/esb'

export interface EventFiltersProps {
    organisationId?: string
    venueId?: string
    status?: Events.Event['status'],
    queryString?: string
    after?: string
    before?: string
}

export default function EventFilters({ onChange, children }: { onChange: (eventFilters: EventFiltersProps) => void, children?: React.ReactNode }) {
    const [organisationId, setOrganisationId] = useState(null)
    const [queryString, setQueryString] = useState('')
    const [statusFilter, setStatusFilter] = useState(null)
    const [before, setBefore] = useState('')
    const [after, setAfter] = useState('')
    const [venueFilter, setVenueFilter] = useState(null)
    const isAdminPortal = useIsAdminPortal()

    const eventProperties = useProResourceProperties('event')
    const statusOptions = (eventProperties?.status as StringResourcePropertyType)?.enumOptions?.map(option => ({
        ...option,
        id: option.value,
    }))

    useEffect(() => {
        onChange({
            organisationId,
            venueId: venueFilter?.id,
            queryString,
            status: statusFilter?.value,
            before,
            after,
        })
    }, [organisationId, venueFilter, queryString, statusFilter, before, after])

    return (
        <div className="event-filters">
            <div className="form-input form-input--wesley">
                {isAdminPortal && (
                    <>
                        <fieldset>
                            <legend>Organisation</legend>
                            <OrganisationSelect
                                value={organisationId ? { id: organisationId } : null}
                                onChange={organisation => setOrganisationId(organisation?.id)}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>Venue</legend>
                            <ProResourceSelect
                                resourceType="venue"
                                value={venueFilter}
                                orderBy={{ field: 'name', order: 'asc' }}
                                onChange={setVenueFilter}
                                noOptionLabel="Any"
                            />
                        </fieldset>
                    </>
                )}
                <fieldset>
                    <legend>On or after</legend>
                    <input type="date" value={after} onChange={event => setAfter(event.target.value)}></input>
                </fieldset>
                <fieldset>
                    <legend>Before</legend>
                    <input type="date" value={before} onChange={event => setBefore(event.target.value)}></input>
                </fieldset>
                <fieldset>
                    <legend>Status</legend>
                    <Select
                        value={statusFilter}
                        getDisplayValue={item => item.label}
                        onChange={option => setStatusFilter(option)}
                        options={statusOptions}
                        noOptionLabel="Any"
                    />
                </fieldset>
                <fieldset>
                    <legend>Search</legend>
                    <DebounceInput
                        className="manage-events-page__search-input"
                        value={queryString}
                        debounceTimeout={300}
                        onChange={event => setQueryString(event.target.value)}
                        placeholder=""
                    />
                </fieldset>
                {children}
            </div>
        </div>
    )
}