import * as React from 'react'
import { forwardRef, HTMLAttributes, ReactNode, useEffect, useState } from "react"
import { getBemClasses } from 'common/frontend/utils'
import * as classnames from 'classnames'
import SearchHighlightExists from "./search/SearchHighlightExists"

import './FoldableSection.scss'
import { BDuration } from '@busby/esb'
import { formatDurationAsMinutesAndSeconds } from '../formatters'

export interface FoldableSectionProps extends HTMLAttributes<HTMLDivElement> {
    title: string
    subtitle?: string
    filled?: boolean // "filled" style
    section?: boolean // "section" style
    defaultClosed?: boolean
    open?: boolean // force the open state
    headerOnly?: boolean
    children?: ReactNode
    sectionDuration?: BDuration
}

/**
 * An accordian
 * 
 * It's also *search aware* so it will open if there is highlighted content inside it.
 */
const FoldableSection = forwardRef<HTMLDivElement, FoldableSectionProps>(({
    title,
    subtitle,
    filled,
    section,
    defaultClosed,
    open: forceOpen,
    children,
    className,
    headerOnly = false,
    sectionDuration,
    ...props
}, ref) => {
    const [maybeOpen, setMaybeOpen] = useState(!defaultClosed)
    const [userChoice, setUserChoice] = useState<undefined | boolean>(undefined)
    function getOpen() {
        // if (forceOpen !== undefined) return forceOpen
        if (userChoice !== undefined) return userChoice
        return maybeOpen
    }

    useEffect(() => {
        if (forceOpen !== undefined) {
            setUserChoice(forceOpen)
        }
    }, [forceOpen])

    const open = getOpen()


    return (
        <div
            ref={ref}
            {...props}
            className={classnames([
                className,
                getBemClasses('foldable-section', {
                    open,
                    filled,
                    section,
                    help: (!filled && !section),
                    'header-only': headerOnly
                })
            ])}
        >
            <div
                className="foldable-section__header"
                // onClick={() => setUserChoice(!open)}
            >
                <div className="foldable-section__header__label">
                    <div className="foldable-section__header__label__title">{title}</div>
                    {subtitle && (
                        <div className="foldable-section__header__label__subtitle">{subtitle}</div>
                    )}
                </div>
                {
                    sectionDuration && <div className="foldable-section__header__duration">
                        {formatDurationAsMinutesAndSeconds(sectionDuration)}
                    </div>
                }
                {/* Removed as funerals only have one section */}
                {/* {!headerOnly && (
                    <div className="foldable-section__header__toggle" />
                )} */}
            </div>
            {!headerOnly && (
                <div className="foldable-section__content">
                    <SearchHighlightExists
                        // TODO: if there is a new search this should reset the user intention
                        onExists={() => setMaybeOpen(true)}
                    >
                        {children}
                    </SearchHighlightExists>
                </div>
            )}
        </div>
    )
})

export default FoldableSection