import { useInfiniteQuery, useQueryClient, useQuery, keepPreviousData } from '@tanstack/react-query'
import { useConfig } from 'common/frontend/state'
import { fixEventDateString, resultOrThrow } from 'common/universal/universalUtils'
import { DigitalSignageGetSignInfoParams, DigitalSignageGetSignInfoResult, EventServiceSubscriber } from 'common/types/eventService'
import { useMemo } from 'react'
import { produce } from 'immer'
import { events } from 'common/types/events'
import { Filters, ResourceOrderBy, ResourceQuery } from '@busby/esb'

export const eventsQueryKeyBase = ['events']
export const proResourceQueryKeyBase = ['resource']
export const organisationsQueryKeyBase = ['resource']

export function useRefetchEvents() {
    const queryClient = useQueryClient()
    return async () => {
        await queryClient.refetchQueries({
            queryKey: eventsQueryKeyBase
        })
    }
}

export function useInfiniteEventsQuery({ organisationId, orderBy, query }: Parameters<EventServiceSubscriber['control']['proGetEvents']>[0]) {
    const config = useConfig()
    const size = 30
    const infiniteQuery = useInfiniteQuery({
        queryKey: [...eventsQueryKeyBase, organisationId, { orderBy, query }],
        async queryFn ({ pageParam}) {
            const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
            const result = await resultOrThrow(subscriber.control.proGetEvents({
                organisationId,
                orderBy,
                query,
                size,
                offset: pageParam
            }))
            return produce(result, draft => {
                for (const event of draft.events) {
                    event.date = fixEventDateString(event.date)
                }
            })
        },
        // next page param is based on size/offset params
        getNextPageParam (page: any, pages: any) {
            if (!page.hasMoreRows) return undefined
            // total count of all loaded events, next page is the ones after this...
            return pages.reduce((sum, page) => sum + page.events.length, 0)
        },
        // in the results for only want the events themselves, don't need the paging metadata
        select({ pages, pageParams }) {
            return {
                pages: [...pages.map(page => page.events)],
                pageParams: [...pageParams],
            }
        },
        // means it doesn't flash empty when we change the query params, goes seamlessly to the next set of results
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
        initialPageParam: 0
    })
    const events = useMemo(() => infiniteQuery.data?.pages?.flat() ?? [], [infiniteQuery])
    return {
        ...infiniteQuery,
        events
    }
}

export function useSignInfoQuery({ id, date }: DigitalSignageGetSignInfoParams) {
    const config: any = useConfig()
    const query = useQuery<DigitalSignageGetSignInfoResult>({
        enabled: Boolean(id),
        queryKey: ['sign-id', id, date],
        queryFn: async () => {
            const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
            const result = await resultOrThrow(subscriber.control.digitalSignageGetSignInfo({id, date, includeOmittedEvents: true}))
            return result;
        },
        retry: false,
        refetchOnWindowFocus: false
    })
    
    return {
        ...query,
        signInfo: query.data?.sign
    }
}

export interface ProResourceQueryParams<RT extends keyof events> {
    organisationId: string
    venueId?: string
    resourceType: RT
    orderBy?: ResourceOrderBy<events[RT]>
    query?: ResourceQuery<events[RT], events, RT>
    filters?: Partial<Filters<events[RT], events>>
}

export function useInfiniteProResourceQuery<RT extends keyof events>({ organisationId, venueId, resourceType, orderBy, query = {}, filters = {} }: ProResourceQueryParams<RT>) {
    const config = useConfig()
    const size = 30
    const infiniteQuery = useInfiniteQuery({
        queryKey: [...proResourceQueryKeyBase, organisationId, venueId, resourceType, { orderBy, query, filters }],
        async queryFn ({ pageParam}) {
            const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
            return await resultOrThrow(subscriber.control.proQueryResources({
                resourceType,
                organisationId,
                venueId,
                // @ts-expect-error
                orderBy,
                // @ts-expect-error
                query,
                // @ts-expect-error
                filters,
                size,
                offset: pageParam
            }))
        },
        // next page param is based on size/offset params
        getNextPageParam (page: any, pages: any) {
            if (!page.hasMoreRows) return undefined
            // total count of all loaded resources, next page is the ones after this...
            return pages.reduce((sum, page) => sum + page.resources.length, 0)
        },
        // in the results for only want the events themselves, don't need the paging metadata
        select({ pages, pageParams }) {
            return {
                pages: [...pages.map(page => page.resources)],
                pageParams: [...pageParams],
            }
        },
        // means it doesn't flash empty when we change the query params, goes seamlessly to the next set of results
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
        initialPageParam: 0
    })
    const resources = useMemo(() => infiniteQuery.data?.pages?.flat() ?? [], [infiniteQuery])
    return {
        ...infiniteQuery,
        resources
    }
}

export interface ProResourceOrganisationsParams {
    query?: string
    type?: "venue" | "organiser"
    size?: number
}

export function useInfiniteOrganisationsQuery({ query, type, size = 30 }: ProResourceOrganisationsParams) {
    const config = useConfig()
    const infiniteQuery = useInfiniteQuery({
        queryKey: [...organisationsQueryKeyBase, { query, type }],
        async queryFn ({ pageParam}) {
            const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
            return await resultOrThrow(subscriber.control.proQueryOrganisations({
                query,
                type,
                size,
                offset: pageParam
            }))
        },
        // next page param is based on size/offset params
        getNextPageParam (page: any, pages: any) {
            if (!page.hasMoreRows) return undefined
            // total count of all loaded resources, next page is the ones after this...
            return pages.reduce((sum, page) => sum + page.organisations.length, 0)
        },
        // in the results for only want the events themselves, don't need the paging metadata
        select({ pages, pageParams }) {
            return {
                pages: [...pages.map(page => page.organisations)],
                pageParams: [...pageParams],
            }
        },
        // means it doesn't flash empty when we change the query params, goes seamlessly to the next set of results
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
        initialPageParam: 0
    })
    const organisations = useMemo(() => infiniteQuery.data?.pages?.flat() ?? [], [infiniteQuery])
    return {
        ...infiniteQuery,
        organisations
    }
}