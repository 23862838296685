import { defaultDropAnimationSideEffects, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, DropAnimation, useDndMonitor } from '@dnd-kit/core'
import { SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import * as classnames from 'classnames'
import { BDateTimeJson } from '@busby/esb'
import { SimpleEvents } from 'common/types/eventService'
import { produce, current } from 'immer'
import { isEqual, sortBy } from 'lodash'
import * as React from 'react'
import { forwardRef, HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import { useAddAction, useDeleteAction, useUpdateAction } from '../actions'
import { useItemMarkers } from '../hooks'
import { ActionDisabled, InOutMarkers, OnMarkersChange, OnRemove, SetUsingUpdater, Updater } from '../model'
import { useRefetchEvent } from '../state'
import { getBemClasses, ImmerRecipe, safeGenerateKeyBetween, wesleyDebugNamespace } from '../utils'
import { noSortingStrategy, useNewItems } from './Scheduler'
import './SchedulerNext.scss'
import TrackBox from './TrackBox'

const debug = wesleyDebugNamespace.extend('scheduler')

export interface NewMusicItem extends SimpleEvents.VisualMusicItemData {
    id: string // this is a temporary id, it will be replaced with the saved one afterwards
    isNew: true
}

export type SchedulerItem = SimpleEvents.VisualMusicItem | NewMusicItem

export interface SchedulerForVisualProps {
    visual: SimpleEvents.Visual
    setVisual: SetUsingUpdater<SimpleEvents.Visual>
    readOnly?: boolean
}

const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        className: {
            active: 'dropping'
        }
    }),
}

export default function SchedulerForVisual({ visual, setVisual, readOnly }: SchedulerForVisualProps) {
    const refetchEvent = useRefetchEvent()

    const savedItems = visual.musicItems
    const [localItems, setLocalItems] = useState<SchedulerItem[]>([])

    // this keeps the our local data in sync with the saved data
    useEffect(() => updateLocalItems(savedItems), [savedItems])

    function updateLocalItems(newItems: SimpleEvents.VisualMusicItem[]) {
        // when we update local data from incoming saved data
        // we need to preserve two kinds of local change:
        // 1. items we are adding, which won't be present
        // 2. items we are deleting, which we should remove
        setLocalItems(localItems => {
            const preserve = localItems.filter(item => addingIds.current[item.id])
            newItems = newItems.filter(item => !deletingIds.current[item.id])
            if (preserve.length > 0) {
                newItems = [...newItems, ...preserve]
            }
            return sortBy(newItems, 'order')
        })
    }

    function saveItems(updater: Updater<SimpleEvents.VisualMusicItem[]>) {
        setVisual(produce(draft => {
            const newMusicItems = updater(current(draft.musicItems))
            updateLocalItems(newMusicItems)
            draft.musicItems = newMusicItems
        }))
    }

    const [add] = useAddAction()
    const [update] = useUpdateAction()
    const [deleteAction] = useDeleteAction()

    // if the user clicks delete quickly we can have two events for the same id
    // this stores the in-progress deletes, so we can avoid that
    const deletingIds = useRef<{ [key: string]: boolean }>({})

    // when updating from saved, we need to preserve new items
    // any ids added here will be kept when updating our local items
    const addingIds = useRef<{ [key: string]: boolean }>({})

    const [activeItem, setActiveItem] = useState<SchedulerItem>(null)

    function onDragStart({ active }: DragStartEvent) {
        setActiveItem(active.data.current.item)
    }

    function onDragCancel() {
        setActiveItem(null)
    }

    function findItem(items: SchedulerItem[], id: string) {
        return items.find(item => item.id === id)
    }

    const onDragEnd = useCallback(async ({ active }: DragEndEvent) => {
        setActiveItem(null)
        const activeId = String(active.id)
        if (!activeId) {
            return
        }
        const item = findItem(localItems, activeId)
        if (!item) item

        try {
            if ('isNew' in item) {
                const { isNew, id, ...data } = item
                const { resource: addedItem } = await add.visualMusicItem(data, visual.id)
                saveItems(produce(draft => {
                    return sortBy([...draft, addedItem], 'order')
                }))
            } else {
                const savedItem = findItem(savedItems, item.id)
                if (savedItem.order === item.order) {
                    return
                }
                const { resource: updatedItem } = await update.visualMusicItem(item)
                saveItems(produce(draft => {
                    const item = findItem(draft, updatedItem.id)
                    if (!item) {
                        // don't worry too much if we can't find it...
                        return
                    }
                    return sortBy([
                        ...draft.filter(i => i.id !== updatedItem.id),
                        updatedItem
                    ], 'order')
                }))
            }
        } catch (error) {
            refetchEvent()
        }
    }, [savedItems, localItems])

    const onDragOver = useCallback(async ({ active, over }: DragOverEvent) => {
        const activeId = String(active.id)
        const overId = over?.id
        if (overId == null) {
            return
        }
        if (activeId === overId) {
            return
        }

        setLocalItems(produce(draft => {
            const { item: activeItem } = active.data.current
            const activeIndex = draft.findIndex(item => item.id === activeId)
            const existingItem = draft[activeIndex]
            const overItem = findItem(draft, String(overId))

            let newIndex: number
            if (overId === 'tracks') {
                newIndex = draft.length
            } else if (overItem) {
                const overIndex = draft.findIndex(item => item.id === overId)
                const isBelowOverItem =
                    over &&
                    active.rect.current.translated &&
                    active.rect.current.translated.top >
                    over.rect.top + over.rect.height

                const modifier = isBelowOverItem ? 1 : 0
                newIndex = overIndex >= 0 ? overIndex + modifier : draft.length + 1
            } else {
                // not over the tracks section or an existing item...
                return
            }

            if (activeIndex === newIndex) {
                return
            }

            if (existingItem) {
                // remove it from where it currently is
                draft.splice(activeIndex, 1)
            }

            // ...and insert it where it needs to go
            const orderBefore = draft[newIndex - 1]?.order
            let orderAfter = draft[newIndex]?.order

            const order = safeGenerateKeyBetween(orderBefore, orderAfter)

            draft.splice(newIndex, 0, {
                ...activeItem,
                order
            })
        }))
    }, [])

    // Something you can pass when you are updating an item, which is contained
    // within sections, does the work of finding the item, and ignoring if it's not
    // present...
    const updateItem = (itemId: string, updater: ImmerRecipe<SchedulerItem>) => {
        return produce<SchedulerItem[]>(draft => {
            const item = findItem(draft, itemId)
            if (!item) return
            updater(item)
        })
    }

    const onMarkersChange = useCallback(async (itemId: string, markers: InOutMarkers) => {
        if (deletingIds.current[itemId]) return
        const item = findItem(localItems, itemId)
        if (!item) {
            return
        }
        const currentMarkers = [item.inTimecode?.time, item.outTimecode?.time]
        if (isEqual(currentMarkers, markers)) {
            debug('no change in markers!')
            return
        }
        const inTimecode: BDateTimeJson = {
            frameRate: 'milli',
            time: markers[0]
        }
        const outTimecode: BDateTimeJson = {
            frameRate: 'milli',
            time: markers[1]
        }
        // optimistic update
        setLocalItems(updateItem(itemId, draft => {
            Object.assign(draft, { inTimecode, outTimecode })
        }))
        const { resource: updatedItem } = await update.visualMusicItem({
            id: item.id,
            inTimecode,
            outTimecode
        })
        saveItems(updateItem(itemId, draft => {
            const { inTimecode, outTimecode } = updatedItem
            Object.assign(draft, { inTimecode, outTimecode })
        }))
    }, [localItems])

    const onRemove = useCallback(async itemId => {
        if (deletingIds.current[itemId]) return
        const item = findItem(localItems, itemId)
        if (!item) {
            return
        }
        deletingIds.current[itemId] = true

        // remove it immediately locally
        setLocalItems(produce(draft => draft.filter(item => item.id !== itemId)))
        try {
            await deleteAction.visualMusicItem(itemId)
            saveItems(produce(draft => {
                const item = findItem(draft, itemId)
                if (!item) {
                    // if we don't find it, it *could* have been subsequently removed
                    // it doesn't really matter, we were trying to get rid of it anyway
                    return
                }
                return draft.filter(item => item.id !== itemId)
            }))
        } catch (error) {
            debug('delete error %s %o', itemId, error)
            refetchEvent()
        }
        requestAnimationFrame(() => {
            delete deletingIds.current[itemId]
        })
    }, [localItems])

    useDndMonitor({
        onDragStart,
        onDragCancel,
        onDragOver,
        onDragEnd
    })

    return (
        <div>
            <SortableContext
                items={localItems}
                strategy={noSortingStrategy}
                disabled={readOnly}
            >
                <DroppableArea id="tracks">
                    {localItems.map((item, index, items) => (
                        <SortableMusicItem
                            key={item.id}
                            item={item}
                            nextItem={items[index + 1]}
                            readOnly={readOnly}
                            onRemove={() => onRemove(item.id)}
                            onMarkersChange={markers => onMarkersChange(item.id, markers)}
                        />
                    ))}
                </DroppableArea>
            </SortableContext>
            <DragOverlay
                dropAnimation={dropAnimation}
            >
                {activeItem && (
                    <MusicItem
                        item={activeItem}
                        className="dragging-overlay"
                    />
                )}
            </DragOverlay>
        </div>
    )
}

export interface DroppableAreaProps {
    id: string
    children: React.ReactNode
}

export function DroppableArea({
    id,
    children
}: DroppableAreaProps) {
    const {
        setNodeRef
    } = useSortable({
        id
    })

    return (
        <div ref={setNodeRef} style={{ minHeight: 80 }}>
            {children}
        </div>
    )
}

export interface SortableMusicItemProps {
    item: SchedulerItem
    nextItem?: SchedulerItem
    onRemove?: () => {}
    onMarkersChange?: OnMarkersChange
    readOnly?: boolean
}

export function SortableMusicItem({
    item,
    nextItem,
    readOnly,
    onRemove,
    onMarkersChange
}: SortableMusicItemProps) {
    const isNew = 'isNew' in item
    const {
        attributes,
        setNodeRef,
        isDragging,
        listeners,
        transform,
        transition
    } = useSortable({
        id: item.id,
        data: {
            item
        },
        disabled: isNew || readOnly // you cannot drag items until they are saved
    })

    const style = {
        // note using CSS.Translate here not CSS.Transform
        // this is to only do the moving, not the stretching, or something like that...
        transform: CSS.Translate.toString(transform),
        transition,
    }

    return (
        <MusicItem
            className={classnames([
                isDragging && 'dragging'
            ])}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={style}
            item={item}
            nextItem={nextItem}
            onRemove={isNew ? ActionDisabled : onRemove}
            onMarkersChange={onMarkersChange}
        />
    )
}

export interface MusicItemProps extends HTMLAttributes<HTMLDivElement> {
    item: SchedulerItem
    nextItem?: SchedulerItem
    onRemove?: OnRemove
    onMarkersChange?: OnMarkersChange
}

export const MusicItem = forwardRef<HTMLDivElement, MusicItemProps>(({ item, nextItem, className, onMarkersChange, ...props }, ref) => {
    const markers = useItemMarkers(item)
    return (
        <TrackBox
            id={item.id}
            track={item.trackInfo}
            ref={ref}
            className={classnames([
                'draggable',
                className
            ])}
            linkedToNext={Boolean(nextItem)}
            markers={markers}
            onMarkersChange={onMarkersChange}
            {...props}
        />
    )
})

export function useNewMusicItems<T>(entries: T[], map: (entry: T) => SimpleEvents.VisualMusicItemData) {
    return useNewItems<T, SimpleEvents.VisualMusicItemData>(entries, map)
}

export interface NewMusicItemDraggableProps {
    item: NewMusicItem
}

export function NewMusicItemDraggable({ item }: NewMusicItemDraggableProps) {
    const {
        attributes,
        listeners,
        setNodeRef,
    } = useSortable({
        id: item.id,
        data: {
            item
        }
    })

    return (
        <TrackBox
            id={item.id}
            ref={setNodeRef}
            track={item.trackInfo}
            {...listeners}
            {...attributes}
            className={classnames([
                getBemClasses('track-box', {
                    draggable: true
                })
            ])}
        />
    )
}