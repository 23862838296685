import { BDuration } from "@busby/esb";
import { Tab } from '@headlessui/react';
import { ModalProps } from "@mantine/core";
import { Project } from "@motion-canvas/core";
import { getDefaultFormState } from "@rjsf/utils";
import validator from '@rjsf/validator-ajv8';
import { SimpleEvents } from "common/types/eventService";
import { filter, find, isEmpty, map } from "lodash";
import * as React from "react";
import { useRef, useState } from "react";
import { bem, getBemClasses, wesleyDebugNamespace } from "../../utils";
import Cover from "../Cover";
import MediaDropzone from "../MediaDropzone";
import Modal from '../Modal';
import { VisualComponentImage } from "./VisualComponentImage";
import { VisualPlayerApi } from "./VisualPlayer";
import { TemplateDescription, templates } from './templates/templates';
import { getVisualProject } from "./VisualUtils";
const debug = wesleyDebugNamespace.extend('media-grid')

export interface VisualAddComponentModalProps {
    onDone: (components: { component: SimpleEvents.VisualComponent, file: File }[]) => void
    types: ('image' | 'video')[]
    multiple?: boolean
    eventId: string
    upload: (files: File[]) => void
    template: string
    aspectRatio: "16/9" | "9/16"
}

let nextUploadId = 0
export function VisualAddComponentModal({ onDone, types, multiple = true, eventId, onClose, template, aspectRatio, ...props }: VisualAddComponentModalProps & Omit<ModalProps, 'className'>) {

    const [files, setFiles] = useState<{ file: File, cacheUrl: string }[]>([])
    const [slideType, setSlideType] = useState<string>()
    const [selectedTab, setSelectedTab] = useState<"media" | "text">("media")
    const [selectedComponent, setSelectedComponent] = useState<number>(0)

    const components: SimpleEvents.VisualComponent[] = map(files, file => {
        const { cacheUrl, file: { type } } = file
        const component: SimpleEvents.VisualComponent = {
            id: "",
            filename: cacheUrl,
            location: "",
            order: "",
            type: type.split("/")[0] as ("image" | "video")
        }
        return component
    })


    const done = async () => {
        const project: Project = getVisualProject(aspectRatio, template)
        const templateDescription: TemplateDescription = project.variables.templateDescription as TemplateDescription;
        const slide = find(templateDescription.slideTypes, { type: slideType })
        let captions = {}
        if (slide.metadataForm) {
            captions = getDefaultFormState(validator, slide.metadataForm.schema)
        }
        let newComponents = [];

        if (selectedTab === "media") {
            for (const { file } of files) {
                const newComponent: any = {
                    type: file.type.split("/")[0],
                    captions,
                    slideType,
                    uploadStatus: "uploading"
                }

                newComponents.push({
                    component: newComponent,
                    file
                })
            }
        } else if (selectedTab === "text") {
            newComponents = [{
                component: {
                    type: "text",
                    captions,
                    slideType
                }
            }]
        }
        onDone(newComponents)
    }

    const onDrop = async (files: File[]) => {
        const result = []
        for (let file of files) {
            result.push({ file, cacheUrl: URL.createObjectURL(file) })
        }

        setFiles(result)
    }

    const setTab = (index: number) => {
        setSlideType(undefined)
        setSelectedTab(index === 0 ? "media" : "text")
    }


    const project: Project = getVisualProject(aspectRatio, template)
    const templateDescription: TemplateDescription = project.variables.templateDescription as TemplateDescription
    const hasMediaSlide = !isEmpty(filter(templateDescription.slideTypes, v => !!v.media))
    const hasTextSlide = !isEmpty(filter(templateDescription.slideTypes, v => !v.media))
    return <Modal onClose={onClose} {...props}>
        <div className="visual-component-add">
            <Tab.Group as="div" className="tabs relative" onChange={(i: number) => setTab(i)}>
                <div className="visual-component-add__header cols cols--center">
                    <Tab.List className="tabs__tabs">
                        {
                            hasMediaSlide && <Tab as="div">Media Slide</Tab>
                        }
                        {
                            hasTextSlide && <Tab as="div">Text Slide</Tab>
                        }
                    </Tab.List>
                </div>
                <Tab.Panels className="tabs__panels">
                    {
                        hasMediaSlide && <Tab.Panel className="pad">
                            {
                                !isEmpty(components) ? <div>
                                    <div className="visual-component-add__images">
                                        {
                                            components.map((component, idx) => <div onClick={() => setSelectedComponent(idx)}>
                                                <VisualAddComponentViewer eventId={eventId} component={component} template={template} selected={idx === selectedComponent} />
                                            </div>
                                            )
                                        }
                                    </div>
                                    <hr />
                                    <div className="visual-component-add__templates__title">Select a slide style</div>
                                    <VisualAddTemplates type="media" eventId={eventId} component={components[selectedComponent]} selectedSlideType={slideType} setSlideType={setSlideType} template={template} aspectRatio={aspectRatio}/>
                                </div>
                                    : <MediaDropzone types={types} multiple={multiple} onDrop={onDrop} />
                            }
                        </Tab.Panel>
                    }
                    {
                        hasTextSlide && <Tab.Panel className="pad">
                            <VisualAddTemplates type="text" eventId={eventId} component={components[selectedComponent]} selectedSlideType={slideType} setSlideType={setSlideType} template={template} aspectRatio={aspectRatio}/>
                        </Tab.Panel>
                    }
                </Tab.Panels>
                {
                    <div className="visual-entry-edit__actions">
                        <button
                            className="btn"
                            disabled={!slideType}
                            onClick={done}
                        >
                            Done
                        </button>
                        <button className="btn btn--secondary" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                }
            </Tab.Group>
        </div>
    </Modal>
}

interface VisualAddComponentViewerProps {
    eventId: string
    component: SimpleEvents.VisualComponent
    template: string
    selected: boolean
}

//Shown directly after the user has uploaded a file, where they select a template
function VisualAddComponentViewer({ eventId, component, template, selected }: VisualAddComponentViewerProps) {
    return <div className={getBemClasses("visual-component-add__viewer", { selected })}>
        <Cover center transparent>
            <div className="visual-component-add__viewer__image" style={{ display: "flex" }}>
                <VisualComponentImage
                    component={component}
                    aspectRatio={"4/3" as any}
                    eventId={eventId}
                    background="#f2f2f2"
                    objectFit="fit"
                    height={200}
                    template="visualComponentPreview"
                />
            </div>
        </Cover>
    </div >
}

interface VisualAddTemplatesProps {
    template: string
    component: SimpleEvents.VisualComponent
    eventId,
    setSlideType: (slideType: string) => void
    selectedSlideType: string
    type: "text" | "media"
    aspectRatio: "16/9" | "9/16"
}

function VisualAddTemplates({ type, template, component, eventId, setSlideType, selectedSlideType, aspectRatio }: VisualAddTemplatesProps) {
    const project: Project = getVisualProject(aspectRatio, template)
    const templateDescription: TemplateDescription = project.variables.templateDescription as TemplateDescription
    
    return <div className="visual-add-templates" style={{gridTemplateRows: `repeat(auto-fill, ${aspectRatio === "16/9" ? 180 : 320}px)`, gridTemplateColumns: `repeat(auto-fill, ${aspectRatio === "16/9" ? 320 : 180}px)`}}>
        {
            map(templateDescription.slideTypes, v => {
                if ((type === "media" && !isEmpty(v.media)) || (type === "text" && isEmpty(v.media))) {
                    let captions = {}
                    if (v.metadataForm) {
                        captions = getDefaultFormState(validator, v.metadataForm.schema)
                    }
                    const templateComponent = { ...component, slideType: v.type, captions }
                    const className = bem("visual-add-templates__template").mod({ selected: v.type === selectedSlideType })
                    return <div className={className} onClick={() => setSlideType(v.type)}>
                        <VisualComponentImage
                            component={templateComponent}
                            aspectRatio={aspectRatio}
                            eventId={eventId}
                            contentQuality="preview"
                            // onLoad={() => setMediaLoaded(true)}
                            // onError={err => setMediaError(err)}
                            template={template}
                        // fadeDuration={fadeDuration}
                        // slideDuration={slideDuration}

                        />
                        {/* <VisualPlayer ref={playerRef} eventId={eventId} components={[templateComponent]} slideDuration={slideDuration} fadeDuration={fadeDuration} metadata={captions} template={template} contentQuality="preview"/> */}
                    </div>
                }
            })
        }
    </div>
}
