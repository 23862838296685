export const funeralDarkImages = []

// Wildcard import for images in a directory
// See https://webpack.js.org/guides/dependency-management/#context-module-api  
importAll(require.context('../assets/funeral-dark', true, /\.jpg$/))
function importAll(r) {
    r.keys().forEach(key => {
        const image = r(key)
        funeralDarkImages.push(image)
    })
}

export const defaultBackgroundImages = funeralDarkImages