import CheckboxWidget from '@rjsf/core/lib/components/widgets/CheckboxWidget'
import { useAcceptInviteAction } from 'common/frontend/actions'
import AuthSection from 'common/frontend/components/AuthSection'
import { useQueryParams } from 'common/frontend/hooks'
import { axios } from 'common/frontend/utils'
import * as React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAsyncEffect } from 'use-async-effect'
import { Form } from 'common/frontend/components/Form'
import { defaultBackgroundImages } from 'common/frontend/images'

const schema = {
    type: 'object',
    required: [
        'firstName',
        'lastName',
        'email',
        'password',
        'confirmPassword',
        'agree'
    ],
    properties: {
        firstName: {
            type: 'string',
            title: 'First name'
        },
        lastName: {
            type: 'string',
            title: 'Last name'
        },
        email: {
            type: "string",
            format: "email",
            title: "Email"
        },
        password: {
            type: "string",
            format: "password",
            title: "Password"
        },
        confirmPassword: {
            type: "string",
            format: "password",
            title: "Confirm password"
        },
        agree: {
            string: "Agree to terms and conditions",
            type: "boolean"
        }
    }
}

const uiSchema = {
    email: {
        "ui:disabled": true
    },
    agree: {
        "ui:label": false,
        "ui:widget": props => {
            // Wrapping the widget so we can use JSX in the label (otherwise it's just a string)
            return <CheckboxWidget
                {...props}
                label={(
                    <span>I confirm that I have read, understood and agree to Wesley Event’s <Link to="/terms">terms & conditions</Link>.</span>
                )}
            />
        }
    }
}

const initialData = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agree: false,
}

export default function AcceptInvitePage() {
    const [data, setData] = useState(initialData)
    const { email, token } = useQueryParams()
    const [inviteIsPending, setInviteIsPending] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false)

    const [acceptInvite, acceptIsPending] = useAcceptInviteAction()

    const isPending = inviteIsPending || acceptIsPending

    function validate(formData, errors) {
        if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword.addError('should match password')
        }
        if (!formData.agree) {
            errors.agree.addError('Please confirm you accept')
        }
        return errors
    }

    useAsyncEffect(async () => {
        if (!email || !token) {
            setIsInvalid(true)
            return
        }
        setInviteIsPending(true)
        try {
            const inviteData = (await axios.get('/auth/invite', { params: { email, token } })).data
            // Merge it into existing data
            setData(data => Object.assign({}, data, inviteData))
        } catch (error) {
            if (is404(error)) {
                setIsInvalid(true)
            } else {
                throw error
            }
        } finally {
            setInviteIsPending(false)
        }
    }, [email, token])

    async function onSubmit() {
        const { firstName, lastName, email, password } = data
        await acceptInvite({ firstName, lastName, email, inviteToken: token, password })
    }

    return (
        <AuthSection images={defaultBackgroundImages} isPending={isPending}>
            {isInvalid && (
                <h3>Sorry, this invite link is not valid</h3>
            )}
            {!isInvalid && (
                <>
                    <h1>Sign up</h1>
                    <p>You have been invited you to join Wesley Media. Please fill out the form below.</p>
                    <Form
                        theme="wesley-auth"
                        jsonSchema={schema}
                        uiSchema={uiSchema}
                        value={{ ...data, email }}
                        onChange={data => setData(data.formData)}
                        customValidate={validate}
                        onSubmit={() => onSubmit()}
                    >
                        <button type="submit" className="btn">Sign up</button>
                        <p className="emphasis">Already have an account? <Link to="/login">Log in</Link></p>
                    </Form>
                </>
            )}
        </AuthSection>
    )
}

function is404(error: any): boolean {
    return error && 'isAxiosError' in error && error.isAxiosError && error.response.status === 404
}