import * as React from 'react'
import { Switch } from '@headlessui/react'
import { bem } from '../utils'
import './Toggle.scss'
import * as classnames from 'classnames'

export interface ToggleProps {
    checked: boolean
    onChange: (checked: boolean) => void
    label: string,
    className?: string
    style?: React.CSSProperties
}

export function Toggle({ checked, onChange, label, className, style }: ToggleProps) {
    const block = bem('toggle')
    const switchEl = block.element('switch')
    const handleEl = switchEl.element('handle')
    return (
        <label className={classnames([block.mod({ checked }), className])} style={style}>
            <Switch
                checked={checked}
                onChange={onChange}
                className={switchEl.mod({ checked })}
            >
                <div className={handleEl.mod({ checked })} />
            </Switch>
            {label}
        </label>
    )
}