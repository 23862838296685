import * as React from 'react'
import LiveEventBox from '../components/LiveEventBox'
import { StoriesDefinition } from './StoriesPage'

const stories: StoriesDefinition = {
    name: 'EventBox',
    stories: [
        {
            name: 'Default',
            component: () => {
                return (
                    <LiveEventBox
                        liveEvent={{
                            id: '1234',
                            name: 'some event',
                            type: {
                                id: '123',
                                name: 'example',
                                iconLocation: null,
                                iconFilename: null
                            },
                            duration: {
                                duration: 123,
                                frameRate: 'milli'
                            }
                        }}
                    />
                )
            }
        }
    ]
}

export default stories