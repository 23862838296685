import * as React from 'react'
import { Link } from 'react-router-dom'
import { LogoLink } from './Logo'
import SocialMediaLinks from './SocialMediaLinks'

import './Footer.scss'
import { useConfig } from '../state'
import * as urlJoin from "url-join"
export default function Footer() {
    const {baseUrl} = useConfig()
    
    return (
        <div className="footer">
            <div className="footer__panels">
                <LogoLink />
                <div className="footer__section">
                    <Link to="/login">Log in</Link>
                    <a target='_blank' href={urlJoin(baseUrl.wesleyMedia, "/#contact-us")}>Contact us</a>
                </div>
                <div className="footer__section">
                    <a target='_blank' href={urlJoin(baseUrl.wesleyMedia, "/privacy-and-cookies-policy.pdf")}>Privacy policy</a>
                    <a target='_blank' href={urlJoin(baseUrl.wesleyMedia, "/terms.pdf")}>Terms and Conditions</a>
                </div>
                <div className="footer__section">
                    <div>
                        <SocialMediaLinks />
                    </div>
                </div>
            </div>
            <div className="copyright">&copy; 2023 Wesley Media · All Rights Reserved · Wesley Media, 20-22 Station Road, Kettering NN15 7HH</div>
        </div>
    )
}