import { EventServiceSubscriber, SearchAudioResult } from 'common/types/eventService'
import { resultOrThrow } from 'common/universal/universalUtils'
import * as React from 'react'

import { useMemo, useCallback, useEffect, useState } from 'react'
import DevInspect from '../components/dev/DevInspect'
import TrackBox from '../components/TrackBox'
import { useConfig } from '../state'
import { StoriesDefinition } from './StoriesPage'
import { debounce } from 'lodash'
import { produce } from 'immer'

const QUERY_PLACEHOLDER = 'QUERY'

const stories: StoriesDefinition = {
    name: 'Search',
    stories: [
        {
            name: 'Default',
            component: () => {
                const config = useConfig()
                const [queryInput, setQueryInput] = useState('')
                const [query, setQuery] = useState('')
                const [result, setResults] = useState<SearchAudioResult>(null)
                const debouncedSetQuery = useCallback(debounce(setQuery, 300), [])

                useEffect(() => {
                    debouncedSetQuery(queryInput)
                }, [queryInput])

                useEffect(() => {
                    async function run() {
                        if (!config) return
                        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                        const results = await resultOrThrow(subscriber.control.searchTracks({
                            query
                        }))
                        setResults(results)
                    }
                    run()
                }, [config, query])
                return (
                    <div className="rounded-box pad" style={{ maxWidth: 800 }}>
                        <h2>Track search</h2>
                        <p>This is so we can see how various queries will work</p>
                        <p>Tip: try searching for <code>{['led', 'benny', 'def', 'iron'].join(' / ')}</code></p>
                        <input
                            autoFocus
                            type="text"
                            value={queryInput}
                            onChange={event => setQueryInput(event.target.value)}
                            style={{ marginBottom: 20 }}
                        />
                        {result && (
                            <div>
                                {result.tracks.map(track => (
                                    <div key={track.id}>
                                        <TrackBox
                                            key={track.id}
                                            id={track.id}
                                            track={track}
                                        />
                                        <DevInspect value={track} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )
            }
        },
        {
            name: 'Query',
            component: () => {
                const config = useConfig()
                const predefinedQueries = useMemo(() => getPredefinedQueries(), [])
                const [queryInput, setQueryInput] = useState(predefinedQueries[0].query)
                const [allResults, setAllResults] = useState<{ [term: string]: SearchAudioResult }>({})
                const [allErrors, setAllErrors] = useState<{ [term: string]: string }>({})

                const [customQuery, setCustomQuery] = useState('')

                const defaultQueryTerms = [
                    'bob mar',
                    'bob dy',
                    'abba',
                    'bach',
                    'fernando abba',
                ]

                const queryTerms = customQuery ? [customQuery] : defaultQueryTerms

                function prettyPrintQuery() {
                    setQueryInput(value => {
                        try {
                            const data = JSON.parse(value)
                            return JSON.stringify(data, null, 2)
                        } catch (err) {
                            alert('INVALID JSON!')
                            console.error(err)
                            return value
                        }
                    })
                }

                function replacePlaceholder(text: string, replacement: string) {
                    return text.split(QUERY_PLACEHOLDER).join(replacement)
                }

                async function search(queryInput: string) {
                    setAllResults({})
                    setAllErrors({})

                    await Promise.all(queryTerms.map(async term => {
                        const queryObject = replacePlaceholder(queryInput, term)
                        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                        try {
                            const searchResults = await resultOrThrow(subscriber.control.searchTracks({
                                query: '',
                                rawQuery: JSON.parse(queryObject),
                                // query: term,
                            }))
                            setAllResults(produce(allResults => {
                                allResults[term] = searchResults
                            }))
                        } catch (err) {
                            console.log('ERR', err)
                            setAllErrors(produce(allErrors => {
                                allErrors[term] = err.message
                            }))
                        }
                    }))
                }

                return (
                    <div className="rounded-box pad">
                        <div>
                            <p>Put a query in here and use <code>{QUERY_PLACEHOLDER}</code> as a placeholder for the query</p>
                            <p>You can compare to <a href="https://wesleymedia.co.uk/music-search">https://wesleymedia.co.uk/music-search</a></p>

                            <textarea
                                rows={30}
                                style={{ width: '100%' }}
                                value={queryInput}
                                onChange={event => setQueryInput(event.target.value)}
                            />

                            Predefined queries:
                            {predefinedQueries.map(predefinedQuery => (
                                <button
                                    key={predefinedQuery.name}
                                    onClick={() => setQueryInput(predefinedQuery.query)}
                                >
                                    {predefinedQuery.name}
                                </button>
                            ))}<br /><br />
                            Custom query:
                            <input value={customQuery} onChange={event => setCustomQuery(event.target.value)} />&nbsp;
                            (or leave empty to search for {defaultQueryTerms.join(', ')})
                            <br /><br />
                            <button onClick={() => prettyPrintQuery()}>Pretty Print</button>
                            <button onClick={() => search(queryInput)}>Search</button>
                            <br />
                            <p>
                                <strong>Note: raw queries are disabled by default, you have to comment out the check in the client handlers searchTracks method for this to work</strong>
                            </p>
                        </div>
                        <div style={{ display: 'flex', gap: 5 }}>
                            {queryTerms.map(term => (
                                <div key={term} style={{ width: `${(100 / queryTerms.length)}%` }}>
                                    <h4>{term}</h4>
                                    {allErrors[term] && (
                                        <code style={{ color: 'red' }}>{allErrors[term]}</code>
                                    )}
                                    {allResults[term] && (
                                        <div>
                                            {allResults[term].tracks.length === 0 && (
                                                <div>No results!</div>
                                            )}
                                            {allResults[term].tracks.map(track => (
                                                <div key={track.id} style={{ marginBottom: 5 }}>
                                                    <div style={{ border: '1px solid #ddd', padding: 5, fontSize: '10px', borderRadius: 5 }}>
                                                        <strong>{track.artist}</strong><br />
                                                        {track.title}<br />
                                                        <code>{track.score}</code>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )
            },
        }
    ]
}

function getPredefinedQueries() {
    return [
        {
            name: 'Default',
            query: disMaxQuery
        },
        {
            name: 'Track title match',
            query: {
                match: { 'track.title': { query: QUERY_PLACEHOLDER } }
            }
        },
        {
            name: 'Multi match',
            query: {
                "multi_match": {
                    "query": "QUERY",
                    "type": "bool_prefix",
                    "operator": "and",
                    "fields": [
                        "track.title.searchAsYouType",
                        "track.artistNames.searchAsYouType"
                    ]
                }
            }
        },
        {
            name: 'Track title keyword',
            query: {
                "term": {
                    "track.title.keyword": {
                        "value": "QUERY"
                    }
                }
            }
        },
        {
            name: 'Artist name keyword',
            query: {
                "term": {
                    "track.artistNames.keyword": {
                        "value": "QUERY"
                    }
                }
            }
        },
        {
            name: 'Prefix',
            query: {
                "multi_match": {
                    "query": "QUERY",
                    "type": "bool_prefix",
                    "operator": "and",
                    "fields": [
                        "track.artistNames.searchAsYouType",
                        "track.artistNames.searchAsYouType._2gram",
                        "track.artistNames.searchAsYouType._3gram",
                        "track.title.searchAsYouType",
                        "track.title.searchAsYouType._2gram",
                        "track.title.searchAsYouType._3gram",
                    ]
                }
            }
        }
    ].map(entry => ({
        ...entry,
        query: JSON.stringify(entry.query, null, 2)
    }))
}

const disMaxQuery = {
    dis_max: {
        queries: [
            {
                // Exact match on title
                "term": {
                    "track.title.keyword": {
                        "value": "QUERY",
                        "boost": 4.0
                    }
                }
            },
            {
                // Exact match on artist
                "term": {
                    "track.artistNames.keyword": {
                        "value": "QUERY",
                        "boost": 4.0
                    }
                }
            },
            {
                // Prefix query on title or artist
                "multi_match": {
                    "query": "QUERY",
                    "type": "bool_prefix",
                    "operator": "and",
                    "boost": 2,
                    "fields": [
                        "track.title.searchAsYouType",
                        "track.title.searchAsYouType._2gram",
                        "track.title.searchAsYouType._3gram",
                        "track.artistNames.searchAsYouType",
                        "track.artistNames.searchAsYouType._2gram",
                        "track.artistNames.searchAsYouType._3gram",
                    ]
                }
            },
            {
                // Match when term is present across title AND name
                "multi_match": {
                    "query": "QUERY",
                    "type": "cross_fields",
                    "boost": 1,
                    "fields": [
                        "track.title",
                        "track.artistNames"
                    ]
                }
            }
        ]
    }
}

export default stories