import * as classnames from 'classnames'
import { useMouseHover, useScrollingOverflow } from 'common/frontend/hooks'
import { bem, getBemClasses } from 'common/frontend/utils'
import { formatDurationAsMinutesAndSeconds } from 'common/frontend/formatters'
import { SimpleEvents } from 'common/types/eventService'
import * as React from 'react'
import { useState } from 'react'
import { mergeRefs } from 'react-advanced-cropper'
import { OnRemove, ActionDisabled, OnLoop } from '../../model'
import { VisualCoverImage } from './VisualCoverImage'
import Modal from '../Modal'
import './VisualBox.scss'
import VisualPreview from './VisualPreview'
import Cover from '../Cover'
import { useEventAspectRatio, useEventOrganisation } from '../../state'
import { calculateVisualDuration } from 'common/universal/universalUtils'
export interface VisualBoxProps extends React.HTMLAttributes<HTMLDivElement> {
    eventId: string
    visual: SimpleEvents.Visual
    onRemove?: OnRemove
    clickToPlay?: boolean
    onLoop?: OnLoop
    loop?: boolean
}

const VisualBox = React.forwardRef<HTMLDivElement, VisualBoxProps>(({
    eventId,
    visual,
    className,
    onRemove,
    clickToPlay = true,
    onLoop,
    loop,
    ...props
}, forwardedRef) => {
    const aspectRatio = useEventAspectRatio()
    const block = bem('visual-box')
    const header = block.element('header')
    const { ref: hoverRef, isHovering } = useMouseHover()
    const { ref: scrollingRef } = useScrollingOverflow(isHovering)
    const [visualPreviewModalIsOpen, setVisualPreviewModalIsOpen] = useState(false)
    const showLoop = Boolean(loop || onLoop) // show the loop thing either if it is looped, or we can toggle it
    function clickRemove(event: React.MouseEvent) {
        event.preventDefault()
        event.stopPropagation()
        if (onRemove !== ActionDisabled) {
            onRemove()
        }
    }

    let visualDuration = calculateVisualDuration(visual)

    return (
        <div
            ref={mergeRefs([hoverRef, forwardedRef])}
            className={classnames([block.className, className])}
            {...props}
        >
            <div className={header.className}>
                <div
                    className={header.el('name')}
                >
                    <div
                        ref={scrollingRef}
                        className="ellipsis"
                    >
                        {visual.name}
                    </div>
                </div>
                {visualDuration && visual.template.type !== "singleImage" && (
                    <div className={classnames([header.el('duration'), 'numeric'])}>
                        {formatDurationAsMinutesAndSeconds(visualDuration)}
                    </div>
                )}
                {showLoop && (
                    <button
                        className={getBemClasses('action', {
                            loop: !loop,
                            unloop: loop,
                            disabled: onLoop === ActionDisabled
                        })}
                        title="Loop track"
                        onClick={onLoop === ActionDisabled ? () => { } : () => onLoop?.()}
                    />
                )}
                {onRemove && (
                    <button
                        className={getBemClasses('action', {
                            trash: true,
                            disabled: onRemove === ActionDisabled
                        })}
                        onClick={clickRemove}
                    />
                )}
            </div>
            <div className={block.el('thumbnail')} style={{ position: 'relative' }}>
                <Cover center transparent enabled={clickToPlay}>
                    <button
                        className="action action--play-overlay"
                        style={{ '--size': '80px' } as React.CSSProperties}
                        onClick={() => setVisualPreviewModalIsOpen(true)}
                    />
                </Cover>
                <VisualCoverImage
                    eventId={eventId}
                    visual={visual}
                    contentQuality="preview"
                    aspectRatio={aspectRatio}
                />
            </div>
            <Modal
                opened={visualPreviewModalIsOpen}
                onClose={() => setVisualPreviewModalIsOpen(false)}
                closeOnEscape={true}
                closeOnClickOutside={true}
            >
                <VisualPreview
                    eventId={eventId}
                    visual={visual}
                    style={{ maxWidth: 800 }}
                    aspectRatio={aspectRatio}
                />
            </Modal>
        </div>
    )
})

export default VisualBox