
import * as lunr from 'lunr'
import * as React from 'react'
import { createContext, useState } from 'react'
import { createMemoryRouter, RouteObject, RouterProvider } from 'react-router-dom'
import { atom, RecoilRoot, selector, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil'
import SearchResults from './SearchResults'
import './Search.scss'

// despite how it sounds, this is supported clientside
// see https://reactjs.org/docs/react-dom-server.html#rendertostaticmarkup
import { renderToStaticMarkup } from 'react-dom/server'
import { SearchableContent, SearchIndex, SearchResult } from './searchModel'
import { createLunrIndex } from './engines/lunrSearch'
import { createSimpleIndex } from './engines/simpleSearch'

/**
 * Client-side searching!
 * 
 * The basic principle is we can re-use react-router route definitions and
 * render the element in a way where we can extract the text content for indexing.
 * 
 * We use the "lunr" client-side indexer.
 * 
 * All searchable content needs to be put inside a <SearchableContent> which performs
 * two functions:
 * 
 *   1. during indexing it'll walk through all the children and extract the text content
 *   2. after searching it'll highlight the terms searched for
 * 
 * Any interesting search state is stored using recoil.
 */

export interface SearchProps {
    initialQueryString?: string
    onSelect?: (selected: SearchResult) => void
}

export interface SearchQueryState {
    queryString: string
    result: SearchResult | null
}

export const searchQueryState = atom<SearchQueryState>({
    key: 'SearchState',
    default: {
        queryString: '',
        result: null
    }
})

export const searchableRoutesState = atom<RouteObject[]>({
    key: 'SearchableRoutes',
    default: []
})

/**
 * All the content across the site we *want* indexed
 */
export const searchableContentState = selector<SearchableContent[]>({
    key: 'SearchableContent',

    // read-only would be using a selector with this bit
    get: ({ get }) => getSearchableContentFromRoutes(get(searchableRoutesState))

    // or use an atom, with effects to initialize
    // effects: [
    //     ({ setSelf }) => {
    //         setSelf(getSearchableContentFromRoutes(searchableRoutes))
    //     }
    // ]
})

/**
 * Stores our lunr index
 */
export const searchIndexState = selector<SearchIndex>({
    key: 'SearchIndex',
    get: ({ get }) => {
        // return createLunrIndex(get(searchableContentState))
        return createSimpleIndex(get(searchableContentState))
    }
})

/**
 * This is where the searching actually takes place
 */
export const searchResultsState = selectorFamily<SearchResult[], { queryString: string }>({
    key: 'SearchResults',
    get: ({ queryString }) => ({ get }) => {
        if (!queryString) return []
        // ... oooh actually doing a search now
        return get(searchIndexState).search(queryString)
    }
})

/**
 * Whether the search dialog is open or not
 */
export const searchIsOpenState = atom<boolean>({
    key: 'SearchIsOpen',
    default: false
})

/**
 * Any highlighted elements are kept here
 */
export const highlightedElementsState = atom<HTMLElement[]>({
    key: 'HighlightedElementsState',
    default: []
})

/**
 * A context is used so the <SearchableContent> can know if we are indexing or not
 */
export const SearchContext = createContext<SearchContextValue>({ mode: 'default' })

export type SearchContextValue = DefaultSearchContext | IndexSearchContext

export interface DefaultSearchContext {
    mode: 'default'
}

export interface IndexSearchContext {
    mode: 'index'
    content: string[] // this is where we'll collect indexed content
}

/**
 * The magic bit! Extracting indexable content from route definitions...
 */
function getSearchableContentFromRoutes(routes: RouteObject[]): SearchableContent[] {
    return routes.map(route => {
        const context: IndexSearchContext = {
            mode: 'index',
            content: []
        }
        extractContent(route.element, context)
        return {
            id: route.path,
            path: route.path,
            title: React.isValidElement(route.element) && route.element.props.title,
            content: context.content.join(' ')
        }
    })
}

/**
 * ... and the real core of it all, handing it over to renderToStaticMarkup
 * 
 * We don't actually use the markup it generates, we are just using it
 * as it'll cause the element and all the children to render ...
 * 
 * ... that gives them a moment for <SearchableContent> in it's indexing mode
 * to push text content into our context object we've set
 * 
 * (it's possible we could use normal "render" here, but it's not sync)
 * (... well, async would be fine too, but it doesn't JustWork)
 * (using renderToStaticMarkup does emit warnings about useLayoutEffect though)
 */
function extractContent(element: React.ReactNode, context: IndexSearchContext) {
    renderToStaticMarkup(
        <Wrap
            element={element}
            context={context}
        />
    )
}

/**
 * Provide a minimal context for rendering an element for indexing purposes
 */
function Wrap({ context, element }: { context: IndexSearchContext, element: React.ReactNode }) {
    return (
        // It gets it's own recoil root, so any state in indexed pages is independent
        <RecoilRoot>
            <SearchContext.Provider value={context}>
                {/* We need a router incase any pages use route related stuff, e.g. Link */}
                <RouterProvider
                    router={createMemoryRouter([
                        {
                            path: '',
                            element
                        }
                    ])}
                />
            </SearchContext.Provider>
        </RecoilRoot>
    )
}