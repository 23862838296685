import * as React from 'react'
import { useLastSaved } from 'common/frontend/state'
import { DateTime } from 'luxon'
import { useState, useEffect } from 'react'
import './LastSaved.scss'
import { getBemClasses } from 'common/frontend/utils'
import { oneSecond } from 'common/universal/universalUtils'

function formatSecondsAgo(secondsAgo: number) {
    if (!secondsAgo) return ''
    if (secondsAgo < 8) {
        return 'just now'
    } else if (secondsAgo < 60) {
        return 'recently'
    }
    return DateTime.local().minus({ seconds: secondsAgo }).toRelative()
}

function getSecondsAgo(date: Date) {
    const dt = DateTime.fromJSDate(date)
    return -dt.diffNow('seconds').as('seconds')
}

function useSecondsAgo(date: Date): number {
    const [value, setValue] = useState(getSecondsAgo(date))
    useEffect(() => {
        setValue(getSecondsAgo(date))
      const interval = setInterval(() => {
        setValue(getSecondsAgo(date))
      }, oneSecond)
      return () => {
        clearInterval(interval)
      }
    }, [date])
    return value
}

export default function LastSaved() {
    const lastSaved = useLastSaved()
    const lastSavedSecondsAgo = useSecondsAgo(lastSaved)
    const lastSavedFormatted = formatSecondsAgo(lastSavedSecondsAgo)
    if (!lastSaved) return null
    return (
        <div 
            key={lastSaved.getTime()}
            className={getBemClasses('last-saved', { flash: lastSavedSecondsAgo <= 1 })}
            title={lastSaved.toISOString()}
        >
            Saved {lastSavedFormatted}
        </div>
    )
}