import { BDateTime, BDateTimeJson, BDuration } from '@busby/esb'
import Modal from 'common/frontend/components/Modal'
import { TrackInfo } from 'common/types/commonTypes'
import { EventServiceSubscriber, SimpleEvents } from 'common/types/eventService'
import { resultOrThrow } from 'common/universal/universalUtils'
import { DateTime, Duration } from 'luxon'
import * as React from 'react'
import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useAsyncEffect } from 'use-async-effect'
import { InOutMarkers } from './model'
import { configState, useConfig, useIsAdminPortal, useIsClientPortal, useIsProfessionalPortal } from './state'
import { wesleyDebugNamespace } from './utils'
import { Form } from './components/Form'
import { TextBlurWidget } from './components/customWidgets'

const debug = wesleyDebugNamespace.extend('hooks')

export function useSectionTimes(event: SimpleEvents.Event) {
    const sectionTimes = useMemo(() => {
        if (!event) return []
        const startTime = DateTime.fromJSDate(new Date(event.date))
        let totalDuration = Duration.fromMillis(0)
        return event.sections.map(section => {
            const time = startTime.plus(totalDuration).toISO()
            totalDuration = totalDuration.plus({
                milliseconds: BDuration.fromJson(section.duration).getMilliseconds()
            })
            return time
        })
    }, [event])
    return sectionTimes
}

/**
 * Screensize utility functions
 */
export function useScreenSize() {
    const isSmallScreen = useMediaQuery({ maxWidth: 767 }) // must match value set in _variables.scss
    return {
        isSmallScreen
    }
}

/**
 * Set page title
 */
let originalPageTitle = null
export function usePageTitle(...parts: string[]) {
    // First use globally, we'll get the server page title, so we can include that
    // hot reload breaks it unfortunately
    if (!originalPageTitle) {
        originalPageTitle = document.title
    }

    useEffect(() => {
        const title = [...parts, originalPageTitle].join(' | ')
        document.title = title
        return () => {
            document.title = originalPageTitle
        }
    }, [parts])
}

/**
 * 
 * Key values over at https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
 */
export function useKeyPress(key: string, callback: (event?: KeyboardEvent) => void, deps: any[] = []) {
    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (event.key === key) {
                callback(event)
            }
        }
        window.addEventListener('keydown', handler)
        return () => {
            window.removeEventListener('keydown', handler)
        }
    }, [key, callback, ...deps])
}

export function useScrollingOverflow(active: boolean): { ref: RefObject<any> } {
    const ref = useRef<HTMLElement>(null)
    const millisecondsPerPixel = 20

    useEffect(() => {
        if (active) {
            return setOverflowStyle(ref.current)
        }
    }, [active])

    function setOverflowStyle(el: HTMLElement): (() => void) | undefined {
        if (!el) return
        const {
            clientWidth, // the width of what is actually visible
            scrollWidth, // the full width, if any overflow was not cut off
        } = el

        const overflow = scrollWidth - clientWidth

        // if overflow is 0 it means the element is NOT overflowing, so we have nothing to do
        if (overflow === 0) return

        // we make the duration dependent on how much we have to scroll so the speed should be always the same
        const duration = overflow * millisecondsPerPixel

        // move it to the left, enough to read all the way to the end, but no more
        const scrollingStyles: React.CSSProperties = {
            transform: `translateX(-${overflow}px)`,
            transition: `linear ${duration}ms`,
            overflow: 'visible'
        }
        Object.assign(el.style, scrollingStyles)

        // returns a function that will reset the style again
        return () => {
            // quickly put it back where it came from
            const revertStyles: React.CSSProperties = {
                transform: `translateX(0)`,
                transition: 'linear 100ms',
            }

            // once the transition is over, remove all trace of our work...
            const resetStyles: React.CSSProperties = {
                transform: null,
                transition: null,
                overflow: null
            }
            function removeStyle() {
                Object.assign(el.style, resetStyles)
                el.removeEventListener('transitionend', removeStyle)
            }
            el.addEventListener('transitionend', removeStyle)

            Object.assign(el.style, revertStyles)
        }
    }
    return { ref }
}

export function useMouseHover(): { ref: RefObject<any>, isHovering: boolean } {
    const ref = useRef(null)
    const [isHovering, setIsHovering] = useState(false)
    useEventListener('mouseenter', () => setIsHovering(true), ref)
    useEventListener('mouseleave', () => setIsHovering(false), ref)
    return { ref, isHovering }
}

export function useEventListener(
    eventName: string,
    handler: (event?: any) => void,
    element?: HTMLElement | RefObject<HTMLElement> // defaults to window if not supplied
) {
    // Store the handler in a ref, so we can always refer to the latest one
    // without having to add/remove event listeners on each change
    // The handler passed in will commonly be a different instance of the same function
    const latestHandler = useRef(handler)
    useEffect(() => {
        latestHandler.current = handler
    }, [handler])


    // This makes the assumption that the ref is set on the first cycle (hence [] deps array)
    // This is mostly the case, but not always. Have to get more funky to handle that case if needed.
    useEffect(() => {
        const el = element ? ('current' in element ? element.current : element) : window
        if (!el) return
        const handleEvent = event => latestHandler.current(event)
        el.addEventListener(eventName, handleEvent)
        return () => el.removeEventListener(eventName, handleEvent)
    }, [])
}

export interface ConfirmModalProps {
    isPending?: boolean
    title?: string
    message?: string
    confirmButton?: string
}

export function useConfirmModal({
    isPending = false,
    title = 'Confirm?',
    message,
    confirmButton = 'OK'
}: ConfirmModalProps) {
    const [open, setOpen] = useState(false)
    const resolvePromise = useRef(null)

    // allow confirming by pressing enter
    useKeyPress('Enter', () => {
        onConfirmed(true)
    }, [])

    function confirm(): Promise<boolean> {
        setOpen(true)
        return new Promise((resolve) => {
            resolvePromise.current = resolve
        })
    }

    function onConfirmed(confirmed: boolean) {
        if (!resolvePromise.current) return
        resolvePromise.current(confirmed)
        resolvePromise.current = null
        setOpen(false)
    }

    const modal = (
        <Modal
            opened={open || isPending}
            isPending={isPending}
            className="confirm-modal"
            closeOnEscape={true}
            closeOnClickOutside={true}
            onClose={() => onConfirmed(false)}
        >
            <h3>{title}</h3>
            {message && (
                <p className="confirm-modal__message">{message}</p>
            )}
            <div className="cols cols--spaced cols--center">
                <button
                    className="btn"
                    onClick={() => onConfirmed(true)}
                >
                    {confirmButton ?? 'OK'}
                </button>
                <button className="btn btn--secondary" onClick={() => onConfirmed(false)}>
                    Cancel
                </button>
            </div>
        </Modal>
    )
    return {
        modal,
        confirm
    }
}

export function useFormModal({ }: ConfirmModalProps = {}) {
    const [open, setOpen] = useState(false)
    const [formData, setFormData] = useState({})
    const formRef = useRef<any>(null)
    const [params, setParams] = useState<{ title: string, schema: any, uiSchema?: any, customValidate?: (formData, errors) => any }>(null)
    const resolvePromise = useRef(null)

    // allow confirming by pressing enter
    useKeyPress('Enter', () => {
        onSubmit(true)
    }, [])

    function openForm(params: { title: string, schema: any, uiSchema?: any, customValidate?: (formData, errors) => any }): Promise<{ answered: boolean, formData: any }> {
        setParams(params)
        setOpen(true)
        return new Promise((resolve) => {
            resolvePromise.current = resolve
        })
    }

    function onSubmit(answered: boolean) {
        if (!resolvePromise.current) return
        if (answered) {
            let valid = formRef.current?.validateForm()
            if (valid) {
                resolvePromise.current({ answered, formData })
                resolvePromise.current = null
                setOpen(false)
            }
        } else {
            resolvePromise.current({ answered })
            resolvePromise.current = null
            setOpen(false)
        }
    }

    const modal = (
        <Modal
            opened={open}
            className="form-modal"
            closeOnEscape={true}
            closeOnClickOutside={true}
            onClose={() => onSubmit(false)}
        >
            <h3>{params?.title}</h3>
            <Form
                ref={formRef}
                jsonSchema={params?.schema}
                uiSchema={params?.uiSchema}
                customValidate={params?.customValidate}
                formData={formData}
                widgets={{ TextBlurWidget }}
                onChange={({ formData }) => setFormData(formData)}
                theme="wesley"
            />
            <div className="cols cols--spaced" style={{ alignItems: 'center' }}>
                <button type="submit" className="btn" onClick={() => onSubmit(true)}>
                    Add
                </button>
                <button onClick={() => onSubmit(false)} className="btn btn--secondary">
                    Cancel
                </button>
            </div>
        </Modal>
    )
    return {
        modal,
        openForm
    }
}

// Uses JSON.stringify to only return the value if it's actually different
export function useDeepChange<T>(value: T): T {
    const [previous, setPrevious] = useState({ value, stringifiedValue: JSON.stringify(value) })

    if (previous) {
        const stringifiedValue = JSON.stringify(value)
        if (previous.stringifiedValue !== stringifiedValue) {
            setPrevious({ value, stringifiedValue: JSON.stringify(value) })
            return value
        } else {
            // they have the same deep value if we got here
            // return the previous value, so identity comparisons will work
            return previous.value
        }
    }

    return value
}

export function useDiagnosticsEnabled() {
    return Boolean(localStorage.getItem('DIAGNOSTICS_ENABLED'))
}

export function useQueryParams() {
    const [searchParams] = useSearchParams()

    return useMemo<{ [key: string]: string }>(() => {
        const result = {}
        searchParams.forEach((value, key) => {
            // only 1 value per key, not doing anything for repeated keys
            result[key] = value
        })
        return result
    }, [searchParams])
}

// Current milliseconds, updated every 5 seconds
export function useNowMilliseconds() {
    const [now, setNow] = useState(() => Date.now())
    useEffect(() => {
        const interval = setInterval(() => {
            setNow(Date.now())
        }, 5000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    return now
}

export function useItemMarkers(item: { type?: string, inTimecode?: BDateTimeJson, outTimecode?: BDateTimeJson }) {
    return useMemo<InOutMarkers | undefined>(() => {
        if ('type' in item && item.type !== 'audio') return
        if (!item.inTimecode || !item.outTimecode) return
        return [
            item.inTimecode,
            item.outTimecode
        ].map(timecode => {
            // BDuration.getMilliseconds() is rounded to nearest second, so not good for us...
            if (timecode.frameRate !== 'milli') throw new Error('frameRate must be milli')
            try {
                return BDateTime.fromJson(timecode)?.getFrames()
            } catch (error) {
                debug('BDuration error for %o', timecode)
                throw error
            }
        }) as InOutMarkers
    }, [item])
}

export function useVisualTemplateType(template: SimpleEvents.VisualTemplate) {
    return {
        isSlideShow: template?.type === "slideshow",
        isSingleImage: template?.type === "singleImage",
    }
}

export function useTrack(id: string) {
    const [track, setTrack] = useState<TrackInfo>()
    const config = useRecoilValue(configState)

    useAsyncEffect(async () => {
        const subscriber = EventServiceSubscriber.get(config.services.eventService)
        const { track } = await resultOrThrow(subscriber.control.getTrack({ id }))
        setTrack(track)
    }, [])

    return track;
}

export function useVisualTributeMode(event: SimpleEvents.Event) {
    const { enableVisualTributeUiOptions } = useConfig()
    const isAdmin = useIsAdminPortal();
    const isProfessional = useIsProfessionalPortal()
    const isClient = useIsClientPortal()


    return useMemo(() => {
        let mode: "full" | "restricted" = "restricted";
        if (!enableVisualTributeUiOptions) {
            mode = "full"
        } else if (isAdmin) {
            mode = "full"
        } else if (isProfessional) {
            mode = event?.uiOptions?.visualTribute?.professionalUserMode ?? "restricted"
        } else if (isClient) {
            mode = event?.uiOptions?.visualTribute?.serviceUserMode ?? "restricted"
        }
        return mode;
    }, [event])
}