import { ResourceData, ResourceGroupSubscriber } from '@busby/esb'
import { useConfig, useEvent } from 'common/frontend/state'
import { Events } from 'common/types/events'
import { EventInfo, EventServiceSubscriber, EventUpdatedStatus, SimpleEvents } from 'common/types/eventService'
import { produce } from 'immer'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { resultOrThrow } from '../../universal/universalUtils'
import DevInspect from '../components/dev/DevInspect'
import { getIconPath } from '../components/LiveEventBox'
import { StoriesDefinition } from './StoriesPage'

export default {
    name: 'Playground',
    stories: [
        {
            name: 'Event service status',
            component: () => {
                const [statusMessages, setStatusMessages] = useState<EventUpdatedStatus[]>([])
                const config = useConfig()
                useEffect(() => {
                    async function init() {
                        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                        subscriber.status.eventUpdated(message => {
                            const { eventId } = message.getParams()

                            setStatusMessages(produce(draft => {
                                draft.push(message.getParams())
                            }))
                        })
                    }
                    init()
                }, [])
                return (
                    <div>
                        {statusMessages.map((message, index) => (
                            <div key={index}>
                                {message.message}
                            </div>
                        ))}
                    </div>
                )
            }
        },
        {
            name: 'Live event types',
            component: () => {
                const [types, setTypes] = useState<SimpleEvents.LiveEventType[]>([])
                const config = useConfig()
                useEffect(() => {
                    async function init() {
                        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)

                        subscriber.status.liveEventTypesUpdated(message => {
                            console.log('updating live event types', { liveEventTypes })
                            setTypes(message.getParams().liveEventTypes)
                        })

                        const { liveEventTypes } = await resultOrThrow(subscriber.control.getLiveEventTypes())
                        console.log('setting initial live event types', { liveEventTypes })
                        setTypes(liveEventTypes)
                    }
                    init()
                }, [])
                return (
                    <div>
                        {types.map(type => (
                            <div key={type.id}>
                                <h3>{type.name}</h3>
                                <img src={getIconPath(type)} />
                                <DevInspect value={type} />
                            </div>
                        ))}
                    </div>
                )
            }
        },
        {
            name: 'Event event atom',
            component: () => {
                const event = useEvent()
                return (
                    <div>
                        <h3>event</h3>
                        <DevInspect value={event} />
                    </div>
                )
            }
        },
        {
            name: 'Event service',
            component: () => {
                const [events, setEvents] = useState<EventInfo[]>([])
                const config = useConfig()

                async function loadEvents() {
                    const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                    const { events } = await resultOrThrow(subscriber.control.getEvents({}))
                    setEvents(events)
                }

                return (
                    <div>
                        <button
                            className="btn"
                            onClick={() => loadEvents()}
                        >
                            load event
                        </button>
                        <DevInspect value={events} />
                    </div>
                )
            }
        },
        {
            name: 'Resource group subscriber',
            component: () => {
                const [n, setN] = useState(0)
                const resourceGroupServiceName = 'events'

                const [debug, setDebug] = useState({})

                function mergeDebug(params) {
                    setDebug(previous => Object.assign(previous, params))
                }

                useEffect(() => {
                    async function init() {
                        const resourceGroupSubscriber = await ResourceGroupSubscriber.getConnected(resourceGroupServiceName)
                        console.log('resourceGroupSubscriber', resourceGroupSubscriber)
                        // console.log('rs', resourceGroupSubscriber.resource('liveEventType'))
                        // window.s = resourceGroupSubscriber
                        const resourceType = 'liveEvent'
                        const schema = (await resourceGroupSubscriber.getJsonFormSchema({ resourceType })).getResult().schema
                        console.log('schema is', resourceType, '->', schema)
                        mergeDebug({ schema })
                        const { resources, hasMoreRows, size } = (await resourceGroupSubscriber.queryResources({
                            resourceType,
                            size: 100,
                            query: {},
                            filters: {
                                resolveLinkTypes: ['liveEventType']
                            },
                            offset: 0,
                        })).getResult()
                        console.log('got resources!', resources, hasMoreRows)
                        mergeDebug({
                            resourceType,
                            resources
                        })
                        // const result = await this.resourceGroupSubscriber.getResources({
                        //     resourceType: 'liveEventType',
                        //     // resourceId,
                        //     // filters: resourceFilters
                        // })
                        // if (!result.isError()) {
                        //     console.log('got result', )
                        //}
                    }

                    init()
                }, [n])

                return (
                    <div>
                        <button onClick={() => setN(n => n + 1)}>re-init</button>
                        <DevInspect value={debug} />
                    </div>
                )
            }
        }
    ]
} as StoriesDefinition