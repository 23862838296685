import GeneralError from 'common/frontend/components/GeneralError'
import AcceptInvitePage from 'common/frontend/pages/AcceptInvitePage'
import AccountPage from 'common/frontend/pages/AccountPage'
import ChangePasswordPage from 'common/frontend/pages/ChangePasswordPage'
import LoggingOut from 'common/frontend/pages/LoggingOut'
import RequestPasswordResetPage from 'common/frontend/pages/RequestPasswordResetPage'
import ResetPasswordPage from 'common/frontend/pages/ResetPasswordPage'
import SimpleMessagePage from 'common/frontend/pages/SimpleMessagePage'
import { eventRoutes } from 'common/frontend/routes'
import { isLoggedInState, useIsAdmin, useResetEvent, useResetEventId, useSetEventId } from 'common/frontend/state'
import StoriesPage from 'common/frontend/stories/StoriesPage'
import * as React from 'react'
import { useEffect } from 'react'
import { Navigate, Outlet, createBrowserRouter, useLocation, useParams, useRouteError } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import MainLayout from './MainLayout'
import EventCreatePage from './pages/EventCreatePage'
import EventEditPage from './pages/EventEditPage'
import LoginPage from './pages/LoginPage'
import ManageEventsPage from './pages/ManageEventsPage'
import ViewAsUserLayout from './pages/ViewAsUserLayout'
import { useIsVenueUser, useSelectedOrganisation, useUserOrganisations } from './state'
import { DigitalSignagePage } from './pages/DigitalSignagePage'
import { DigitalSignageAddPage } from './pages/DigitalSignageAddPage'
import { isEmpty } from 'lodash'
import { DigitalSignageBackgroundPage } from './pages/DigitalSignageBackgroundPage'


function RedirectToLoginIfLoggedOut({ children }) {
    const isLoggedIn = useRecoilValue(isLoggedInState)
    const location = useLocation()

    if (!isLoggedIn) {
        return <Navigate to="/login" replace state={{ redirectTo: location.pathname }} />
    }
    return children
}

function RedirectIfLoggedIn({ children }) {
    const isLoggedIn = useRecoilValue(isLoggedInState)
    const location = useLocation()

    if (isLoggedIn) {
        return <Navigate to={location.state?.redirectTo || "/events"} replace />
    }
    return children
}

function RouterError() {
    const error = useRouteError()
    return <GeneralError error={error} />
}

function SelectEvent({ children }) {
    const { eventId } = useParams()
    const setEventId = useSetEventId()
    const resetEvent = useResetEvent()
    const resetEventId = useResetEventId()
    useEffect(() => {
        setEventId(eventId)
        return () => {
            resetEvent()
            resetEventId()
        }
    }, [eventId])
    return children
}

function EnsureHasOrganisation({ children }) {
    // const organisation = useSelectedOrganisation()
    const isAdmin = useIsAdmin()
    const organisations = useUserOrganisations()
    if (isEmpty(organisations) && !isAdmin) {
        return (
            <SimpleMessagePage message="No organisations available" />
        )
    }

    return children
}

function EnsureVenueUser({ children }) {
    const venueUser = useIsVenueUser()
    const isAdmin = useIsAdmin()
    if (!venueUser && !isAdmin) {
        return (
            <SimpleMessagePage message="Digital signage is only available to venue users" />
        )
    }

    return children
}

export default createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        errorElement: <RouterError />,
        children: [
            {
                path: 'logout',
                element: <LoggingOut />
            },
            {

                // logged out pages

                path: '',
                element: (
                    <RedirectIfLoggedIn>
                        <Outlet />
                    </RedirectIfLoggedIn>
                ),
                children: [
                    {
                        path: '',
                        element: <Navigate to="/login" replace />
                    },
                    {
                        path: 'login',
                        element: <LoginPage />
                    },
                    {
                        path: 'invite',
                        element: <AcceptInvitePage />
                    },
                    {
                        path: 'forgot-password',
                        element: <RequestPasswordResetPage />
                    },
                ]
            },
            {
                // logged in pages

                path: '',
                element: (
                    <RedirectToLoginIfLoggedOut>
                        <Outlet />
                    </RedirectToLoginIfLoggedOut>
                ),
                children: [
                    {
                        path: 'account',
                        element: <AccountPage />
                    },
                    {
                        path: 'change-password',
                        element: <ChangePasswordPage />
                    },
                    {
                        path: 'digital-signage',
                        // element: <EnsureVenueUser>
                        //     <Outlet />
                        // </EnsureVenueUser>,
                        children: [
                            {
                                path: '',
                                element: <DigitalSignagePage />
                            },
                            {
                                path: "add",
                                element: <DigitalSignageAddPage />
                            },
                            {
                                path: "background",
                                element: <DigitalSignageBackgroundPage />
                            }
                        ]
                    },
                    {
                        path: 'events',
                        element: (
                            <EnsureHasOrganisation>
                                <Outlet />
                            </EnsureHasOrganisation>
                        ),
                        children: [
                            {
                                path: '',
                                element: <ManageEventsPage />
                            },
                            {
                                path: 'create',
                                element: <EventCreatePage />
                            },
                            {
                                path: ':eventId',
                                element: (
                                    <SelectEvent>
                                        <Outlet />
                                    </SelectEvent>
                                ),
                                children: [
                                    {
                                        path: '',
                                        element: <Navigate to="view" replace />
                                    },
                                    {
                                        path: 'view',
                                        element: (
                                            <ViewAsUserLayout>
                                                <Outlet />
                                            </ViewAsUserLayout>
                                        ),
                                        children: eventRoutes
                                    },
                                    {
                                        path: 'edit',
                                        element: <EventEditPage />
                                    },
                                ]
                            }
                        ]
                    }
                ]
            },

            // can be logged out or logged in

            {
                path: 'reset-password',
                element: <ResetPasswordPage />
            },

            // fall back
            {
                path: '*',
                element: <SimpleMessagePage message="Page not found" />
            }
        ]
    },

    // dev only pages
    ...(process.env.NODE_ENV === 'development' ? [
        {
            path: 'stories',
            element: <StoriesPage />
        }
    ] : []),
])