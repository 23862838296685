//Since updating to motion-canvas 3.9.0, this needs to be imported before importing from @motion-canvas/core to stop a circular dependency error...
import "@motion-canvas/player";
import { Project } from "@motion-canvas/core";

import { isEmpty, each } from "lodash"
import { AdjustParams, CropParams } from "../../../../../common/types/eventService";

export type TemplateParams = {
    slideDuration: number
    fadeDuration: number
    eventId: string
    slides: SlideParams[]
}

export type SlideParams = {
    type: string
    captions?: { [key: string]: string }
    userMedia?: {
        type: "image" | "video" | "text"
        filename: any
        adjust: AdjustParams
        crop?: CropParams
        objectFit?: "fill" | "fit"
        muted?: boolean
        urlPathPrefix?: string
        contentQuality?: "original" | "preview"
    }[]
}

export type TemplateDescription = {
    slideTypes: {
        type: "title" | string
        metadataForm?: {
            schema: any
        },
        media?: {
            freeformAspectRatio?: boolean
            aspectRatio: { width: number, height: number }
        }[]
    }[]
    fonts: { name: string, filename: string }[]
}


export const loadTemplateFonts = async (project: Project, urlPathPrefix = "resources") => {
    const { variables } = project
    if (!isEmpty(variables)) {
        const templateDescription: TemplateDescription = variables.templateDescription as TemplateDescription;
        each(templateDescription.fonts, ({ name, filename }) => {
            const font = new FontFace(name, `url(/${urlPathPrefix}/media/templates/${project.name}/fonts/${filename})`, {})
            //@ts-ignore
            document.fonts.add(font);
            font.load();
        })

    }
    
    await document.fonts.ready
}


export const templates: {[key: string] : any} = {}

const templateNames = [
    'blueMemories', 
    'blueMemoriesPortrait', 
    'brightBouquet', 
    'brightBouquetPortrait', 
    'candles', 
    'candlesPortrait', 
    'creamLeaves', 
    'creamLeavesPortrait', 
    'darkRose', 
    'darkRosePortrait', 
    'limestoneBouquet', 
    'limestoneBouquetPortrait', 
    'singleImage',
    'singleImagePortrait',
    'simple',
    'simplePortrait',
    'videoPlayer', 
    'visualComponentPreview'
]

export const loadTemplates = () => {
    for(let name of templateNames){
        templates[name] = require(`./${name}Project`)
    }
}
