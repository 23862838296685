import { BDurationJson } from '@busby/esb'

export interface PortalUser {
    id: string
    email: string
    firstName: string
    lastName: string
    username: string
    admin?: boolean
}

// Config we reveal to the frontend publically
// No secrets!
export interface PublicConfig {
    locations: {
        media: string
    }
    services: {
        eventService: string
    }
    uploader: {
        maxFileSize: number
    }
    baseUrl: {
        wesleyMedia: string
        digitalSignage?: string
    }
    portalType: "admin" | "professional" | "client"
    enableVisualTributeUiOptions?: boolean
    disableAddFamilyOption?: boolean
}

export type UploadedAssetType = 'image' | 'video' | 'audio'

interface BaseUploadedAsset {
    type: UploadedAssetType
    location: string
    filename: string
    mimeType: string
    data?: string
}

interface UploadedMediaAsset extends BaseUploadedAsset {
    height: number
    width: number
} 

export interface UploadedImageAsset extends UploadedMediaAsset {
    type: 'image'
}

export interface UploadedVideoAsset extends UploadedMediaAsset {
    type: 'video'
    duration: BDurationJson
    hasAudio: boolean
}

export interface UploadedAudioAsset extends BaseUploadedAsset {
    type: 'audio'
    name: string,
    duration: BDurationJson
}

export type UploadedVisualAsset = UploadedImageAsset | UploadedVideoAsset
export type UploadedAsset = UploadedImageAsset | UploadedVideoAsset | UploadedAudioAsset

// A simplified representation of a track in the MAM database
// It will likely have more fields one day, artist/title is a bit simple :)

export type SearchTrackInfo = TrackInfo & {
    score: number
}

export interface TrackInfo {
    id: string
    trackNo?: string
    type: 'normal' | 'user'
    title: string
    artist?: string
    orchestras?: string[]
    duration?: BDurationJson
    audio?: {
        path: string
        filename: string
        location: string
    }
    image?: {
        path: string
        filename: string
        location: string
    }

    // Stuff that can come from userMetadata
    isHymn?: boolean
    instruments?: string[]
    noOfVerses?: number
    selectedVersesText?: string
}

export interface Size {
    width?: number
    height?: number
}

export interface TrackRequest {
    type: "missing" | "edit" | "query"
    artist?: string
    composer?: string
    track?: string
    choir?: string
    conductor?: string
    soloist?: string
    details?: string
}

export interface TrackRequestJob {
    status: 'pending' | 'found' | 'notFound' | 'edited'
    trackRequest: TrackRequest
    trackInfo?: TrackInfo
}

export interface AudioUpload {
    name: string
    filename: string
    location: string
    duration: BDurationJson
}

export interface AudioUploadJob {
    status: 'pending' | 'approved' | 'declined'
    audioUpload: AudioUpload
}


export const trackRequestFieldNames: {[key in keyof TrackRequest]: string} = {
    type: "Type",
    artist: 'Artist',
    composer: 'Composer',
    track: 'Name of song',
    choir: 'Choir',
    conductor: 'Conductor',
    soloist: 'Soloist',
    details: 'Details'
}

export const orderedTrackRequestFields: (keyof TrackRequest)[] = [
    'type',
    'track',
    'artist',
    'composer',
    'conductor',
    'choir',
    'soloist',
    'details'
]

export type EventSource = "oldSystem" | "newSystem"


export interface VisualTributeRequest {
    details: string
}

export const visualTributeRequestFieldNames: {[key in keyof VisualTributeRequest]: string} = {
    details: 'Details'
}

export const orderedVisualTributeRequestFields: (keyof VisualTributeRequest)[] = [
    'details'
]