import { WsSubscriberClient } from "@busby/esb/lib/esbInterface/WsSubscriberClient";

const subscriber = new WsSubscriberClient(`${location.protocol}//${location.hostname}:${location.port}`);

export default subscriber


/**
 * An async wrapper for sending sockets on sockets
 * 
 * Expects the server to ack with an object:
 * 
 *   {
 *     data: any // will resolve the promise with this value
 *     error: any // will reject the promise with this error
 *   }
 * 
 *   ... or it'll reject the promise with an error
 * 
 *  Works with socketHandlers() function on the server
 */
export function emit(channel: string, payload?: any): Promise<any> {
    // TODO: would be nice to have these typed with the server...
    return new Promise((resolve, reject) => {
        subscriber.socket.emit(channel, payload, ({ error, data }) => {
            if (error) return reject(error)
            if (data) return resolve(data)
            reject(new Error('expected error or data from server'))
        })
    })
}

export function connect() {
    subscriber.socket.connect()
}

export function disconnect() {
    subscriber.socket.disconnect()
}

export function reconnect() {
    disconnect()
    connect()
}