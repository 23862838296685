import * as React from 'react'

type MusicSearchButtonsProps = {
    hasPrev: boolean
    hasNext: boolean
    prevPage: () => void
    nextPage: () => void
}

export function MusicSearchButtons({hasNext, hasPrev, prevPage, nextPage}: MusicSearchButtonsProps) {
    
    return <div className="music-search-buttons">
        <button disabled={!hasPrev} onClick={prevPage} className='btn'>Previous</button>
        <button disabled={!hasNext} onClick={nextPage} className='btn'>Next</button>
    </div>
}