import * as React from 'react'
import ProgressSteps from 'common/frontend/components/ProgressSteps'
import Scheduling from '../components/Scheduling'
import { usePageTitle, useScreenSize } from 'common/frontend/hooks'
import SchedulerViewOnly from '../components/SchedulerViewOnly'

export default function SchedulingPage() {
    usePageTitle('Scheduling')
    const { isSmallScreen } = useScreenSize()
    return (
        <div className="scheduling-page">
            <section className="progress-navigation">
                <ProgressSteps headerOnly/>
            </section>
            <section className="section--grey container-padding">
                {isSmallScreen ? <SchedulerViewOnly /> : <Scheduling />}
            </section>
        </div>
    )
}