import prettyBytes from 'pretty-bytes'
import * as React from 'react'
import { Accept, DropzoneOptions, useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { useConfig } from '../state'
import { uniq } from 'lodash'

export type AcceptType = 'image' | 'video' | 'audio'

const acceptTypes: { [k in AcceptType]: Accept} = {
    image: {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
    },
    video: {
        'video/mp4': ['.mp4'],
        'video/mpeg': ['.mpeg'],
    },
    audio: {
        'audio/mp3': ['.mp3'],
        'audio/mpeg': ['.mp3'],
        'audio/wav': ['.wav'],
        'audio/m4a': ['.m4a'],
    },
}

export interface MediaDropzoneProps extends DropzoneOptions {
    types: AcceptType[]
    maxFileSize?: number
}

export default function MediaDropzone({ types, maxFileSize, ...props }: MediaDropzoneProps) {
    const config = useConfig()

    if (!maxFileSize) {
        maxFileSize = config.uploader?.maxFileSize
    }

    // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    const accept: Accept = {}

    for (const type of types) {
        Object.assign(accept, acceptTypes[type])
    }

    const extensions = Object.values(accept).flat().map(ext => ext.replace(/^\./, '').toUpperCase())
    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        accept,
        maxSize: maxFileSize,
        onDropRejected: rejections => {
            for (const rejection of rejections) {
                for (const error of rejection.errors) {
                    if (error.code === 'file-too-large') {
                        toast('File is bigger than ' + prettyBytes(maxFileSize))
                    } else if(error.code === 'file-invalid-type') {
                        toast('File type must be one of ' + extensions.join(", "))
                    }else {
                        toast(error.message)
                    }
                }
            }
        },
        ...props
    })
    return (
        <button
            className="dropzone"
            {...getRootProps()}
        >
            <input
                {...getInputProps()}
            />
            <strong>Select a file to upload</strong>
            <span className="dropzone__supported">Supported files: {uniq(extensions).join(', ')}</span>
            {maxFileSize && (
                <span className="dropzone__max">Up to {prettyBytes(maxFileSize)}</span>
            )}
        </button>
    )
}