import { SimpleEvents } from 'common/types/eventService'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useEventPathTo } from '../eventPaths'
import { SetUsingUpdater } from '../model'
import MusicTabs from './MusicTabs'
import SchedulerContext from './SchedulerContext'
import SchedulerForVisual from './SchedulerForVisual'


const schema = {
    type: "object",
    properties: {
        query: {
            type: "string",
        }
    }
}

const uiSchema = {
    query: {
        "ui:label": false,
        "ui:placeholder": "Search music",
        "ui:options": { hideTitle: true },
        classNames: "search-input"
    }
}

export interface MusicSelectionForVisualProps {
    visual: SimpleEvents.Visual
    setVisual: SetUsingUpdater<SimpleEvents.Visual>
    readOnly: boolean
}

export default function MusicSelectionForVisual({ visual, setVisual, readOnly }: MusicSelectionForVisualProps) {
    const to = useEventPathTo()

    return (
        <div style={{marginTop: 15}} className="music-selection">
            {
                !readOnly && <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                    <div>
                        <h3>Add music</h3>
                    </div>
                    <Link
                        style={{ marginLeft: 'auto', textAlign: 'right' }}
                        to={to('music/request')}
                    >
                        <button className='btn btn--long'>
                            Request missing track
                        </button>
                    </Link>
                </div>
            }
            <SchedulerContext>
                <div className="cols cols--spaced">
                    {!readOnly && <MusicTabs target="visualMusicItem" />}
                    <div style={{width: !readOnly ? "60%" : "100%"}} className="music-selection__selected-tracks rounded-box">
                        <h4>Selected tracks</h4>
                        <SchedulerForVisual
                            visual={visual}
                            setVisual={setVisual}
                            readOnly={readOnly}
                        />
                    </div>
                </div>
            </SchedulerContext>
        </div>
    )
}