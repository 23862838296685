import Cover from 'common/frontend/components/Cover'
import { pickKeys, wesleyDebugNamespace } from 'common/frontend/utils'
import { EventUserInfo, SimpleEvents, proUpdatableEventFields } from 'common/types/eventService'
import * as React from 'react'
import { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'
import { useSaveEventAction } from '../actions'
import { EventParams } from '../model'
import { useSelectedOrganisationId } from '../state'
import EventForm from './EventForm'
import { OptionalIdDeep } from 'common/types/typeUtils'
import { toast } from 'react-toastify'

const debug = wesleyDebugNamespace.extend('event-edit')

export default function EventCreate() {
    const organisationId = useSelectedOrganisationId()
    const navigate = useNavigate()
    const formRef = useRef(null)

    // local storage so user doesn't lose stuff they input before saving (only for new events in reality, otherwise we'd have to storage changes only somehow..)
    // see https://github.com/astoilkov/use-local-storage-state

    const [eventUserInfos, setEventUserInfos, { removeItem: removeEventUserInfos }] = useLocalStorageState<EventUserInfo[]>(
        'CreateEventUserInfos', { defaultValue: [] }
    )

    const [event, setEvent, { removeItem: removeEventDetailsItem }] = useLocalStorageState<OptionalIdDeep<SimpleEvents.Event>>(
        'CreateEvent', { defaultValue: null }
    )

    const [liveValidate, setLiveValidate] = useState(false)

    async function onChange({ event, eventUserInfos }: EventParams) {
        setEventUserInfos(eventUserInfos)
        setEvent(event)
    }

    function removeLocalStorageCache() {
        removeEventUserInfos()
        removeEventDetailsItem()
    }

    const [saveEvent, saveEventIsPending] = useSaveEventAction()

    async function save() {
        setLiveValidate(true) // start doing the live validation after first save
        try {
            // Don't save if client-side validation fails
            if (formRef.current && !formRef.current.checkValidity()) return

            const { event: savedEvent } = await saveEvent({
                organisationId,
                event: 'id' in event ? pickKeys(event, 'id', ...proUpdatableEventFields) : pickKeys(event, ...proUpdatableEventFields),
                eventUserInfos,
                removeEventUsersById: []
            })

            removeLocalStorageCache()
            navigate(`/events/${savedEvent.id}/view`)
        } catch (e) {
            toast.error(e.message || "Error saving", {
                autoClose: 5000,
                closeButton: false,
                position: 'top-center',
                // className: 'small-toast'
            })
        }
    }

    return (
        <div className="event-edit relative">
            <Cover center translucent loading enabled={saveEventIsPending} />
            <div style={{ maxWidth: 500, marginBottom: 40 }}>
                <h2>Create event</h2>
                <p>Enter in the details of the event below.</p>
            </div>
            <EventForm
                ref={formRef}
                event={event}
                eventUserInfos={eventUserInfos}
                removeEventUsersById={[]}
                liveValidate={liveValidate}
                onChange={onChange}
                hideAdminOptions
            />
            <div className="cols cols--spaced cols--center">
                <button
                    className="btn"
                    type="submit"
                    onClick={() => save()}
                >
                    Create
                </button>
                <Link to="/events">
                    <button
                        className='btn btn--secondary'
                        onClick={removeLocalStorageCache}
                    >
                        Cancel
                    </button>
                </Link>
            </div>
        </div>
    )
}