import axios, { AxiosResponse } from 'axios'
import * as React from 'react'
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react"
import { useConfig } from '../state'
import { eventMediaUploadPath } from '../utils'
import mediaInfo from 'mediainfo.js'
import { UploadedAsset } from 'common/types/commonTypes'
import { SimpleEvents } from 'common/types/eventService'
import { filter, find, isEqual, pick, findIndex } from 'lodash'
import { useAddAction, useUpdateAction } from '../actions'
import {useImmer} from "use-immer"
import * as uuid from 'uuid'

type Upload = {
    progress?: number
    status?: "uploading" | "processing" | "done" | "failed"
    uploadId: string
    visualId: string
    file: File
    eventId: string
    component: SimpleEvents.VisualComponentData
}

type Uploads = Upload[]

const Context = createContext<VisualComponentUploadContext>(null)


interface VisualComponentUploadContext {
    uploads: Uploads
    doUpload: (visualId: string, eventId: string, component: SimpleEvents.VisualComponentData, file: File) => void
    removeUpload: (uploadId: string) => void
    retryUpload: (uploadId: string) => void
}

export function useVisualComponentUploadStatus(id: string) {
    const ctx = useContext(Context)
    if (!ctx) throw new Error('Missing an visual component upload context, include <VisualComponentUploadContext> in your root!')

    const {uploads} = ctx
    
    return uploads[id]
}

export function useVisualComponentsUploadStatus(visualId: string) {
    const ctx = useContext(Context)
    if (!ctx) throw new Error('Missing an visual component upload context, include <VisualComponentUploadContext> in your root!')

    const {uploads} = ctx
    
    return filter(uploads, v => v.visualId === visualId)
}

export function useVisualComponentUploadsContext() {
    const ctx = useContext(Context)
    if (!ctx) throw new Error('Missing an audio context, include <VisualComponentUploadContext> in your root!')

    const {uploads, doUpload, removeUpload, retryUpload} = ctx
    
    return {
        uploads,
        doUpload,
        removeUpload,
        retryUpload
    }
}


export function VisualComponentUploadProvider({ children }: { children: ReactNode }) {
    const config = useConfig()
    const [add] = useAddAction()
    const [uploads, setUploads] = useImmer<Uploads>([])
    
    function setUpload(upload: Partial<Upload> & {uploadId: string}){
        setUploads(uploads => {
            const {uploadId} = upload
            const index = findIndex(uploads, {uploadId})
            if(index > -1){
                uploads[index] = {
                    ...uploads[index],
                    ...upload
                }
            }else{
                uploads.push(upload as Upload)
            }
        })
    }

    function removeUpload(uploadId: string){
        setUploads(uploads => {
            return filter(uploads, v => v.uploadId !== uploadId)
        })
    }

    async function retryUpload(uploadId: string){
        const upload = find(uploads, {uploadId})
        if(upload){
            const {uploadId, visualId, eventId, component, file} = upload
            await doUpload(visualId, eventId, component, file, uploadId)
        }
    }

    //upload is triggered here
    //visualComponent creation is done in the S3
    async function doUpload(visualId: string, eventId: string, component: SimpleEvents.VisualComponentData, file: File, incomingUploadId?: string){
        const formData = new FormData();
        formData.append('resource', file);
        formData.append('context', JSON.stringify({
            visualComponent: {
                ...component
            },
            visualId
        }));
        const uploadId = incomingUploadId ?? uuid.v4()
        setUpload({
            uploadId, 
            visualId, 
            file,
            eventId,
            component,
            status: "uploading", 
            progress: 0
        })

        try{
            const result = await axios.post(
                eventMediaUploadPath(eventId, config.locations.media),
                formData,
                { 
                    onUploadProgress: (event) => {
                        setUpload({
                            uploadId, 
                            visualId, 
                            status: "uploading", 
                            progress: Math.round((event.loaded * 100) / event.total)
                        })
                    }
                }
            )

            removeUpload(uploadId)
    
            // const uploadedAsset : UploadedAsset = result.data
        
            // const newComponent : SimpleEvents.VisualComponentData = {
            //     ...component,
            //     filename: uploadedAsset.filename,
            //     type: (uploadedAsset.type as any),
            //     mimeType: uploadedAsset.mimeType,
            //     location: uploadedAsset.location,
            //     duration: 'duration' in uploadedAsset ? uploadedAsset.duration : undefined,
    
            // }
    
            // if (uploadedAsset.type === "video") {
            //     newComponent.hasAudio = uploadedAsset.hasAudio
            //     newComponent.includeAudio = uploadedAsset.hasAudio
            //     newComponent.width = uploadedAsset.width
            //     newComponent.height = uploadedAsset.height
            // }
    
            // await add.visualComponent(newComponent)

        }catch(e){
           setUpload({
                uploadId,
                visualId,
                progress: 0,
                status: "failed"
           })
        }
    }

    return <Context.Provider value={{
        doUpload,
        removeUpload,
        retryUpload,
        uploads
    }}>
        {children}
    </Context.Provider>
}