import { Popover } from "@mantine/core";
import Action from "common/frontend/components/Action";
import { useConfig, useIsAdmin } from "common/frontend/state";
import { DateTime } from "luxon";
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useRecoilState } from "recoil";
import DigitalSignageGrid from "../components/DigitalSignageGrid";
import ProResourceSelect from "../components/ProResourceSelect";
import { digitalSignageState, useSelectedOrganisation } from "../state";
import "./DigitalSignagePage.scss";
import OrganisationSelect from "../components/OrganisationSelect";

export function DigitalSignagePage() {

    const [date, setDate] = useState(DateTime.utc().toFormat("yyyy-MM-dd"))
    // const [selectedVenue, setselectedVenue] = useState(null)
    // const [selectedSign, setselectedSign] = useState(null)
    const [state, setState] = useRecoilState(digitalSignageState)
    const { selectedSign, selectedVenue, selectedOrganisation } = state;
    const isAdmin = useIsAdmin()
    const config = useConfig()

    const setVenue = (value) => {
        setState(v => ({
            ...v,
            selectedVenue: value,
            selectedSign: undefined
        }))
    }

    const setSign = (value) => {
        setState(v => ({
            ...v,
            selectedSign: value
        }))
    }

    const setOrganisation = (value) => {
        setState(v => ({
            ...v,
            selectedOrganisation: value,
            selectedSign: undefined,
            selectedVenue: undefined
        }))
    }


    return <div className="digital-signage-page">
        <div className="digital-signage-page__search">
            <div style={{ display: "flex", gap: 10 }}>
                {
                    state.selectedSign &&
                    <>
                        <Link to="/digital-signage/add">
                            <button className='btn btn--secondary'>Add</button>
                        </Link>
                        <Link to="/digital-signage/background">
                            <button className='btn btn--secondary'>Background</button>
                        </Link>
                        <Popover position="bottom-start" withArrow shadow="md">
                            <Popover.Target>
                                <button className="btn btn--secondary">Get URLs</button>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", alignItems: "center", gap: 10 }}>
                                    <span>Landscape</span>
                                    <UrlBox value={`${config.baseUrl.digitalSignage}/${selectedSign.key}`} />
                                    <span>Portrait</span>
                                    <UrlBox value={`${config.baseUrl.digitalSignage}/${selectedSign.key}?portrait=true`} />
                                </div>
                            </Popover.Dropdown>
                        </Popover>
                    </>
                }
            </div>
            <div className="form-input form-input--wesley">
                <fieldset>
                    <legend>Organisation</legend>
                    <OrganisationSelect
                        value={selectedOrganisation}
                        onChange={setOrganisation}
                        noOptionsLabel="Select an organisation"
                        type="venue"
                    />
                </fieldset>
                <fieldset>
                    <legend>Venue</legend>
                    <ProResourceSelect
                        disabled={!selectedOrganisation}
                        resourceType="venue"
                        value={selectedVenue}
                        orderBy={{ field: 'name', order: 'asc' }}
                        onChange={setVenue}
                        organisationId={selectedOrganisation?.id}
                        noOptionLabel="Select a venue"
                    />
                </fieldset>
                <fieldset>
                    <legend>Sign</legend>
                    <ProResourceSelect
                        disabled={!selectedVenue}
                        resourceType="digitalSign"
                        filters={{resolveLinkTypes: ["digitalSignTemplate"]}}
                        value={selectedSign}
                        orderBy={{ field: 'name', order: 'asc' }}
                        onChange={setSign}
                        venueId={selectedVenue?.id}
                        noOptionLabel="Select a sign"
                        organisationId={selectedOrganisation?.id}
                    />
                </fieldset>
                <fieldset>
                    <legend>Date</legend>
                    <input type="date" value={date} onChange={event => setDate(event.target.value)}></input>
                </fieldset>
            </div>
        </div>
        {
            selectedSign && <DigitalSignageGrid signId={selectedSign.id} date={date} />
        }
    </div>
}

export function UrlBox({ value }: { value: string }) {

    const onClick = () => {
        navigator.clipboard.writeText(value)
        toast('Copied', {
            autoClose: 500,
            closeButton: false,
            position: 'bottom-center',
            className: 'small-toast'
        })
    }

    return <div className="rounded-box pad" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Link to={value} target="_blank">
            {value}
        </Link>
        <Action icon="Copy" onClick={onClick} />
    </div>
}