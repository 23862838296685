
import { ContentQuality, SimpleEvents } from 'common/types/eventService'
import { produce } from 'immer'
import * as React from 'react'
import { forwardRef, memo, useMemo, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { useAsyncEffect } from 'use-async-effect'
import { useDeepChange } from '../../hooks'
import VisualPreview from './VisualPreview'
import { Player, PlayerSettings, Project, Stage, StageSettings } from '@motion-canvas/core'
import {SlideParams, templates} from "./templates/templates"
import { useResizeDetector } from "react-resize-detector"
import {useEffect} from 'react';

export interface VisualComponentPreviewProps {
    eventId: string
    component: SimpleEvents.VisualComponent
    contentQuality: ContentQuality
    aspectRatio: "16/9" | "9/16"
}

const VisualComponentPreview = forwardRef<HTMLCanvasElement, VisualComponentPreviewProps>(({ eventId, component: incomingComponent, contentQuality, aspectRatio }, forwardedRef) => {
    const component = useMemo(() => {
        return produce(incomingComponent, draft => {
            delete draft.crop
        })
    }, [incomingComponent])

    const renderComponent = useDeepChange(component)

    return (
        <CanvasPreview
            ref={forwardedRef}
            eventId={eventId}
            component={renderComponent}
            contentQuality={contentQuality}
            aspectRatio={aspectRatio}
        />
    )
})

enum State {
    Initial = 'initial',
    Mounted = 'mounted',
    Loading = 'loading',
    Ready = 'ready',
    Error = 'error',
}

export const CanvasPreview = memo(forwardRef<HTMLCanvasElement,VisualComponentPreviewProps>(({
    eventId,
    component
}, forwardedRef) => {
    
    const playing = useRef<boolean>(false)
    const abortController = useRef<AbortController>();
    const player = useRef<Player>();
    const state = useRef<State>(State.Initial)
    const defaultSettings = useRef<PlayerSettings & StageSettings>()
    const stage = useRef<Stage>(new Stage());
    const canvas = useRef<HTMLCanvasElement>(stage.current.finalBuffer);
    const project = useRef<Project>()
    
    const { width = 0, height = 0, ref: detectRef } = useResizeDetector<HTMLDivElement>({ refreshMode: 'debounce', refreshRate: 0 });
    const setState = (newState: State) => {
        state.current = newState;
    }

    useAsyncEffect(async () => {
        if(state.current === State.Ready){
            updateSettings()
        }
        if(width && height && state.current === State.Mounted){
            await cue()
        }
    }, [width, height])

    useEffect(() => {
        detectRef.current.prepend(canvas.current)
        setState(State.Mounted)
    }, [])

    useEffect(() => {
        player.current?.setVariables(getVariables())
        //@ts-ignore - This is dodgy as it's calling a private function, but it works. Hopefully motion-canvas exposes this soon...
        player.current?.requestRecalculation()
        player.current?.playback.reload()
        render()
    }, [component])

    const getVariables = () => {
        const {adjust, crop, type} = component
        const {filename} = component
        const previewSlide : SlideParams = {
            type: "visualPreview",
            //@ts-ignore
            userMedia: [{adjust, type, filename, objectFit: "fit", contentQuality: "preview"}]
        }
        const templateParams = {
            eventId,
            slides: [
                previewSlide
            ]
        }
        return {
            ...project.current.variables,
            templateParams
        }
    }

    const cue = async () => {
        abortController.current?.abort();
        abortController.current = new AbortController();
        
        
        setState(State.Loading);

        //To be replaces with dynamic import from S3 when we switch to Vite...
        project.current = templates.visualComponentPreview.default;

        defaultSettings.current = project.current.meta.getFullRenderingSettings();
        const newPlayer = new Player(project.current);
        newPlayer.setVariables(getVariables());

        newPlayer?.onRender.unsubscribe(render);
        newPlayer?.togglePlayback(false);
        newPlayer?.deactivate();
        newPlayer.toggleAudio(false)
        newPlayer.toggleLoop(false)

        if (player.current) {
            player.current.onRender.unsubscribe(render);
        }


        player.current = newPlayer;
        updateSettings();
        player.current.onRender.subscribe(render);
        player.current.togglePlayback(playing.current);
        player.current?.activate()
        setState(State.Ready);
    }

    const updateSettings = () => {
        const resolutionScale = height / defaultSettings.current.size.height;
        const settings: PlayerSettings & StageSettings = {
            ...defaultSettings.current,
            resolutionScale,
            background: "#FFFFFFFF",
            fps: 25
        };

        stage.current.configure(settings);
        player.current.configure(settings);
    }

    const render = async () => {
        if (player.current) {
            try{
                await stage.current.render(
                    player.current.playback.currentScene,
                    player.current.playback.previousScene,
                );
            }catch(e){
                console.error(e)
            }
            
        }
    };
    
    return <div ref={detectRef} className="visual-player"/>
}))

export default VisualComponentPreview