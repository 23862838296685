import { Form } from 'common/frontend/components/Form'
import { useRequestTrackAction } from 'common/frontend/actions'
import { TrackRequest, trackRequestFieldNames } from 'common/types/commonTypes'
import { JSONSchema7 } from 'json-schema'
import * as React from 'react'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useRefreshTrackRequestJobs } from '../state'
import TrackBox from '../components/TrackBox'
import { useTrack } from '../hooks'

const schema: JSONSchema7 = {
    type: 'object',
    required: ['type', 'details'],
    properties: {
        type: {
            type: "string",
            title: trackRequestFieldNames.type,
            enum: ["query", "edit"],
            //@ts-ignore
            enumNames: ["Track query", "Track edit"]
        },
        details: {
            type: "string",
            title: trackRequestFieldNames.details,
        },
    }
}

const uiSchema = {
    details: {
        "ui:widget": "textarea",
        "ui:options": {
            rows: 6,
        },
    }
}

const initialTrackRequest: TrackRequest = {
    type: 'query',
    details: ''
}

export default function MusicRequestPage() {
    const navigate = useNavigate()
    const [trackRequest, setTrackRequest] = useState(initialTrackRequest)
    const [requestTrack, isPending] = useRequestTrackAction()
    const refresh = useRefreshTrackRequestJobs()
    const { trackId } = useParams()
    const track = useTrack(trackId)
    
    async function onSubmit() {
        await requestTrack({ 
            trackRequest,
            trackInfo: track
        })
        toast('Your request was made')
        setTrackRequest(initialTrackRequest)
        navigate(-1) // go back, as we might have come from music page or visual edit page
        refresh()
    }

    return (
        <div className="music-request-page">
            <section className="section--grey cols container-padding">
                <div style={{ maxWidth: 600 }}>
                    <h3>Music request</h3>
                    <p>Need some more information about a track, or need a track editted? Fill in as much information as you can below, which will be sent to the Wesley music team.</p>
                    {
                        track && <TrackBox id={track.id} track={track} />
                    }
                    <Form
                        theme="wesley"
                        jsonSchema={schema}
                        uiSchema={uiSchema}
                        value={trackRequest}
                        onChange={data => setTrackRequest(data.formData)}
                        onSubmit={() => onSubmit()}
                    >
                        <div className="cols cols--spaced" style={{ alignItems: 'center' }}>
                            <button
                                type="submit"
                                className="btn btn--long"
                            >
                                Submit request
                            </button>
                            <button onClick={() => navigate(-1)} className="btn btn--secondary">
                                Cancel
                            </button>
                        </div>
                    </Form>
                </div>
            </section>
        </div>
    )
}