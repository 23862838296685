import { useEvent, useVisualTemplates } from 'common/frontend/state'
import * as React from 'react'
import { VisualCoverImage } from './VisualCoverImage'
import { templates } from './templates/templates'
import { TemplateDescription } from './templates/templates';
import { Project } from '@motion-canvas/core';
import { find, map } from 'lodash';
import { useEventId } from 'common/frontend/state';
import { BDuration } from '@busby/esb';
import { getBemClasses } from 'common/frontend/utils';
import { useEffect, useRef, useState } from 'react';
import validator from '@rjsf/validator-ajv8';
import { WidgetProps, getDefaultFormState } from '@rjsf/utils';
import { VisualComponentImage } from './VisualComponentImage';
import { getVisualProject } from './VisualUtils';
type VisualTemplateCarouselProps = {
    value: any
    onChange: (value) => void
}

export function VisualTemplateCarousel({ value, onChange }: VisualTemplateCarouselProps) {
    const options = useVisualTemplates()
    const eventId = useEventId()
    const refs = useRef<{ [key: string]: HTMLDivElement }>({})
    const [ready, setReady] = useState<{ [key: string]: boolean }>({})
    const [initialised, setInitialised] = useState(false)
    const event = useEvent()
    
    const onClick = (projectId) => {
        const template = find(options, { projectId })
        onChange(template)
    }

    useEffect(() => {
        if (!initialised) {
            let isReady = true;
            for (let { projectId } of options) {
                if (!ready[projectId]) {
                    isReady = false;
                }
            }

            if (isReady) {
                const ref = refs.current[value.projectId]
                ref.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                setInitialised(true)
            }
        }
    }, [ready])

    const getPreview = (projectId) => {
        const project = getVisualProject(event.room.aspectRatio, projectId)
        const templateDescription: TemplateDescription = project.variables.templateDescription as TemplateDescription;
        let slide = find(templateDescription.slideTypes, v => !v.media)

        let captions = {}
        if (slide?.metadataForm) {
            captions = getDefaultFormState(validator, slide.metadataForm.schema);
        }

        const templateComponent: any = { slideType: slide?.type ?? "carouselPreview", captions }
        
        const selected = value?.projectId === projectId
        const className = getBemClasses("visual-tribute-carousel__preview", { selected })
        return <div key={projectId} id={projectId} ref={ref => refs.current[projectId] = ref} className={className} onClick={() => onClick(projectId)}>
            <VisualComponentImage onReady={() => setReady({ ...ready, [projectId]: true })} aspectRatio={event.room.aspectRatio} template={projectId} eventId={eventId} component={templateComponent} height={200} />
        </div>
    }

    return <div className="visual-tribute-carousel">
        {
            map(options, v => getPreview(v.projectId))
        }
    </div>
}

export function VisualTemplateCarouselWidget(props: WidgetProps) {
    const { id, title, required: requiredOption } = props.options
    const { value, onChange, required: requiredProp } = props
    const required = Boolean(requiredOption || requiredProp)
    return (
        <div className="visual-tribute-carousel_widget rounded-box">
            <VisualTemplateCarousel value={value} onChange={onChange} />
        </div>
    )
}
