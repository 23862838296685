import VisualComponentEdit, { VisualComponentEditModal } from '../components/visuals/VisualComponentEdit'
import { useEvent } from 'common/frontend/state'
import { SimpleEvents } from 'common/types/eventService'
import { sample } from 'lodash'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { StoriesDefinition } from "./StoriesPage"

const stories: StoriesDefinition = {
    name: 'VisualEntryEdit',
    stories: [
        {
            name: 'Open on page',
            component: () => {
                const {
                    entry,
                    nextEntry
                } = useRandomEntry()

                if (!entry) return <NoEntries />

                return (
                    <div>
                        <button className="btn" onClick={nextEntry}>Pick Next Entry</button>
                        <div
                            className="rounded-box"
                            style={{
                                width: '100%',
                                marginTop: 20
                            }}
                        >
                            <VisualComponentEdit
                                aspectRatio='16/9'
                                template='leaves'
                                key={entry.id}
                                component={entry}
                                onCancel={() => { }}
                            />
                        </div>
                    </div>
                )
            }
        },
        {
            name: 'Open in <Modal>',
            component: () => {
                const {
                    entry: resource,
                    nextEntry: nextResource
                } = useRandomEntry()
                const [open, setOpen] = useState(false)

                if (!resource) return <NoEntries />

                function clickOpen() {
                    nextResource()
                    setOpen(true)
                }

                return (
                    <div>
                        <button
                            className="btn"
                            onClick={() => clickOpen()}
                        >
                            Open Modal
                        </button>
                        <VisualComponentEditModal
                            aspectRatio='16/9'
                            template='leaves'
                            key={resource.id}
                            opened={open}
                            onClose={() => setOpen(false)}
                            component={resource}
                            onDone={entry => console.log('would save entry!', entry)}
                        />
                    </div>
                )
            }
        }
    ]
}

export default stories

function useRandomEntry() {
    const event = useEvent()
    const entries = useMemo(() => findEntries(event, 'image'), [event])
    const [resource, setResource] = useState(sample(entries))

    function findRandomResource() {
        return sample(findEntries(event, 'image'))
    }

    function nextResource() {
        let next = findRandomResource()
        // Always pick a different one if we have multiple
        if (entries.length > 1) {
            while (next === resource) {
                next = findRandomResource()
            }
        }
        setResource(next)
    }

    return {
        entry: resource,
        nextEntry: nextResource
    }
}

function NoEntries() {
    return (
        <div>
            <p>no image entries available</p>
            <p>it gets them from your currently logged in session</p>
        </div>
    )
}

function findEntries(event: SimpleEvents.Event, type: 'image' | 'video'): SimpleEvents.VisualComponent[] {
    if (!event) return []
    const entries = []
    for (const visual of event.visuals) {
        for (const component of visual.components) {
            if (component.type === type) {
                entries.push(component)
            }
        }
    }
    return entries
}