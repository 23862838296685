import * as React from 'react'
import ProgressBar from '../components/ProgressBar'
import { StoriesDefinition } from './StoriesPage'

const stories: StoriesDefinition = {
    name: 'ProgressBar',
    stories: [
        {
            name: '7 out of 10',
            component: () => <ProgressBar loaded={7} total={10} />
        }
    ]
}

export default stories