import { wesleyDebugNamespace } from '../../../utils'
import { SearchableContent, SearchIndex, SearchResultHighlights } from '../searchModel'

const debug = wesleyDebugNamespace.extend('simple-search')

export function createSimpleIndex(entries: SearchableContent[]): SearchIndex {
    const index = entries.map(entry => ({
        entry,
        titleLower: entry.title.toLowerCase(),
        contentLower: entry.content.toLowerCase()
    }))
    return {
        search(queryString) {
            if (queryString.length < 3) return []
            const queryStringLower = queryString.toLowerCase()
            return index.map(({ entry, titleLower, contentLower }) => {
                const titleIndex = titleLower.indexOf(queryStringLower)
                const contentIndex = contentLower.indexOf(queryStringLower)
                if (titleIndex === -1 && contentIndex === -1) return
                const highlights: SearchResultHighlights = { title: null, content: [] }

                // function extractTitleHighlight(index: number) {

                //     let currentIndex = 0
                //     const endIndex = index + entry.title.length
                //     if (currentIndex < index) {
                //         // the bit of text before the highlight
                //         highlight.before = fieldValue.substring(currentIndex, startIndex)
                //     }
                //     // the highlighted bit
                //     highlight.term = fieldValue.substring(startIndex, endIndex)
                //     currentIndex = endIndex
                // }
                // // ... if we have some left over add it
                // if (currentIndex < fieldValue.length) {
                //     highlight.after = fieldValue.substring(currentIndex, fieldValue.length)
                // }
                // }

                function extractHighlight(text: string, index: number, extraCharCount = 10) {
                    const beforeIndex = Math.max(0, index - extraCharCount)
                    const afterStartIndex = index + queryString.length
                    const afterEndIndex = Math.min(afterStartIndex + extraCharCount, text.length)
                    return {
                        before: text.substring(beforeIndex, index),
                        beforeHasMore: beforeIndex > 0,
                        term: text.substring(index, index + queryString.length),
                        after: text.substring(afterStartIndex, afterEndIndex),
                        afterHasMore: afterEndIndex < text.length
                    }
                }

                if (titleIndex !== -1) {
                    highlights.title = extractHighlight(entry.title, titleIndex, Infinity)
                }

                if (contentIndex !== -1) {
                    for (const index of allIndexesOf(contentLower, queryStringLower)) {
                        highlights.content.push(extractHighlight(entry.content, index))
                    }
                }

                return {
                    entry,
                    highlights
                }
            }).filter(Boolean)
        }
    }
}

// like str.indexOf(substr), but gives you all indexes...
function allIndexesOf(str: string, substr: string): number[] {
    return str
        .split(substr)
        .slice(0, -1)
        .map(val => val.length)
        .map((_, i, lengths) => (
            lengths
                .slice(0, i + 1)
                .reduce((a, b) => a + b, i * substr.length)
        ))
}