import { produce } from 'immer'
import * as React from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { useAddAction, useDeleteAction } from '../../actions'
import { useConfirmModal } from '../../hooks'
import { useEventId, useVisualTemplates, useVisuals, visualsState, useEvent } from '../../state'
import { wesleyDebugNamespace } from '../../utils'
import VisualBox from './VisualBox'
import { VisualAddModal } from './VisualAddModal'
import { useState } from 'react'

const debug = wesleyDebugNamespace.extend('visual-selection-page')

// Now visuals are always created by a pro/admin in the event editor
// ... but incase that changes, we could put it back :)
const USER_CAN_CREATE_VISUALS = false
function requireVisualCreatingPower() {
    if (!USER_CAN_CREATE_VISUALS) {
        throw new Error('users cannot create/delete visuals')
    }
}

export default function VisualsOverview() {
    const eventId = useEventId()
    const visualTemplates = useVisualTemplates()
    const visuals = useVisuals()
    const setVisuals = useSetRecoilState(visualsState)
    const [add, isPending] = useAddAction()
    const [deleteAction, deleteIsPending] = useDeleteAction()
    const [showAddModal, setShowAddModal] = useState(false)
    
    const {
        modal,
        confirm
    } = useConfirmModal({
        isPending: deleteIsPending,
        title: 'Are you sure you want to remove this visual?'
    })

    async function createVisual({name, template}) {
        requireVisualCreatingPower()
        if (visualTemplates.length === 0) {
            throw new Error('no templates available')
        }
        // @ts-expect-error
        const { resource: createdVisual } = await add.visual({
            name,
            fadeInDuration: {
                duration: 1000,
                frameRate: 'milli'
            },
            slideDuration: {
                duration: 10000,
                frameRate: 'milli'
            },
            duration: {
                duration: 0,
                frameRate: 'milli'
            },
            template,
            templateParams: {
                message: ''
            },
            useAudioFromVideo: false,
            components: []
        })
        debug('created visual %o', createdVisual)

        setVisuals(produce(draft => {
            draft.push(createdVisual)
        }))

        setShowAddModal(false)
    }

    async function onRemove(visual) {
        requireVisualCreatingPower()
        if (await confirm()) {
            debug('remove %o', { visual })
            // @ts-expect-error
            await deleteAction.visual(visual.id)
            setVisuals(produce(draft => {
                const idx = draft.findIndex(item => item.id === visual.id)
                if (idx !== -1) {
                    draft.splice(idx, 1)
                }
            }))
        }
    }
    return (
        <div
            className="rounded-box"
            style={{ padding: '10px 20px' }}
        >
            {USER_CAN_CREATE_VISUALS && (
                <button
                    className="btn btn--secondary"
                    style={{ marginBottom: 10, width: "100%" }}
                    onClick={() => setShowAddModal(true)}
                >
                    Add visual
                </button>
            )}
            <div
                className="visuals-grid"
                style={{
                    '--grid-columns': 4
                } as React.CSSProperties}
            >
                {visuals.map(visual => (
                    <Link
                        key={visual.id}
                        to={visual.id}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        <VisualBox
                            eventId={eventId}
                            visual={visual}
                            clickToPlay={false}
                            onRemove={USER_CAN_CREATE_VISUALS ? () => onRemove(visual) : undefined}
                        />
                    </Link>
                ))}
            </div>
            {modal}
            {
                showAddModal && <VisualAddModal opened onClose={() => setShowAddModal(false)} onSubmit={(data) => createVisual(data) }/>
            }
        </div>
    )
}