import { debounce } from 'lodash'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useEventPathTo } from '../eventPaths'
import MusicTabs from './MusicTabs'
import Scheduler from './Scheduler'
import SchedulerContext from './SchedulerContext'
import { useEventSource } from '../state'
import SchedulerViewOnly from './SchedulerViewOnly'


export default function MusicSelection() {
    const to = useEventPathTo()
    const eventSource = useEventSource()

    return (
        <div className="music-selection">
            <SchedulerContext>
                <div className="cols cols--spaced">
                    <div>
                        <h2>Music selection</h2>
                        <p>Drag tracks from the search box into the schedule on the right.</p>
                        <p>You may also upload your own track or ask Wesley to find a track for you.</p>
                    </div>
                </div>
                <div className="cols cols--spaced" style={{ alignItems: 'center', justifyContent: "end", marginBottom: '10px' }}>
                    <div className="cols">
                        <Link
                            style={{ marginLeft: 'auto', textAlign: 'right' }}
                            to={to('music/request')}
                        >
                            {
                                eventSource === "newSystem" && <button className='btn btn--long'>
                                    Request missing track
                                </button>
                            }
                        </Link>
                    </div>
                </div>
                {
                    eventSource === "newSystem" &&
                    <div className="cols cols--spaced">
                        <MusicTabs target="scheduleItem" />
                        <div className="music-selection__selected-tracks rounded-box">
                            <h4>Selected tracks</h4>
                            <Scheduler
                                types={['audio']}
                            />
                        </div>
                    </div>
                }
                {
                    eventSource === "oldSystem" &&
                    <div className="music-selection__selected-tracks rounded-box">
                        <h4>Selected tracks</h4>
                        <SchedulerViewOnly
                            types={['audio']}
                        />
                    </div>
                }
            </SchedulerContext>
        </div>
    )
}