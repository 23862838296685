import StreamingViewers from 'common/frontend/components/StreamingViewers'
import { usePageTitle, useScreenSize } from 'common/frontend/hooks'
import { useEvent, useEventFeatures } from 'common/frontend/state'
import * as React from 'react'
import { EventDetails } from './ReviewPage'
import { SmallScreenStreamingList } from './StreamingPage'

export default function SubmittedPage() {
    usePageTitle('Submitted')
    const event = useEvent()
    const { isSmallScreen } = useScreenSize()
    if (!event) return null
    return isSmallScreen ? <SmallScreenSubmittedPage /> : <BigScreenSubmittedPage />
}

function BigScreenSubmittedPage() {
    usePageTitle('Submitted')
    const event = useEvent()
    const { hasStreaming } = useEventFeatures()

    if (!event) return null

    return (
        <div className="review-page">
            <section className="section--grey container-padding">
                <div className="cols cols--50 cols--spaced" style={{ marginBottom: 20 }}>
                    <div>
                        <h3>Your event</h3>
                        <p>The details of your Wesley Events package are summarised here.</p>
                    </div>
                </div>
                <div className="cols cols--50 cols--spaced">
                    <EventDetails />
                    {hasStreaming && (
                        <div className="rounded-box pad">
                            <div className="cols cols--center">
                                <h4>Invited webcast viewers</h4>
                            </div>
                            <StreamingViewers />
                        </div>
                    )}
                </div>
            </section>
        </div>
    )
}

function SmallScreenSubmittedPage() {
    return (
        <div className="review-page">
            <section className="section--grey container-padding">
                <EventDetails />
            </section>
            <section className="section--grey container-padding">
                <div className="rounded-box pad">
                    <h4>Invited webcast viewers</h4>
                    <SmallScreenStreamingList />
                </div>
            </section>
        </div>
    )
}