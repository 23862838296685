import * as _ from 'lodash';

export type ModifierMap<T extends string = string> = Partial<Record<T, boolean>>;

export const getBemClasses = (
    block: string,
    modifier?: string|string[]|ModifierMap
): string => {

    let modStr = '';

    if (_.isString(modifier)) {
        modStr = block+'--'+modifier;
    } else if (_.isArray(modifier)) {
        modStr = _.map(modifier, (mod) => block+'--'+mod).join(' ');
    } else if (_.isPlainObject(modifier)) {
        modStr = _.map(_.keys(_.pickBy(modifier, (set: boolean, mod) => set)),(mod) => block+'--'+mod).join(' ');
    }


    return _.trim(block+' '+modStr);
};