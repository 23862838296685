import { produce, original } from 'immer'
import { SimpleEvents } from 'common/types/eventService'
import * as React from 'react'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { current } from "immer"
import { useOrganisationIdForQuery, useProEventDetails, useProResourceProperties, useSetProEventDetails, useTopLevelOptions } from '../state'
import './EventOptionForm.scss'
import { useEventId, useEventSource, useIsAdmin, useIsEventSubmitted } from 'common/frontend/state'
import { useEventPathTo } from 'common/frontend/eventPaths'
import VisualBox from 'common/frontend/components/visuals/VisualBox'
import { useConfirmModal, useFormModal } from 'common/frontend/hooks'
import { StringResourcePropertyType } from '@busby/esb'
import { OptionalIdDeep } from 'common/types/typeUtils'
import { differenceBy, isEmpty, map, sortBy, uniqBy } from 'lodash'
import { TextBlurWidget } from 'common/frontend/components/customWidgets'
import { bem } from 'common/frontend/utils'
import ActionMenu from 'common/frontend/components/ActionMenu'
import classnames from 'classnames'
import { Form } from 'common/frontend/components/Form'
import { validate } from "email-validator"
import Modal from "common/frontend/components/Modal"
import Action from 'common/frontend/components/Action'
import { useRefreshPinAction } from '../actions'

interface EventOptionsFormProps {
    event: OptionalIdDeep<SimpleEvents.Event>
    onChange: (event: OptionalIdDeep<SimpleEvents.Event>) => void
    readOnly: boolean
    liveValidate: boolean
}

export const EventOptionsForm = forwardRef<void, EventOptionsFormProps>(({ event, onChange, readOnly, liveValidate }: EventOptionsFormProps, forwardedRef) => {
    const organisationIdForQuery = useOrganisationIdForQuery(event)
    const options = useTopLevelOptions(organisationIdForQuery)
    const optionProperties = useProResourceProperties('option')
    const eventSubmitted = useIsEventSubmitted()
    const isAdmin = useIsAdmin()
    const capabilities = event?.room?.capabilities ?? []
    const { modal: formModal, openForm } = useFormModal()
    const { modal: confirmModal, confirm } = useConfirmModal({ message: `Are you sure you want to reset the streaming pin?` });
    const { hasCapabilities, requireCapabilities } = useCapabilities(capabilities)
    const optionRefs = useRef<any>({})
    const [refreshPin] = useRefreshPinAction()
    const savedDetails = useProEventDetails(event?.id)
    const setSavedDetails = useSetProEventDetails(event?.id)
    const eventSource = useEventSource()

    useImperativeHandle(forwardedRef, () => ({
        checkValidity(): boolean {
            try {
                for (const optionRef of Object.values(optionRefs.current) as any) {
                    if (optionRef && !optionRef.checkValidity()) {
                        return false
                    }
                }
                return true
            } catch (err) {
                console.error('error encountered whilst checking validity, assuming invalid', err)
                return false
            }
        },
    }))

    // This is where we turn it from an Option to an EventOption
    function optionToEventOption(option: SimpleEvents.Option): OptionalIdDeep<SimpleEvents.EventOption> {
        return {
            fromOption: option,

            // The fields we can specify
            category: option.category,
            name: option.name,
            params: option.params,

            // We can add these fields here initially, but the server will actually copy them fresh
            // from the fromOption, as they are not intended to be user editable
            pricePP: option.pricePP,
            priceRRP: option.priceRRP,
            requiredCapabilities: option.requiredCapabilities,
        }
    }

    async function onAddOption(option: SimpleEvents.Option) {
        let formData;
        if (!isEmpty(option.form)) {
            const { schema, ui } = option.form
            const result = await openForm({ title: option.name, schema, uiSchema: ui, customValidate: validateOptionForm })
            if (!result.answered) {
                return
            } else {
                formData = result.formData
            }
        }
        requireCapabilities(option)

        onChange(produce(event, draft => {
            if (!draft.options) draft.options = []
            const eventOption = optionToEventOption(option)
            eventOption.formData = formData

            const {type} = eventOption.params
            if(type === "singleImage" || type === "singleVideo" || type === "visual"){
                let suffixCode = 64
                const {name} = eventOption.params
                const {visuals} = event;
                for(const visual of visuals || []){
                    const [matches, suffix] = visual.name.match(`^${name} ([A-Z])$`) || []
                    if(matches){
                        let currentSuffixCode = suffix.charCodeAt(0)
                        if(currentSuffixCode > suffixCode){
                            suffixCode = currentSuffixCode
                        }
                    }
                }
                eventOption.params = {
                    ...eventOption.params,
                    name: `${eventOption.params.name} ${String.fromCharCode(suffixCode+1)}`
                }
            }

            draft.options.push(eventOption)
        }))
    }

    function onAddChildOption(childOption: SimpleEvents.Option, parentEventOption: OptionalIdDeep<SimpleEvents.EventOption>) {
        requireCapabilities(childOption)
        onChange(produce(event, draft => {
            // Find the draft version of the parent
            const draftParentOption = draft.options.find(option => original(option) === parentEventOption)
            // Add a child!
            if (!draftParentOption.children) draftParentOption.children = []
            draftParentOption.children.push(optionToEventOption(childOption))
        }))
    }

    function onEventOptionFormDataChange(option: SimpleEvents.EventOption, formData: any) {
        onChange(produce(event, draft => {
            const draftOption = draft.options.find(v => original(v) === option)
            console.log(current)
            draftOption.formData = formData
        }))
    }

    function onRemoveChildOption(childEventOption: OptionalIdDeep<SimpleEvents.EventOption>, parentEventOption: OptionalIdDeep<SimpleEvents.EventOption>) {
        onChange(produce(event, draft => {
            // Find the draft version of the parent
            const draftParentOption = draft.options.find(option => original(option) === parentEventOption)
            // Find the draft version of the child we're removing
            const idx = draftParentOption.children.findIndex(childOption => original(childOption) === childEventOption)
            if (idx !== -1) {
                draftParentOption.children.splice(idx, 1)
            }
        }))
    }

    // top level only
    async function onRemoveEventOption(eventOption: OptionalIdDeep<SimpleEvents.EventOption>) {
        onChange(produce(event, draft => {
            const idx = draft.options.findIndex(option => original(option) === eventOption)
            if (idx !== -1) {
                draft.options.splice(idx, 1)
            }
        }))
    }

    function optionsFor(category: string) {
        return sortBy(options?.filter(option => option.category === category), 'name')
    }

    function eventOptionsFor(category: string) {
        return sortBy(event.options?.filter(option => option.category === category), 'name')
    }

    async function onClickRefreshPin() {
        if (await confirm()) {
            const { pin } = await refreshPin({ eventId: event.id })
            setSavedDetails({
                event: { ...savedDetails.event, pin },
                eventUserInfos: savedDetails.eventUserInfos
            })
        }
    }

    if (!event) return null

    return (
        <div>
            {(optionProperties?.category as StringResourcePropertyType)?.enumOptions.map(category => (
                <div key={category.value}>
                    <div className="cols cols--center cols--spaced">
                        <h4>{category.label}</h4>
                        {event.pin && category.value === 'webcasts' && (event.hasStreaming || isAdmin) && (
                            <div className="rounded-box" style={{ padding: 6, display: "flex", alignItems: "center", gap: 5 }}>
                                PIN: {event.pin}
                                {
                                    isAdmin && <Action onClick={onClickRefreshPin} style={{ borderRadius: '10px', width: "initial", height: "initial" }} icon="ArrowClockwise" size={35} />
                                }
                            </div>
                        )}
                        {!eventSubmitted && (
                            <div className="cols__right">
                                <ActionMenu
                                    icon="PlusSquare"
                                    openLeft={true}
                                    entries={optionsFor(category.value).map(option => ({
                                        label: option.name,
                                        value: option,
                                        disabled: !hasCapabilities(option) || maxCountReached(option, event.options) || readOnly
                                    }))}
                                    onAction={option => onAddOption(option)}
                                />
                            </div>
                        )}
                    </div>
                    {
                        category.value === "recordings" && eventSource === "oldSystem" && event.legacyRecordingNotes && <div className='rounded-box pad' style={{marginBottom: 10}}>
                            <div style={{fontWeight: "bold", marginBottom: 5}}>Legacy recording notes</div>
                            {
                                map(event.legacyRecordingNotes.split(" / "), v => <div>{v}</div>)
                            }
                        </div>
                    }
                    {eventOptionsFor(category.value).map((eventOption, idx) => (
                        <EventOption
                            key={idx}
                            ref={ref => optionRefs.current[idx] = ref}
                            event={event}
                            eventOption={eventOption}
                            disable={readOnly}
                            capabilities={capabilities}
                            onAddChildOption={(child, parent) => onAddChildOption(child, parent)}
                            onRemoveChildOption={(child, parent) => onRemoveChildOption(child, parent)}
                            onRemove={() => onRemoveEventOption(eventOption)}
                            onEventOptionFormDataChange={(formData) => onEventOptionFormDataChange(eventOption as any, formData)}
                            liveValidate={liveValidate}
                        />
                    ))}
                </div>
            ))}
            {formModal}
            {confirmModal}
        </div>
    )
})

interface EventOptionProps {
    event: OptionalIdDeep<SimpleEvents.Event>
    eventOption: OptionalIdDeep<SimpleEvents.EventOption>
    capabilities: OptionalIdDeep<SimpleEvents.Capability>[]
    disable: boolean
    liveValidate: boolean
    onRemove: () => void
    onAddChildOption?: (childOption: SimpleEvents.Option, parentEventOption: OptionalIdDeep<SimpleEvents.EventOption>) => void
    onEventOptionFormDataChange?: (formData: any) => void
    onRemoveChildOption?: (childEventOption: OptionalIdDeep<SimpleEvents.EventOption>, parentEventOption: OptionalIdDeep<SimpleEvents.EventOption>) => void
}


const EventOption = forwardRef<void, EventOptionProps>(({ event, eventOption, capabilities, disable, liveValidate, onRemove, onAddChildOption, onEventOptionFormDataChange, onRemoveChildOption }: EventOptionProps, forwardedRef) => {
    const organisationIdForQuery = useOrganisationIdForQuery(event)
    const options = useTopLevelOptions(organisationIdForQuery)
    const { hasCapabilities, MissingCapabilitiesWarning } = useCapabilities(capabilities)
    const to = useEventPathTo()
    const eventId = useEventId() // TODO(PR): double check this is ok to use here!
    const [formData, setFormData] = useState<{ recordingEmail?: string }>(eventOption.formData)
    const linkedVisual = event.visuals?.filter(visual => visual.eventOption).find(visual => visual.eventOption.id === eventOption.id) as SimpleEvents.Visual | undefined
    const isRecording = eventOption.params.type
    const fromOption = options?.find(option => option.id === eventOption.fromOption?.id)
    const formRef = useRef<any>()

    useImperativeHandle(forwardedRef, () => ({
        checkValidity(): boolean {
            try {
                if (formRef.current) {
                    return Boolean(formRef.current.validateForm())
                } else {
                    return true
                }
            } catch (err) {
                console.error('error encountered whilst checking validity, assuming invalid', err)
                return false
            }
        },
    }))

    const {
        modal: confirmDeleteOptionModal,
        confirm: confirmDeleteOption
    } = useConfirmModal({
        title: 'Delete selected option',
        message: `Are you sure want to delete the selected option${linkedVisual ? ' and the visual' : ''}?`,
        confirmButton: 'Delete',
    })

    const {
        modal: confirmDeleteChildOptionModal,
        confirm: confirmDeleteChildOption
    } = useConfirmModal({
        title: 'Delete selected option',
        message: `Are you sure want to delete the selected option?`,
        confirmButton: 'Delete',
    })

    async function confirmOnRemove() {
        if (disable) return
        if (await confirmDeleteOption()) {
            onRemove()
        }
    }

    async function confirmOnRemoveChild(child: OptionalIdDeep<SimpleEvents.EventOption>, parent: OptionalIdDeep<SimpleEvents.EventOption>) {
        if (disable) return
        if (await confirmDeleteChildOption()) {
            onRemoveChildOption(child, eventOption)
        }
    }

    const onFormDataChange = (formData) => {
        onEventOptionFormDataChange(formData)
    }

    // find the option it came from and see if it has children

    const childOptions = fromOption?.children

    const eventOptionChildren = sortBy(eventOption?.children ?? [], 'name')
    const block = bem('event-option')
    const elChildOption = block.element('child')

    return (
        <div className={block.mod({ 'missing-capabilities': !hasCapabilities(eventOption), disable })} style={{ marginBottom: 12 }}>
            <div className="cols cols--center">
                <div style={{ fontSize: '110%', fontWeight: 500 }}>{eventOption.name}</div>
                {!disable && onRemove && (
                    <button
                        className="action action--trash cols__right"
                        style={{ '--size': '36px' } as React.CSSProperties}
                        onClick={() => confirmOnRemove()}
                    />
                )}
            </div>

            <MissingCapabilitiesWarning option={eventOption} />

            {eventOptionChildren.length > 0 && (
                <div style={{ marginTop: 6 }}>
                    {eventOptionChildren.map((child, idx) => (
                        <div
                            key={idx}
                            className={classnames(['cols', 'cols--centre', elChildOption.mod({ 'missing-capabilities': !hasCapabilities(child) })])}
                            style={{ paddingRight: 3 }}
                        >
                            <div>+ {child.name}</div>
                            {!disable && onRemoveChildOption && (
                                <button className="action action--trash cols__right" onClick={() => confirmOnRemoveChild(child, eventOption)} />
                            )}
                        </div>
                    ))}
                </div>
            )}

            {!disable && onAddChildOption && childOptions?.length > 0 && (
                <div>
                    <ActionMenu
                        label="Add option"
                        entries={childOptions.map(option => ({
                            label: option.name,
                            value: option,
                            disabled: !hasCapabilities(option) || maxCountReached(option, eventOptionChildren),
                        }))}
                        onAction={option => onAddChildOption(option, eventOption)}
                    />
                </div>
            )}

            {
                !isEmpty(fromOption.form) && <div style={{ marginTop: 20 }}>
                    <Form
                        ref={formRef}
                        theme="wesley"
                        jsonSchema={fromOption.form.schema}
                        uiSchema={fromOption.form.ui}
                        value={eventOption.formData}
                        disabled={disable}
                        readonly={disable}
                        onChange={data => onFormDataChange(data.formData)}
                        customValidate={validateOptionForm}
                        autoComplete={false}
                        liveValidate={liveValidate}
                        widgets={{ TextBlurWidget }}
                    />
                </div>
            }
            {linkedVisual && (
                <div
                    style={{
                        maxWidth: 260,
                        margin: '20px auto'
                    }}
                >
                    <Link
                        to={to(`visuals/${linkedVisual.id}`)}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        <VisualBox
                            eventId={eventId}
                            visual={linkedVisual}
                            clickToPlay={false}
                        />
                    </Link>
                </div>
            )}
            {confirmDeleteOptionModal}
            {confirmDeleteChildOptionModal}
        </div>
    )
})

function useCapabilities(capabilities: OptionalIdDeep<SimpleEvents.Capability>[]) {
    const capabilityIds = useMemo(() => capabilities.map(capability => capability.id), [capabilities])

    function hasCapabilities(option: OptionalIdDeep<SimpleEvents.Option> | OptionalIdDeep<SimpleEvents.EventOption>) {
        if (!option) return true
        const parentHasCapabilities = option.requiredCapabilities?.every(capability => capabilityIds.includes(capability.id))
        if (!parentHasCapabilities) return false
        // parent is ok, check the children
        return option.children?.every(child => hasCapabilities(child)) ?? true
    }

    function requireCapabilities(option: OptionalIdDeep<SimpleEvents.Option> | OptionalIdDeep<SimpleEvents.EventOption>) {
        if (!hasCapabilities(option)) {
            const missing = getMissingCapabilities(option)
            throw new Error('Missing the following required capabilities: ' + missing.map(capability => capability.name).join(' '))
        }
    }

    function getMissingCapabilities(option: OptionalIdDeep<SimpleEvents.Option> | OptionalIdDeep<SimpleEvents.EventOption>) {
        if (!option) return []
        const requiredCapabilities = [...option.requiredCapabilities]
        if (option.children) {
            for (const child of option.children) {
                requiredCapabilities.push(...child.requiredCapabilities)
            }
        }
        const uniqueRequiredCapabilities = uniqBy(requiredCapabilities, capability => capability.id)
        return differenceBy(uniqueRequiredCapabilities, capabilities, capability => capability.id)
    }

    function MissingCapabilitiesWarning({ option }: { option: OptionalIdDeep<SimpleEvents.Option> | OptionalIdDeep<SimpleEvents.EventOption> }) {
        const missingCapabilities = getMissingCapabilities(option)
        if (!missingCapabilities || missingCapabilities.length === 0) {
            return null
        }
        return (
            <div className="rounded-box rounded-box--warning pad" style={{ margin: '16px 0' }}>
                Missing capabilities:&nbsp;
                {missingCapabilities.map((capability, idx, entries) => (
                    <React.Fragment key={capability.id}>
                        <strong>{capability.name}</strong>
                        {idx < entries.length - 1 && ', '}
                    </React.Fragment>
                ))}
            </div>
        )
    }

    return {
        hasCapabilities,
        requireCapabilities,
        getMissingCapabilities,
        MissingCapabilitiesWarning,
    }
}

function maxCountReached(option: SimpleEvents.Option, eventOptions?: OptionalIdDeep<SimpleEvents.EventOption>[]): boolean {
    if (!option.maxCount || !eventOptions) return false
    const currentCount = eventOptions.filter(eventOption => eventOption.fromOption?.id === option.id).length
    return currentCount >= option.maxCount
}

const validateOptionForm = (formData, errors) => {
    if (formData.contactEmail && !validate(formData.contactEmail)) {
        errors.contactEmail.addError('Invalid email')
    }

    return errors;
}