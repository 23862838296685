
import { AbstractSubscriber, BDurationJson, CompositeKeyType, EsbMessageAck, GetResourcePropertiesParams, GetResourcePropertiesResult, QueryResourcesParams, Resource, ResourceGroupSignature, ResourceOrderBy, ResourceQuery, SendControlOptions, SimpleResource, SimpleResourceData } from "@busby/esb"
import { resultOrThrow } from '../universal/universalUtils'
import { AudioUpload, AudioUploadJob, SearchTrackInfo, TrackInfo, TrackRequest, TrackRequestJob, UploadedAudioAsset, VisualTributeRequest } from './commonTypes'
import { Events, events } from "./events"
import { OptionalIdDeep } from "./typeUtils"

export type VisualComponentType = SimpleEvents.VisualComponent['type']
export type StreamingViewerStatus = SimpleEvents.StreamingViewer['status']

export type ContentQuality = 'original' | 'preview'
export type AdjustParams = Events.EventsVisualComponentAdjust
export type CropParams = Events.EventsVisualComponentCrop
export type AspectRatio = CropParams['aspectRatio']
export interface SearchTracksResult {
    tracks: SearchTrackInfo[]
    hasMoreRows: boolean
}

export class EventServiceSubscriber extends AbstractSubscriber<EventService.Control, EventService.Status> {
    setDefaultControlOptions(options?: SendControlOptions): void {
        super.setDefaultControlOptions({
            fromIp: true,
            fromUser: true,
            ...options,
        })
    }

    get eventResource(): {
        add: AddEventResource,
        update: UpdateEventResource,
        delete: DeleteEventResource,
        deleteMultiple: DeleteEventResources
    } {
        return {
            add: new Proxy({}, {
                get: <RT extends keyof EventsRG & UserAddResourceType>({ }, resourceType: RT): AddEventResource[RT] => {
                    return (eventId, data: SimpleResourceData<any>, parentId) => {
                        if (!userAddResourceTypes.includes(resourceType)) throw new Error('user cannot add ' + resourceType)
                        return resultOrThrow(this.control.add({
                            eventId,
                            resourceType,
                            parentId,
                            data
                        }))
                    }
                }
            }) as AddEventResource,
            update: new Proxy({} as any, {
                get: <RT extends keyof EventsRG & UserUpdateResourceType>({ }, resourceType: RT): UpdateEventResource[RT] => {
                    return (eventId, data: SimpleResource<any>, parentId) => {
                        if (!userUpdateResourceTypes.includes(resourceType)) throw new Error('user cannot update ' + resourceType)
                        return resultOrThrow(this.control.update({
                            eventId,
                            resourceType,
                            parentId,
                            data
                        }))
                    }
                }
            }) as UpdateEventResource,
            delete: new Proxy({} as any, {
                get: <RT extends keyof EventsRG & UserDeleteResourceType>({ }, resourceType: RT): DeleteEventResource[RT] => {
                    return (eventId, resourceId) => {
                        if (!userDeleteResourceTypes.includes(resourceType)) throw new Error('user cannot delete ' + resourceType)
                        return resultOrThrow(this.control.delete({
                            eventId,
                            resourceType,
                            resourceId
                        }))
                    }
                }
            }) as DeleteEventResource,
            deleteMultiple: new Proxy({} as any, {
                get: <RT extends keyof EventsRG & UserDeleteResourceType>({ }, resourceType: RT): DeleteEventResources[RT] => {
                    return (eventId, resourceIds) => {
                        if (!userDeleteResourceTypes.includes(resourceType)) throw new Error('user cannot delete ' + resourceType)
                        return resultOrThrow(this.control.deleteMultiple({
                            eventId,
                            resourceType,
                            resourceIds
                        }))
                    }
                }
            }) as DeleteEventResources
        }
    }
}

export namespace EventService {

    // for normal users, using client authentication service
    // or professional users, using professional authentication service
    export interface ClientControl {
        getEvents: [GetEventsParams, GetEventsResult]
        getEvent: [GetEventParams, GetEventResult]

        getUsers: [GetEventUsersParams, GetEventUsersResult]
        saveUsers: [SaveEventUsersParams, SaveEventUsersResult]

        searchTracks: [SearchAudioParams, SearchAudioResult]

        getVisualTemplates: [void, GetVisualTemplatesResult]
        getLiveEventTypes: [void, GetLiveEventTypesResult]
        getScheduleItemTags: [GetScheduleItemTagsParams, GetScheduleItemTagsResult]
        getTrack: [GetTrackParams, GetTrackResult]

        add: [AddEventResourceParams, AddEventResourceResult]
        delete: [DeleteEventResourceParams, DeleteEventResourceResult]
        deleteMultiple: [DeleteEventResourcesParams, DeleteEventResourcesResult]
        update: [UpdateEventResourceParams, UpdateEventResourceResult]

        requestTrack: [RequestTrackParams, RequestTrackResult]
        requestVisualTribute: [RequestVisualTributeParams, RequestVisualTributeResult]
        getTrackRequests: [GetTrackRequestsParams, GetTrackRequestsResult]
        getAudioUploads: [GetAudioUploadsParams, GetAudioUploadsResult]

        checkAccess: [CheckAccessParams, CheckAccessResult]

        submitEvent: [SubmitEventParams, SubmitEventResult]
    }

    // for streaming viewers (not logged in, access by pin)
    export interface StreamingControl {
        streamingStart: [StreamingStartParams, StreamingStartResult]
        streamingNotifyStart: [StreamingNotifyStartParams, StreamingNotifyStartResult]
        streamingStop: [StreamingStopParams, StreamingStopResult]
        streamingReset: [StreamingResetParams, StreamingResetResult]
        streamingHold: [StreamingHoldParams, StreamingHoldResult]
        streamingEndVod: [StreamingEndVodParams, StreamingEndVodResult]
        streamingOverrideVod: [StreamingOverrideVodParams, StreamingOverrideVodResult]
        streamingGetAllStatus: [StreamingGetAllStatusParams, StreamingGetAllStatusResult]
        streamingInviteGet: [StreamingInviteGetParams, StreamingInviteGetResult]
        streamingInviteRespond: [StreamingInviteResponseParams, StreamingInviteResponseResult]
    }

    // for professional users, using professional authentication service
    export interface ProControl {
        proGetEvents: [ProGetEventsParams, ProGetEventsResult]
        proGetEvent: [ProGetEventParams, ProGetEventResult]

        proGetOrganisation: [ProGetOrganisationParams, ProGetOrganisationResult]
        proQueryOrganisations: [ProQueryOrganisationsParams, ProQueryOrganisationsResult]
        proQueryResources: [ProQueryResourcesParams, ProQueryResourcesResult]
        proGetResourceProperties: [ProGetResourcePropertiesParams, ProGetResourcePropertiesResult]
        proSaveEvent: [ProSaveEventParams, ProSaveEventResult]
        proDeleteEvents: [ProDeleteEventsParams, ProDeleteEventsResult]
        proRefreshPin: [ProRefreshPinParams, ProRefreshPinResult]

        proSetLogo: [ProSetLogoParams, ProSetLogoResult]
        proGetMinEventDate: [ProGetMinEventDateParams, ProGetMinEventDateResult]
    }

    export interface DigitalSignageControl {
        digitalSignageGetSignInfo: [DigitalSignageGetSignInfoParams, DigitalSignageGetSignInfoResult]
        digitalSignageUpdateEvent: [DigitalSignageUpdateEventParams, DigitalSignageUpdateEventResult]
        digitalSignageGetTemplates: [DigitalSignageGetTemplatesParams, DigitalSignageGetTemplatesResult]
        digitalSignageUpdateTemplate: [DigitalSignageUpdateTemplateParams, DigitalSignageUpdateTemplateResult]
    }

    // only for internal uses (i.e. server to server)
    // wesleyAuthService restricts "internal" prefixed methods
    export interface InternalControl {
        internalGetEvent: [InternalGetEvent, InternalGetEventResult]
        internalGetEvents: [InternalGetEvents, InternalGetEventsResult]
        internalCreateAudioUploadWorkflowJob: [InternalCreateAudioUploadWorkflowJob, InternalCreateAudioUploadWorkflowJobResult]
        internalRegisterProUser: [InternalRegisterProUserParams, InternalRegisterProUserResult],
        internalSyncEventOptions: [InternalSyncEventOptionsParams, EsbMessageAck]
        internalSubmitEvent: [InternalSubmitEventParams, InternalSubmitEventResult]
    }

    export type Control = ClientControl & StreamingControl & ProControl & InternalControl & DigitalSignageControl

    export interface Status {
        eventUpdated: EventUpdatedStatus
        liveEventTypesUpdated: LiveEventTypesUpdatedStatus
        streamingUpdated: StreamingUpdatedStatus
        streamingStarting: StreamingStartingStatus
        streamingStart: StreamingStartStatus
        streamingStop: StreamingStopStatus
        digitalSignageUpdated: DigitalSignageUpdatedStatus
    }
}

export type ProQueryResourcesParams = { organisationId?: string, venueId?: string } & QueryResourcesParams<CompositeKeyType<EventsRG>, events, keyof events>

export type ProQueryResourcesResult = {
    resources: SimpleResource<EventResource>[]
    hasMoreRows: boolean
    size?: number
}

export type ProGetResourcePropertiesParams = GetResourcePropertiesParams
export type ProGetResourcePropertiesResult = GetResourcePropertiesResult

export interface EventTemplateTemplate {
    sections: {
        name: string,
        duration: BDurationJson
    }[]
}

export interface ProGetEventsParams {
    organisationId?: string
    orderBy?: ResourceOrderBy<Events.Event>[]
    query?: ResourceQuery<Events.Event, events, "event">
    size?: number
    offset?: number
}

export interface ProGetEventsResult {
    events: SimpleEvents.Event[]
    hasMoreRows: boolean
    size?: number
}

/*

 When accessing users (user or pro) we do it via these interfaces
 Which abstracts the difference between the pro/event user and auth user
 It's used for saving and updating

   eventUsers -> EventUserInfo, this allows a full register/update
   proUsers -> AuthUserInfo (we only allow initial registration using this)

 */

export interface AuthUserInfo {
    // Fields that will be saved on the related auth user
    email: string
    firstName: string
    lastName: string
    phoneNumber: string
}

export interface EventUserInfo extends AuthUserInfo {
    // Fields that will end up on eventUser
    id?: string
    role?: SimpleEvents.EventUser['role']

    // Just for reading
    createdAt?: string
}

export const eventUserInfoVirtualProps = ['firstName', 'lastName', 'email'] as const

// Interface for handling event options to simplify the editing/saving of them
export interface EventOptionInfo extends SimpleEvents.EventOptionData {
    id?: string
}

const info: EventOptionInfo = {
    category: 'foo',
    name: 'yay',
    children: [],
}

export interface ProGetEventParams {
    eventId: string
}

export interface ProGetEventResult {
    event: SimpleEvents.Event
    eventUserInfos: EventUserInfo[]
}

export interface ProGetOrganisationParams {
    organisationId: string
}

export interface ProGetOrganisationResult {
    organisation: SimpleEvents.ProfessionalOrganisation
}

export interface ProQueryOrganisationsParams {
    query?: string
    offset?: number
    size?: number
    type?: "venue" | "organiser"
}

export interface ProQueryOrganisationsResult {
    organisations: SimpleEvents.ProfessionalOrganisation[]
    hasMoreRows: boolean
    size?: number
}

export interface ProSaveEventParams {
    organisationId: string
    event: OptionalIdDeep<SimpleEvents.Event>
    eventUserInfos?: EventUserInfo[]
    // eventOptionInfos?: EventOptionInfo[]
    removeEventUsersById?: string[]
}

export interface ProSaveEventResult {
    event: SimpleEvents.Event
    eventUserInfos: EventUserInfo[]
}

export interface ProDeleteEventsParams {
    eventIds: string[]
}

export interface ProRefreshPinParams {
    eventId: string
}

export interface ProRefreshPinResult {
    pin: string
}

export interface ProDeleteEventsResult { }

export interface ProSetLogoParams {
    organisationId: string
    logoFile: string
}

export interface ProSetLogoResult { }

export interface ProGetMinEventDateParams { }

export interface ProGetMinEventDateResult {
    minDate: string
}

export interface InternalGetEvent {
    eventId: string
}

export interface InternalGetEvents {
    email: string
}

export interface InternalGetEventsResult {
    results: {
        event: SimpleEvents.Event
        eventUsers: SimpleEvents.EventUser[]
    }[]
}

export interface InternalGetEventResult {
    event: SimpleEvents.Event
}

export interface InternalCreateAudioUploadWorkflowJob {
    eventId: string
    audioUpload: AudioUpload
    userId?: string
    userEmail?: string
}

export interface InternalCreateAudioUploadWorkflowJobResult {
    jobId: string
}

export interface InternalRegisterProUserParams {
    organisationIds: string[]
    userInfo: AuthUserInfo
}

export interface InternalRegisterProUserResult {
    authUserId: string
    proUserId: string
}

export interface InternalSyncEventOptionsParams {
    eventId: string
    options: OptionalIdDeep<SimpleEvents.EventOption>[]
}

export interface InternalSubmitEventParams {
    eventId: string
}

export interface InternalSubmitEventResult {
    jobId: string
}

// we only allow some of the resource types to be updated by the user
export const userUpdateResourceTypes = [
    'liveEvent',
    'scheduleItem',
    'streamingViewer',
    'visual',
    'visualComponent',
    'visualMusicItem',
    'event'
] as const

export const userAddResourceTypes = [
    'liveEvent',
    'scheduleItem',
    'streamingViewer',
    // 'visual', // adding/deleting managed via pro portal now
    'visualComponent',
    'visualMusicItem'
] as const

export const userDeleteResourceTypes = [
    'liveEvent',
    'scheduleItem',
    'streamingViewer',
    // 'visual', // adding/deleting managed via pro portal now
    'visualComponent',
    'visualMusicItem'
] as const

export const userViewOnlyResourceTypes = [
    'event',
    'liveEventType',
    'visualTemplate',
    'scheduleSection',
    'room',
    'venue'
] as const

export const userViewableResourceTypes = [
    ...userUpdateResourceTypes,
    ...userViewOnlyResourceTypes,
] as const

export const proUpdatableEventFields: (keyof Events.Event)[] = [
    'organisation',
    'date',
    'name',
    'venue',
    'room',
    'options',
    'uiOptions',
    'cutOff'
]

type EventsRG = events

export type UserUpdateResourceType = keyof EventsRG & typeof userUpdateResourceTypes[number]
export type UserAddResourceType = keyof EventsRG & typeof userAddResourceTypes[number]
export type UserDeleteResourceType = keyof EventsRG & typeof userDeleteResourceTypes[number]
export type UserViewOnlyResourceType = keyof EventsRG & typeof userViewOnlyResourceTypes[number]

export type ValueOf<T> = T[keyof T]

export type EventResource = Resource<ValueOf<ResourceGroupSignature<EventsRG>>, ResourceGroupSignature<EventsRG>, keyof EventsRG>
export type SimpleEventResource = SimpleResource<EventResource>
export type SimpleEventResourceData = SimpleResourceData<EventResource>

export type AddEventResource = {
    [RT in keyof EventsRG & UserAddResourceType]: (
        eventId: string,
        data: SimpleResourceData<Resource<EventsRG[RT], EventsRG, RT>>,
        parentId?: string
    ) => Promise<AddEventResourceResult<EventsRG, RT>>
}

export type UpdateEventResource = {
    [RT in keyof EventsRG & UserUpdateResourceType]: (
        eventId: string,
        data: { id: string } & Partial<SimpleResource<Resource<EventsRG[RT], EventsRG, RT>>>,
        parentId?: string
    ) => Promise<UpdateEventResourceResult<EventsRG, RT>>
}

export type DeleteEventResource = {
    [RT in keyof EventsRG & UserDeleteResourceType]: (
        eventId: string,
        id: string
    ) => Promise<DeleteEventResourceResult>
}

export type DeleteEventResources = {
    [RT in keyof EventsRG & UserDeleteResourceType]: (
        eventId: string,
        ids: string[]
    ) => Promise<DeleteEventResourceResult>
}

export interface AddEventResourceParams<T = ValueOf<EventsRG>, RT extends UserAddResourceType = UserAddResourceType> {
    eventId: string
    resourceType: RT
    parentId?: string
    data: SimpleResourceData<Resource<T, EventsRG, RT>>
}

export interface AddEventResourceResult<RG = any, RT extends keyof RG & UserAddResourceType = any> {
    resourceId: string
    resource: SimpleResource<Resource<RG[RT], events, RT>>
}

export interface UpdateEventResourceParams<T = ValueOf<EventsRG>, RT extends UserUpdateResourceType = UserUpdateResourceType> {
    eventId: string
    resourceType: RT
    parentId?: string
    data: { id: string } & Partial<SimpleResource<Resource<T, EventsRG, RT>>>,
}

export interface UpdateEventResourceResult<RG = any, RT extends keyof RG & UserUpdateResourceType = any> {
    resource: SimpleResource<Resource<RG[RT], events, RT>>
}

export interface RequestTrackParams {
    eventId: string
    trackRequest: TrackRequest
    trackInfo?: TrackInfo
}

export interface RequestVisualTributeParams {
    eventId: string
    visualTributeRequest: VisualTributeRequest
    visualId: string
}

export interface RequestTrackResult { }

export interface RequestVisualTributeResult { }

export interface GetTrackRequestsParams {
    eventId: string
}

export interface GetTrackRequestsResult {
    requests: TrackRequestJob[]
}

export interface GetAudioUploadsParams {
    eventId: string
}

export interface GetAudioUploadsResult {
    uploads: AudioUploadJob[]
}

export interface DeleteEventResourceParams<RT = UserDeleteResourceType> {
    eventId: string
    resourceType: RT,
    resourceId: string
}

export type DeleteEventResourceResult = void

export interface DeleteEventResourcesParams<RT = UserDeleteResourceType> {
    eventId: string
    resourceType: RT,
    resourceIds: string[]
}

export type DeleteEventResourcesResult = void

export interface CheckAccessParams {
    eventId: string
}

export interface CheckAccessResult {
    ok: boolean
}

export interface SubmitEventParams {
    eventId: string
}

export interface SubmitEventResult { }

export interface StreamingInviteGetParams {
    pin: string
}

export interface StreamingStartParams {
    eventId: string
    playerId: string
}

export interface StreamingNotifyStartParams {
    eventId: string
}

export interface StreamingNotifyStartResult {

}

export interface StreamingStopParams {
    eventId: string
    playerId: string
}

export interface StreamingResetParams {
    eventId: string
    playerId: string
}

export interface StreamingResetResult {

}

export interface StreamingHoldParams {
    eventId: string
    holdId: string
}

export interface StreamingEndVodParams {
    eventId: string
}

export interface StreamingOverrideVodParams {
    eventId: string
    instanceUuid
}

export interface StreamingGetAllStatusParams {
    streamStatus: "notStarted" | "starting" | "streaming" | "vod" | "finished" | "error"
}

// Minimal information to provide to streaming viewers
export type StreamingEventInfo = Pick<SimpleEvents.Event, 'name' | 'date' | 'streamStatus' | 'id' | 'pin' | 'streamHold' | 'venue'> & { overrideStreamVod: { id: string, filename: string } }
export type StreamingViewerInfo = Pick<SimpleEvents.StreamingViewer, 'status'>

export interface StreamingInviteGetResult {
    event: StreamingEventInfo
}

export interface StreamingInviteResponseParams {
    token: string
    action: 'accept' | 'decline'
}

export interface StreamingInviteResponseResult { }

export interface StreamingStartResult { }

export interface StreamingStopResult { }

export interface StreamingHoldResult { }

export interface StreamingEndVodResult { }

export interface StreamingOverrideVodResult { }

export interface StreamingGetAllStatusResult {
    events: StreamingEventInfo[]
}


export interface DigitalSignageGetSignInfoParams {
    id?: string
    key?: string
    date: string
    includeOmittedEvents?: boolean
}
export interface DigitalSignageGetSignInfoResult {
    sign: {
        template: {
            backgrounds: {
                portraitFilename: string,
                landscapeFilename: string
            }
        }
        venue: {
            id: string
            name: string
        }
        rooms: DigitalSignageRoom[]
        events: DigitalSignageEvent[]
        date: string
    } | null
}

export interface DigitalSignageUpdateEventParams {
    event: DigitalSignageEvent
    signId?: string
    signKey?: string
}

export interface DigitalSignageGetTemplatesParams {
    venueId: string
}

export interface DigitalSignageGetTemplatesResult {
    templates: {
        id: string
        name: string
        portraitBackgroundFilename: string
        landscapeBackgroundFilename: string
    }[]
}

export interface DigitalSignageUpdateTemplateParams {
    signId: string
    templateId: string
}

export interface DigitalSignageUpdateTemplateResult {}

export interface DigitalSignageUpdateEventResult { }

export interface DigitalSignageEvent {
    editId?: string
    eventId?: string
    name: string
    date: string
    note?: string
    adHoc?: boolean
    roomId: string
    omitEvent?: boolean
}

export interface DigitalSignageRoom {
    id: string
    name: string
}

export interface RestrictedStatus {
    $restrictedToUserIds: string[] | 'all'
    $restrictedExcludeSocketIds?: string[]
}

export interface EventUpdatedStatus {
    eventId: string
    message: string
}

export interface StreamingUpdatedStatus {
    eventId: string
    pin: string
    status: string
}

export interface StreamingStartStatus {
    eventId: string
    pin: string
}

export interface StreamingStartingStatus {
    eventId: string
    pin: string
}

export interface StreamingStopStatus {
    eventId: string
    pin: string
}

export interface DigitalSignageUpdatedStatus {
    id: string
    key: string
}

export interface LiveEventTypesUpdatedStatus extends RestrictedStatus {
    $restrictedToUserIds: 'all' // live event types are global types
    liveEventTypes: SimpleEvents.LiveEventType[]
}

export type EventInfo = Pick<SimpleEvents.Event, 'id' | 'name' | 'bookingId' | 'date' | 'status' | 'venue' | 'room'>

export interface GetEventsParams { }
export interface GetEventsResult {
    events: EventInfo[]
}

export interface GetEventParams {
    eventId: string
}

export interface GetEventResult {
    event: SimpleEvents.Event
}

export interface GetEventUsersParams {
    eventId: string
}

export interface GetEventUsersResult {
    eventUserInfos: EventUserInfo[]
}

export interface SaveEventUsersParams {
    eventId: string
    eventUserInfos?: EventUserInfo[]
    removeEventUsersById?: string[]
}

export interface SaveEventUsersResult {
    eventUserInfos: EventUserInfo[]
}

export interface SearchAudioParams {
    query: string
    rawQuery?: any
    searchAfter?: SearchTrackInfo
}

export interface SearchAudioResult extends SearchTracksResult { }

export interface GetVisualTemplatesResult {
    visualTemplates: SimpleEvents.VisualTemplate[]
}

export interface GetLiveEventTypesResult {
    liveEventTypes: SimpleEvents.LiveEventType[]
}

export interface GetScheduleItemTagsParams {
    venueId: string
}

export type ScheduleItemTagWithLoop = SimpleEvents.ScheduleItemTag & {defaultLoop?: boolean}
export interface GetScheduleItemTagsResult {
    scheduleItemTags: ScheduleItemTagWithLoop[]
}

export interface GetTrackParams {
    id: string
}

export interface GetTrackResult {
    track: TrackInfo
}

export type SimpleResourceGroup<RG extends { [key: string]: any }> = {
    [RT in keyof RG]: SimpleResource<Resource<RG[RT], RG, RT & string>>
}

export type SimpleResourceGroupData<RG extends { [key: string]: any }> = {
    [RT in keyof RG]: SimpleResourceData<Resource<RG[RT], RG, RT & string>>
}

export type simpleEvents = SimpleResourceGroup<events>
export type simpleEventsData = SimpleResourceGroupData<events>

export interface WithTrackInfo {
    trackInfo?: TrackInfo
}

export interface EventTrackInfos {
    userTrackInfos?: TrackInfo[]
    requestedTrackInfos?: TrackInfo[]
}

// types for Option/EventOption params fields

namespace OptionParamsTypes {

    // Webcast/streaming types

    export interface Webcast {
        type: 'webcast'
    }

    // Recordings types

    export interface Recording {
        type: 'recording'
        download: boolean
        dvdOrUsb: boolean
    }

    export interface RecordingIncludeVisual {
        type: 'recordingIncludeVisual'
    }

    // Visual Tribute types

    export interface Visual {
        type: 'visual'
        name?: string
        music?: boolean
        imageCount?: number
        videoCount?: number
        templateProjectId?: string
    }

    export interface VisualExtras {
        type: 'visualExtras'
        imageCount?: number
        videoCount?: number
    }

    export interface SingleImage {
        type: 'singleImage'
        name?: string
        templateProjectId?: string
    }

    export interface SingleVideo {
        type: 'singleVideo'
        name?: string
        templateProjectId?: string
        music?: boolean
    }

    export interface VisualDownload {
        type: 'visualDownload'
    }

    export interface VisualDVDOrUSB {
        type: 'visualDVDOrUSB'
    }

    
}

// Manually managed Union
// Would be nice if there was a typescript way to do this
export type OptionParams = (
    OptionParamsTypes.Webcast |
    OptionParamsTypes.Recording |
    OptionParamsTypes.RecordingIncludeVisual |
    OptionParamsTypes.Visual |
    OptionParamsTypes.VisualExtras |
    OptionParamsTypes.SingleImage |
    OptionParamsTypes.SingleVideo |
    OptionParamsTypes.VisualDownload |
    OptionParamsTypes.VisualDVDOrUSB
)

// This are maintained manually
export namespace SimpleEvents {
    export type Event = simpleEvents['event'] & EventTrackInfos
    export type EventData = simpleEventsData['event'] & EventTrackInfos
    export type ProfessionalUser = simpleEvents['professionalUser']
    export type ProfessionalUserData = simpleEventsData['professionalUser']
    export type EventUser = simpleEvents['eventUser']
    export type EventUserData = simpleEventsData['eventUser']
    export type ProfessionalOrganisation = simpleEvents['professionalOrganisation']
    export type ProfessionalOrganisationData = simpleEventsData['professionalOrganisation']
    export type VisualTemplate = simpleEvents['visualTemplate']
    export type VisualTemplateData = simpleEventsData['visualTemplate']
    export type Visual = simpleEvents['visual']
    export type VisualData = simpleEventsData['visual']
    export type VisualMusicItem = simpleEvents['visualMusicItem'] & WithTrackInfo
    export type VisualMusicItemData = simpleEventsData['visualMusicItem'] & WithTrackInfo
    export type VisualComponent = simpleEvents['visualComponent']
    export type VisualComponentData = simpleEventsData['visualComponent']
    export type StreamingViewer = simpleEvents['streamingViewer']
    export type StreamingViewerData = simpleEventsData['streamingViewer']
    export type EventTemplate = simpleEvents['eventTemplate']
    export type EventTemplateData = simpleEventsData['eventTemplate']
    export type ScheduleSection = simpleEvents['scheduleSection']
    export type ScheduleSectionData = simpleEventsData['scheduleSection']
    export type ScheduleItem = simpleEvents['scheduleItem'] & WithTrackInfo
    export type ScheduleItemData = simpleEventsData['scheduleItem'] & WithTrackInfo
    export type LiveEvent = simpleEvents['liveEvent']
    export type LiveEventData = simpleEventsData['liveEvent']
    export type LiveEventType = simpleEvents['liveEventType']
    export type LiveEventTypeData = simpleEventsData['liveEventType']
    export type Venue = simpleEvents['venue']
    export type VenueData = simpleEventsData['venue']
    export type Room = simpleEvents['room']
    export type RoomData = simpleEventsData['room']
    export type Player = simpleEvents['player']
    export type PlayerData = simpleEventsData['player']
    export type PlaylistTransfer = simpleEvents['playlistTransfer']
    export type PlaylistTransferData = simpleEventsData['playlistTransfer']
    export type Track = simpleEvents['track']
    export type UserTrack = simpleEvents['userTrack']
    export type Option = simpleEvents['option']
    export type OptionData = simpleEventsData['option']
    export type EventOption = simpleEvents['eventOption']
    export type EventOptionData = simpleEventsData['eventOption']
    export type Capability = simpleEvents['capability']
    export type CapabilityData = simpleEventsData['capability']
    export type ScheduleItemTag = simpleEvents['scheduleItemTag']
    export type DigitalSignTemplate = simpleEvents['digitalSignTemplate']
}
