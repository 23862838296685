import { ContentQuality, CropParams, SimpleEvents } from 'common/types/eventService'
import { produce } from 'immer'
import * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CropperRef, Size, Coordinates, Cropper, stretchCropperBoundary } from 'react-advanced-cropper'
import { createBlankImageDataURL, getScaledSize } from '../CanvasImageHelper'
import { SetUsingUpdater } from '../../model'
import { getBemClasses, getNumericAspectRatio } from '../../utils'
import VisualComponentPreview from './VisualComponentPreview'


interface VisualCropperProps {
    eventId: string
    component: SimpleEvents.VisualComponent // just image for the moment
    setComponent: SetUsingUpdater<SimpleEvents.VisualComponent>
    contentQuality: ContentQuality
    disabled?: boolean
    aspectRatio?: string
    freeformAspectRatio: boolean
}

export function VisualCropper({ freeformAspectRatio, eventId, component, setComponent, contentQuality, disabled, aspectRatio }: VisualCropperProps) {
    const cropperRef = useRef<CropperRef>(null)
    const [size, setSize] = useState<Size>(null)
    const [coordinates, setCoordinates] = useState<Coordinates>(null)

    function onChange(cropper: CropperRef) {
        const {
            imageSize: newSize,
            coordinates: newCoordinates
        } = cropper.getState()
    
        if (!coordinates || !coordinatesEqual(newCoordinates, coordinates)) {
            setCoordinates(newCoordinates)
        }

        if (!size || !sizeEqual(newSize, size)) {
            setSize(newSize)
        }
    }

    function sizeEqual(a: Size, b: Size) {
        return (
            a.height === b.height &&
            a.width === b.width
        )
    }

    function coordinatesEqual(a: Coordinates, b: Coordinates) {
        return (
            a.height === b.height &&
            a.width === b.width &&
            a.top === b.top &&
            a.left === b.left
        )
    }

    const getCropDefaults = () => {
        const cropDefaults: CropParams = {
            aspectRatio: 'original',
            top: 0,
            left: 0,
            width: 1,
            height: 1
        }

        if (size && !freeformAspectRatio) {
            const [top, bottom] = aspectRatio.split('/').map(s => parseInt(s))
            const widthScale = size.width / top
            const heightScale = size.height / bottom
            const scale = Math.min(widthScale, heightScale)

            const relativeWidth = (top * scale) / size.width
            const relativeHeight = (bottom * scale) / size.height;
            
            return {
                aspectRatio: 'original' as any,
                top: 0.5 - (relativeHeight / 2),
                left: 0.5 - (relativeWidth / 2),
                width: 1,
                height: 1
            }
        }else {
            return {
                aspectRatio: 'original' as any,
                top: 0,
                left: 0,
                width: 1,
                height: 1
            }
        }

        return cropDefaults
    }

    useEffect(() => {
        if (!size) return
        const coords = toCoordinates(size, component.crop || getCropDefaults())
        cropperRef.current.setCoordinates(coords)
    }, [size])

    useEffect(() => {
        // special case if component.crop gets set to undefined, this is a reset
        if (cropperRef.current && size && component.crop === undefined) {
            cropperRef.current.setCoordinates(toCoordinates(size, getCropDefaults()))
        }
    }, [size, component.crop])

    useEffect(() => {
        if (!size || !coordinates) return
        // only if size is set... otherwise it is the initial load...
        setComponent(produce(draft => {
            draft.crop = {
                aspectRatio: draft.crop?.aspectRatio || 'custom',
                ...toCropParams(size, coordinates)
            }
        }))
    }, [size, coordinates])

    function toCoordinates(imageSize: Size, crop: CropParams): Coordinates {
        if (!crop) throw new Error('whoops empty crop!')
        if (!imageSize) throw new Error('whoops empty imageSize!')
        const coor = {
            top: crop.top * imageSize.height,
            left: crop.left * imageSize.width,
            width: crop.width * imageSize.width,
            height: crop.height * imageSize.height
        }
        
        return coor;
    }

    function toCropParams(size: Size, coordinates: Coordinates): Omit<CropParams, 'aspectRatio'> {
        const coor = {
            top: (Math.round(coordinates.top * 10000)/10000) / size.height,
            left: (Math.round(coordinates.left * 10000)/10000) / size.width,
            height: coordinates.height / size.height,
            width: coordinates.width / size.width
        }

        return coor;
    }
    // For some reason the cropper always wants a src even though we use a background canvas
    // For images we can provide the actual URL, but for videos it doesn't like that... so
    // we just give it blank image of the right size.
    // Which also works for images, so we can use it all the time.
    const url = useMemo(
        () => createBlankImageDataURL(getScaledSize(component, contentQuality)),
        [component.width, component.height]
    )

    return (
        <Cropper
            className={getBemClasses('visual-cropper', { disabled })}
            ref={cropperRef}
            src={url}
            onChange={cropper => onChange(cropper)}
            style={{ aspectRatio: '16/9' }}
            backgroundComponent={VisualComponentPreview}
            backgroundProps={{ eventId, component, contentQuality }}
            boundaryStretchAlgorithm={stretchCropperBoundary}
            stencilProps={{
                aspectRatio: !freeformAspectRatio ? getNumericAspectRatio(aspectRatio) : undefined,
                movable: !disabled,
                resizable: !disabled,
                handlers: {
                    eastNorth: !disabled,
                    north: false,
                    westNorth: !disabled,
                    west: false,
                    westSouth: !disabled,
                    south: false,
                    eastSouth: !disabled,
                    east: false,
                },
                handlerClassNames: {
                    default: 'cropper__handler',
                    hover: 'cropper__handler--hover',
                    eastNorth: 'cropper__handler--north-east',
                    westNorth: 'cropper__handler--north-west',
                    westSouth: 'cropper__handler--south-west',
                    eastSouth: 'cropper__handler--south-east',
                }
            }}
        />
    )
}