import { BDuration, BDurationJson } from '@busby/esb'
import NumberField from '@rjsf/core/lib/components/fields/NumberField'
import * as React from 'react'
import { durationToSeconds, secondsToDuration } from './customWidgets'

/**
 * A field to modify a BDurationJson using a NumberField.
 * 
 * Input is in seconds, output is a BDurationJson with framerate 'milli'
 */
export function BDurationSecondsNumberField(props: any) {
    const { formData, onChange } = props
    return <NumberField
        {...props}
        formData={durationToSeconds(formData)}
        onChange={value => onChange(secondsToDuration(value))}
    />
}