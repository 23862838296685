import { BDateTime, BDuration, BDurationJson, SelectorOperations } from '@busby/esb'
import * as room16x9Src from 'common/assets/visualRoom16x9.jpg'
import * as room9x16Src from 'common/assets/visualRoom9x16.jpg'
import { usePauseAudio } from 'common/frontend/audio'
import { Toggle } from 'common/frontend/components/Toggle'
import { useVisualTemplateType } from 'common/frontend/hooks'
import { SimpleEvents } from 'common/types/eventService'
import * as React from 'react'
import { HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { useResizeDetector } from 'react-resize-detector'
import screenfull from 'screenfull'
import { useAsyncEffect } from "use-async-effect"
import { bem, eventManuallyCreatedVisualsPath, wesleyDebugNamespace } from '../../utils'
import Cover from '../Cover'
import VideoControls from '../VideoControls'
import { VisualPlayer, VisualPlayerApi } from './VisualPlayer'
import './VisualPreview.scss'
import { calculateVisualDuration } from 'common/universal/universalUtils'
import { find } from 'lodash'

const debug = wesleyDebugNamespace.extend('visual-preview')

const block = bem('visual-preview')
const stage = block.element('stage')

export interface VisualPreviewProps extends HTMLAttributes<HTMLDivElement> {
    eventId: string
    aspectRatio: "16/9" | "9/16"
    visual: SimpleEvents.Visual
    showInRoom?: boolean
    onShowInRoomChanged?: (showInRoom: boolean) => void
}

function useIsFullscreen() {
    const [isFullscreen, setIsFullscreen] = useState(screenfull.isFullscreen)
    useEffect(() => {
        const onChange = () => {
            setIsFullscreen(screenfull.isFullscreen)
        }
        screenfull.on('change', onChange)
        return () => screenfull.off('change', onChange)
    }, [])
    return isFullscreen
}

export default function VisualPreview({ eventId, visual, showInRoom: externalShowInRoom, onShowInRoomChanged, aspectRatio, ...attributes }: VisualPreviewProps) {
    const { isSlideShow } = useVisualTemplateType(visual.template)
    const { manuallyCreatedVisual } = visual;
    const fullscreenRef = useRef<HTMLDivElement>(null)
    const player = useRef<VisualPlayerApi>(null)
    const [position, setPosition] = useState(0)
    const [isLoadingInitial, setIsLoadingInitial] = useState(true)
    const [playerIsReady, setPlayerIsReady] = useState<boolean>(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const { width = 0, height = 0, ref: detectRef } = useResizeDetector<HTMLDivElement>({ refreshMode: 'debounce', refreshRate: 0 })
    const stageSize = useRef({ height, width })
    stageSize.current = { height, width }
    const [roomVisible, setRoomVisible] = useState(false)
    const isFullscreen = useIsFullscreen()
    const [showInRoom, setLocalShowInRoom] = useState(externalShowInRoom ?? false)
    const pauseMusic = usePauseAudio()
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const [duration, setDuration] = useState<BDuration>(BDuration.zero("milli"))
    useEffect(() => {
        if (videoRef?.current) {
            videoRef.current.addEventListener('timeupdate', onVideoTimeUpdate)
            videoRef.current.addEventListener('play', onPlay)
            videoRef.current.addEventListener('pause', onPause)
        }

        const newDuration = calculateVisualDuration(visual)
        if(newDuration.getMilliseconds() !== duration.getMilliseconds()){
            setDuration(newDuration)
        }


        return () => {
            if (videoRef?.current) {
                videoRef.current.removeEventListener('timeupdate', onVideoTimeUpdate)
                videoRef.current.removeEventListener('play', onPlay)
                videoRef.current.removeEventListener('pause', onPause)
            }
        }
    }, [visual])

    const onVideoTimeUpdate = () => {
        onPositionChange(BDateTime.fromJson({ time: videoRef.current?.currentTime * 1000, frameRate: "milli" }))
    }

    useEffect(() => {
        // Changed from outside
        setLocalShowInRoom(externalShowInRoom)
    }, [externalShowInRoom])

    function setShowInRoom(value: boolean) {
        setLocalShowInRoom(value)
        onShowInRoomChanged?.(value)
    }

    useAsyncEffect(async () => {
        if (visual && playerIsReady) {
            // await player.current.cue(visual)
            setIsLoadingInitial(false)
        }
    }, [visual, playerIsReady])

    function setPositionMillis(millis: number) {
        if (!position === undefined) return
        if (manuallyCreatedVisual) {
            videoRef.current.currentTime = millis / 1000
        } else {
            player.current.setPosition(BDateTime.fromJson({ time: millis / (1000 / 30), frameRate: "ndf30" }))
        }
    }

    async function playPause() {
        if (manuallyCreatedVisual) {
            if (videoRef.current?.paused) {
                videoRef.current?.play()
            } else {
                videoRef.current?.pause()
            }
        } else {
            if (player.current.playing.current) {
                player.current.pause()
            } else {
                pauseMusic()
                player.current.play()
            }
        }
    }

    /**
     *  This has two purposes:
     * 
     *  - we only want to do transitions when we're turning "show in room" on or off
     *   (otherwise we get transitions when adjusting size as the browser changes size, which we don't want)
     * 
     *  - to only show the room image when needed
     *   (when we turn "show in room" off, we need to keep it visible until the animation has ended)
     */
    useEffect(() => {
        const canvas = player.current?.canvas.current
        if (!canvas) return

        const transition = 'transform 0.5s ease-in-out'

        // These are per template
        const transform = `
            translate(35.0%, 20.2%)
            scaleX(21.4%)
            scaleY(21%)
            rotate(2deg)
            perspective(calc(var(--stage-height) / 35))
            rotateY(0.03deg)
        `

        if (showInRoom) {
            if (!canvas.style.transform) {
                setRoomVisible(true)
                Object.assign(canvas.style, { transition, transform })
                afterAnimation(() => {
                    canvas.style.transition = ''
                })
            }
        } else {
            if (canvas.style.transform) {
                Object.assign(canvas.style, {
                    transform: '',
                    transition,
                })
                afterAnimation(() => {
                    canvas.style.transition = ''
                    setRoomVisible(false)
                })
            }
        }

        function afterAnimation(fn: () => void) {
            const listener = () => {
                canvas.removeEventListener('transitionend', listener)
                fn()
            }
            canvas.addEventListener('transitionend', listener)
        }

    }, [showInRoom])

    function onRequestFullScreen() {
        screenfull.request(fullscreenRef.current)
    }

    function onRequestFullScreenExit() {
        screenfull.exit()
    }

    const onPlay = useCallback(() => setIsPlaying(true), [])
    const onPause = useCallback(() => setIsPlaying(false), [])
    const onReady = useCallback(() => setPlayerIsReady(true), [])
    const onPositionChange = useCallback((position) => setPosition(position.getMillis()), [])

    let manualLocation: any;
    if (manuallyCreatedVisual) {
        const { location } = find(manuallyCreatedVisual.instances, v => v.type === "manuallyCreatedVisual").fileMetadata
        manualLocation = location
    }

    return (
        <div
            className={block.className}
            {...attributes}
        >
            <Cover center translucent loading enabled={isLoadingInitial && !manuallyCreatedVisual} />
            <div
                ref={mergeRefs([detectRef, fullscreenRef])}
                onClick={() => playPause()}
                style={{height: "calc(100% - 80px)", width: "100%"}}
            >
                <div
                    className={stage.mod({
                        "show-in-room": showInRoom,
                        fullscreen: isFullscreen
                    })}
                    style={{
                        '--aspect-ratio': aspectRatio,
                        '--stage-height': `${height}px`,
                    } as React.CSSProperties}
                >
                    {
                        !manuallyCreatedVisual && <VisualPlayer
                            musicItems={visual.musicItems}
                            eventId={eventId}
                            contentQuality='preview'
                            onPositionChange={onPositionChange}
                            ref={player}
                            components={visual.components}
                            fadeDuration={visual.fadeInDuration}
                            slideDuration={visual.slideDuration}
                            template={visual.template.projectId}
                            onPlay={onPlay}
                            onPause={onPause}
                            onReady={onReady}
                            duration={duration}
                            enableMusic
                            aspectRatio={aspectRatio}
                        />
                    }
                    {
                        manuallyCreatedVisual && <video width="100%" height="100%" ref={videoRef} src={eventManuallyCreatedVisualsPath(eventId, manualLocation.location, manualLocation.filename)} />
                    }
                    <div className={stage.el('room')} style={{ display: roomVisible ? '' : 'none' }}>
                        <img src={aspectRatio === '16/9' ? room16x9Src : room9x16Src} />
                    </div>
                    {isFullscreen && (
                        // Fullscreen-only controls, within the video player frame
                        <>
                            <Toggle
                                className="toggle-show-in-room"
                                checked={showInRoom}
                                onChange={checked => setShowInRoom(checked)}
                                label="Show in example room"
                            />
                            <VideoControls
                                duration={duration.getMilliseconds()}
                                position={position}
                                isPlaying={isPlaying}
                                onPositionChange={position => setPositionMillis(position)}
                                onRequestFullscreen={screenfull.isEnabled ? onRequestFullScreenExit : null}
                                playPause={playPause}
                            />
                        </>
                    )}
                </div>
            </div>
            {
                isSlideShow && <VideoControls
                    duration={duration.getMilliseconds()}
                    position={position}
                    isPlaying={isPlaying}
                    // No audio in the visuals yet...
                    // onVolumeChange={value => playerRef.current?.setVolume(value)}
                    // onMuted={muted => playerRef.current?.setMute(muted)}
                    onPositionChange={position => setPositionMillis(position)}
                    onRequestFullscreen={screenfull.isEnabled ? onRequestFullScreen : null}
                    playPause={playPause}
                />
            }
        </div>
    )
}