import { Listbox } from '@headlessui/react'
import { bem, wesleyDebugNamespace } from 'common/frontend/utils'
import * as React from 'react'
import { useLiveEventTypes, useVisualTemplates } from 'common/frontend/state'
import { getIconPath } from './LiveEventBox'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as classnames from 'classnames'
import { sortBy, groupBy, some } from 'lodash'
import { useMouseHover, useScrollingOverflow } from '../hooks'
import Action from './Action'

const debug = wesleyDebugNamespace.extend('select')

const block = bem('select-widget')
const elButton = block.element('button')
const elOptions = block.element('options')
const elOption = elOptions.element('option')
const elGroupLabel = elOptions.element('group-label')

export function LiveEventTypeSelect({ value, required = false, onChange }) {
    const options = useLiveEventTypes()
    return (
        <Select
            value={value}
            onChange={onChange}
            required={required}
            options={options}
            getDisplayValue={value => value.name}
            getIconPath={value => getIconPath(value)}
        />
    )
}

export function VisualTemplateSelect({ value, required, onChange, aspectRatio }) {
    const options = useVisualTemplates()
    return (
        <Select
            value={value}
            onChange={onChange}
            options={sortBy(options.filter(option => option.aspectRatio === aspectRatio), "name")}
            required={required}
            getDisplayValue={value => value.name}
        />
    )
}

// We use this to represent when we do not have a value set
// Otherwise the underlying input ends up switching between a controlled/uncontrolled input
// React does not like this
// Externally we accept/emit null as the value when it is not set
const NoValue = '' as const

export interface IdObject {
    id: string
}

export interface SelectProps<T extends IdObject> {
    options: T[]
    value: T | null
    disabled?: boolean
    required?: boolean
    onChange: (value: T | null) => void
    getDisplayValue: (value: T) => React.ReactNode
    getFilterValue?: (value: T) => string
    groupBy?: (value: T) => string
    groups?: { value: any, label: string }[]
    getIconPath?: (value: T) => string
    style?: React.CSSProperties
    className?: string
    noOptionLabel?: string
    selectLabel?: string
    showFilter?: boolean
    onFilterChange?: (query: string) => void
    loader?: {
        hasNextPage: boolean
        isFetching: boolean
        fetchNextPage: () => void
    }
}

export function Select<T extends IdObject>({
    options,
    value,
    disabled,
    required = false,
    onChange,
    getDisplayValue,
    groupBy: groupByFn,
    groups,
    getIconPath,
    style,
    className,
    noOptionLabel = 'None',
    selectLabel,
    showFilter = false,
    onFilterChange,
    loader,
    getFilterValue
}: SelectProps<T>) {

    const selectedOption = (value && options?.find(option => option.id === value.id)) ?? NoValue
    const selectedIconPath = selectedOption !== NoValue ? getIconPath?.(selectedOption) : undefined

    const { ref: hoverRef, isHovering } = useMouseHover()
    const { ref: scrollingRef } = useScrollingOverflow(isHovering)

    const [filterQuery, setFilterQuery] = useState('')

    function onFilter(value: string) {
        setFilterQuery(value)
        onFilterChange?.(value)
    }

    const filteredOptions = useMemo(() => {
        if (!filterQuery) return options
        const lowerFilterQuery = filterQuery.toLowerCase()

        return options.filter(option => {
            if(getFilterValue){
                const filterValue = getFilterValue(option)
                return filterValue.toLowerCase().includes(lowerFilterQuery)
            }
        })
    }, [filterQuery, options, getFilterValue])

    useEffect(() => {
        if (value && options && selectedOption === NoValue) {
            // our value is not one of the options, the outer context should know this!
            onChange(null)
        }
    }, [selectedOption, value])

    const groupedOptions = useMemo(() => {
        if (!groupByFn) return
        if (!filteredOptions) return []
        if (groups) {
            // retain the order of the passed in groups
            return groups
                .map(group => ({
                    ...group,
                    // find the matching options
                    options: filteredOptions.filter(option => groupByFn(option) === group.value)}
                ))
                // remove any that didn't match any of the options
                .filter(group => group.options.length > 0)
        } else {
            // Use the value we get from the groupByFn as the label and value
            const grouped = groupBy(filteredOptions, groupByFn)   
            return Object.keys(grouped).map(value => ({
                value,
                label: value,
                options: grouped[value]
            }))
        }
    }, [groupByFn, groups, filteredOptions])

    function Options({ options }:{ options: T[] }) {
        return <>
            {options?.map(option => {
                const iconPath = getIconPath?.(option)
                return (
                    <Listbox.Option
                        key={option.id}
                        value={option}
                        as="div"
                        className={elOption.mod({ icon: Boolean(iconPath) })}
                        style={iconPath && {
                            '--icon-path': `url(${iconPath})`
                        } as React.CSSProperties}
                    >
                        {getDisplayValue(option)}
                    </Listbox.Option>
                )
            })}
        </>
    }

    function getButtonLabel() {
        if (selectedOption) {
            return getDisplayValue(selectedOption)
        }
        if (options?.length > 0) {
            if (required) {
                return selectLabel ?? 'Select...'
            }
            else {
                return noOptionLabel
            }
        }
        return noOptionLabel
    }

    const fetchMoreOnBottomReached = useCallback(
        (el?: HTMLDivElement | null) => {
            if (el && loader) {
                const { scrollHeight, scrollTop, clientHeight } = el
                if (
                    scrollHeight - scrollTop - clientHeight < 300 &&
                    !loader.isFetching &&
                    loader.hasNextPage
                ) {
                    loader.fetchNextPage()
                }
            }
        },
        [loader]
    )

    return (
        <Listbox
            value={selectedOption}
            disabled={disabled}
            onChange={value => onChange(value !== NoValue ? value : null)}
            as="div"
            className={classnames([className, block.className])}
            style={style}
        >
            <Listbox.Button
                className={elButton.mod({ icon: Boolean(selectedIconPath) })}
                style={selectedIconPath && {
                    '--icon-path': `url(${selectedIconPath})`
                } as React.CSSProperties}
                ref={hoverRef}
            >
                <div className={elButton.el('label')} ref={scrollingRef}>
                    {getButtonLabel()}
                </div>
            </Listbox.Button>
            <Listbox.Options
                as="div"
                className={elOptions.className}
            >
                {showFilter && (
                    <div
                        className={elOptions.el('filter')}
                    >
                        <input
                            type="text"
                            placeholder="Filter..."
                            value={filterQuery}
                            onChange={event => onFilter(event.target.value)}
                        />
                        {filterQuery && (
                            <button
                                className="action action--close"
                                onClick={() => onFilter('')}
                            />
                        )}
                    </div>
                )}
                <div
                    className={elOptions.el('scrollable')}
                    onScroll={event => fetchMoreOnBottomReached(event.target as HTMLDivElement)}
                >
                    {!required && (
                        <Listbox.Option
                            value={null}
                            as="div"
                            className={elOption.className}
                        >
                            {noOptionLabel}
                        </Listbox.Option>
                    )}
                    {groupedOptions ? (
                        groupedOptions.map(group => (
                            <React.Fragment key={group.value} >
                                <div className={elGroupLabel.className}>{group.label}</div>
                                <Options options={group.options} />
                            </React.Fragment>
                        ))
                    ) : (
                        <Options options={filteredOptions} />
                    )}
                </div>
            </Listbox.Options>
        </Listbox>
    )
}

export function SelectLoader() {
    return (
        <div className={block.className}>
            <button className={elButton.className}>Loading...</button>
        </div>
    )
}