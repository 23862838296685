import { SimpleEvents } from "common/types/eventService";
import { templates } from "./templates/templates";
import { Project } from "@motion-canvas/core";

export const getVisualProject = (aspectRatio: string, projectId: string) => {
    let newProjectId = projectId
    if (aspectRatio === "9/16") {
        newProjectId += "Portrait"
    }
    let project: Project;
    if (templates[newProjectId]) {
        project = templates[newProjectId].default
    }
    return project
}