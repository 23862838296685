import { Form } from 'common/frontend/components/Form'
import { useChangePasswordAction } from 'common/frontend/actions'
import AuthSection from 'common/frontend/components/AuthSection'
import Cover from 'common/frontend/components/Cover'
import { JSONSchema7 } from 'json-schema'
import * as React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultBackgroundImages } from '../images'

const schema: JSONSchema7 = {
    type: 'object',
    required: [
        'currentPassword',
        'password',
        'confirmPassword',
    ],
    properties: {
        currentPassword: {
            type: "string",
            format: "password",
            title: "Current password"
        },
        password: {
            type: "string",
            format: "password",
            title: "Password"
        },
        confirmPassword: {
            type: "string",
            format: "password",
            title: "Confirm password",
        },
    }
}

export default function ChangePasswordPage() {
    return (
        <AuthSection images={defaultBackgroundImages}>
            <ChangePasswordForm />
        </AuthSection>
    )
}

const initialData = {
    currentPassword: '',
    password: '',
    confirmPassword: ''
}

function ChangePasswordForm() {

    const [data, setData] = useState(initialData)
    const [changePassword, isPending] = useChangePasswordAction()
    const navigate = useNavigate()

    function validate(formData, errors) {
        if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword.addError('should match password')
        }
        return errors
    }

    async function onSubmit() {
        const {
            currentPassword,
            password
        } = data
        await changePassword({
            currentPassword,
            newPassword: password
        })
        navigate('/')
    }
    return (
        <div>
            <h1>Create new password</h1>
            <Form
                theme="wesley-auth"
                jsonSchema={schema}
                value={data}
                onChange={data => setData(data.formData)}
                onSubmit={() => onSubmit()}
                customValidate={validate}
            >
                <button type="submit" className="btn">Change password</button>
            </Form>
            <Cover center translucent loading enabled={isPending} />
        </div>
    )
}