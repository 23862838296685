import { times } from 'lodash'
import * as React from 'react'
import { useMemo, useState } from 'react'
import TrackBox from '../components/TrackBox'
import { makeWesleyTrackInfo } from '../utils'
import { StoriesDefinition } from './StoriesPage'

const stories: StoriesDefinition = {
    name: 'Trackbox',
    stories: [
        {
            name: 'Basic',
            component: () => (
                <>
                    {times(10).map(n => (
                        <div
                            key={n}
                            style={{ maxWidth: 500 }}
                        >
                            <TrackBox id={String(n)} track={makeWesleyTrackInfo()} />
                        </div>
                    ))}
                </>
            )
        },
        {
            name: 'Various Widths',
            component: () => {
                const [n, setN] = useState(0)
                const refresh = () => setN(n => n + 1)
                const [hasRemove, setHasRemove] = useState(false)
                const widths = [
                    100,
                    150,
                    200,
                    300,
                    500,
                    800
                ]
                const tracks = useMemo(() => widths.map(makeWesleyTrackInfo), [n])
                return (
                    <div>
                        <div className="cols cols--center cols--spaced" style={{ marginBottom: 20 }}>
                            <button
                                className="btn"
                                onClick={() => refresh()}
                            >
                                refresh tracks
                            </button>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={hasRemove}
                                    onChange={event => setHasRemove(event.target.checked)}
                                /> show remove button?
                            </label>
                        </div>
                        {widths.map((width, index) => (
                            <div
                                key={width}
                            >
                                <div style={{ marginBottom: 5 }}>{width}px wide</div>
                                <div style={{ width, marginBottom: 15 }}>
                                    <TrackBox
                                        id={String(index)}
                                        track={tracks[index]}
                                        onRemove={hasRemove ? () => {} : undefined}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        }
    ]
}

export default stories