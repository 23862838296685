
import * as unknownTrackImageURL from 'common/assets/unknown-track.png'
import { orderedTrackRequestFields, TrackInfo, TrackRequest, trackRequestFieldNames } from 'common/types/commonTypes'
import { nl2br } from 'common/universal/universalUtils'
import * as React from 'react'
import { bem } from '../utils'
import './TrackRequestBox.scss'
import { startCase } from 'lodash'
import TrackBox from './TrackBox'

export interface TrackRequestBoxProps {
    trackRequest: TrackRequest
    status: string
    trackInfo?: TrackInfo
}   

export default function TrackRequestBox({ trackRequest, status, trackInfo }: TrackRequestBoxProps) {
    const block = bem('track-request-box')
    const fields = block.element('fields')
    const field = fields.element('field')
    return (
        <div className={block.className}>
            <img src={unknownTrackImageURL} />
            <div className={fields.className}>
                {orderedTrackRequestFields.filter(key => trackRequest[key]).map(key => (
                    <div
                        key={key}
                        className={field.className}
                    >
                        <div>{trackRequestFieldNames[key]}</div>
                        <div>{nl2br(trackRequest[key])}</div>
                    </div>
                ))}
                 {
                    trackInfo && <TrackBox id={trackInfo.id} track={trackInfo} />
                }
            </div>
            {
                status !== "found" && <div style={{ marginLeft: 'auto', marginTop: '15px', marginRight: '15px' }}><em>{startCase(status)}</em></div> 
            }
        </div>
    )
}