import { Select } from 'common/frontend/components/Select'
import { events } from 'common/types/events'
import * as React from 'react'
import { Filters, ResourceOrderBy, ResourceQuery } from '@busby/esb'
import { useInfiniteProResourceQuery } from '../queries'

export interface ProResourceDropdownProps<RT extends keyof events> {
    organisationId?: string
    venueId?: string
    resourceType: RT
    orderBy?: ResourceOrderBy<events[RT]>
    query?: ResourceQuery<events[RT], events, RT>
    filters?: Partial<Filters<events[RT], events>>
    value: any
    disabled?: boolean
    required?: boolean
    noOptionLabel?: string
    onChange: (value: any) => void
}

export default function ProResourceSelect<RT extends keyof events>({
    organisationId,
    venueId,
    resourceType,
    orderBy,
    query,
    filters,
    value,
    disabled,
    required = false,
    noOptionLabel,
    onChange
}: ProResourceDropdownProps<RT>) {
    const { resources, hasNextPage, isFetching, fetchNextPage } = useInfiniteProResourceQuery({ organisationId, venueId, resourceType, orderBy, query, filters, })

    // If we have a selected value, but it's not in our list, add it in
    // This is for the case that it's not within this page of results
    // We are operating on good faith that it is in fact a valid option
    if (value?.id && !resources.some(resource => resource.id === value.id)) {
        resources.push(value)
    }

    return (
        <Select
            value={value}
            disabled={disabled}
            required={required}
            onChange={onChange}
            options={resources}
            getDisplayValue={value => value.name}
            noOptionLabel={noOptionLabel}
            loader={{ hasNextPage, isFetching, fetchNextPage }}
        />
    )
}