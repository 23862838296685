import * as React from 'react'
import { SubscriberManager } from "@busby/esb"
import axios from "axios"
import { Progress } from "common/frontend/model"
import { wesleyDebugNamespace } from "common/frontend/utils"
import { AudioUploadJob, EventSource, PortalUser, PublicConfig, SearchTrackInfo, TrackRequestJob } from 'common/types/commonTypes'
import { EventServiceSubscriber, ScheduleItemTagWithLoop, SimpleEvents } from "common/types/eventService"
import { fixEventDateString, getEventSource, isEventCutOffPassed, oneMinute, resultOrThrow } from 'common/universal/universalUtils'
import { produce } from 'immer'
import { isEmpty, last, orderBy, sortBy } from "lodash"
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { atom, selector, selectorFamily, useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import websocket, { disconnect, emit, reconnect } from "./websocket"
import { loadTemplates } from "./components/visuals/templates/templates"
import { useAsyncEffect } from "use-async-effect"
import { useResetAudio } from "./audio"
import { filter } from "lodash"
import { DateTime } from 'luxon'

const debug = wesleyDebugNamespace.extend('state')

export const configState = selector<PublicConfig>({
    key: 'Config',
    get: async () => {
        return (await axios.get('/config')).data
    }
})

export function useConfig() {
    return useRecoilValue(configState)
}

/**
 * Storing lastSaved information in a context rather than recoil state, as I encountered a bug (perhaps a recoil bug?)
 * where updating a recoil atom just after saving (in useEventResourceAction) would sometimes give an error from deep inside
 * recoil of "Got unexpected null or undefined". Using a context rather than recoil state for this, I do not encounter the error.
 * 
 * The symptom was during lots of updates (e.g. dragging and dropping music tracks very quickly) it could get out of sync with the server state.
 */
const LastSavedContext = createContext<{ lastSaved: Date, setLastSaved: (lastSaved: Date) => void }>({ lastSaved: null, setLastSaved: () => { } })

export function LastSavedProvider({ children }: { children: ReactNode }) {
    const [lastSaved, setLastSaved] = useState(null)
    return (
        <LastSavedContext.Provider value={{ lastSaved, setLastSaved }}>
            {children}
        </LastSavedContext.Provider>
    )
}

export function useLastSaved() {
    const { lastSaved } = useContext(LastSavedContext)
    return lastSaved
}

export function useSetLastSaved() {
    const { setLastSaved } = useContext(LastSavedContext)
    return setLastSaved
}

const eventBasePath = atom<string>({
    key: 'EventBasePath',
    default: ''
})

export function useEventBasePath() {
    return useRecoilValue(eventBasePath)
}

export function useSetEventBasePath() {
    return useSetRecoilState(eventBasePath)
}

export const currentUserState = atom<PortalUser>({
    key: 'CurrentUser',
    default: undefined,
    effects: [
        ({ setSelf }) => {
            setSelf(initialLoad())

            async function initialLoad() {
                // Fetch initial user data
                debug('fetching initial user')
                try {
                    const { user } = (await axios.get('/auth/user')).data
                    debug('got user %o', user)
                    return user || undefined
                } catch (error) {
                    return undefined
                }
            }

            // set up periodic refresh
            const interval = setInterval(async () => {
                try {
                    const { user } = await emit('refreshSession')
                    setSelf(user)
                } catch (error) {
                    // I guess there wasn't one!
                    setSelf(undefined)
                }
            }, oneMinute)

            // onSet((newValue, oldValue) => {
            //     if (!newValue || !oldValue) {
            //         // when we either log in or out cleanup our subscriptions
            //         // this won't catch our own sets though...
            //         console.log('closing all subscriptions')
            //         SubscriberManager.get().closeAll()
            //     }
            //     if (newValue && !oldValue) {
            //         // logged in
            //         reconnect()
            //     } else if (oldValue && !newValue) {
            //         // not logged in, don't need a socket...
            //         disconnect()
            //     }
            // })

            return () => {
                clearInterval(interval)
            }
        }
    ]
})

export function useCurrentUser() {
    return useRecoilValue(currentUserState)
}

export function useSetCurrentUser() {
    const setCurrentUser = useSetRecoilState(currentUserState)
    const currentUser = useRecoilValue(currentUserState);

    return {
        set: (user) => {
            if (!user || !currentUser) {
                // when we either log in or out cleanup our subscriptions
                // this won't catch our own sets though...
                console.log('closing all subscriptions')
                SubscriberManager.get().closeAll()
            }
            if (user && !currentUser) {
                // logged in
                reconnect()
            } else if (currentUser && !user) {
                // not logged in, don't need a socket...
                disconnect()
            }

            setCurrentUser(user);
        }
    }
}



export function useResetCurrentUser() {
    return useResetRecoilState(currentUserState)
}

export const isConnectedState = atom<boolean>({
    key: 'IsConnectedState',
    default: websocket.socket.connected,
    effects: [
        ({ setSelf }) => {
            setSelf(websocket.socket.connected)

            websocket.socket.on('connect', () => {
                debug('event socket connected...')
                setSelf(true)

            })
            websocket.socket.on('disconnect', () => {
                debug('event socket disconnected...')
                setSelf(false)
            })
        }
    ]
})

export function useIsConnected() {
    return useRecoilValue(isConnectedState)
}

export const isLoggedInState = selector({
    key: 'IsLoggedIn',
    get: ({ get }) => {
        return Boolean(get(currentUserState))
    }
})

export function useIsLoggedIn() {
    return useRecoilValue(isLoggedInState)
}

export function useIsAdminPortal() {
    const { portalType } = useConfig()
    return portalType === 'admin'
}

export function useIsProfessionalPortal() {
    const { portalType } = useConfig()
    return portalType === 'professional'
}

export function useIsClientPortal() {
    const { portalType } = useConfig()
    return portalType === 'client'
}

export function useIsAdmin() {
    const user = useCurrentUser()
    return Boolean(user?.admin)
}

export const visualTemplatesState = atom<SimpleEvents.VisualTemplate[]>({
    key: 'VisualTemplates',
    default: [],
    effects: [
        ({ setSelf, getPromise }) => {
            async function fetchData() {
                debug('fetching visual templates')
                const config = await getPromise(configState)
                const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                const { visualTemplates } = await resultOrThrow(subscriber.control.getVisualTemplates())
                const filteredVisualTemplates = filter(visualTemplates, v => v.type === "slideshow")
                return sortBy(filteredVisualTemplates, "order")
            }
            // no updates, as they don't change so much...
            setSelf(fetchData())
        }
    ]
})

export function useVisualTemplates() {
    return useRecoilValue(visualTemplatesState)
}

export const scheduleItemTagsState = selector<ScheduleItemTagWithLoop[]>({
    key: 'ScheduleItemTags',
    // default: [],
    // effects: [
    //     ({ setSelf, getPromise }) => {
    //         async function fetchData() {
    //             debug('fetching schedule item tags')
    //             const config = await getPromise(configState)
    //             const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
    //             const { scheduleItemTags } = await resultOrThrow(subscriber.control.getScheduleItemTags({venueId}))

    //             return scheduleItemTags
    //         }
    //         // no updates, as they don't change so much...
    //         setSelf(fetchData())
    //     }
    // ],
    get: ({ get }) => {
        async function fetchData() {
            debug('fetching schedule item tags')
            const config = get(configState)
            const venueId = get(venueIdState)
            const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
            const { scheduleItemTags } = await resultOrThrow(subscriber.control.getScheduleItemTags({ venueId }))

            return scheduleItemTags
        }
        
        return fetchData()
    }
})

export function useScheduleItemTags() {
    return useRecoilValue(scheduleItemTagsState)
}

export function sortVisual(visual: SimpleEvents.Visual): SimpleEvents.Visual {
    if (!visual) return
    return produce(visual, draft => {
        draft.components = sortBy(draft.components, 'order')
    })
}

export function sortEvent(event: SimpleEvents.Event): SimpleEvents.Event {
    if (!event) return
    // go through and sort all the fields
    // TODO: we are we sorting in all places needed?
    // TODO: if we do this here, can probably remove some sorting in other places
    function lowerCaseName(object: any): string {
        return object.name.toLowerCase()
    }
    return produce(event, draft => {
        draft.liveEvents = sortBy(draft.liveEvents, lowerCaseName)
        draft.streamingViewers = sortBy(draft.streamingViewers, lowerCaseName)

        draft.visuals = sortBy(draft.visuals, lowerCaseName).map(sortVisual)

        draft.sections = sortBy(draft.sections, 'index')
        for (const section of draft.sections) {
            section.items = sortBy(section.items, 'order')
            for (const item of section.items) {
                if (item.visual) {
                    item.visual = sortVisual(item.visual)
                }
            }
        }
    })
}

const eventState = atom<SimpleEvents.Event>({
    key: 'EventLocal',
    default: undefined
})

const venueIdState = selector<string>({
    key: "VenueState",
    get: ({get}) => {
        return get(eventState).venue.id
    }
})

// we use this to bump a number to trigger a refetch when we need it
const refetchEventCounterState = atom<number>({
    key: 'RefetchEventCounter',
    default: 0
})

export function useRefetchEvent() {
    const setRefetchEvent = useSetRecoilState(refetchEventCounterState)
    return () => setRefetchEvent(n => n + 1)
}

// A component version of useSetupEventSync you can drop in
export function SetupEventSync() {
    useSetupEventSync()
    return null
}

// Using a normal hook as atoms or selectors can't use other arbitary hooks
// You should only include this ONCE, and in a top level component
// Potentially it could be implemented with/using a Context
function useSetupEventSync() {
    const refetchCounter = useRecoilValue(refetchEventCounterState)
    const isConnected = useIsConnected()
    const isLoggedIn = useRecoilValue(isLoggedInState)
    const eventId = useRecoilValue(eventIdState)
    const config = useRecoilValue(configState)
    const setEvent = useSetRecoilState(eventState)

    const isReady = [isConnected, isLoggedIn, eventId, config].every(Boolean)

    const fetchAndSetEvent = useCallback(async () => {
        if (!isReady) return

        loadTemplates()

        // if we are changing eventId and have an event already
        // clear that one out, so we don't see a flash of the previous event
        setEvent(existingEvent => {
            if (existingEvent?.id !== eventId) {
                return null
            }
            return existingEvent
        })

        setEvent(await fetchEvent())

        async function fetchEvent() {
            const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
            try {
                const { event } = await resultOrThrow(subscriber.control.getEvent({ eventId }))
                return sortEvent(produce(event, draft => {
                    draft.date = fixEventDateString(draft.date)
                }))
            } catch (error) {
                debug('error getting event %s', error.message)
                return undefined
            }
        }
    }, [isReady, config, eventId])

    useEffect(() => {
        if (!isReady) return
        let unsubscribe: () => void

        fetchAndSetEvent()
        subscribeForUpdates()

        async function subscribeForUpdates() {
            const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
            const { dispose } = subscriber.status.eventUpdated(message => {
                const { eventId: updatedEventId } = message.getParams()
                if (eventId === updatedEventId) {
                    debug('event updated %o', { eventId })
                    fetchAndSetEvent()
                }
            })
            unsubscribe = dispose
        }

        return () => {
            unsubscribe?.()
        }
    }, [fetchAndSetEvent, eventId, isReady, refetchCounter])
}

export function useEvent() {
    return useRecoilValue(eventState)
}

export function useIsEventSubmitted(): boolean {
    const event = useEvent()
    if (!event) return false
    return event.status !== 'notSubmitted'
}

export function useIsEventCutOffPassed(): boolean {
    const event = useEvent()
    if (!event) return false
    return isEventCutOffPassed(event)
}

export function useEventSource(): EventSource {
    const event = useEvent()
    if (!event) return null
    return getEventSource(event.bookingId)
}

export function useEventFeatures(): { hasMusic: boolean, hasVisuals: boolean, hasStreaming: boolean } {
    const event = useEvent()

    const hasMusic = true
    const hasStreaming = event?.hasStreaming
    const hasVisuals = event?.visuals?.length > 0

    return {
        hasMusic,
        hasVisuals,
        hasStreaming
    }
}

export function useEventAspectRatio(): "16/9" | "9/16" {
    const event = useEvent()

    return event.room.aspectRatio
}

export function useSetEvent() {
    return useSetRecoilState(eventState)
}

export function useResetEvent() {
    return useResetRecoilState(eventState)
}

export const sectionsState = selector({
    key: 'Sections',
    get: ({ get }) => sortBy(get(eventState)?.sections ?? [], 'index'),
    set: ({ set }, updatedSections) => {
        set(eventState, produce(draft => {
            draft.sections = updatedSections
        }))
    }
})

export function useSections() {
    return useRecoilValue(sectionsState)
}

export function useSectionsState() {
    return useRecoilState(sectionsState)
}

export const eventIdState = atom<string | undefined>({
    key: 'EventId',
    default: null,
    effects: [
        ({ setSelf }) => {
            //TODO: Maybe there's a neater way of doing this?
            const initialEventId = location.pathname.match(/\/events\/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/)?.[1] ?? undefined
            if (initialEventId) {
                setSelf(initialEventId)
            }
        }
    ]
})

export function useEventId() {
    return useRecoilValue(eventIdState)
}

export function useSetEventId() {
    return useSetRecoilState(eventIdState)
}

export function useResetEventId() {
    return useResetRecoilState(eventIdState)
}

export const liveEventTypesState = atom<SimpleEvents.LiveEventType[]>({
    key: 'LiveEventTypes',
    default: [],
    effects: [
        ({ setSelf, getPromise }) => {
            let disposeSubscriber: () => void

            async function fetchData() {
                const isLoggedIn = await getPromise(isLoggedInState)
                if (!isLoggedIn) return []
                const config = await getPromise(configState)
                const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                const { liveEventTypes } = await resultOrThrow(subscriber.control.getLiveEventTypes())
                debug('setting initial live event types %', liveEventTypes)
                return liveEventTypes
            }

            async function subscribe() {
                if (disposeSubscriber) {
                    disposeSubscriber()
                    disposeSubscriber = null
                }
                const isLoggedIn = await getPromise(isLoggedInState)
                if (!isLoggedIn) return
                const config = await getPromise(configState)
                const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                const { dispose } = subscriber.status.liveEventTypesUpdated(message => {
                    const { liveEventTypes } = message.getParams()
                    debug('updating live event types %o', { liveEventTypes })
                    setSelf(liveEventTypes)
                })
                disposeSubscriber = dispose
            }

            setSelf(fetchData())

            subscribe()

            return () => disposeSubscriber?.()
        }
    ]
})

export function useLiveEventTypes() {
    return useRecoilValue(liveEventTypesState)
}

const trackRequestJobs = selector<TrackRequestJob[]>({
    key: 'TrackRequestJobs',
    get: async ({ get }) => {
        const config = get(configState)
        const eventId = get(eventIdState)
        if (!config || !eventId) return []
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        const { requests } = await resultOrThrow(subscriber.control.getTrackRequests({
            eventId
        }))
        return requests
    }
})

export function useTrackRequestJobs() {
    return useRecoilValue(trackRequestJobs)
}

export function useRefreshTrackRequestJobs() {
    return useRecoilRefresher_UNSTABLE(trackRequestJobs)
}

const audioUploadJobs = selector<AudioUploadJob[]>({
    key: 'AudioUploadJobs',
    get: async ({ get }) => {
        const config = get(configState)
        const eventId = get(eventIdState)
        if (!config || !eventId) return []
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        const { uploads } = await resultOrThrow(subscriber.control.getAudioUploads({
            eventId
        }))
        return uploads
    }
})

export function useAudioUploadJobs() {
    return useRecoilValue(audioUploadJobs)
}

export function useRefreshAudioUploadJobs() {
    return useRecoilRefresher_UNSTABLE(audioUploadJobs)
}

export const visualsState = selector({
    key: 'Visuals',
    get: ({ get }) => get(eventState)?.visuals ?? [],
    set: ({ set }, updatedVisuals) => {
        set(eventState, produce(draft => {
            draft.visuals = updatedVisuals
        }))
    }
})

export function useVisuals() {
    return orderBy(useRecoilValue(visualsState), ["name"], ["asc"])
}

export const visualState = selectorFamily<SimpleEvents.Visual, string>({
    key: 'Visual',
    get: id => ({ get }) => {
        if (!id) return
        return get(visualsState).find(entry => entry.id === id) as SimpleEvents.Visual
    },
    set: id => ({ set }, updatedEntry) => {
        set(eventState, produce(draft => {
            const entry = draft.visuals.find(entry => entry.id === id)
            if (entry) {
                Object.assign(entry, updatedEntry)
            }
        }))
    }
})

export function useVisual(id: string) {
    return useRecoilValue(visualState(id))
}

export function useVisualState(id: string) {
    return useRecoilState(visualState(id))
}

export const liveEventState = selectorFamily<SimpleEvents.LiveEvent, string>({
    key: 'LiveEvent',
    get: id => ({ get }) => {
        if (!id) return
        return get(eventState)?.liveEvents?.find(entry => entry.id === id) as SimpleEvents.LiveEvent
    }
})

export function useLiveEvent(id: string) {
    return useRecoilValue(liveEventState(id))
}

export const liveEventsState = selector<SimpleEvents.LiveEvent[]>({
    key: 'LiveEventResources',
    get: ({ get }) => get(eventState)?.liveEvents ?? [],
    set: ({ set }, updatedLiveEvents) => {
        set(eventState, produce(draft => {
            draft.liveEvents = updatedLiveEvents
        }))
    }
})

export const streamingViewersState = selector({
    key: 'StreamingViewers',
    get: ({ get }) => sortBy(get(eventState)?.streamingViewers ?? [], viewer => viewer.name.toLowerCase()),
    set: ({ set }, updatedStreamingViewers) => {
        set(eventState, produce(draft => {
            draft.streamingViewers = updatedStreamingViewers
        }))
    }
})

export function useStreamingViewers() {
    return useRecoilValue(streamingViewersState)
}

export const progressValue = selector<Progress>({
    key: 'ProgressValue',
    get: ({ get }) => {
        const event = get(eventState)
        return {
            // everything always false... 
            music: {
                // at least one bit of audio
                done: false // event?.sections.some(section => section.items.some(item => item.type === "audio"))
            },
            visuals: {
                // at least one visual
                done: false // event?.sections.some(section => section.items.some(item => item.type === "visual"))
            },
            scheduling: {
                done: false
            },
            streaming: {
                done: false // event?.streamingViewers.length > 0
            },
            review: {
                done: false
            }
        }
    }
})


export function useMusicSearchResults(props: { query: string, isPublic?: boolean }) {
    const { query, isPublic } = props;
    const [allResults, setAllResults] = useState<SearchTrackInfo[] | undefined>(undefined)
    const [results, setResults] = useState<SearchTrackInfo[] | undefined>(undefined)
    const [pageNumber, setPageNumber] = useState(undefined)
    const [maxPageNumber, setMaxPageNumber] = useState()
    const [loadingResults, setLoadingResults] = useState(false)
    const config = useConfig()
    const resetAudio = useResetAudio()
    useEffect(() => {
        if (pageNumber !== undefined) {
            const results = getTracks(pageNumber)
            setResults(results)
        }
    }, [pageNumber, allResults])

    useAsyncEffect(async () => {
        setMaxPageNumber(undefined)
        if (query) {
            await runQuery([], 0)
            setPageNumber(0)
        } else {
            setResults(undefined)
            setPageNumber(undefined)
        }
    }, [query])

    const runQuery = async (currentResults, currentPage, searchAfter?: SearchTrackInfo) => {

        if (query) {
            setLoadingResults(true)
            let hasMoreRows, tracks;
            if (isPublic) {
                const result = await axios.post('/api/music-library-search', { query, searchAfter })
                if (result.status !== 200) {
                    throw new Error("Failed to get results")
                }
                hasMoreRows = result.data.hasMoreRows
                tracks = result.data.tracks
            } else {
                if (!query || !config) return []
                const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                const result = await resultOrThrow(subscriber.control.searchTracks({
                    query,
                    searchAfter
                }))
                hasMoreRows = result.hasMoreRows
                tracks = result.tracks
            }
            setAllResults([...currentResults, ...tracks])
            if (!hasMoreRows) {
                setMaxPageNumber(currentPage)
            }
            setLoadingResults(false)
        } else {
            setAllResults(undefined)
        }
    }

    const getTracks = (pageNumber: number) => {
        const start = pageNumber * 10;
        return allResults.slice(
            start,
            start + 10
        )
    }

    const nextPage = async () => {
        resetAudio()
        const nextPage = pageNumber + 1;
        const nextTracks = getTracks(nextPage)
        const currentTracks = getTracks(pageNumber)
        if (isEmpty(nextTracks)) {
            await runQuery(allResults, nextPage, last(currentTracks))
        }
        setPageNumber(nextPage)
    }

    const prevPage = () => {
        resetAudio()
        setPageNumber(pageNumber - 1)
    }

    return {
        nextPage,
        prevPage,
        loadingResults,
        hasNext: !isEmpty(allResults) && maxPageNumber !== pageNumber,
        hasPrev: pageNumber !== 0,
        results,
        allResults
    }
}


const eventOrganisationState = selector<SimpleEvents.ProfessionalOrganisation | undefined>({
    key: 'EventOrganisation',
    get: async ({ get }) => {
        const event = get(eventState)
        return event?.organisation
    }
})

export function useEventOrganisation() {
    return useRecoilValue(eventOrganisationState)
}