import { defaultDropAnimationSideEffects, DndContext, DragCancelEvent, DragEndEvent, DragOverlay, DragStartEvent, DropAnimation, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { NoButtonsPointerSensor, wesleyDebugNamespace } from 'common/frontend/utils'
import { SimpleEvents } from 'common/types/eventService'
import { generateKeyBetween, generateNKeysBetween } from 'fractional-indexing'
import { produce } from 'immer'
import { map, sortBy } from 'lodash'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useEventId } from '../../state'
import Cover from '../Cover'
import { VisualAddComponentModal } from './VisualAddComponentModal'
import { VisualComponentEditModal } from "./VisualComponentEdit"
import VisualComponentGridItem, { VisualComponentGridItemOverlay } from './VisualComponentGridItem'

const debug = wesleyDebugNamespace.extend('media-grid')

export interface VisualComponentGridProps {
    components: SimpleEvents.VisualComponent[]
    addTypes: ('image' | 'video')[]
    addMultiple: boolean
    onAdd: (components: {component: SimpleEvents.VisualComponentData, file: File}[]) => void
    onChange: (component: SimpleEvents.VisualComponent) => void
    onRemove: (id: string) => void
    onComponentSelected: (id: string, selected: boolean) => void
    aspectRatio: "16/9" | "9/16"
    template: string
    disableEdit?: boolean
    readOnly?: boolean
    selectedComponents?: string[]
}

const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        className: {
            active: 'dropping'
        }
    }),
}

export function VisualComponentGrid({ components, addTypes, addMultiple, onAdd, onRemove, onChange, onComponentSelected, selectedComponents, aspectRatio, template, disableEdit, readOnly }: VisualComponentGridProps) {
    const sensors = useSensors(useSensor(NoButtonsPointerSensor))
    const eventId = useEventId()
    const [editComponent, setEditComponent] = useState<SimpleEvents.VisualComponent>(undefined)
    const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [sortedComponents, setSortedComponents] = useState<SimpleEvents.VisualComponent[]>([])

    useEffect(() => {
        setSortedComponents(sortBy(components, 'order'))
    }, [components])

    function onDragStart({ active }: DragStartEvent) {
        setActiveComponent(active.data.current.component)
    }

    function onDragCancel({ active }: DragCancelEvent) {
        setActiveComponent(null)
    }

    function onDragEnd({ active, over }: DragEndEvent) {
        setActiveComponent(null)
        const currentIndex = active.data.current.sortable.index
        if (!over?.data.current) return
        const newIndex = over.data.current.sortable.index

        if (newIndex === currentIndex) return

        // finding the order for the next one *after* we've rearranged them...
        const component = sortedComponents[currentIndex]

        function getNewOrder() {
            const index = newIndex > currentIndex ? newIndex : newIndex - 1
            const before = sortedComponents[index]?.order ?? null
            const after = sortedComponents[index + 1]?.order ?? null
            return generateKeyBetween(before, after)
        }

        const order = getNewOrder()

        const updatedComponent = produce(component, draft => {
            draft.order = order
        })

        // immediately switch them, so it updates probably, makes react-dnd happier
        setSortedComponents(produce(draft => {
            draft.splice(currentIndex, 1)
            draft.splice(newIndex, 0, updatedComponent)
        }))

        // ... then also set the order properly...
        onChange(updatedComponent)
    }

    function onEdit(id: string) {
        setEditComponent(components.find(item => item.id === id))
    }

    function closeEditModal() {
        setEditComponent(undefined);
    }

    const onDone = (newComponents: {component: SimpleEvents.VisualComponent, file: File}[]) => {
        onAdd(newComponents)
        setAddModalOpen(false)
    }

    const [activeComponent, setActiveComponent] = useState<SimpleEvents.VisualComponent>(null)

    return (
        <div
            className="media-grid"
        >
            <DndContext
                sensors={sensors}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
                onDragCancel={onDragCancel}
            >
                <div className="media-grid__resources" style={{gridTemplateColumns: `repeat(${aspectRatio === "16/9" ? 3 : 5}, 1fr)`}}>
                    <SortableContext
                        disabled={readOnly}
                        items={sortedComponents.map(entry => entry.id)}
                    >
                        {sortedComponents.map((resource, index) => (
                            <VisualComponentGridItem
                                disableEdit={disableEdit}
                                readOnly={readOnly}
                                key={resource.id}
                                number={index + 1}
                                component={resource}
                                onChange={onChange}
                                onRemove={onRemove}
                                onEdit={onEdit}
                                aspectRatio={aspectRatio}
                                template={template}
                                isSelected={selectedComponents?.includes(resource.id)}
                                onSelected={onComponentSelected}
                            />

                        ))}
                    </SortableContext>
                    {addTypes.length > 0 && !readOnly && (
                        <div className='media-grid__resources__entry' onClick={() => setAddModalOpen(true)}>
                            <div className="media-grid__resources__entry__add" style={{aspectRatio}}>
                                <Cover center transparent style={{aspectRatio}}>
                                    <div className="media-grid__resources__entry__add__plus" />
                                    <span>Add new slide(s)</span>
                                </Cover>
                            </div>
                        </div>
                    )}
                </div>
                <DragOverlay
                    dropAnimation={dropAnimation}
                >
                    {activeComponent && (
                        <VisualComponentGridItemOverlay
                            component={activeComponent}
                            aspectRatio={aspectRatio}
                            template={template}
                        />
                    )}
                </DragOverlay>
            </DndContext>
            {editComponent && (
                <VisualComponentEditModal
                    aspectRatio={aspectRatio}
                    template={template}
                    opened={true}
                    component={editComponent}
                    onClose={() => closeEditModal()}
                    onDone={onChange}
                />
            )}
            {
                addModalOpen && <VisualAddComponentModal template={template} types={addTypes} multiple={addMultiple} eventId={eventId} upload={(files) => { }} opened={true} onClose={() => setAddModalOpen(false)} onDone={onDone} aspectRatio={aspectRatio}/>
            }
        </div>
    );
}