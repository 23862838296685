import * as React from 'react'
import SearchableContent, { HighlightedContent } from '../components/search/SearchableContent'
import SearchDropdown from '../components/search/SearchDropdown'
import { StoriesDefinition } from './StoriesPage'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { searchableContentState, searchableRoutesState, searchQueryState, searchResultsState } from '../components/search/Search'
import DevInspect from '../components/dev/DevInspect'
import { useEffect } from 'react'
import { Highlight } from '../components/search/SearchResults'

function Wrapper({ children }) {
    return (
        <div className="rounded-box" style={{ width: 600, background: 'white', padding: 20 }}>
            {children}
        </div>
    )
}

const stories: StoriesDefinition = {
    name: 'PageSearch',
    hidden: true,
    stories: [
        {

            name: 'Search',
            children: [
                {

                    name: 'empty',
                    component: () => (
                        <Wrapper>
                            <SearchDropdown
                                key="empty"
                            />
                        </Wrapper>
                    )
                },
                {

                    name: 'query: kitten',
                    component: () => (
                        <Wrapper>
                            <SearchDropdown
                                key="query-kitten"
                                initialQueryString="kitten"
                            />
                        </Wrapper>
                    )
                },
                {

                    name: 'query: selection',
                    component: () => (
                        <Wrapper>
                            <SearchDropdown
                                key="query-selection"
                                initialQueryString="selection"
                            />
                        </Wrapper>
                    )
                }
            ]
        },
        {
            name: 'SearchDropdown',
            component: () => {
                return (
                    <div
                        style={{
                            background: 'white',
                            padding: 20,
                            maxWidth: 800,
                            display: 'flex',
                            justifyContent: 'end'
                        }}
                    >
                        <SearchDropdown />
                    </div>
                )
            }
        },
        {
            name: 'SearchResults',
            component: () => {
                const queryString = 'foo'
                const results = useRecoilValue(searchResultsState({ queryString }))
                const setSearchableRoutes = useSetRecoilState(searchableRoutesState)
                useEffect(() => {
                    setSearchableRoutes([
                        {
                            path: '/foo',
                            element: (
                                <div title="foo page title">
                                    <SearchableContent>you can search foo and some text after it</SearchableContent>
                                </div>
                            )
                        },
                        {
                            path: '/blah',
                            element: (
                                <div title="blah page title">
                                    <SearchableContent>you can search blah</SearchableContent>
                                </div>
                            )
                        }
                    ])
                }, [])
                return (
                    <div>
                        <h3>Search results</h3>
                        {results.map(result => (<div>
                            <div key={result.entry.id}>
                                <h4>Title</h4>
                                <Highlight highlight={result.highlights.title} />
                                <h4>Content</h4>
                                {result.highlights.content.map((highlight, index) => (
                                    <Highlight key={index} highlight={highlight} />
                                ))}
                            </div>
                        </div>))}
                        <DevInspect label='searchStateQuery' value={results} />
                    </div>
                )
            }
        },
        {
            name: 'HighlightedContent',
            component: () => (
                <HighlightedContent
                    query={{ queryString: 'blah', result: null }}
                >
                    <h2>Some Blah in the Title</h2>
                    <p>And some other blah here</p>
                </HighlightedContent>
            )
        }
    ]
}

export default stories