import { BDuration, BDurationJson, IInstance } from '@busby/esb'
import { Player, PlayerSettings, Project, Stage, StageSettings } from '@motion-canvas/core'
import { SimpleEvents } from 'common/types/eventService'
import { find, memoize } from 'lodash'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import { omitKeys } from '../../utils'
import { getScaledHeight, getScaledWidth } from '../CanvasImageHelper'
import Cover from '../Cover'
import { SlideParams, TemplateParams, loadTemplateFonts, templates } from "./templates/templates"
import { getVisualProject } from './VisualUtils'

export interface VisualCoverImageProps extends React.HTMLAttributes<HTMLImageElement> {
    eventId: string
    visual: SimpleEvents.Visual
    contentQuality?: "preview" | "original"
    aspectRatio: "16/9" | "9/16"
    onReady?: () => void
}

export function VisualCoverImage({
    eventId,
    visual,
    contentQuality,
    onReady,
    aspectRatio,
    ...attributes
}: VisualCoverImageProps) {
    const [image, setImage] = useState(null)
    
    const params = useMemo<GenerateCoverImageParams | undefined>(() => {
        if (!visual) return
        
        return {
            eventId,
            contentQuality,
            visual,
            aspectRatio
        }
    }, [visual, contentQuality, eventId])

    useAsyncEffect(async () => {
        if (params) {
            setImage(await generateVisualCoverCached(params))
        } else {
            setImage(null)
        }
    }, [params])

    useEffect(() => {
        onReady?.()
    }, [image])

    return (
        <div style={{ aspectRatio, width: "100%", position: "relative" }} {...attributes}>
            <img
                style={{ aspectRatio }}
                src={image}
            />
            {
                !image && <Cover loading transparent center />
            }
        </div>
    )
}

export const generateVisualCoverCached = memoize(generateVisualCover, JSON.stringify)
export interface GenerateCoverImageParams {
    eventId: string
    visual: SimpleEvents.Visual
    contentQuality?: "preview" | "original"
    aspectRatio: "16/9" | "9/16"
}

export async function generateVisualCover({
    eventId, visual, contentQuality, aspectRatio
}: GenerateCoverImageParams) {

    try {
        const width = 300;
        const { template, components, slideDuration, fadeInDuration } = visual
        const height = getScaledHeight(template.aspectRatio, width)
        const stage = new Stage()
        let project: Project;
        try {

            //To be replaces with dynamic import from S3 when we switch to Vite...
            if(visual.manuallyCreatedVisual){
                project = templates.videoPlayer.default
            }else{
                project = getVisualProject(aspectRatio, template.projectId)
            }
            await loadTemplateFonts(project)
            // if (module) {
            //     project = module.default;
            // } else {
            //     throw new Error(`Module not found`)
            // }

        } catch (e) {
            console.error(e);
            return;
        }

        const defaultSettings = project.meta.getFullRenderingSettings();
        const player = new Player(project);
        const slides = []
        let component;
        const componentCount = visual.components.length;
        if(componentCount > 0){
            if(componentCount % 2 === 0){
                component = components[componentCount/2]
            }else{
                component = components[Math.floor(componentCount/2)]
            }
        }
        
        if (component) {
            const { adjust, crop, type, filename, captions = {} } = component
            let slide;
            if(visual.manuallyCreatedVisual){
                const { filename } = find(visual.manuallyCreatedVisual.instances, v => v.type === "manuallyCreatedVisual").fileMetadata.location
                slide = {
                    type: "videoPlayer",
                    eventMedia: {
                        path: `manuallyCreatedVisuals/${filename}`
                    }
                }
            }else{
                slide = {
                    captions,
                    type: component.slideType,
                    userMedia: [{ adjust, crop, type, filename, contentQuality }]
                }
            }
            slides.push(slide)
        }
        const templateParams: TemplateParams = {
            slideDuration: BDuration.fromJson(slideDuration).getMilliseconds()/1000,
            fadeDuration: BDuration.fromJson(fadeInDuration).getMilliseconds()/1000,
            eventId,
            slides
        }

        const variables = {
            ...project.variables,
            templateParams
        }

        player.setVariables(variables);

        const resolutionScale = height / defaultSettings.size.height;
        const settings: PlayerSettings & StageSettings = {
            ...defaultSettings,
            resolutionScale,
            background: "#FFFFFFFF",
            fps: 25
        };

        stage.configure(settings);
        await player.configure(settings);
        player.activate()

        const result = await new Promise((resolve, reject) => {
            const onRender = async () => {
                try {
                    const { firstFrame, lastFrame } = player.playback.currentScene
                    const position = (lastFrame - firstFrame) / 2

                    await player.playback.seek(position);
                    await stage.render(
                        player.playback.currentScene!,
                        player.playback.previousScene,
                    );

                    player.onRender.unsubscribe(onRender);
                    const blob: Blob = await new Promise(resolve => {
                        stage.finalBuffer.toBlob((blob) => {
                            resolve(blob)
                        }, "image/png");
                    })
                    resolve(URL.createObjectURL(blob))
                } catch (e) {
                    reject(e)
                }
            }
            player.onRender.subscribe(onRender);
        })

        return result;
    } catch (e) {
        console.error(e)
    }
}

