import { useConfirmModal } from 'common/frontend/hooks'
import { SimpleEvents } from 'common/types/eventService'
import * as React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { getBemClasses } from '../../../../../../common/BEM/utils'
import { useDeleteEventsAction } from '../actions'
import EventGrid from '../components/EventGrid'
import { useRefetchEvents } from '../queries'
import './ManageEventsPage.scss'
import EventFilters from '../components/EventFilters'

export default function ManageEventsPage() {
    const [selectedEvents, setSelectedEvents] = useState<SimpleEvents.Event[]>([])
    const [deleteEvents, deleteEventsIsPending] = useDeleteEventsAction()
    const refetchEvents = useRefetchEvents()

    const [eventFilters, setEventFilters] = useState({})

    const {
        modal,
        confirm
    } = useConfirmModal({
        title: 'Delete selected events',
        message: 'Are you sure want to delete the selected items?',
        confirmButton: 'Delete',
        isPending: deleteEventsIsPending
    })

    async function onDeleteSelected() {
        if (selectedEvents.length === 0) return
        if (await confirm()) {
            await deleteEvents({ eventIds: selectedEvents.map(event => event.id) })
            await refetchEvents()
        }
    }

    return (
        <div className="manage-events-page">
            <section className="container-padding">
                <EventFilters onChange={eventFilters => setEventFilters(eventFilters)}>
                    <Link to="/events/create">
                        <button
                            className="btn btn--secondary nowrap"
                            style={{ fontSize: '14px' }}
                        >
                            Create event
                        </button>
                    </Link>
                    <button
                        className={getBemClasses('btn', {
                            secondary: selectedEvents.length === 0
                        })}
                        style={{ fontSize: '14px' }}
                        disabled={selectedEvents.length === 0}
                        onClick={() => onDeleteSelected()}
                    >
                        Delete
                    </button>
                </EventFilters>
                <EventGrid
                    {...eventFilters}
                    onSelectedChanged={events => setSelectedEvents(events)}
                />
            </section>
            {modal}
        </div>
    )
}
