import * as React from 'react'
import { useEffect, useState } from 'react'
import { getBemClasses, stopAndPrevent } from '../utils'
import SliderBox from './SliderBox'
import './VideoControls.scss'
import { DateTime } from "luxon"
export interface VideoPlayer {
    position: number
    duration: number
    isPlaying: boolean
    isMuted?: boolean
    playPause?: () => void
    onVolumeChange?: (value: number) => void
    onPositionChange?: (value: number) => void
    onMuted?: (muted: boolean) => void
    onRequestFullscreen?: () => void
}

/**
 * Video controls! Bring your own video player and wire them up.
 * 
 * Includes:
 * - transport slider thing
 * - volume with mute control
 * - play/pause toggle bottom
 * - fullscreen
 */
export default function VideoControls({
    // All units in milliseconds
    position,
    duration,
    isPlaying,
    isMuted,
    playPause,
    onVolumeChange,
    onPositionChange,
    onMuted,
    onRequestFullscreen
}: VideoPlayer) {
    const [volume, setVolume] = useState(1)
    useEffect(() => {
        onVolumeChange?.(volume)
    }, [onVolumeChange, volume])

    let positionFormat = "mm:ss";
    const positionDateTime = DateTime.fromMillis(position, { zone: "utc" })
    if (positionDateTime.hour > 0) {
        positionFormat = "HH:mm:ss"
    }

    let durationFormat = "mm:ss";
    const durationDateTime = DateTime.fromMillis(duration, { zone: "utc" })
    if (durationDateTime.hour > 0) {
        durationFormat = "HH:mm:ss"
    }

    return (
        <div className="video-controls">
            <SliderBox
                min={0}
                max={duration}
                value={position}
                step={1}
                sliderMode={1}
                sliderWidth="100%"
                onUpdate={onPositionChange}
                onChange={() => { }}
                style={{
                    '--height': '16px',
                    '--padding': '4px',
                } as React.CSSProperties}
            />
            <div className="video-controls__controls">
                <div className="video-controls__controls__left">
                    <div className="video-controls__controls__left__position">
                        {positionDateTime.toFormat(positionFormat)} / {durationDateTime.toFormat(durationFormat)}
                    </div>
                </div>
                <div className="video-controls__controls__center">
                    {playPause && (
                        <button
                            className={getBemClasses('action', [
                                isPlaying ? 'pause' : 'play',
                                'circle'
                            ])}
                            onClick={stopAndPrevent(() => playPause())}
                        />
                    )}
                </div>
                <div className="video-controls__controls__right">
                    {onVolumeChange && (
                        <SliderBox
                            min={0}
                            max={1}
                            value={volume}
                            step={0.01}
                            sliderMode={1}
                            onUpdate={setVolume}
                            onChange={setVolume}
                            toggleAction="muted"
                            onToggle={onMuted}
                            style={{
                                '--padding': '9px',
                                '--handle__oversize': '3px',
                                '--track__color': 'var(--main-color)',
                                '--width': '120px'
                            } as React.CSSProperties}
                        />
                    )}
                    {
                        onMuted && !onVolumeChange && (
                            <button
                                className={getBemClasses('action', [
                                    'muted',
                                    isMuted ? 'enabled' : null,
                                    'circle'
                                ])}
                                onClick={stopAndPrevent(() => onMuted(!isMuted))}
                            />
                        )
                    }
                    {onRequestFullscreen && (
                        <button
                            className={getBemClasses('action', [
                                'fullscreen',
                                'circle'
                            ])}
                            onClick={stopAndPrevent(() => onRequestFullscreen())}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}