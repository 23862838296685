import * as classnames from 'classnames'
import { getBemClasses } from 'common/frontend/utils'
import * as React from 'react'
import { Children } from 'react'
import { Link, resolvePath, useLocation } from 'react-router-dom'
import './Steps.scss'

export interface StepsProps {
    headerOnly: boolean,
    children: React.ReactElement<StepProps>[]
}

export default function Steps({ headerOnly, children }: StepsProps) {
    const { pathname } = useLocation()

    const steps = Children.toArray(children).map(child => {
        if (!React.isValidElement(child) || 
            typeof child.type !== 'function' || 
            child.type.name !== Step.name
        ) {
            console.warn('can only pass Step components into Steps, not', child)
            return
        }
        return child.props as StepProps
    }).filter(Boolean)
    const currentIndex = steps.findIndex(props => pathname.startsWith(resolvePath((props as StepProps).to).pathname))

    return (
        <div className={getBemClasses('steps', { 'header-only': headerOnly, 'with-header': !headerOnly })}>
            {steps.map(({ to, done, title, children }, index) => (
                <Link
                    key={index}
                    to={to}
                    className={getBemClasses('steps__step', {
                        // The steps are linear, and we consider anything current or previous as "active"
                        active: index <= currentIndex,
                        current: index === currentIndex,
                        done,
                    })}
                >
                    <div className="steps__step__content">
                        <h3><span className="steps__step__content__number">{index + 1}</span>{title}</h3>
                        {!headerOnly && children}
                    </div>
                </Link>
            ))}
            <RoundedCornersFilter />
        </div>
    )
}

interface StepProps {
    title: string
    subtitle?: string
    to: string
    done: boolean
    children: React.ReactNode
}

function Step(props: StepProps) {
    // We don't actually render this...
    return <></>
}

Steps.Step = Step


/**
 * Crazy svg filter things for doing rounded corners on a clip-path
 * See https://www.plantcss.com/blog/how-to-make-rounded-corners-when-using-css-clip-path 
 */
function RoundedCornersFilter() {
    return (
        <svg className="filter" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <filter id="filter--rounded-corners">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur" />
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="filter--rounded-corners" />
                    <feComposite in="SourceGraphic" in2="filter--rounded-corners" operator="atop"/>
                </filter>
            </defs>
        </svg>
    )
}