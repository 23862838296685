import * as React from 'react'

export default function SimpleMessagePage({ message }: { message: string }) {
    return (
        <div style={{
            margin: '0 auto',
            maxWidth: 600
        }}>
            <div
                className="rounded-box"
                style={{
                    margin: '80px 20px',
                    padding: 40
                }}
            >
                <h2
                    style={{
                        fontWeight: 500,
                        textAlign: 'center'
                    }}
                >
                    {message}
                </h2>
            </div>
        </div>
    )
}