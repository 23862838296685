import * as React from 'react'
import { useMemo, useRef, useState } from 'react'
import { Handles, Rail, Slider, Tracks } from 'react-compound-slider'
import { InOutMarkers } from '../model'
import { getBemClasses, stopAndPrevent, wesleyDebugNamespace } from '../utils'
import { CustomMode } from 'react-compound-slider/dist/types/types'

const debug = wesleyDebugNamespace.extend('slider')

export interface WesleySliderInputProps {
    value: number
    onChange: (value: number) => void
    onUpdate?: (value: number) => void
    disabled?: boolean
    min?: number
    max?: number
    step?: number
    sliderWidth?: string
    sliderMode?: 1 | 2 | 3 | CustomMode
    trackLeft?: boolean
    trackRight?: boolean
    markers?: InOutMarkers
    onMarkersChange?: (markers: InOutMarkers) => void
    onMarkersUpdate?: (markers: InOutMarkers) => void
}

/**
 *  I tried to use a SliderInput, but adding support for markers was fiddly, so I
 *  made a new component, it generally conforms to the same props as SliderInput.
 */
export default function WesleySliderInput({
    value = 0,
    onChange,
    onUpdate,
    disabled,
    min = 0,
    max: maxProp,
    step = 1,
    sliderWidth,
    sliderMode = 2,
    trackLeft,
    trackRight = false,
    markers,
    onMarkersChange,
    onMarkersUpdate
}: WesleySliderInputProps) {

    const useMarkers = Boolean(markers)
    const enableUpdates = useRef(false);
    const lastEmittedMarkers = useRef({ change: null, update: null })

    if (trackLeft === undefined) {
        // default to having a left track unless we're using markers
        trackLeft = !useMarkers
    }

    const values = useMemo(() => {
        if (useMarkers) {
            const [inMarker, outMarker] = markers
            return [
                // default in marker to min
                inMarker || min,

                // ensure value is between in/out markers
                Math.max(Math.min(value, outMarker - step), inMarker + step),

                // default out marker to max
                outMarker || maxProp
            ]
        } else {
            return [value]
        }
    }, [useMarkers, markers, value])

    function on(action: 'change' | 'update', values: readonly number[]) {
        if (enableUpdates.current) {
            const onValue = action === 'change' ? onChange : onUpdate
            if (useMarkers) {
                const onMarkers = action === 'change' ? onMarkersChange : onMarkersUpdate
                const [newInMarker, newValue, newOutMarker] = values

                const lastEmitted = lastEmittedMarkers.current[action]
                // only emit if it's not what we last emitted...
                // prevents rapidly emitting the same value multiple times
                if (!lastEmitted || lastEmitted[0] !== newInMarker || lastEmitted[1] !== newOutMarker) {
                    const newMarkers: InOutMarkers = [nullIfIsNan(newInMarker), nullIfIsNan(newOutMarker)]
                    lastEmittedMarkers.current[action] = newMarkers
                    // debug('triggering markers %s -> %o, current are %o', action, newMarkers, markers)
                    onMarkers?.(newMarkers)
                }

                // Avoid jumping the position if only the markers have changed
                if (value !== newValue && !Number.isNaN(newValue)) {
                    onValue?.(newValue)
                }
            } else {
                const newValue = values[0]
                if (value !== newValue && !Number.isNaN(newValue)) {
                    onValue?.(newValue)
                }
            }
        }
    }

    function onSlideStart(){
        console.log("onSlideStart"); 
        enableUpdates.current = true
    }

    // ensure max is the biggest of all available numbers...
    let max = useMarkers ? Math.max(maxProp, ...values) : maxProp

    // Slider really doesn't like max === min ///
    if (max <= min) {
        max = min + step
    }

    return (
        <div
            className={getBemClasses('slider-input', {
                'use-markers': useMarkers
            })}
            // Prevent clicks on the track/slider from propagating upward, we have handled them...
            onClick={stopAndPrevent()}
        >
            <Slider
                disabled={disabled}
                className={"slider-input-container"}
                domain={[min, max]}
                values={values}
                step={step}
                mode={sliderMode}
                onChange={values => on('change', values)}
                onUpdate={values => on('update', values)}

                onSlideStart={onSlideStart}
                onSlideEnd={(values) => {
                    console.log("onSlideEnd", values); 
                    enableUpdates.current = true
                    on("update", values)
                    on("change", values)
                    enableUpdates.current = false
                }}
                rootStyle={{
                    width: sliderWidth
                }}>
                <Rail>
                    {({ getRailProps }) => (
                        <div
                            className="slider-input-rail"
                            style={{ width: sliderWidth }}
                            //@ts-ignore
                            {...getRailProps({onMouseDown: onSlideStart})}
                        />
                    )}
                </Rail>
                <Handles>
                    {({ handles, getHandleProps }) => (
                        <div className="slider-input-handle-container">
                            {handles.map(handle => (
                                <Handle
                                    key={handle.id}
                                    handle={handle}
                                    getHandleProps={getHandleProps}
                                    modifier={useMarkers && {
                                        // Hope these marker ids are stable!
                                        // Can't use index as they are not always in same order as values array...
                                        '$$-0': 'in-marker',
                                        '$$-2': 'out-marker'
                                    }[handle.id]}
                                />
                            ))}
                        </div>
                    )}
                </Handles>
                <Tracks left={trackLeft} right={trackRight}>
                    {({ tracks, getTrackProps }) => (
                        <div className="slider-input-track-container">
                            {tracks.map(({ id, source, target }) => (
                                <Track
                                    key={id}
                                    source={source}
                                    target={target}
                                    //@ts-ignore
                                    trackProps={getTrackProps({onMouseDown: onSlideStart})}
                                />
                            ))}
                        </div>
                    )}
                </Tracks>
            </Slider>
        </div>
    )
}

function Handle({
    handle: { id, value, percent },
    getHandleProps,
    modifier = ''
}) {
    return (
        <div
            className={getBemClasses('slider-input-handle', modifier)}
            style={{
                left: `${percent}%`,
            }}
            {...getHandleProps(id)}
        >
            <div className="slider-input-handle-line" />
        </div>
    )
}

function Track({ source, target, trackProps }) {
    return (
        <div
            className="slider-input-track"
            style={{
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            {...trackProps}
        />
    )
}

function nullIfIsNan(value?: number) {
    return Number.isNaN(value) ? null : value
}