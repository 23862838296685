import * as React from 'react'
import { Menu } from '@headlessui/react'
import { bem } from '../utils'
import './ActionMenu.scss'
import Icon, { IconName } from './Icon'

const block = bem('action-menu')
const elButton = block.element('button')
const elLabel = elButton.element('label')
const elIcon = elButton.element('icon')
const elItems = block.element('items')
const elItem = elItems.element('item')

export interface ActionMenuProps<T> {
    label?: string
    icon?: IconName
    openLeft?: boolean
    entries: { label: string, value: T, disabled?: boolean }[]
    onAction: (value: T) => void
}

export default function ActionMenu<T>({
    label,
    icon,
    openLeft,
    entries,
    onAction,
}: ActionMenuProps<T>) {
    const allDisabled = entries.every(entry => entry.disabled)
    return (
        <Menu as="div" className={block.mod({ 'open-left': openLeft })}>
            <Menu.Button as="a" className={elButton.className}>
                {icon && (
                    <Icon
                        className={elIcon.className}
                        name={icon}
                        fill={allDisabled ? '#ccc' : '#8357E1'}
                        size={24}
                    />
                )}
                {label && <span className={elLabel.mod({ 'disable': allDisabled })}>{label}</span>}
            </Menu.Button>
            <Menu.Items className={elItems.className}>
                {entries.map(entry => (
                    <Menu.Item key={entry.label} disabled={entry.disabled}>
                        {({ active }) => (
                            <div
                                className={elItem.mod({ active, disabled: entry.disabled })}
                                onClick={() => onAction(entry.value)}
                            >
                                {entry.label}
                            </div>
                        )}
                    </Menu.Item>
                ))}
            </Menu.Items>
        </Menu>
    )
}