import * as React from 'react'
import { useScheduleItemTags } from '../state'
import "./TrackTag.scss"
import { Select } from './Select'
import { ScheduleItemTagWithLoop, SimpleEvents } from 'common/types/eventService'
import { OnTagChange } from '../model'

type TrackTagProps = {
    onChange: OnTagChange
    value: ScheduleItemTagWithLoop
    disabled?: boolean
}

export function TrackTag({value, onChange, disabled} : TrackTagProps){

    const scheduleItemTags = useScheduleItemTags()
     
    return <div className='track-tag' style={{width: 380}}>
        <legend>Tag</legend>
        <Select disabled={disabled} getDisplayValue={(item) => item.name} onChange={onChange} options={scheduleItemTags} value={value} />
    </div>  
}