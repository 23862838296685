import * as React from 'react'
import { SVGProps } from 'react'
import icons from './icons'
import * as classnames from 'classnames'

export type IconName = keyof (typeof icons)

export interface IconProps extends SVGProps<SVGElement>{
    name: IconName
    size?: number
}

export default function Icon({ name, size = 30, ...attrs }: IconProps) {
    const SvgIcon = icons[name]
    if (!SvgIcon) throw new Error('invalid icon: ' + name)
    return (
        <SvgIcon className={classnames(['icon', attrs.className])} width={size} height={size} {...attrs} />
    )
}