import * as React from 'react'
import EventCreate from '../components/EventCreate'

export default function EventCreatePage() {
    return (
        <div className="edit-event-page">
            <section className="container-padding">
                <EventCreate />
            </section>
        </div>
    )
}