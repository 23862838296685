import ProgressSteps from 'common/frontend/components/ProgressSteps'
import VisualEdit from '../components/visuals/VisualEdit'
import { wesleyDebugNamespace } from 'common/frontend/utils'
import * as React from 'react'
import { useParams } from 'react-router-dom'

const debug = wesleyDebugNamespace.extend('visual-edit-page')

export default function VisualEditPage() {
    const { visualId } = useParams()
    return (
        <div className="visual-selection-page">
        <section className="progress-navigation">
            <ProgressSteps headerOnly />
        </section>
        <section className="section--grey container-padding">
            <VisualEdit
                visualId={visualId}
            />
        </section>
    </div>
    )
}