import * as React from 'react'

import * as facebookImage from 'common/assets/facebook-square.svg'
import * as twitterImage from 'common/assets/twitter.png'
import * as linkedinImage from 'common/assets/linkedin.png'
import Icon from './Icon'

export default function SocialMediaLinks() {
    return (
        <div className="social-media-links">
            <a href="https://www.facebook.com/wesleymedia/">
                <Icon name='FacebookSquare' />
            </a>
            <a href="https://twitter.com/wesleymedia">
                <Icon name='TwitterSquare' />
            </a>
            <a href="https://uk.linkedin.com/company/wesleymedia">
                <Icon name='LinkedinSquare' />
            </a>
        </div>
    )
}