
import { ResourceTypeFields } from '@busby/esb'
import {
    RowData, RowSelectionState, SortingState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table'
import Cover from 'common/frontend/components/Cover'
import { formatTime } from 'common/frontend/formatters'
import { getBemClasses, wesleyDebugNamespace } from 'common/frontend/utils'
import { DigitalSignageEvent, EventServiceSubscriber } from 'common/types/eventService'
import { Events } from 'common/types/events'
import { find } from 'lodash'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useSignInfoQuery } from '../queries'
import './EventGrid.scss'
import './DigitalSignageGrid.scss'
import { DateTime } from 'luxon'
import { useConfig } from 'common/frontend/state'
import { resultOrThrow } from 'common/universal/universalUtils'
import { toast } from 'react-toastify'
declare module '@tanstack/table-core' {
    interface ColumnMeta<TData extends RowData, TValue> {
        className?: string
    }
}

const debug = wesleyDebugNamespace.extend('event-grid')

type QueryField = keyof Events.Event | ResourceTypeFields<Events.Event, false>

const orderByMapping: Record<string, QueryField> = {
    name: 'name',
    date: 'date',
    venue: 'venue.name',
    room: 'room.name',
    organisation: 'organisation.name',
}
export interface EventGridProps {
    onSelectedChanged?: (events: DigitalSignageEvent[]) => void
    signId: string
    date: string
}

export default function DigitalSignageGrid({ signId, date, onSelectedChanged }: EventGridProps) {
    const [sorting, setSorting] = useState<SortingState>([{
        id: "room",
        desc: false
    },{
        id: "date",
        desc: false
    }])
    
    const config: any = useConfig()
    const { signInfo, status, refetch } = useSignInfoQuery({ id: signId, date })
    const { events = [], rooms } = signInfo || {}
    const onCellChanged = async (row: DigitalSignageEvent, key: string, value: any) => {
        try{
            const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
            const event = {
                ...row,
                [key]: value
            }
            await resultOrThrow(subscriber.control.digitalSignageUpdateEvent({ event, signId }))
            refetch()
            toast('Saved', {
                autoClose: 500,
                closeButton: false,
                position: 'bottom-center',
                className: 'small-toast'
            })
        }catch(e){
            toast.error(e.message || "Error saving", {
                autoClose: 5000,
                closeButton: false,
                position: 'top-center'
            })
        }
    }

    const onTimeCellChanged = async (row: DigitalSignageEvent, key: string, value: any) => {
        const time = DateTime.fromFormat(value, "HH:mm", {zone: "Europe/London"}).setZone("utc")
        const dateTime = DateTime.fromISO(row.date, {zone: "utc"}).set({hour: time.hour, minute: time.minute})

        onCellChanged(row, "date", dateTime.toISO())
    }

    const {
        getRowModel,
        getFlatHeaders,

    } = useReactTable({
        data: events,
        columns: [
            {
                header: 'Name',
                accessorKey: 'name',
                cell: cell => <EditableInput value={cell.getValue()} onChange={(value) => onCellChanged(cell.row.original, "name", value)} type="textarea" />,
                size: 300
            },
            {
                id: 'date',
                header: 'Time',
                accessorKey: 'date',
                sortingFn: 'datetime',
                cell: cell => <EditableInput value={cell.getValue()} onChange={(value) => onTimeCellChanged(cell.row.original, "time", value)} type="time" />,
                meta: {
                    className: 'numeric'
                },
                size: 50
            },
            {
                id: 'room',
                header: 'Room',
                accessorFn: row => find(rooms, { id: row.roomId }).name
            },
            {
                header: 'Note',
                accessorKey: 'note',
                cell: cell => <EditableInput value={cell.getValue()} onChange={(value) => onCellChanged(cell.row.original, "note", value)} type="textarea" />,
                size: 300
            },
            {
                header: 'Omit',
                accessorKey: 'omitEvent',
                id: "omitEvent",
                cell: cell => <EditableBoolean value={cell.getValue()} onChange={(value) => onCellChanged(cell.row.original, "omitEvent", value)} />,
                size: 50
            }
        ],
        state: {
            sorting
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getRowId: row => row.eventId || row.editId
    })

    return (
        <div className="event-grid digital-signage-grid">
            <Cover center translucent loading enabled={status !== "success"} />
            <table>
                <thead>
                    <tr>
                        {getFlatHeaders().map(header => (
                            <th
                                key={header.id}
                                onClick={header.column.getToggleSortingHandler()}
                                style={header.column.getCanSort() ? { cursor: 'pointer', width: header.getSize() } : {}}
                            >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {header.column.getCanSort() && (
                                    <span className={getBemClasses('sort-direction', header.column.getIsSorted() as string)} />
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {getRowModel().rows.map(row => (
                        <tr
                            key={row.id}
                        >
                            {row.getVisibleCells().map(cell => (
                                <td
                                    key={cell.id}
                                    style={{ pointerEvents: row.getValue("omitEvent") && cell.column.id !== "omitEvent" ? "none" : "initial", width: cell.column.getSize() }}
                                    className={row.getValue("omitEvent") ? "digital-signage-grid__cell-disabled" : null}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export function EditableInput({ value, onChange, type }: { value: string, onChange: (value: string) => void, type: "textarea" | "text" | "time" }) {
    const [editting, setEditting] = useState(false);
    const [editValue, setEditValue] = useState<string>(value)
    const ref = useRef<any>()

    useEffect(() => {
        if (editting) {
            ref.current.focus()
        }
    }, [editting])

    const onBlur = () => {
        onChange(editValue)
        setEditting(false)
    }

    if (editting) {
        if (type === "textarea") {
            return <textarea ref={ref} onBlur={onBlur} value={editValue} onChange={e => setEditValue(e.target.value)} />
        } else if (type === "text") {
            return <input className='text-input' ref={ref} onBlur={onBlur} value={editValue} onChange={e => setEditValue(e.target.value)} />
        } else if (type === "time") {
            return <input className='time-input' type="time" ref={ref} onBlur={onBlur} value={DateTime.fromISO(editValue, { zone: "utc" }).setZone("Europe/London").toFormat("HH:mm")} onChange={e => setEditValue(e.target.value)} />
        }
    } else {
        return <div style={{ minHeight: 19 }} onDoubleClick={() => setEditting(true)}>{type === "time" ? formatTime(value) : value}</div>
    }
}

export function EditableBoolean({ value, onChange }: { value: boolean, onChange: (value: boolean) => void }) {
    return <input type='checkbox' checked={value} onChange={e => onChange(e.target.checked)} />
}
