import { useSections, useEvent } from 'common/frontend/state'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilCallback } from 'recoil'
import Modal from 'common/frontend/components/Modal'
import DevInspect from './DevInspect'
import * as calculateHash from 'object-hash'
import { useDiagnosticsEnabled } from '../../hooks'

const style: React.CSSProperties = {
    display: 'flex',
    gap: 10,
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: '10px 20px',
    backgroundColor: 'rgba(200, 200, 200, 0.3)',
    zIndex: 1000
}

export default function DevBar() {
    const event = useEvent()
    const sections = useSections()
    const [showPlan, setShowPlan] = useState(false)

    const diagnosticsEnabled = useDiagnosticsEnabled()

    const hash = useMemo(() => calculateHash(sections), [sections])

    const onClick = useRecoilCallback(({ snapshot }) => async () => {
        console.debug('Atom values:');
        for (const node of snapshot.getNodes_UNSTABLE()) {
            const value = await snapshot.getPromise(node);
            console.info(node.key, value);
        }
    }, [])

    if (!diagnosticsEnabled) return null

    return (
        <div style={style}>
            <code>{hash}</code>
            <Link to="/stories">stories</Link>
            <button onClick={onClick}>Dump State</button>
            <button onClick={() => setShowPlan(true)}>show plan</button>
            <Modal
                opened={showPlan}
                onClose={() => setShowPlan(false)}
            >
                <DevInspect value={event} />
            </Modal>
        </div>
    )
}