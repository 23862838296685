import * as React from 'react'
import { digitalSignageState } from '../state'
import { useRecoilValue } from "recoil";
import { useNavigate } from 'react-router';
import "./DigitalSignageBackgroundPage.scss"
import ProResourceSelect from '../components/ProResourceSelect';
import { EventServiceSubscriber, SimpleEvents } from 'common/types/eventService';
import { useEffect, useState } from 'react';
import { Select } from 'common/frontend/components/Select'
import { useAsyncEffect } from "use-async-effect"
import { useConfig } from 'common/frontend/state';
import { resultOrThrow } from 'common/universal/universalUtils';
import { sortBy } from 'lodash';
import Cover from 'common/frontend/components/Cover';
import { digitalSignageBackgroundPath } from 'common/frontend/utils';
import { toast } from 'react-toastify'

export function DigitalSignageBackgroundPage() {
    const state = useRecoilValue(digitalSignageState)
    const navigate = useNavigate()
    const [template, setTemplate] = useState<SimpleEvents.DigitalSignTemplate>(state?.selectedSign?.template)
    const [templates, setTemplates] = useState([])
    const [loading, setLoading] = useState(true)
    const config = useConfig()
    useAsyncEffect(async () => {
        if (!state.selectedSign) {
            navigate("/digital-signage")
        } else {
            const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
            const { templates } = await resultOrThrow(subscriber.control.digitalSignageGetTemplates({ venueId: state.selectedVenue?.id }))
            setTemplates(sortBy(templates, 'name'))
            setLoading(false)
        }
    }, [])

    const updateBackground = async () => {
        try {
            const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
            await resultOrThrow(subscriber.control.digitalSignageUpdateTemplate({
                templateId: template.id,
                signId: state.selectedSign.id
            }))
            toast('Saved', {
                autoClose: 500,
                closeButton: false,
                position: 'bottom-center',
                className: 'small-toast'
            })
        } catch (e) {
            toast.error(e.message || "Error saving", {
                autoClose: 5000,
                closeButton: false,
                position: 'top-center',
                // className: 'small-toast'
            })
        }
    }

    if (!state?.selectedSign) {
        return null
    } else if (loading) {
        return <Cover loading center />
    }

    return <div className="digital-signage-background-page relative">
        {/* <Cover center translucent loading enabled={saveEventIsPending} /> */}
        <div style={{ maxWidth: 500, marginBottom: 40 }}>
            <h2>Set background for {state.selectedSign.name}</h2>
            <p>Please select a background below.</p>
        </div>
        <div className="form-input form-input--wesley">
            <fieldset>
                <legend>Template</legend>
                <div style={{ display: "flex", gap: 20, width: "100%" }}>
                    <div style={{ flex: 1 }}>
                        <Select
                            value={template}
                            onChange={setTemplate}
                            options={templates}
                            getDisplayValue={value => value.name}
                            noOptionLabel={"None"}
                        />
                    </div>
                    <button className='btn btn--long' onClick={updateBackground}>Set background</button>
                </div>
            </fieldset>
        </div>
        {
            template && <div className='digital-signage-background-page__preview'>
                {
                    template.landscapeBackgroundFilename && <div className="rounded-box pad digital-signage-background-page__preview__img">
                        <span>Landscape</span>
                        <img src={digitalSignageBackgroundPath(template.landscapeBackgroundFilename)} />
                    </div>
                }
                {
                    template.portraitBackgroundFilename && <div className="rounded-box pad digital-signage-background-page__preview__img">
                        <span>Portrait</span>
                        <img src={digitalSignageBackgroundPath(template.portraitBackgroundFilename)} />
                    </div>
                }
            </div>
        }
    </div>
}
