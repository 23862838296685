import HeroSection from 'common/frontend/components/HeroSection'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { funeralDarkImages } from '../images'
import { useIsProfessionalPortal } from '../state'

export default function AccountPage() {
    const isProfessionalPortal = useIsProfessionalPortal()
    return (
        <div>
            <HeroSection images={funeralDarkImages}>
                <h1>Account</h1>
                <p>Account settings such as username and organisation logo can be set here</p>
            </HeroSection>
            <section className="container-padding">
                <div className="cols cols--spaced cols--small-screen-fullwidth">
                    <div className="col-grow">
                        <h3 className="help-page__title">
                            Account
                        </h3>
                        <Link to="/change-password" className="block-link">
                            Change Password
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    )
}