import * as React from 'react'
import { ReactNode } from 'react'
import { BackgroundCarousel, BackgroundCarouselProps } from './BackgroundCarousel'
import './HeroSection.scss'

export interface HeroSectionProps extends BackgroundCarouselProps {
    children?: ReactNode
}

export default function HeroSection({ images, video, children, muted }: HeroSectionProps) {
    return (
        <BackgroundCarousel video={video} images={images} muted={muted} className="hero-section" as="section">
            <div className="hero-section__content">
                {children}
            </div>
        </BackgroundCarousel>
    )
}