import { Listbox } from '@headlessui/react'
import * as React from 'react'
import { bem, wesleyDebugNamespace } from '../utils'

import TextWidget from '@rjsf/core/lib/components/widgets/TextWidget'
import RjsfUpDownWidget from '@rjsf/core/lib/components/widgets/UpDownWidget'
import TextareaWidget from '@rjsf/core/lib/components/widgets/TextareaWidget'
import DateTimeWidget from '@rjsf/core/lib/components/widgets/DateTimeWidget'
import { useEffect, useState } from 'react'
import { BDuration, BDurationJson } from '@busby/esb'
import { Toggle } from './Toggle'

const debug = wesleyDebugNamespace.extend('custom-widgets')

export function useUpdateOnBlur(props: any) {
    const [isFocused, setIsFocused] = useState(false)
    const [localValue, setLocalValue] = useState(props.value)

    const externalValue = props.value

    useEffect(() => {
        // Only update from outside world if we are not currently focused
        if (!isFocused) {
            setLocalValue(externalValue)
        }
    }, [isFocused, externalValue])

    function onFocus() {
        setIsFocused(true)
    }

    function onChange(value: any) {
        setLocalValue(value ?? '')
    }

    function onBlur() {
        if (localValue !== externalValue) {
            props.onChange(localValue)
        }
        setIsFocused(false)
    }

    return {
        ...props,
        value: localValue,
        onFocus,
        onChange,
        onBlur
    }
}

export function BlurInput(props: any) {
    const updateOnBlurProps = useUpdateOnBlur(props)
    return (
        <input
            {...updateOnBlurProps}
            onChange={event => updateOnBlurProps.onChange(event.target.value)}
        />
    )
}

export function TextBlurWidget(props: any) {
    const updateOnBlurProps = useUpdateOnBlur(props)
    return (
        <TextWidget {...updateOnBlurProps} />
    )
}

export function TextareaBlurWidget(props: any) {
    const updateOnBlurProps = useUpdateOnBlur(props)
    return (
        <TextareaWidget {...updateOnBlurProps} />
    )
}

export function DateTimeBlurWidget(props: any) {
    const updateOnBlurProps = useUpdateOnBlur(props)
    const { options } = props
    return (
        <DateTimeWidget min={options?.min} max={options?.max} {...updateOnBlurProps} />
    )
}

interface SelectWidgetProps {
    value: string
    onChange: (value: string) => void
    options: {
        enumOptions: { label: string, value: string }[]
    }
}

export function SelectWidget({ value, options, onChange }: SelectWidgetProps) {
    const block = bem('select-widget')
    const elButton = block.element('button')
    const elOptions = block.element('options')
    const elOption = elOptions.element('option')
    const { enumOptions } = options
    const selectedOption = enumOptions.find(option => option.value === value)
    return (
        <Listbox
            value={value}
            onChange={value => onChange(value)}
            className={block.className}
            as="div"
        >
            <Listbox.Button
                className={elButton.mod(value && `value-${value}`)}
            >
                {selectedOption && selectedOption.label}
            </Listbox.Button>
            <Listbox.Options
                className={elOptions.className}
            >
                {enumOptions.map(option => (
                    <Listbox.Option
                        key={option.value}
                        value={option.value}
                        className={elOption.mod(`value-${option.value}`)}
                    >
                        {option.label}
                    </Listbox.Option>
                ))}
            </Listbox.Options>
        </Listbox>
    )
}

export function durationToSeconds(json: BDurationJson): number {
    if (!json) return undefined
    try {
        const duration = BDuration.fromJson(json)
        const seconds = duration.getSeconds()
        return seconds >= 0 ? seconds : 0
    } catch (error) {
        return undefined
    }
}

export function millisecondsToDuration(milliseconds: number): BDurationJson {
    if (milliseconds === undefined) {
        return undefined
    }
    try {
        return BDuration.fromFrames(milliseconds, 'milli').toJson(true)
    } catch (error) {
        return BDuration.fromFrames(0, 'milli').toJson(true)
    }
}

export function secondsToDuration(seconds: number): BDurationJson {
    if (seconds === undefined) {
        return undefined
    }
    return millisecondsToDuration(seconds * 1000)
}

export function useBDurationNumber(props: any) {
    const { value, onChange } = props
    return {
        ...props,
        value: durationToSeconds(value),
        onChange: value => onChange(secondsToDuration(value))
    }
}

export function BDurationUpDownWidget(props: any) {
    const updateOnBlurProps = useUpdateOnBlur(props)
    const { value, onChange } = updateOnBlurProps
    const {maximum, minimum} = props.schema

    return <RjsfUpDownWidget
        {...updateOnBlurProps}
        value={durationToSeconds(value)}
        onChange={value => onChange(secondsToDuration(setNumberBounds(value, maximum, minimum)))}
    />
}
export function UpDownWidget(props: any) {

    const updateOnBlurProps = useUpdateOnBlur(props)
    const { value, onChange } = updateOnBlurProps
    const {maximum, minimum} = props.schema

    return <RjsfUpDownWidget
        {...updateOnBlurProps}
        value={value}
        onChange={value => onChange(setNumberBounds(value, maximum, minimum))}
    />
}

export function BooleanWidget(props: any) {
    const updateOnBlurProps = useUpdateOnBlur(props)
    const { value, onChange } = updateOnBlurProps
    console.log(value)
    return <Toggle
        {...updateOnBlurProps}
        checked={value}
        onChange={value => {
            onChange(value)
        }}
    />
}

const setNumberBounds = (number, maximum, minimum) => {
    if (number !== undefined) {
        let result = parseInt(number)

        if (minimum !== undefined) {
            result = Math.max(result, minimum)
        }
        if (maximum !== undefined) {
            result = Math.min(result, maximum)
        }
        return result
    } else {
        return undefined
    }
}
