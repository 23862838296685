import { Popover, Transition } from '@headlessui/react'
import { useLogoutAction } from 'common/frontend/actions'
import SearchDropdown from 'common/frontend/components/search/SearchDropdown'
import { useCurrentUser, useIsAdmin, useIsAdminPortal, useIsClientPortal, useIsLoggedIn, useIsProfessionalPortal } from 'common/frontend/state'
import * as React from 'react'
import { Portal } from 'react-portal'
import { Link } from 'react-router-dom'
import { LogoLink } from './Logo'

import { useScreenSize } from '../hooks'
import Action from './Action'
import './Header.scss'
import Icon from './Icon'

export interface HeaderProps {
    showSearch?: boolean
    showLogin?: boolean
    showDigitalSignage?: boolean
    children?: React.ReactNode
}

export default function Header({ showSearch = false, showLogin = false, showDigitalSignage = false, children }: HeaderProps) {
    const isLoggedIn = useIsLoggedIn()
    const isAdmin = useIsAdminPortal()
    const isProfessional = useIsProfessionalPortal();
    const isClient = useIsClientPortal();
    const isProOrAdmin = isProfessional || isAdmin
    const { isSmallScreen } = useScreenSize()
    const [logout] = useLogoutAction()
    const user = useCurrentUser()

    let title
    if (isClient) {
        title = "Service Portal"
    } else if (isAdmin) {
        title = "Admin Portal"
    } else if (isProfessional) {
        title = "Professional Portal"
    }

    const inner = <>
        {children}
        {showSearch && <SearchDropdown />}
        {showLogin && (
            isLoggedIn && user ? (
                <>
                    {
                        isProOrAdmin && <Link to="/events">
                            <Action style={{ borderRadius: '10px', width: "initial", height: "initial" }} icon="CalendarWeek" text='Events' size={45} />
                        </Link>
                    }
                    {
                        isProOrAdmin && showDigitalSignage && <Link to="/digital-signage">
                            <Action style={{ borderRadius: '10px', width: "initial", height: "initial" }} icon="Display" text='Digital signage' size={45} />
                        </Link>
                    }
                    {!isAdmin && (
                        // Admins don't have account page...
                        <Link to="/account">
                            <Action style={{ borderRadius: '10px', width: "initial", height: "initial" }} icon="Account" size={45} text={user.email} />
                        </Link>
                    )}
                    <button
                        className="btn"
                        onClick={() => logout({})}
                    >
                        Log out
                    </button>
                </>
            ) : (
                <Link to="/login">
                    <button className="btn btn--inversed">Login</button>
                </Link>
            )
        )}
    </>

    return (
        <div className="header">
            <div className="header__logo">
                <LogoLink />
            </div>
            <div className="header__title">
                <span>{title}</span>
            </div>
            {isSmallScreen && isLoggedIn ? (
                <HeaderMenu>
                    {inner}
                </HeaderMenu>
            ) : inner}
        </div>
    )
}

export function HeaderWithoutAuth() {
    return (
        <div className="header">
            <div className="header__logo">
                <LogoLink />
            </div>
        </div>
    )
}

function HeaderMenu({ children }) {
    return (
        <Popover as="div" className="header-menu">
            <Popover.Button className="btn">
                Menu
                <Icon name="Account" fill="white" />
            </Popover.Button>

            <Portal>
                <Transition
                    as={React.Fragment}
                    enter="transition__enter"
                    enterFrom="transition__enter__from"
                    enterTo="transition__enter__to"
                    leave="transition__leave"
                    leaveFrom="transition__leave__from"
                    leaveTo="transition__leave__to"
                >
                    <Popover.Panel className="header-menu-panel">
                        {({ close }) => (
                            <div
                                className="header-menu-panel__inner"
                                onClick={(event) => {
                                    const el = event.target as Element
                                    // don't close if what we click on has a popup (probably the organisation selector)
                                    if (el.getAttribute('aria-haspopup')) return
                                    close()
                                }}
                            >
                                <div className="cols cols--center">
                                    <button
                                        className="action action--close cols__right"
                                        onClick={() => close()}
                                    />
                                </div>
                                {children}
                            </div>
                        )}
                    </Popover.Panel>
                </Transition>
            </Portal>
        </Popover>
    )
}