import { BDateTimeJson, BDuration } from '@busby/esb'
import { DragCancelEvent, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, DropAnimation, UniqueIdentifier, defaultDropAnimationSideEffects, useDndMonitor } from '@dnd-kit/core'
import { SortableContext, SortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import * as classnames from 'classnames'
import { ScheduleItemTagWithLoop, SimpleEvents } from 'common/types/eventService'
import { calculateVisualDuration } from 'common/universal/universalUtils'
import { original, produce } from 'immer'
import { isEqual, sortBy } from 'lodash'
import * as React from 'react'
import { HTMLAttributes, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as uuid from 'uuid'
import { useAddAction, useDeleteAction, useUpdateAction } from '../actions'
import { useItemMarkers } from '../hooks'
import { ActionDisabled, InOutMarkers, OnMarkersChange, OnRemove, OnTagChange, Updater } from '../model'
import { sortVisual, useEventId, useLiveEvent, useRefetchEvent, useSectionsState, useVisual } from '../state'
import { ImmerRecipe, getBemClasses, safeGenerateKeyBetween, wesleyDebugNamespace } from '../utils'
import FoldableSection from './FoldableSection'
import LiveEventBox from './LiveEventBox'
import './SchedulerNext.scss'
import TrackBox from './TrackBox'
import VisualBox from './visuals/VisualBox'

const debug = wesleyDebugNamespace.extend('scheduler')

export interface NewItem {
    id: string // this is a temporary id, it will be replaced with the saved one afterwards
    isNew: true
}

export interface NewScheduleItem extends SimpleEvents.ScheduleItemData, NewItem { }

export type SchedulerItem = SimpleEvents.ScheduleItem | NewScheduleItem

export interface SchedulerSection extends SimpleEvents.ScheduleSection {
    items?: SchedulerItem[]
}

export interface SchedulerProps {
    types?: SimpleEvents.ScheduleItem['type'][]
}

const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        className: {
            active: 'dropping'
        }
    }),
}

export const noSortingStrategy: SortingStrategy = () => null

export default function Scheduler({ types = ['audio', 'visual', 'liveEvent'] }: SchedulerProps) {
    // saved sections are from our recoil atom
    // it will be kept up to date with changes made elsewhere
    const [savedSections, setSavedSections] = useSectionsState()
    const refetchEvent = useRefetchEvent()

    // we keep a copy of the sections locally, which we update as we drag
    // it also allows new items, before they have been saved (which have a temporary id)
    const [localSections, setLocalSections] = useState<SchedulerSection[]>([])

    // this keeps the our local sections in sync with the saves ones
    useEffect(() => updateLocalSections(savedSections), [savedSections])

    const findLocalItem = useCallback(id => findItem(localSections, id), [localSections])
    const findSavedItem = useCallback(id => findItem(savedSections, id), [savedSections])

    const [add] = useAddAction()
    const [update] = useUpdateAction()
    const [deleteAction] = useDeleteAction()

    // if the user clicks delete quickly we can have two events for the same id
    // this stores the in-progress deletes, so we can avoid that
    const deletingIds = useRef<{ [key: string]: boolean }>({})

    // when updating from saved, we need to preserve new items
    // any ids added here will be kept when updating our local items
    const addingIds = useRef<{ [key: string]: boolean }>({})

    function updateLocalSections(newSections: SimpleEvents.ScheduleSection[]) {
        // when we update local sections from incoming saved sections
        // we need to preserve two kinds of local change:
        // 1. items we are adding, which won't be present
        // 2. items we are deleting, which we should remove
        setLocalSections(localSections => {
            const preserve = {}
            for (const section of localSections) {
                preserve[section.id] = section.items.filter(item => addingIds.current[item.id])
            }
            return produce(newSections, draft => {
                for (const section of draft) {
                    section.items = section.items.filter(item => !deletingIds.current[item.id])
                    if (preserve[section.id]) {
                        section.items.push(...preserve[section.id])
                        section.items = sortBy(section.items, 'order')
                    }
                }
            })
        })
    }

    // const [activeId, setActiveId] = useState(null)
    const [activeDataItem, setActiveDataItem] = useState(null)
    const activeItem = useMemo(() => {
        if (activeDataItem) {
            const { item } = findLocalItem(activeDataItem.id)
            if (item) {
                return item
            } else {
                return activeDataItem
            }
        }
    }, [activeDataItem])

    // Something you can pass when you are updating an item, which is contained
    // within sections, does the work of finding the item, and ignoring if it's not
    // present...
    const updateItem = (itemId: string, updater: ImmerRecipe<SchedulerItem>) => {
        return produce<SchedulerSection[]>(draft => {
            const { item } = findItem(draft, itemId)
            if (!item) return
            updater(item)
        })
    }

    function findItem(sections: SchedulerSection[], id: string): { section: SchedulerSection | null, item: SchedulerItem | null } {
        for (const section of sections) {
            for (const item of section.items) {
                if (item.id === id) {
                    return { section, item }
                }
            }
        }
        return { item: null, section: null }
    }

    const onDragStart = ({ active }: DragStartEvent) => {
        const item = active.data.current.item
        setActiveDataItem(item)
        if ('isNew' in item) {
            addingIds.current[item.id] = true
        }
    }

    const onDragCancel = ({ active }: DragCancelEvent) => {
        setActiveDataItem(null)
        delete addingIds.current[active.id]
    }

    const onDragOver = useCallback(async ({ active, over }: DragOverEvent) => {
        const activeId = active.id
        const overId = over?.id
        if (overId == null) {
            return
        }
        setLocalSections(produce(draft => {
            // we use the item from the active data, as that is consistent for new and existing items
            const { item: activeItem } = active.data.current

            if (!activeItem) {
                return
            }

            // it could be over another item, or a section
            const isOverSection = Boolean(over.data.current.section)

            // we will have an action section if it's an existing item
            // new items won't have one
            const activeSection = findSection(draft, activeId)

            // we must have an over section though if we want to do anything
            const overSection = findSection(draft, overId)

            if (!overSection) {
                return
            }

            if (activeId === overId) {
                return
            }

            const activeIndex = activeSection && activeSection.items.findIndex(item => item.id === activeId)

            let newIndex: number

            if (isOverSection) {
                // just stick it at the beginning, it'll probably get nudged to be over an item soon anyway...
                // we *could* optimise and stick it at the top/bottom depending on where it's nearer
                //
                // we used to stick it on the end by default, but that could cause a bug:
                // see: https://trello.com/c/I2GwOOFb/195-bug-when-dragging-visual-tribute-into-scheduling-list
                newIndex = 0
            } else {
                // we are over an item, so decide if we want to go above or below
                // this doesn't take into account direction of movement or anything fancy
                // for that we would likely need custom collision detector
                const overIndex = overSection.items.findIndex(item => item.id === overId)
                const isBelowOverItem =
                    over &&
                    active.rect.current.translated &&
                    active.rect.current.translated.top >
                    over.rect.top + over.rect.height

                const modifier = isBelowOverItem ? 1 : 0
                newIndex = overIndex >= 0 ? overIndex + modifier : overSection.items.length + 1
            }

            // we can abort if it's not actually moving anywhere...
            if (activeSection && activeSection.id === overSection.id && activeIndex === newIndex) {
                return
            }

            // remove it from whereever it currently is
            if (activeSection) {
                activeSection.items.splice(activeIndex, 1)
            }

            const beforeItem = overSection.items[newIndex - 1]

            // ...and insert it where it needs to go
            const orderBefore = beforeItem?.order
            let orderAfter = overSection.items[newIndex]?.order

            const order = safeGenerateKeyBetween(orderBefore, orderAfter)

            // take on the link value of the item before where it's being dropped... feels more natural
            // let's you drop it into the middle of a linked group and it stays linked
            const linkedToNext = beforeItem ? beforeItem.linkedToNext : false

            overSection.items.splice(newIndex, 0, {
                ...activeItem,
                order,
                linkedToNext
            })
        }))

    }, [])

    function saveSections(updater: Updater<SimpleEvents.ScheduleSection[]>) {
        setSavedSections(currentSavedSections => {
            const newSavedSections = updater(currentSavedSections)
            updateLocalSections(newSavedSections)
            return newSavedSections
        })
    }

    const onDragEnd = useCallback(async ({ active }: DragEndEvent) => {
        setActiveDataItem(null)
        const activeId = String(active.id)
        if (!activeId) {
            return
        }
        const { item, section: toSection } = findLocalItem(activeId)
        if (!item || !toSection) {
            debug('could not find item')
            return
        }
        const toSectionId = toSection.id

        if ('isNew' in item) {
            debug('dropped a new item! %o', item)
            // need to exclude the special params, isNew, and id (which was only a temporary one)
            const { isNew, id, ...data } = item
            try {
                if (data.visual?.defaultLoop) {
                    data.loop = true;
                }
                const { resource: addedItem } = await add.scheduleItem(data, toSectionId)
                if (addedItem.visual) {
                    addedItem.visual = sortVisual(addedItem.visual)
                }
                delete addingIds.current[item.id]
                saveSections(produce(draft => {
                    const section = draft.find(section => section.id === toSectionId)
                    if (section) {
                        section.items = sortBy([...section.items, addedItem], 'order')
                    }
                }))
            } catch (error) {
                delete addingIds.current[item.id]
                refetchEvent()
            }
        } else {
            // see if the order is different to latest saved version
            const { item: savedItem, section: savedSection } = findSavedItem(item.id)
            const fromOrder = savedItem.order
            const fromSectionId = savedSection.id
            debug('existing item')
            if (fromOrder === item.order && fromSectionId === toSectionId) {
                debug('no change!')
                return
            }
            try {
                const { resource: updatedItem } = await update.scheduleItem(item, toSectionId)
                saveSections(produce(draft => {
                    const { item, section } = findItem(draft, updatedItem.id)
                    if (!item || !section) {
                        // don't worry too much if we can't find it...
                        return
                    }
                    section.items.splice(section.items.indexOf(item), 1)
                    const toSection = draft.find(section => section.id === toSectionId)
                    toSection.items = sortBy([...toSection.items, updatedItem], 'order')
                }))
            } catch (error) {
                refetchEvent()
            }
        }
    }, [findLocalItem, findSavedItem])

    const onLoop = useCallback(async itemId => {
        if (deletingIds.current[itemId]) return
        const { item } = findLocalItem(itemId)
        if (!item) return
        const loop = !item.loop
        setLocalSections(updateItem(itemId, draft => {
            draft.loop = loop
        }))
        const { resource: updatedItem } = await update.scheduleItem({
            id: item.id,
            loop
        })
        saveSections(updateItem(itemId, draft => {
            draft.loop = updatedItem.loop
        }))
    }, [findLocalItem])

    const onLink = useCallback(async itemId => {
        if (deletingIds.current[itemId]) return
        const { item } = findLocalItem(itemId)
        if (!item) return
        const linkedToNext = !item.linkedToNext
        // optimistic local update
        setLocalSections(updateItem(itemId, draft => {
            draft.linkedToNext = linkedToNext
        }))
        const { resource: updatedItem } = await update.scheduleItem({
            id: item.id,
            linkedToNext
        })
        saveSections(updateItem(itemId, draft => {
            draft.linkedToNext = updatedItem.linkedToNext
        }))
    }, [findLocalItem])

    const onMarkersChange = useCallback(async (itemId: string, markers: InOutMarkers) => {
        if (deletingIds.current[itemId]) return
        const { item } = findLocalItem(itemId)
        if (!item) {
            return
        }
        const currentMarkers = [item.inTimecode?.time, item.outTimecode?.time]
        if (isEqual(currentMarkers, markers)) {
            debug('no change in markers!')
            return
        }
        const inTimecode: BDateTimeJson = {
            frameRate: 'milli',
            time: markers[0]
        }
        const outTimecode: BDateTimeJson = {
            frameRate: 'milli',
            time: markers[1]
        }
        // optimistic update
        setLocalSections(updateItem(itemId, draft => {
            Object.assign(draft, { inTimecode, outTimecode })
        }))
        const { resource: updatedItem } = await update.scheduleItem({
            id: item.id,
            inTimecode,
            outTimecode
        })
        saveSections(updateItem(itemId, draft => {
            const { inTimecode, outTimecode } = updatedItem
            Object.assign(draft, { inTimecode, outTimecode })
        }))
    }, [findLocalItem])

    const onTagChange = useCallback(async (itemId: string, tag: ScheduleItemTagWithLoop) => {
        console
        if (deletingIds.current[itemId]) return
        const { item } = findLocalItem(itemId)
        if (!item) {
            return
        }

        if (isEqual(item.tag, tag)) {
            debug('no change in markers!')
            return
        }

        // optimistic update
        setLocalSections(updateItem(itemId, draft => {
            Object.assign(draft, { tag })
        }))
        const { resource: updatedItem } = await update.scheduleItem({
            id: item.id,
            tag,
            loop: tag.defaultLoop !== undefined ? tag.defaultLoop : undefined
        })
        saveSections(updateItem(itemId, draft => {
            Object.assign(draft, { 
                tag: updatedItem.tag,
                loop: updatedItem.loop
            })
        }))
    }, [findLocalItem])

    const onRemove = useCallback(async itemId => {
        if (deletingIds.current[itemId]) return
        const { item, section } = findLocalItem(itemId)
        if (!item || !section) {
            return
        }
        deletingIds.current[itemId] = true

        // remove it immediately locally
        setLocalSections(produce(draft => {
            const { item, section } = findItem(draft, itemId)
            section.items.splice(section.items.indexOf(item), 1)
        }))
        try {
            await deleteAction.scheduleItem(itemId)
            saveSections(produce(draft => {
                const { item, section } = findItem(draft, itemId)
                if (!item || !section) {
                    // if we don't find it, it *could* have been subsequently removed
                    // it doesn't really matter, we were trying to get rid of it anyway
                    return
                }
                section.items.splice(section.items.indexOf(item), 1)
            }))
        } catch (error) {
            debug('delete error %s %o', itemId, error)
            refetchEvent()
        }
        requestAnimationFrame(() => {
            delete deletingIds.current[itemId]
        })
    }, [findLocalItem])

    // finds a section by section id OR item id within that section
    function findSection(sections: SimpleEvents.ScheduleSection[], id: UniqueIdentifier) {
        return sections.find(section => section.id === id || section.items.some(item => item.id === id))
    }

    useDndMonitor({
        onDragStart,
        onDragCancel,
        onDragOver,
        onDragEnd
    })

    return (
        <div>
            <SortableContext
                items={localSections.map(section => section.id)}
                strategy={noSortingStrategy}
            >
                {localSections.map(section => (
                    <DroppableSection
                        key={section.id}
                        section={section}
                    >
                        <SortableContext
                            items={section.items}
                            strategy={noSortingStrategy}
                        >
                            {section.items.map((item, index, items) => (
                                types.includes(item.type) && (
                                    <SortableItem
                                        key={item.id}
                                        item={item}
                                        previousItem={items[index - 1]}
                                        nextItem={items[index + 1]}
                                        onLoop={() => onLoop(item.id)}
                                        onLink={() => onLink(item.id)}
                                        onRemove={() => onRemove(item.id)}
                                        onMarkersChange={markers => onMarkersChange(item.id, markers)}
                                        onTagChange={tag => onTagChange(item.id, tag)}
                                    />
                                )
                            ))}
                        </SortableContext>
                    </DroppableSection>
                ))}
            </SortableContext>
            <DragOverlay
                dropAnimation={dropAnimation}
            >
                {activeItem && (
                    <Item
                        item={activeItem}
                        className="dragging-overlay"
                    />
                )}
            </DragOverlay>
        </div>
    )
}

export interface DroppableContainerProps {
    section: SimpleEvents.ScheduleSection
    children: React.ReactNode
}

export function DroppableSection({
    section,
    children
}: DroppableContainerProps) {
    const {
        setNodeRef,
        isOver
    } = useSortable({
        id: section.id,
        data: {
            section
        }
    })

    let totalDuration = BDuration.zero("milli")
    for (let item of section.items) {
        if (item.type === "visual") {
            const visualDuration = calculateVisualDuration(item.visual)
            if (visualDuration) {
                totalDuration = totalDuration.addDuration(visualDuration)
            }
        } else if (item.type === "audio") {
            totalDuration = totalDuration.addDuration(BDuration.fromJson((item as any).trackInfo.duration))
        }
    }

    return (
        <FoldableSection
            title={section.name}
            section
            open={isOver ? true : undefined}
            ref={setNodeRef}
            sectionDuration={totalDuration}
        >
            {children}
        </FoldableSection>
    )
}

export interface SortableItemProps {
    item: SchedulerItem
    previousItem?: SchedulerItem
    nextItem?: SchedulerItem
    onLoop?: () => {}
    onLink?: () => {}
    onRemove?: () => {}
    onMarkersChange?: OnMarkersChange
    onTagChange?: OnTagChange
}

export function SortableItem({
    item,
    previousItem,
    nextItem,
    onLoop,
    onLink,
    onRemove,
    onMarkersChange,
    onTagChange
}: SortableItemProps) {
    const isNew = 'isNew' in item
    const {
        attributes,
        setNodeRef,
        isDragging,
        listeners,
        transform,
        transition
    } = useSortable({
        id: item.id,
        data: {
            item
        },
        disabled: isNew // you cannot drag items until they are saved
    })

    const style = {
        // note using CSS.Translate here not CSS.Transform
        // this is to only do the moving, not the stretching, or something like that...
        transform: CSS.Translate.toString(transform),
        transition,
    }

    return (
        <Item
            className={classnames([
                isDragging && 'dragging'
            ])}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={style}
            previousItem={previousItem}
            nextItem={nextItem}
            item={item}
            onLoop={onLoop}
            onLink={onLink}
            onRemove={isNew ? ActionDisabled : onRemove}
            onMarkersChange={onMarkersChange}
            onTagChange={onTagChange}
        />
    )
}

export interface ItemProps extends HTMLAttributes<HTMLDivElement> {
    item: SchedulerItem
    previousItem?: SchedulerItem
    nextItem?: SchedulerItem
    onLoop?: () => void
    onLink?: () => void
    onRemove?: OnRemove
    onMarkersChange?: OnMarkersChange
    onTagChange?: OnTagChange
}

export const Item = forwardRef<HTMLDivElement, ItemProps>(({ item, className, previousItem, nextItem, onLoop, onLink, onMarkersChange, onTagChange, ...props }, ref) => {
    // even though the item has a full copy of referenced liveEvent/visual
    // we get the fresh one from the main event as that will have latest updates
    // the referenced one can potentially be stale
    const liveEvent = useLiveEvent(item.liveEvent?.id)
    const visual = useVisual(item.visual?.id)
    const eventId = useEventId()
    const markers = useItemMarkers(item)

    if (item.type === 'audio') {
        const nextItemIsAudio = nextItem?.type === 'audio'
        const linkedToNext = nextItemIsAudio && item.linkedToNext
        const canLink = nextItemIsAudio
        const canLoop = !previousItem || !previousItem.linkedToNext
        if (!item.trackInfo) {
            debug('item is missing trackInfo %o', item)
            return null
        }
        return (
            <TrackBox
                id={item.id}
                track={item.trackInfo}
                ref={ref}
                className={classnames([
                    'draggable',
                    className
                ])}
                preload
                linkedToNext={linkedToNext}
                loop={item.loop}
                onLoop={canLoop ? onLoop : ActionDisabled}
                onLink={canLink ? onLink : ActionDisabled}
                markers={markers}
                tag={item.tag}
                onMarkersChange={onMarkersChange}
                onTagChange={onTagChange}
                showTag
                {...props}
            />
        )
    } else if (item.type === 'visual') {
        if (!visual) return null
        return (
            <VisualBox
                eventId={eventId}
                visual={visual}
                loop={item.loop}
                onLoop={onLoop}
                ref={ref}
                className={classnames([
                    'draggable',
                    className
                ])}
                {...props}
            />
        )
    } else if (item.type === 'liveEvent') {
        if (!liveEvent) return null
        return (
            <LiveEventBox
                liveEvent={liveEvent}
                ref={ref}
                className={classnames([
                    'draggable',
                    className
                ])}
                {...props}
            />
        )
    }
})

/**
 * This handles generating items that can be dropped into the schedule.
 * 
 * We re-generate the id after you drop it, so you can drop multiple of the same item without
 * the dnd ids getting reused.
 */
export function useNewItems<T, V>(entries: T[], map: (entry: T) => V): (V & NewItem)[] {
    const [items, setItems] = useState<(V & NewItem)[]>([])
    useEffect(() => {
        setItems(entries.map(entry => ({
            id: uuid.v4(),
            isNew: true,
            ...map(entry)
        })))
    }, [entries])

    useDndMonitor({
        onDragEnd
    })

    // once they are dropped into place, we need to regenerate the id we just used
    // or we have two draggables with the same id and they would remain related
    // this allows dragging multiple copies of the same track
    function onDragEnd({ active }: DragEndEvent) {
        if (active.id) {
            debug('drag end %s', active.id)
            setItems(produce(draft => {
                const item = draft.find(item => item.id === active.id)
                if (!item) return
                debug('regenerating id for dropped item', original(item))
                item.id = uuid.v4()
            }))
        }
    }

    return items
}

export function useNewScheduleItems<T>(entries: T[], map: (entry: T) => SimpleEvents.ScheduleItemData): NewScheduleItem[] {
    return useNewItems<T, SimpleEvents.ScheduleItemData>(entries, map)
}

export interface NewScheduleItemDraggableProps {
    item: NewScheduleItem
    onEdit?: () => void
    onRemove?: () => void
}

/**
 * These are new items that have already been dropped in the schedule, just not saved yet.
 */
export function NewScheduleItemDraggable({ item, onEdit, onRemove }: NewScheduleItemDraggableProps) {
    const eventId = useEventId()

    const {
        attributes,
        listeners,
        setNodeRef,
    } = useSortable({
        id: item.id,
        data: {
            item
        }
    })

    switch (item.type) {
        case 'audio': {
            return (
                <TrackBox
                    id={item.id}
                    ref={setNodeRef}
                    track={item.trackInfo}
                    {...listeners}
                    {...attributes}
                    className={classnames([
                        getBemClasses('track-box', {
                            draggable: true
                        })
                    ])}
                />
            )
        }
        case 'visual': {
            return (
                <VisualBox
                    ref={setNodeRef}
                    eventId={eventId}
                    visual={item.visual}
                    {...listeners}
                    {...attributes}
                    className={classnames([
                        getBemClasses('visual-box', {
                            draggable: true
                        })
                    ])}
                />
            )
        }
        case 'liveEvent': {
            return (
                <LiveEventBox
                    ref={setNodeRef}
                    {...attributes}
                    {...listeners}
                    liveEvent={item.liveEvent}
                    onEdit={onEdit}
                    onRemove={onRemove}
                    className={classnames([
                        getBemClasses('live-event-box', {
                            draggable: true
                        })
                    ])}
                />
            )
        }
    }
}