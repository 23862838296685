import { Form } from 'common/frontend/components/Form'
import { useRequestTrackAction, useRequestVisualTributeAction, useUpdateAction } from 'common/frontend/actions'
import { VisualTributeRequest, visualTributeRequestFieldNames } from 'common/types/commonTypes'
import { JSONSchema7 } from 'json-schema'
import * as React from 'react'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useConfirmModal, useVisualTributeMode } from '../hooks'
import { useEvent, useVisualState } from '../state'
import { useEventPathTo } from '../eventPaths'
import useLocalStorageState from 'use-local-storage-state'

const schema: JSONSchema7 = {
    type: 'object',
    required: ['details'],
    properties: {
        details: {
            type: "string",
            title: visualTributeRequestFieldNames.details,
        }
    }
}

const uiSchema = {
    details: {
        "ui:widget": "textarea",
        "ui:options": {
            rows: 10,
        },
    }
}

const initialVisualTributeRequest: VisualTributeRequest = {
    details: ''
}

export default function VisualTributeRequestPage() {
    const navigate = useNavigate()
    const event = useEvent()
    const to = useEventPathTo()
    const mode = useVisualTributeMode(event)
    const { visualId } = useParams()
    const [visual, setVisual] = useVisualState(visualId)
    const [update] = useUpdateAction()
    const { modal, confirm } = useConfirmModal({
        title: `Are you sure you want to submit this visual tribute?`,
        message: 'You will not be able to edit it after submission.',
    })
    const [visualTributeRequest, setVisualTributeRequest, { removeItem: removeVisualTributeLocal }] = useLocalStorageState<VisualTributeRequest>(
        `visualTributRequest__${visual.id}`, { defaultValue: initialVisualTributeRequest }
    )

    const [requestVisualTribute, isPending] = useRequestVisualTributeAction()

    async function onSubmit() {
        let proceed = true
        if (mode === "restricted") {
            proceed = await confirm()
        }
        if (proceed) {
            await requestVisualTribute({ visualTributeRequest, visualId })
            if (mode === "restricted") {
                toast('Your visual tribute has been submitted')
            } else {
                toast('Your request was made')
            }
            await setVisualTributeRequest(initialVisualTributeRequest)
            const { resource: updatedVisual } = await update.visual({id: visualId, requestStatus: "processing"})
            setVisual(updatedVisual)
            removeVisualTributeLocal()
            navigate(to(`visuals/${visualId}`))
        }
    }

    return (
        <div className="music-request-page">
            <section className="section--grey cols container-padding">
                <div style={{ maxWidth: 600 }}>
                    <h3>Visual tribute request</h3>
                    <p>Fill out the details of your visual tribute request, which will be sent to the Wesley Visual team.</p>
                    <Form
                        theme="wesley"
                        jsonSchema={schema}
                        uiSchema={uiSchema}
                        value={visualTributeRequest}
                        onChange={data => setVisualTributeRequest(data.formData)}
                        onSubmit={() => onSubmit()}
                    >
                        <div className="cols cols--spaced" style={{ alignItems: 'center' }}>
                            <button
                                type="submit"
                                className="btn btn--long"
                            >
                                {
                                    mode === "restricted" ? "Submit visual tribute" : "Submit request"
                                }
                            </button>
                            <button onClick={() => navigate(-1)} className="btn btn--secondary">
                                Cancel
                            </button>
                        </div>
                    </Form>
                </div>
            </section>
            {modal}
        </div>
    )
}