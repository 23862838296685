import * as React from 'react'

const style: React.CSSProperties = {
    margin: 10,
    padding: 10,
    backgroundColor: '#fafafa'
}

interface DevInspectProps {
    value: any
    label?: string
}

export default function DevInspect({ value, label }: DevInspectProps) {
    function toString(value: any) {
        if (value === null) return 'null'
        if (value === undefined) return 'undefined'
        return JSON.stringify(value, null, 2)
    }
    return (
        <pre style={style}>{label && <span>{label} </span>}{toString(value)}</pre>
    )
}