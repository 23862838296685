import { Tab } from '@headlessui/react'
import { useAddAction, useDeleteAction, useUpdateAction } from 'common/frontend/actions'
import { LiveEventEditModal } from 'common/frontend/components/LiveEventEdit'
import { useConfirmModal } from 'common/frontend/hooks'
import { liveEventsState, useEventFeatures, useSetEvent, visualsState } from 'common/frontend/state'
import { wesleyDebugNamespace } from 'common/frontend/utils'
import { SimpleEvents } from 'common/types/eventService'
import { produce } from 'immer'
import * as React from 'react'
import { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import Scheduler, { NewScheduleItemDraggable, useNewScheduleItems } from './Scheduler'
import SchedulerContext from './SchedulerContext'

const debug = wesleyDebugNamespace.extend('scheduling')

export default function Scheduling() {
    const { hasVisuals } = useEventFeatures()
    return (
        <div className="scheduling">
            <SchedulerContext>
                <div className="cols cols--spaced">
                    <div>
                        <h2>Scheduling</h2>
                        <p>Drag your visual tribute into the schedule on the right.</p>
                    </div>
                </div>
                <div className="cols cols--spaced">
                    <div className="scheduling__blocks">
                        <Tab.Group as="div" className="tabs">
                            <Tab.List className="tabs__tabs">
                                {hasVisuals && <Tab as="div">Visuals</Tab>}
                                <Tab as="div">Notes</Tab>
                            </Tab.List>
                            <Tab.Panels className="tabs__panels">
                                {hasVisuals && (
                                    <Tab.Panel className="scheduling__blocks__visuals">
                                        <Visuals />
                                    </Tab.Panel>
                                )}
                                <Tab.Panel className="scheduling__blocks__events">
                                    <Events />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                    <div className="scheduling__timeline">
                        <Scheduler
                            types={['audio', 'visual', 'liveEvent']}
                        />
                    </div>
                </div>
            </SchedulerContext>
        </div>
    )
}

function Visuals() {
    const visuals = useRecoilValue(visualsState)

    const items = useNewScheduleItems(visuals, visual => ({
        type: 'visual',
        order: null,
        visual
    }))

    return (
        <div className="visuals-grid">
            {items.map(item => (
                <NewScheduleItemDraggable
                    key={item.id}
                    item={item}
                />
            ))}
        </div>
    )
}

function Events() {
    const [liveEvents, setLiveEvents] = useRecoilState(liveEventsState)
    const setEvent = useSetEvent()

    const [open, setOpen] = useState(false)
    const [editEvent, setEditEvent] = useState(null)
    const [add, addIsPending] = useAddAction()
    const [update, updateIsPending] = useUpdateAction()
    const [deleteAction, deleteIsPending] = useDeleteAction()

    const items = useNewScheduleItems(liveEvents, liveEvent => ({
        type: 'liveEvent',
        order: null,
        liveEvent
    }))

    async function onSave(saveEvent: SimpleEvents.LiveEvent | SimpleEvents.LiveEventData) {
        if ('id' in saveEvent) {
            const { resource: updatedLiveEvent } = await update.liveEvent(saveEvent)
            debug('updatedLiveEvent %o', updatedLiveEvent)

            setLiveEvents(produce(draft => {
                const idx = draft.findIndex(item => item.id === updatedLiveEvent.id)
                if (idx !== -1) {
                    draft[idx] = updatedLiveEvent
                }
            }))
        } else {
            const { resource: addedLiveEvent } = await add.liveEvent(saveEvent)
            debug('addedLiveEvent %o', addedLiveEvent)
            setLiveEvents(produce(draft => {
                draft.push(addedLiveEvent)
            }))
        }
    }

    const {
        modal,
        confirm
    } = useConfirmModal({
        isPending: deleteIsPending,
        title: 'Are you sure you want to remove this note?',
        message: 'Any scheduled notes of this type will also be removed'
    })

    async function onRemove(id: string) {
        if (!id) return
        if (await confirm()) {
            await deleteAction.liveEvent(id)
            setEvent(produce(draft => {
                // remove the live event itself
                const idx = draft.liveEvents.findIndex(item => item.id === id)
                if (idx !== -1) {
                    draft.liveEvents.splice(idx, 1)
                }
                // and also any scheduled items for it
                for (const section of draft.sections) {
                    section.items = section.items.filter(item => item.liveEvent?.id !== id)
                }
            }))
        }
    }

    function onEdit(id: string) {
        if (!id) return
        setEditEvent(liveEvents.find(liveEvent => liveEvent.id === id))
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
        setEditEvent(null)
    }

    return (
        <div>
            <p>Click the button below to create a note which will be seen be the crematorium staff.</p>
            <button
                className="btn btn--secondary"
                onClick={() => setOpen(true)}
                style={{ marginBottom: 10, width: '100%' }}
            >
                Add Note
            </button>
            <div>
                {items.map(item => (
                    <NewScheduleItemDraggable
                        key={item.id}
                        item={item}
                        onEdit={() => onEdit(item.liveEvent?.id)}
                        onRemove={() => onRemove(item.liveEvent?.id)}
                    />
                ))}
            </div>
            <LiveEventEditModal
                isPending={addIsPending || updateIsPending}
                opened={open}
                event={editEvent}
                onClose={handleClose}
                onSave={onSave}
            />
            {modal}
        </div>
    )
}