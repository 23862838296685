import * as React from 'react'
import { useDiagnosticsEnabled } from '../hooks'

export default function GeneralError({ error, resetErrorBoundary = undefined } ) {
    function goHome() {
        window.location.reload()
    }

    const diagnosticsEnabled = useDiagnosticsEnabled()

    return (
        <div className="container">
            <div className="general-error">
                <p>Oops, something went wrong</p>
                {diagnosticsEnabled && (
                    <pre>{error.message}</pre>
                )}
                <div className="cols cols--spaced">
                    <button
                        className="btn"
                        onClick={() => goHome()}
                    >
                        Reload page
                    </button>
                    {diagnosticsEnabled && resetErrorBoundary && (
                        <button
                            className="btn"
                            onClick={() => resetErrorBoundary()}
                        >
                            OK
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}