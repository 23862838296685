import { Select } from 'common/frontend/components/Select'
import { events } from 'common/types/events'
import * as React from 'react'
import { Filters, ResourceOrderBy, ResourceQuery } from '@busby/esb'
import { useInfiniteOrganisationsQuery, useInfiniteProResourceQuery } from '../queries'
import { useState } from 'react'
import { useIsAdminPortal } from 'common/frontend/state'

export interface OrganisationSelect<RT extends keyof events> {
    value: any
    disabled?: boolean
    required?: boolean
    style?: React.CSSProperties
    noOptionsLabel?: string
    type?: "venue" | "organiser"
    onChange: (value: any) => void
}

export default function OrganisationSelect<RT extends keyof events>({
    value,
    disabled,
    required = false,
    style,
    noOptionsLabel,
    type,
    onChange
}: OrganisationSelect<RT>) {
    const [query, setQuery] = useState('')
    const { organisations, hasNextPage, isFetching, fetchNextPage } = useInfiniteOrganisationsQuery({ query, type })
    const isAdminPortal = useIsAdminPortal()

    // If we have a selected value, but it's not in our list, add it in
    // This is for the case that it's not within this page of results
    // We are operating on good faith that it is in fact a valid option
    if (value?.id && !organisations.some(organisation => organisation.id === value.id)) {
        organisations.push(value)
    }
    return (
        <Select
            value={value}
            disabled={disabled}
            required={required}
            onChange={onChange}
            options={organisations}
            groupBy={organisation => organisation.type}
            groups={[{
                value: 'organiser',
                label: 'Organisations',
            }, {
                value: 'venue',
                label: 'Venue Organisations',
            }]}
            getDisplayValue={value => value.name}
            getFilterValue={value => value.name}
            noOptionLabel={noOptionsLabel ?? "Any"}
            loader={{ hasNextPage, isFetching, fetchNextPage }}
            showFilter={isAdminPortal}
            onFilterChange={query => setQuery(query)}
            style={style}
        />
    )
}