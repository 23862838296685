import * as classnames from 'classnames'

import { autoUpdate, flip } from '@floating-ui/dom'
import { useDismiss, useFloating, useInteractions } from '@floating-ui/react'
import { formatDurationAsMinutesAndSeconds, formatMillisAsMinutesAndSeconds } from 'common/frontend/formatters'
import { useMouseHover, useScrollingOverflow } from 'common/frontend/hooks'
import { TrackInfo } from 'common/types/commonTypes'
import * as React from 'react'
import { forwardRef, HTMLAttributes, useMemo, useState } from 'react'
import { mergeRefs } from "react-merge-refs"
import { getBemClasses } from '../../../../../common/BEM/utils'
import { useAudioPlayer } from '../audio'
import { ActionDisabled, InOutMarkers, OnLink, OnLoop, OnMarkersChange, OnRemove, OnTagChange, OnTrackRequestClick } from '../model'
import * as joinArray from "join-array"
import Icon from './Icon'
import './TrackBox.scss'
import TrackEdit from './TrackEdit'
import { audioPath, coverArtPath } from '../utils'
import Action from './Action'
import { TrackTag } from './TrackTag'
import { ScheduleItemTagWithLoop, SimpleEvents } from 'common/types/eventService'
import { useIsAdmin } from '../state'
import { useNavigate } from 'react-router'
import { useEventPathTo } from '../eventPaths'

export interface TrackBoxProps extends HTMLAttributes<HTMLDivElement> {
    // not the id of the track itself, but the logical thing it is part of
    // identifies the entity this is associated with...
    // used for knowing if we are playing this particular track box, not just the same piece of music...
    id: string
    track: TrackInfo
    linkedToNext?: boolean
    loop?: boolean
    markers?: InOutMarkers,
    tag?: ScheduleItemTagWithLoop
    onLink?: OnLink
    onRemove?: OnRemove
    onLoop?: OnLoop
    onMarkersChange?: OnMarkersChange
    onTagChange?: OnTagChange
    showTag?: boolean
    enableTrackRequest?: boolean
    preload?: boolean
}

const TrackBox = forwardRef<HTMLDivElement, TrackBoxProps>(({
    id,
    track,
    linkedToNext,
    loop,
    markers,
    tag,
    onRemove,
    onLink,
    onLoop,
    onMarkersChange,
    onTagChange,
    showTag,
    enableTrackRequest,
    className,
    preload,
    ...props
}, forwardedRef) => {
    const { ref: hoverRef, isHovering } = useMouseHover()
    const { ref: titleRef } = useScrollingOverflow(isHovering)
    const { ref: subtitleRef } = useScrollingOverflow(isHovering)
    const { ref: extraInfoRef } = useScrollingOverflow(isHovering)
    const [isFloating, setIsFloating] = useState<"edit" | "tag" | null>(null)
    const isAdmin = useIsAdmin()
    const src = track.audio ? audioPath(track.audio) : undefined
    const navigate = useNavigate()
    const to = useEventPathTo()

    const {
        isPlaying,
        isLoading,
        playPause,
        stop
    } = useAudioPlayer({ id, src, markers, preload })

    const showLoop = Boolean(loop || onLoop) // show the loop thing either if it is looped, or we can toggle it

    const { x, y, strategy, refs, context } = useFloating({
        open: isFloating !== null,
        onOpenChange: () => setIsFloating(null),
        whileElementsMounted: autoUpdate,
        placement: 'top-end',
        middleware: [flip()]
    })

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useDismiss(context, {
            escapeKey: true,
            outsidePress: true
        })
    ])

    function openEdit() {
        setIsFloating("edit")
    }

    function openTag() {
        setIsFloating("tag")
    }

    function stopThenRemove() {
        stop()
        if (onRemove && onRemove !== ActionDisabled) {
            onRemove()
        }
    }

    const displayDuration = useMemo(() => {
        if (markers && markers[0] != null && markers[1] !== null) {
            return formatMillisAsMinutesAndSeconds(markers[1] - markers[0])
        } else if (track.duration) {
            return formatDurationAsMinutesAndSeconds(track.duration)
        } else {
            return '--:--'
        }
    }, [track, markers])

    const imageSrc = track.image ? coverArtPath(track.image) : undefined

    const hasThreeLines = track.artist && track.title && track.isHymn

    return (
        <>
            {isFloating && (
                <div
                    ref={refs.setFloating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        width: 'max-content',
                        zIndex: 1000000
                    }}
                    {...getFloatingProps()}
                >
                    {
                        isFloating === "edit" && <TrackEdit
                            id={id}
                            src={src}
                            track={track}
                            markers={markers}
                            onMarkersChange={onMarkersChange}
                            onRequestClose={() => setIsFloating(null)}
                        />
                    }
                    {
                        isFloating === "tag" && <TrackTag
                            disabled={!onTagChange}
                            onChange={onTagChange}
                            value={tag}
                        />
                    }
                </div>
            )}
            <div
                ref={mergeRefs([refs.setReference, forwardedRef, hoverRef])}
                className={classnames([
                    getBemClasses('track-box', {
                        'linked-to-next': linkedToNext,
                        'three-lines': hasThreeLines,
                    }),
                    className
                ])}
                // if I put this after props, the dnd doesn't work
                // so I wonder if we are losing something worthwhile here too...
                {...getReferenceProps()}
                {...props}
                data-tip={[track.artist, track.title].join('<br/>')}
            >
                {imageSrc && !track.isHymn ?
                    <img src={imageSrc} /> :
                    track.type === "user" ? <UserTrackImage /> : <DefaultTrackImage />
                }
                <div
                    className="track-box__label"
                >
                    {track.artist && (
                        <div
                            ref={titleRef}
                            className="track-box__label__title"
                        >
                            {track.artist}
                        </div>
                    )}
                    <div
                        ref={subtitleRef}
                        className="track-box__label__subtitle"
                    >
                        {track.title}
                    </div>
                    <div
                        ref={extraInfoRef}
                        className="track-box__label__extra-info"
                    >
                        {
                            isAdmin && track.trackNo && <div>
                                <span style={{ fontWeight: 500 }}>Track No</span> {track.trackNo}
                            </div>
                        }
                        {track.isHymn && track.instruments?.length > 0 && (
                            <div>                                <span style={{ fontWeight: 500 }}>Acc.</span>&nbsp;
                                {joinArray({
                                    array: track.instruments,
                                    seperator: ', ',
                                    last: ' & ',
                                })}
                            </div>
                        )}
                        {track.isHymn && track.noOfVerses && (
                            <div>
                                <span style={{ fontWeight: 500 }}>Verses</span> {track.noOfVerses}
                            </div>
                        )}
                        {track.isHymn && track.selectedVersesText && (
                            <div>
                                <span style={{ fontWeight: 500 }}>Selected</span> {track.selectedVersesText}
                            </div>
                        )}
                    </div>
                </div>
                <button
                    className={getBemClasses('action', {
                        play: !isPlaying,
                        pause: isPlaying,
                        loading: isLoading
                    })}
                    onClick={() => playPause()}
                />
                {enableTrackRequest && (
                    <Action
                        icon='Question'
                        onClick={() => navigate({ pathname: to(`music/track/${track.id}/request`) })}
                        title='Help'
                    />
                )}
                <div className="track-box__duration numeric">
                    {displayDuration}
                </div>
                {showLoop && (
                    <button
                        className={getBemClasses('action', {
                            loop: !loop,
                            unloop: loop,
                            disabled: onLoop === ActionDisabled
                        })}
                        title="Loop track"
                        onClick={onLoop === ActionDisabled ? () => { } : () => onLoop?.()}
                    />
                )}
                {/* Removed until we can change the player */}
                {/* {onLink && (
                    <button
                        className={getBemClasses('action', {
                            unlink: linkedToNext,
                            link: !linkedToNext,
                            disabled: onLink === ActionDisabled
                        })}
                        title="Automatic follow on"
                        onClick={onLink === ActionDisabled ? () => { } : () => onLink()}
                    />
                )} */}
                {
                    showTag && <Action icon='Tag' onClick={openTag} title='Tag' /> 
                }
                {onRemove && (
                    <button
                        className={getBemClasses('action', {
                            trash: true,
                            disabled: onRemove === ActionDisabled
                        })}
                        title="Delete"
                        onClick={onRemove === ActionDisabled ? () => { } : () => stopThenRemove()}
                    />
                )}
            </div>
        </>
    )
})

export default TrackBox

export function DefaultTrackImage() {
    return (
        <div className="default-track-image">
            <Icon name="DefaultMusic" />
        </div>
    )
}

export function UserTrackImage() {
    return (
        <div className="default-track-image">
            <Icon name="Account" />
        </div>
    )
}