import { useSubmitEventAction } from 'common/frontend/actions'
import ProgressSteps from 'common/frontend/components/ProgressSteps'
import SchedulerViewOnly from 'common/frontend/components/SchedulerViewOnly'
import StreamingViewers from 'common/frontend/components/StreamingViewers'
import { formatDateTimeFull } from 'common/frontend/formatters'
import { useConfirmModal, usePageTitle, useScreenSize, useVisualTributeMode } from 'common/frontend/hooks'
import { useAudioUploadJobs, useEvent, useEventAspectRatio, useEventFeatures, useEventSource, useIsAdmin, useTrackRequestJobs } from 'common/frontend/state'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useEventPathTo } from '../eventPaths'
import { entries, filter, find, includes, map, uniq } from 'lodash'
import * as joinArray from "join-array"
import { getVisualImageAndVideoCounts } from 'common/universal/universalUtils'
import { SimpleEvents } from 'common/types/eventService'
import { templates, TemplateDescription } from "../components/visuals/templates/templates"
import { Project } from '@motion-canvas/core'
import { useState } from 'react'
import { calculateAdditionalRequiredMusicMilliseconds } from '../utils'
import { getVisualProject } from '../components/visuals/VisualUtils'

export default function ReviewPage() {
    usePageTitle('Review')
    const event = useEvent()
    const { isSmallScreen } = useScreenSize()
    if (!event) return null
    return isSmallScreen ? <SmallScreenReviewPage /> : <BigScreenReviewPage />
}

function BigScreenReviewPage() {
    const event = useEvent()
    const eventSource = useEventSource()
    const to = useEventPathTo()
    const { hasStreaming } = useEventFeatures()
    const [submitEvent, isPending] = useSubmitEventAction()
    const isAdmin = useIsAdmin()
    const visualMode = useVisualTributeMode(event)
    const aspectRatio = useEventAspectRatio()
    const { confirm, modal } = useConfirmModal({
        isPending,
        title: 'Are you sure you are ready to send to your venue?',
        message: 'Changes cannot be made after this',
        confirmButton: 'Yes'
    })

    const ownershipAcknowledgementRequired = event?.userTrackInfos?.length > 0
    const [ownershipAcknowledged, setOwnershipAcknowledged] = useState(false)

    const getScheduledVisualIds = () => {
        let scheduledVisualIds = []

        for (let { items } of event.sections) {
            for (let { type, id, visual } of items) {
                if (type === "visual") {
                    scheduledVisualIds.push(visual.id)
                }
            }
        }

        scheduledVisualIds = uniq(scheduledVisualIds)
        return scheduledVisualIds
    }

    const trackRequestJobs = useTrackRequestJobs()
    const audioUploadJobs = useAudioUploadJobs()
    const trackRequestsPendingCount = trackRequestJobs.filter(job => job.status === 'pending').length
    const audioUploadsPendingCount = audioUploadJobs.filter(job => job.status === 'pending').length
    const scheduledVisuals = getScheduledVisualIds().map(id => find(event.visuals, { id }))
    const visualsWithIssues: { [id: string]: { name: string, slidesWithPlaceholderText?: string[], slidesWithPendingMedia?: string[], tooManyImagesOrVideos?: boolean, notEnoughMusic?: boolean, requestInProgress?: boolean, requestNotSubmitted?: boolean } } = {}
    const scheduledVisualIds = getScheduledVisualIds()
    const visualIds = map(event.visuals, v => v.id)
    const visualsNotScheduled = filter(uniq(visualIds), v => {
        return !includes(uniq(scheduledVisualIds), v)
    })

    function getVisualSlidesWithPlaceholderText(visual: SimpleEvents.Visual): string[] {
        const slides = []
        const project: Project = getVisualProject(aspectRatio, visual.template.projectId)
        const templateDescription: TemplateDescription = project.variables.templateDescription as TemplateDescription;
        for (const [index, component] of Object.entries(visual.components) || []) {
            const slideDescription = find(templateDescription.slideTypes, { type: component.slideType })
            for (const [key, caption] of entries(component.captions)) {
                if (slideDescription?.metadataForm?.schema.properties[key] && caption === "Your text here") {
                    slides.push(index)
                }
            }
        }
        return slides
    }

    function getVisualSlidesWithPendingMedia(visual: SimpleEvents.Visual): string[] {
        const slides: string[] = []
        for (const [index, component] of Object.entries(visual.components) || []) {
            if (component.uploadStatus !== "ready") {
                slides.push(index)
            }
        }
        return slides;
    }

    for (const visual of scheduledVisuals) {
        const slidesWithPlaceholderText = getVisualSlidesWithPlaceholderText(visual)

        if(!visual.requestStatus && visualMode === "restricted"){
            if (!visualsWithIssues[visual.id]) visualsWithIssues[visual.id] = { name: visual.name }
            visualsWithIssues[visual.id].requestNotSubmitted = true
        }
        
        if(visual.requestStatus === "processing"){
            if (!visualsWithIssues[visual.id]) visualsWithIssues[visual.id] = { name: visual.name }
            visualsWithIssues[visual.id].requestInProgress = true
        }

        if (slidesWithPlaceholderText.length > 0) {
            if (!visualsWithIssues[visual.id]) visualsWithIssues[visual.id] = { name: visual.name }
            visualsWithIssues[visual.id].slidesWithPlaceholderText = uniq(slidesWithPlaceholderText)
        }

        const slidesWithPendingMedia = getVisualSlidesWithPendingMedia(visual)
        if (slidesWithPendingMedia.length > 0) {
            if (!visualsWithIssues[visual.id]) visualsWithIssues[visual.id] = { name: visual.name }
            visualsWithIssues[visual.id].slidesWithPendingMedia = uniq(slidesWithPendingMedia)
        }
        if (calculateAdditionalRequiredMusicMilliseconds(visual) > 5000) {
            if (!visualsWithIssues[visual.id]) visualsWithIssues[visual.id] = { name: visual.name }
            visualsWithIssues[visual.id].notEnoughMusic = true
        }
    }

    for (const visual of event.visuals) {
        const {
            exceededImageCount,
            exceededVideoCount,
        } = getVisualImageAndVideoCounts(visual)
        if (exceededImageCount > 0 || exceededVideoCount > 0) {
            if (!visualsWithIssues[visual.id]) visualsWithIssues[visual.id] = { name: visual.name }
            visualsWithIssues[visual.id].tooManyImagesOrVideos = true
        }
    }

    const disableSubmitMusic = trackRequestsPendingCount > 0 || audioUploadsPendingCount > 0
    const disableSubmitVisual = Object.keys(visualsWithIssues).length > 0
    const disableSubmit = disableSubmitMusic || disableSubmitVisual || (ownershipAcknowledgementRequired && !ownershipAcknowledged)
    const warningVisual = visualsNotScheduled.length > 0

    async function sendToVenue() {
        if (disableSubmit) return
        let result = true;

        if (result && await confirm()) {
            await submitEvent({})
        }
    }
    return (
        <div className="review-page">
            <section className="progress-navigation">
                <ProgressSteps headerOnly />
            </section>
            <section className="section--grey container-padding">
                <div className="cols cols--50 cols--spaced" style={{ marginBottom: 20 }}>
                    <div>
                        <h3>Review</h3>
                        <p>The details of your Wesley Events package are summarised here. Please check them carefully.</p>
                    </div>
                    {
                        (eventSource === "newSystem" || isAdmin) &&
                        <div className="rounded-box pad">
                            <p><strong>Ready to send to your venue?</strong></p>
                            <p>Changes cannot be made after this</p>
                            {ownershipAcknowledgementRequired && (
                                <label className="confirm">
                                    <input
                                        type="checkbox"
                                        checked={ownershipAcknowledged}
                                        onChange={event => setOwnershipAcknowledged(event.target.checked)}
                                    />
                                    I acknowledge ownership of all uploaded material
                                </label>
                            )}
                            {(disableSubmitMusic || disableSubmitVisual) && (
                                <div className="rounded-box pad" style={{ backgroundColor: '#ffdddd', marginBottom: 10 }}>
                                    You cannot submit yet:
                                    {
                                        disableSubmitMusic &&
                                        [
                                            <ul>
                                                {audioUploadsPendingCount > 0 && <li>uploads waiting for approval ({audioUploadsPendingCount})</li>}
                                                {trackRequestsPendingCount > 0 && <li>track requests still waiting ({trackRequestsPendingCount})</li>}
                                            </ul>,
                                            <span>Visit the <Link to={to('music')}>music section</Link> for details.</span>
                                        ]
                                    }
                                    {
                                        disableSubmitVisual &&
                                        <ul>
                                            {
                                                map(Object.entries(visualsWithIssues), ([id, { name, slidesWithPlaceholderText = [], slidesWithPendingMedia = [], tooManyImagesOrVideos = false, notEnoughMusic = false, requestInProgress = false, requestNotSubmitted = false }]) => {
                                                    return (
                                                        <li>
                                                            <Link to={to(`visuals/${id}`)}>{name}</Link>&nbsp;
                                                            {slidesWithPlaceholderText.length > 0 && <>
                                                                has "Your text here" placeholder text on {slidesWithPlaceholderText.length === 1 ? "slide" : "slides"} {joinArray({ array: slidesWithPlaceholderText.map(v => parseInt(v) + 1), last: ' and ', separator: ', ' })}&nbsp;
                                                            </>}
                                                            {slidesWithPendingMedia.length > 0 && <>
                                                                has media that is not ready on {slidesWithPendingMedia.length === 1 ? "slide" : "slides"} {joinArray({ array: slidesWithPendingMedia.map(v => parseInt(v) + 1), last: ' and ', separator: ', ' })}&nbsp;
                                                            </>}
                                                            {slidesWithPlaceholderText.length > 0 && tooManyImagesOrVideos && <>and&nbsp;</>}
                                                            {tooManyImagesOrVideos && <>has too many images or videos</>}
                                                            {notEnoughMusic && <>don't have enough music</>}
                                                            {requestInProgress && <>
                                                                has a request still in progress. Please wait for the Wesley Visual team to complete your request.
                                                            </>}
                                                            {requestNotSubmitted && <>
                                                                has is not submitted. Please submit your visual tribute media to the Wesley Media Visuals team for processing.
                                                            </>}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </div>
                            )}
                            {
                                warningVisual && <div className="rounded-box pad" style={{ backgroundColor: '#fff2dd', marginBottom: 10 }}>
                                    Warnings:
                                    <ul>
                                        {
                                            map(visualsNotScheduled, id => {
                                                const visual = find(event.visuals, { id })
                                                return <li>Visual <Link to={to(`visuals/${id}`)}>{visual.name}</Link>&nbsp; has not been scheduled</li>
                                            })
                                        }
                                    </ul>
                                </div>
                            }
                            <button
                                style={{ float: "right" }}
                                className="btn btn--long"
                                onClick={() => sendToVenue()}
                                disabled={disableSubmit}
                            >
                                Finalise service
                            </button>
                        </div>
                    }
                </div>
                <div className="cols cols--50 cols--spaced">
                    <EventDetails />
                    {hasStreaming && eventSource === "newSystem" && (
                        <div className="rounded-box pad">
                            <div className="cols cols--center">
                                <h4>Invited webcast viewers</h4>
                                <Link to={to('webcast')} className="cols__right"><button className='btn'>Edit</button></Link>
                            </div>
                            <StreamingViewers />
                        </div>
                    )}
                </div>
            </section>
            {modal}
        </div>
    )
}

export function SmallScreenReviewPage() {
    return (
        <div className="review-page">
            <section className="progress-navigation">
                <ProgressSteps headerOnly />
            </section>
            <section className="section--grey container-padding">
                <EventDetails />
            </section>
        </div>
    )
}

export function EventDetails() {
    const event = useEvent()
    if (!event) return null
    return (

        <div className="rounded-box pad">
            <h4>Event details</h4>
            <p>
                <strong>Booking ID</strong><br />
                {event.bookingId}
            </p>
            <p>
                <strong>Name</strong><br />
                {event.name}
            </p>

            <p>
                <strong>Venue</strong><br />
                {event.room && <span>{event.room.name}, </span>}
                {event.venue.name}
            </p>

            <p>
                <strong>Date</strong><br />
                {formatDateTimeFull(event.date)}
            </p>

            <div>
                <strong>Ceremony</strong><br /><br />
                <SchedulerViewOnly
                    includeTimings={false}
                />
            </div>
        </div>
    )
}