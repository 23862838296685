import { BDuration, BDurationJson } from '@busby/esb'
import { PointerSensor } from '@dnd-kit/core'
import Axios from 'axios'
import { TrackInfo } from 'common/types/commonTypes'
import { AspectRatio, ContentQuality, SimpleEvents } from 'common/types/eventService'
import * as Debug from 'debug'
import { generateKeyBetween } from 'fractional-indexing'
import { omit, pick, random, sample } from 'lodash'
import * as React from 'react'
import * as uuid from 'uuid'
import { getBemClasses } from '../../../../common/BEM/utils'
import exampleSongs from './exampleSongs'
import { MouseEventHandler, PointerEvent } from 'react'
import { Draft } from 'immer'
import {join} from "path"
import { MUSIC_GAP_MS, oneSecond } from 'common/universal/universalUtils'

export const wesleyDebugNamespace = Debug('busby').extend('wesley')

// an axios instance with any config we want to set generally
export const axios = Axios.create({
    timeout: oneSecond * 5
})

export type ImmerRecipe<T> = (state: Draft<T>) => T | void | undefined

export { getBemClasses } // re-exported

export function eventMediaPath(eventId: string, location: string, filename: string, contentQuality?: ContentQuality): string {
    return `/resources/${location}/eventMedia/${eventId}/uploads/${filename}${contentQuality === "preview" ? "?preview" : ""}`
}

export function eventRenderedVisualsPath(eventId: string, location: string, filename: string): string {
    return `/resources/${location}/eventMedia/${eventId}/renderedVisuals/${filename}`
}

export function eventManuallyCreatedVisualsPath(eventId: string, location: string, filename: string): string {
    return `/resources/${location}/eventMedia/${eventId}/manuallyCreatedVisuals/${filename}`
}

export function eventMediaUploadPath(eventId: string, location: string) {
    return `/resources/${location}/eventMedia/${eventId}/uploads/`
}

export function templateMediaPath(location: string, path: string, filename: string, contentQuality?: ContentQuality): string {
    return join(`/resources/${location}/templates`, path || "", filename, contentQuality === "preview" ? "?preview" : "")
}

export function digitalSignageBackgroundPath(filename: string): string {
    return join(`/resources/digitalSignage/backgrounds`, filename)
}

export function audioPath({ location ,path, filename }: { location: string, path: string, filename: string }) {
    return `/resources/${location}/${path}/${filename}`
}

export function coverArtPath({ location ,path, filename }: { location: string, path: string, filename: string }) {
    // e.g. --> /resources/audio/library/coverArt/Iron%20Maiden%20-%20Fear%20of%20The%20Dark.jpeg
    // path = path.replace('music', 'coverArt')
    // filename = filename.replace(/\..+$/, '.jpeg')
    return `/resources/${location}/${path}/${filename}`
}

export function omitKeys<T extends object, K extends [...(keyof T)[]]>(object: T, ...keys: K): { [K2 in Exclude<keyof T, K[number]>]: T[K2] } {
    return omit(object, keys) as any
}

export function pickKeys<T extends object, K extends [...(keyof T)[]]>(object: T, ...keys: K): Pick<T, K[number]> {
    return pick(object, keys) as any
}

export function walkChildren(
    children: React.ReactNode,
    fn: (child: React.ReactNode) => void
) {
    React.Children.forEach(children, (child: React.ReactNode) => {
        if (React.isValidElement(child) && child.props.children) {
            walkChildren(child.props.children, fn)
        }
        fn(child)
    })
}

export type ChildMapper = (child: React.ReactNode) => React.ReactNode

/**
 * Recursively map child nodes.
 * 
 * Each node will be passed as fn(child). You return the same child, or a new one.
 */
export function mapChildren(
    children: React.ReactNode,
    fn: ChildMapper
): React.ReactNode[] {
    return React.Children.map(children, (child: React.ReactNode) => {
        if (React.isValidElement(child) && child.props.children) {
            const newChildren = mapChildren(child.props.children, fn)
            delete child.props.children
            return fn(React.cloneElement(child, child.props, newChildren))
        }
        return fn(child)
    })
}

export function bem(block: string) {
    return {
        className: block,
        mod(modifier: Parameters<typeof getBemClasses>[1]) {
            return getBemClasses(block, modifier)
        },
        el(...names: string[]) {
            return [block, ...names].join('__')
        },
        element(name) {
            return bem([block, name].join('__'))
        }
    }
}

// dev-only stuff....
// TODO: dev-only, remove or put elsewhere?

export function randomThumbnailImageUrl() {
    return `https://picsum.photos/seed/${Math.random()}/200`
}

export function randomDuration(): BDurationJson {
    return BDuration.fromFrames(random(10, 800) * 1000, 'milli').toJson(true)
}

export function zeroDuration(): BDurationJson {
    return BDuration.zero('milli').toJson(true)
}

export function makeWesleyTrackInfo(): TrackInfo {
    return {
        ...randomArtistAndTitle(),
        id: uuid.v4(),
        type: 'normal',
        duration: randomDuration(),
        // thumbnailImageURL: randomThumbnailImageUrl(),
    }
}

export function randomArtistAndTitle(): { artist: string, title: string } {
    const {
        artist,
        title
    } = sample(exampleSongs.songs)
    return {
        artist,
        title
    }
}

export function getAspectRatioLabel(aspectRatio: AspectRatio): string {
    switch (aspectRatio) {
        case 'custom': return 'Custom'
        case 'original': return 'Original'
        case '1/1': return 'Square'
        default: {
            if (isAspectRatioNumeric(aspectRatio)) {
                return aspectRatio.split('/').join(' / ')
            }
            return aspectRatio
        }
    }
}

export function isAspectRatioNumeric(aspectRatio: string) {
    return aspectRatio && /^\d+\/\d+$/.test(aspectRatio)
}

export function getNumericAspectRatio(aspectRatio: string): number {
    if (isAspectRatioNumeric(aspectRatio)) {
        const [top, bottom] = aspectRatio.split('/').map(s => parseInt(s))
        return top / bottom
    }
}

// generateKeyBetween is very particular and if we specify non-sensical before/after values it will error
// so if they are the same, or before is bigger, just set after to null
// afterwards everything will be reordered and there might be a glitch but the show must go on
export function safeGenerateKeyBetween(
    a: string | null | undefined,
    b: string | null | undefined
) {
    if(a === undefined){
        a = null
    }
    if(b === undefined){
        b = null
    }
    if (a === b || (a && b && a > b)) b = null
    return generateKeyBetween(a, b)
}

/**
 * This is so we can control which elements trigger the drag n drop behaviour, e.g. buttons should not
 */
export class NoButtonsPointerSensor extends PointerSensor {
    static activators = [{
        eventName: 'onPointerDown' as any,
        handler: ({ nativeEvent: event }: PointerEvent<Element>) => {
            const el = event.target as Element
            return (
                // allow <button> elements inside the dnd element
                el.tagName.toLowerCase() !== 'button' &&
                // ... or things inside buttons
                !el.closest('button') &&
                // allow some elements to disable dnd behaviour
                !el.classList.contains('dnd--disable') &&
                !el.closest('.dnd--disable')
            )
        }
    }]
}

export function eventStatusLabel(status?: SimpleEvents.Event['status']): string {
    return {
        // These duplicate the enum labels in the events resource group
        'notSubmitted': 'Not submitted',
        'processing': 'Processing',
        'scheduledForDelivery': 'Scheduled for delivery',
        'waitForPlayerDelivery': "Wait for player delivery",
        'deliveredToPlayers': "Delivered to players",
    }[status] || 'Unknown'
}

/**
 * Returns a click handler you can use that calls stopPropagation and preventDefault and then your function if provided.
 * e.g.
 * 
 *   <button onClick={stopAndPrevent(() => someFunction())} />
 */
export function stopAndPrevent<T>(fn?: () => any): MouseEventHandler<T> {
    return event => {
        if (event) {
            event.stopPropagation()
            event.preventDefault()
        }
        if (fn) {
            fn()
        }
    }
}



export function calculateAdditionalRequiredMusicMilliseconds(visual: SimpleEvents.Visual): number {
    if (!visual) return 0
    if (!visual.hasMusic) return 0
    if (visual.autoSlideDuration) return 0 // auto mode fixes everything \o/
    const musicMilliseconds = calculateMusicDurationMilliseconds(visual.musicItems)
    const slidesMilliseconds = calculateSlidesDurationMilliseconds(visual)
    const slidesWithoutMusicMilliseconds = slidesMilliseconds - musicMilliseconds
    return Math.max(slidesWithoutMusicMilliseconds, 0)
}

export function calculateSlidesDurationMilliseconds(visual: SimpleEvents.Visual) {
    if (!visual) return 0
    const millisecondsFade = BDuration.fromJson(visual.fadeInDuration).getMilliseconds()
    const millisecondsPerSlide = BDuration.fromJson(visual.slideDuration).getMilliseconds() + (millisecondsFade * 2)
    return visual.components.reduce((total, component) => {
        if (component.duration) {
            // The full duration of the component
            return total + BDuration.fromJson(component.duration).getMilliseconds()
        } else {
            // The slide time
            return total + millisecondsPerSlide
        }
    }, 0)
}

export function calculateMusicDurationMilliseconds(musicItems: SimpleEvents.VisualMusicItem[]): number {
    let totalMilliseconds = 0
    let added = 0;
    if (musicItems) {
        for (const musicItem of musicItems) {
            if ('trackInfo' in musicItem) {
                const trackInfo = musicItem.trackInfo as TrackInfo
                totalMilliseconds += BDuration.fromJson(trackInfo.duration).getMilliseconds()
                added++;
            }
        }
    }

    const gap = (added - 1) * MUSIC_GAP_MS
    return totalMilliseconds + gap
}