import * as React from 'react'
import FoldableSection from '../components/FoldableSection'
import LoremIpsum from '../components/LoremIpsum'
import { StoriesDefinition } from './StoriesPage'

const stories: StoriesDefinition = {
    name: 'Foldable',
    stories: [
        {
            name: 'Help style',
            component: () => (
                <>
                    <FoldableSection
                        title='Section 1'
                    >
                        <LoremIpsum count={3} units={'paragraphs'} />
                    </FoldableSection>
                    <FoldableSection
                        title='Section 2'
                    >
                        <LoremIpsum count={3} units={'paragraphs'} />
                    </FoldableSection>
                    <FoldableSection
                        title='Section 3'
                    >
                        <LoremIpsum count={3} units={'paragraphs'} />
                    </FoldableSection>
                </>
            )
        },
        {
            name: 'Filled style',
            component: () => (
                <>
                    <FoldableSection
                        filled
                        title='Section 1'
                    >
                        <LoremIpsum count={3} units={'paragraphs'} />
                    </FoldableSection>
                    <FoldableSection
                        filled
                        title='Section 2'
                    >
                        <LoremIpsum count={3} units={'paragraphs'} />
                    </FoldableSection>
                    <FoldableSection
                        filled
                        title='Section 3'
                    >
                        <LoremIpsum count={3} units={'paragraphs'} />
                    </FoldableSection>
                </>
            )
        },
        {
            name: 'Section style',
            component: () => (
                <>
                    <FoldableSection
                        section
                        title='Section 1'
                    >
                        <LoremIpsum count={3} units={'paragraphs'} />
                    </FoldableSection>
                    <FoldableSection
                        section
                        title='Section 2'
                    >
                        <LoremIpsum count={3} units={'paragraphs'} />
                    </FoldableSection>
                    <FoldableSection
                        section
                        title="Ceremony"
                    >
                        <LoremIpsum count={3} units={'paragraphs'} />
                    </FoldableSection>
                </>
            )
        }
    ]
}

export default stories