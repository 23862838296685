import { useKeyPress } from 'common/frontend/hooks'
import { getBemClasses } from 'common/frontend/utils'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SearchHighlight, SearchResult } from './searchModel'

export interface SearchResultsProps {
    results: SearchResult[]
    onSelectResult: (selected: SearchResult) => void
}

// we don't do very fancy routing stuff
// this assumption could break for some route definitions
// e.g. nested routes (which aren't handled)
export function getRoutePath(result: SearchResult) {
    let path = result.entry.path
    if (!/^\//.test(path)) {
        return '/' + path
    }
    return path
}

/**
 * This has the uneviable task of turning the results into something nice for the user to use
 */
export default function SearchResults({ results, onSelectResult }: SearchResultsProps) {
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        setSelectedIndex(null)
    }, [results])

    useKeyPress('ArrowDown', () => {
        if (selectedIndex !== null) {
            if (results[selectedIndex + 1]) {
                setSelectedIndex(selectedIndex + 1)
            }
        } else {
            if (results.length > 0) {
                setSelectedIndex(0)
            }
        }
    }, [selectedIndex, results])

    useKeyPress('ArrowUp', () => {
        if (selectedIndex !== null) {
            if (results[selectedIndex - 1]) {
                setSelectedIndex(selectedIndex - 1)
            } else {
                setSelectedIndex(null)
            }
        } else {
            if (results.length > 0) {
                setSelectedIndex(results.length - 1)
            }
        }
    }, [selectedIndex, results])

    useKeyPress('Enter', () => {
        let result = results[selectedIndex]
        if (!result) result = results[0] // just go to the first one
        if (!result) return
        setSelectedIndex(null)
        onSelectResult(result)
        navigate(getRoutePath(result))
    }, [selectedIndex, results])

    if (results.length === 0) return null
    return (
        <div className="search-results">
            {results.map((result, index) => {
                const {
                    entry,
                    highlights: {
                        title: titleHighlight,
                        content: contentHighlights
                    }
                } = result

                const path = getRoutePath(result)

                return (
                    <Link
                        key={index}
                        to={path}
                        onClick={() => onSelectResult(result)}
                        className={getBemClasses('search-results__result', { selected: selectedIndex === index })}
                    >
                        <div className="search-results__result__path">{path}</div>
                        <div className="search-results__result__title">{titleHighlight ? <Highlight highlight={titleHighlight} /> : entry.title}</div>
                        <div className="search-results__result__preview">
                            {contentHighlights.length === 0 && (
                                <span>
                                    {result.entry.content.slice(0, 20)}
                                </span>
                            )}
                            {contentHighlights.map((highlight, index) => (
                                <span
                                    key={index}
                                    className="search-results__result__preview__highlight"
                                >
                                    <Highlight highlight={highlight} />
                                </span>
                            ))}
                        </div>
                    </Link>
                )
            })}
        </div>
    )
}

/*
export function getHighlights(result: SearchResult) {
    const entry = result.entry
    const highlights = { entry, title: [], content: [] }
    const { matchData: { metadata } } = result

    // Matches are per term and field, but we want to collect them all per field first..
    const allPositions = {}
    for (const term of Object.keys(metadata)) {
        // term might be a stemmed version, and not actually in the doc, we don't care about it
        for (const field of Object.keys(metadata[term])) {
            const { position: positions } = metadata[term][field]
            if (!allPositions[field])
                allPositions[field] = []
            // collect all the positions per field
            allPositions[field].push(...positions)
        }
    }

    for (const field of Object.keys(allPositions)) {
        const positions = allPositions[field]
        if (field === 'title') {
            // for title include all the highlights
            let currentIndex = 0
            const highlight = []
            const fieldValue = entry[field]
            for (const position of positions) {
                const [startIndex, length] = position
                const endIndex = startIndex + length
                if (currentIndex < startIndex) {
                    // the bit of text before the highlight
                    highlight.push(
                        <span key="pre">
                            {fieldValue.substring(currentIndex, startIndex)}
                        </span>
                    )
                }
                // the highlighted bit
                highlight.push(
                    <mark key=".search-result-highlight">
                        {fieldValue.substring(startIndex, endIndex)}
                    </mark>
                )
                currentIndex = endIndex
            }
            // ... if we have some left over add it
            if (currentIndex < fieldValue.length) {
                highlight.push(
                    <span key="post">
                        {fieldValue.substring(currentIndex, fieldValue.length)}
                    </span>
                )
            }
            highlights.title = highlight
        } else if (field === 'content') {
            for (const position of positions) {
                const [startIndex, length] = position
                const endIndex = startIndex + length
                highlights[field].push({
                    field,
                    position,
                    snippet: entry[field].substring(startIndex, startIndex + length),
                    highlight: [
                        <span key="pre">
                            ...{entry[field].substring(startIndex - 10, startIndex)},
                        </span>,
                        <mark key=".search-result-highlight">
                            {entry[field].substring(startIndex, endIndex)}
                        </mark>,
                        <span key="post">
                            {entry[field].substring(endIndex, endIndex + 10)}...
                        </span>
                    ]
                })
            }
        }
    }

    return highlights
}
*/


export function Highlight({ highlight }: { highlight: SearchHighlight }) {
    return (
        <>
            {highlight.before && (
                <span key="pre">
                    {highlight.beforeHasMore && <span>&hellip;</span>}
                    {highlight.before}
                </span>
            )}
            <mark key=".search-result-highlight">
                {highlight.term}
            </mark>
            {highlight.after && (
                <span key="post">
                    {highlight.after}
                    {highlight.afterHasMore && <span>&hellip;</span>}
                </span>
            )}
        </>
    )
}