import { useVisualTemplates } from 'common/frontend/state'
import * as React from 'react'
import { useState } from "react"
import { Select, LiveEventTypeSelect } from '../components/Select'
import { StoriesDefinition } from "./StoriesPage"

export default {
    name: 'Select',
    stories: [
        {
            name: 'Default',
            component: () => {
                const [liveEventType, setLiveEventType] = useState(null)

                const [visualTemplate, setVisualTemplate] = useState(null)
                const visualTemplates = useVisualTemplates()
                return (
                    <div>
                        <div>liveEventTypes</div>
                        <LiveEventTypeSelect
                            value={liveEventType}
                            onChange={setLiveEventType}
                        />

                        <div>visualTemplates</div>
                        <Select
                            options={visualTemplates}
                            value={visualTemplate}
                            onChange={setVisualTemplate}
                            getDisplayValue={value => value.name}
                        />
                    </div>
                )
            }
        }
    ]
} as StoriesDefinition