import * as classnames from 'classnames'
import { shuffle } from 'lodash'
import * as React from 'react'
import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react'
import { getBemClasses } from '../utils'
import './BackgroundCarousel.scss'

export interface BackgroundCarouselProps extends HTMLAttributes<HTMLElement> {
    duration?: number // milliseconds to show each image
    fadeDuration?: number // milliseconds of fade time
    images?: string[]
    video?: string
    muted?: boolean
    children?: ReactNode
    as?: 'div' | 'section'
}

/**
 *  A background carousel that creates a parent element, div (or other element via "as" prop)
 *  with a nested <img> tag for each image.
 * 
 *  We then cycle through them, and use CSS opacity transitions between them.
 */
export function BackgroundCarousel({
    duration = 5000,
    fadeDuration = 2000,
    images,
    video,
    children,
    muted = true,
    as = 'div',
    ...attributes
}: BackgroundCarouselProps) {
    // shuffle images so we don't always start on the same one
    const shuffledImages = useMemo(() => shuffle(images), [images])

    const [activeIndex, setActiveIndex] = useState(0)

    // cycles through our images
    useEffect(() => {
        const timer = setInterval(() => {
            setActiveIndex(index => (index + 1) % shuffledImages.length)
        }, duration)
        return () => clearTimeout(timer)
    }, [duration, shuffledImages])

    return React.createElement(
        as,
        {
            ...attributes,
            style: {
                position: 'relative',
                ...attributes.style
            },
            className: classnames(['background-carousel', attributes.className])
        }, (
        <>
            {children && <div className="background-carousel__children">{children}</div>}
            {
                images && shuffledImages.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        style={{
                            '--transition-duration': `${fadeDuration}ms`
                        } as React.CSSProperties}
                        className={getBemClasses('background-carousel__image', {
                            active: activeIndex === index
                        })}
                    />
                ))
            }
            {
                video && <video
                    src={video}
                    className={getBemClasses('background-carousel__image', {active: true})}
                    controls={false}
                    muted={muted}
                    autoPlay
                    loop
                />
            }
        </>
    ))
}