import { useResetAudio } from 'common/frontend/audio'
import DevBar from 'common/frontend/components/dev/DevBar'
import Footer from 'common/frontend/components/Footer'
import Header from 'common/frontend/components/Header'
import { useIsAdmin, useIsLoggedIn } from 'common/frontend/state'
import * as React from 'react'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import OrganisationSelector from './components/OrganisationSelector'
import { useIsVenueUser } from './state'

export default function MainLayout() {
    const isLoggedIn = useIsLoggedIn()
    const resetAudio = useResetAudio()
    const location = useLocation()
    
    useEffect(() => {
        resetAudio()
    }, [location])

    return (
        <div className="container">
            {
                isLoggedIn ? <HeaderLoggedIn /> : <Header showLogin/>
            }
            <Outlet />
            <Footer/>
            <DevBar/>
        </div>
    )
}

function HeaderLoggedIn(){
    const venueUser = useIsVenueUser()
    const isAdmin = useIsAdmin()
    return <Header showLogin showDigitalSignage={venueUser || isAdmin}/>
}