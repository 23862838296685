import * as React from 'react'
import { ReactNode } from 'react'
import './AuthSection.scss'
import { BackgroundCarousel, BackgroundCarouselProps } from './BackgroundCarousel'
import Cover from './Cover'

export interface AuthSectionProps {
    images: BackgroundCarouselProps['images']
    children: ReactNode
    isPending?: boolean
}

export default function AuthSection({ images, isPending = false, children }: AuthSectionProps) {
    return (
        <BackgroundCarousel images={images} className="auth-section" as="section">
            <div className="auth-section__inner">
                <Cover center translucent loading enabled={isPending} />
                {children}
            </div>
        </BackgroundCarousel>
    )
}