import { BDateTime, BDuration, BDurationJson } from "@busby/esb";
import axios, { AxiosResponse } from "axios";
import { audioPath, calculateMusicDurationMilliseconds } from "common/frontend/utils";
import { SimpleEvents } from "common/types/eventService";
import { debounce, each, find, flatten, last, map, sortBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Mutex } from "async-mutex"
import { MUSIC_GAP_MS } from "common/universal/universalUtils";
type UseVisualPlayerAudioProps = {
    musicItems: SimpleEvents.VisualMusicItem[]
    duration: BDurationJson
    fadeDuration: BDurationJson
    slideDuration: BDurationJson;
    components: SimpleEvents.VisualComponent[]
    getPosition: () => BDateTime
}

export function useVisualPlayerAudio({ duration, musicItems, fadeDuration, slideDuration, components, getPosition }: UseVisualPlayerAudioProps) {

    // const audioElement = useRef(document.createElement("audio"))
    const audioContext = useRef(new AudioContext())
    const source = useRef<AudioBufferSourceNode>()
    const gainNode = useRef<GainNode>(audioContext.current.createGain())
    const mutex = useRef<Mutex>(new Mutex())
    const playing = useRef(false)
    const audioBuffer = useRef<AudioBuffer>()

    useEffect(() => {
        gainNode.current.connect(audioContext.current.destination);

        return () => {
            pause()
            audioContext.current?.close()
        }
    }, [])

    useEffect(() => {
        if (duration && musicItems) {
            generateAudio()
        }
    }, [musicItems, duration, fadeDuration, slideDuration, components])

    const pause = () => {
        if (playing.current) {
            source.current?.stop();
            audioContext.current.suspend()
        }
        playing.current = false
    }

    const getDucks = () => {
        const fadeMs = BDuration.fromJson(fadeDuration).getMilliseconds()
        const slideMs = BDuration.fromJson(slideDuration).getMilliseconds()

        const componentsSorted = sortBy(components, "order")
        let ducks: { from: number, to: number, fadeTime: number }[] = []
        let currentTimeMs = 0;
        let prevComponent: any = {}


        for (let component of componentsSorted) {
            const { type, duration, includeAudio, hasAudio, filename, location, id } = component
            const { type: prevType, includeAudio: prevIncludeAudio, hasAudio: prevHasAudio } = prevComponent
            if (type === "video") {
                if (duration) {
                    const videoMs = BDuration.fromJson(duration).getMilliseconds()

                    if (hasAudio && includeAudio) {
                        //If previous component was video and had audio, extend the last duck. This stops the background music appearing during the slide transition
                        if (prevType === "video" && prevHasAudio && prevIncludeAudio) {
                            last(ducks).to = currentTimeMs + videoMs
                        } else {
                            ducks.push({
                                from: currentTimeMs,
                                to: currentTimeMs + videoMs,
                                fadeTime: fadeMs
                            })
                        }
                    }
                    currentTimeMs += videoMs
                } else {
                    ducks = []
                    break;
                }
            } else {
                currentTimeMs += fadeMs * 2
                currentTimeMs += slideMs
            }
            prevComponent = component
        }

        return ducks
    }

    const play = async () => {
        const release = await mutex.current.acquire()
        try {
            if (source.current) {
                try {
                    source.current.stop()
                } catch (e) {

                }
            }
            source.current?.disconnect()

            if(!audioBuffer.current){
                return;
            }

            gainNode.current.gain.cancelScheduledValues(0)
            source.current = audioContext.current.createBufferSource()

            const position = getPosition().getMillis() / 1000
            const acCurrent = audioContext.current.currentTime
            source.current.connect(gainNode.current);

            const musicDuration = calculateMusicDurationMilliseconds(musicItems) / 1000
            const totalDurationMs = BDuration.fromJson(duration).getMilliseconds()
            const totalDuration = totalDurationMs / 1000
            const fade = BDuration.fromJson(fadeDuration).getMilliseconds() / 1000
            const musicExceedsSlideshow = musicDuration > totalDuration;


            const ducks = getDucks()
            let videoDuckEnvelopes: { time: number, volume: number }[] = []
            console.log("ducks", ducks)

            for (const { from, to, fadeTime } of ducks) {
                const fadeInTime = Math.max(0, (from - 1000))
                const fadeTimeSec = fadeTime / 1000
                if (fadeInTime !== 0) {
                    videoDuckEnvelopes.push(
                        {
                            time: Math.max(0, (from - fadeTime) / 1000),
                            volume: 1
                        })
                }
                videoDuckEnvelopes.push(
                    {
                        time: Math.max(0, from / 1000),
                        volume: 0
                    },
                    {
                        time: to / 1000,
                        volume: 0
                    },
                    {
                        time: (to / 1000) + fadeTimeSec,
                        volume: 1
                    }
                )
            }


            if (!find(videoDuckEnvelopes, { time: 0 })) {
                videoDuckEnvelopes = [
                    {
                        time: 0,
                        volume: 1
                    },
                    ...videoDuckEnvelopes
                ]
            }

            let prevEnv : {time: number, volume: number} = {time: 0, volume: 0};
            for (const env of videoDuckEnvelopes) {
                const {time, volume} = env
                if (time < position) {
                    prevEnv = env;
                } else {
                    const newVolume = interpolateValue(prevEnv.volume, volume, prevEnv.time, time, position)
                    console.log(`Setting initial volume`, newVolume)
                    gainNode.current.gain.value = isNaN(newVolume) ? 0 : newVolume
                    break;
                }
            }

            console.log(videoDuckEnvelopes)
            console.log({ position, current: audioContext.current.currentTime })
            for (const { time, volume } of videoDuckEnvelopes) {
                const newPosition = time + acCurrent - position
                if (newPosition >= 0) {
                    console.log(`Setting volume ${volume} at time ${newPosition}`)
                    gainNode.current.gain.linearRampToValueAtTime(volume, newPosition)
                }
            }

            const videoAtEnd = find(ducks, { to: totalDurationMs })
            if (musicExceedsSlideshow && !videoAtEnd) {
                gainNode.current.gain.linearRampToValueAtTime(1, totalDuration - fade + acCurrent - position)
                gainNode.current.gain.linearRampToValueAtTime(0, totalDuration + acCurrent - position)
            }



            source.current.buffer = audioBuffer.current
            source.current.start(0, position, Math.max(totalDuration - position, 0))
        } catch (e) {
            throw e
        } finally {
            release()
        }
    }


    const generateAudio = async () => {

        const totalDurationMs = BDuration.fromJson(duration).getMilliseconds()
        if(musicItems.length === 0){
            audioBuffer.current = null
            return;
        }
        if (totalDurationMs === 0) {
            return;
        }
        const getPromises: Promise<AxiosResponse>[] = [];
        for (const musicItem of sortBy(musicItems, "order")) {
            const { path, filename, location } = musicItem.trackInfo.audio
            console.log(`getting ${filename}`)
            const promise = axios.get(audioPath({ location, path, filename }), { responseType: "arraybuffer" })
            getPromises.push(promise)
        }

        const getResults = await Promise.all(getPromises)

        const decodePromises: Promise<AudioBuffer>[] = []
        for (const result of getResults) {
            decodePromises.push(audioContext.current.decodeAudioData(result.data))
        }

        const audioBuffers = await Promise.all(decodePromises)

        let length = 0;
        for (const audioBuffer of audioBuffers) {
            length += audioBuffer.length
        }

        const { sampleRate } = audioBuffers[0]

        const totalSamples = (totalDurationMs / 1000) * sampleRate

        const concatenatedBuffer = audioContext.current.createBuffer(
            audioBuffers[0].numberOfChannels,
            totalSamples,
            sampleRate
        );

        let currentSample = 0;
        for (const incomingAudioBuffer of audioBuffers) {
            const remainingSamples = totalSamples - currentSample
            let audioBuffer = incomingAudioBuffer
            let exitEarly = false;
            const channels = audioBuffer.numberOfChannels
            if (remainingSamples < audioBuffer.length) {
                audioBuffer = audioContext.current.createBuffer(channels, remainingSamples, sampleRate)
                for (let channel = 0; channel < channels; channel++) {
                    let array = new Float32Array(remainingSamples);
                    incomingAudioBuffer.copyFromChannel(array, channel, 0);
                    audioBuffer.copyToChannel(array, channel, 0);
                }
                exitEarly = true;
            }
            concatenatedBuffer.getChannelData(0).set(audioBuffer.getChannelData(0), currentSample);
            currentSample = audioBuffer.length + (MUSIC_GAP_MS / 1000) * sampleRate
            if (exitEarly) {
                break;
            }
        }

        audioBuffer.current = concatenatedBuffer
    }

    const debouncedPlay = debounce(() => play(), 250)

    return {
        getDucks,
        play: () => {
            playing.current = true
            play()
            audioContext.current.resume()
        },
        pause: () => {
            pause()
        },
        setPosition: () => {
            if (playing.current) {
                debouncedPlay()
            }
        }
    }
}

function interpolateValue(
    startValue: number,
    endValue: number,
    startTime: number,
    endTime: number,
    currentTime: number
  ): number {
    // Ensure currentTime is within the range of startTime and endTime
    if (currentTime <= startTime) {
      return startValue;
    } else if (currentTime >= endTime) {
      return endValue;
    }
  
    // Calculate the percentage of time elapsed
    const timeRatio = (currentTime - startTime) / (endTime - startTime);
  
    // Interpolate between startValue and endValue based on the time ratio
    const interpolatedValue = startValue + (endValue - startValue) * timeRatio;
  
    return interpolatedValue;
  }