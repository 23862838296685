import * as React from 'react'
import Cover from './Cover'

export interface LoadingProps {
    cover?: boolean
    coverCenter?: boolean
}

export default function Loading({ cover = false, coverCenter = false }: LoadingProps) {
    if (cover) {
        return <Cover center={coverCenter} loading />
    } else {
        return <div className="loading-spinner" />
    }
}