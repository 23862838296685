import { getBemClasses } from '../../../../../common/BEM/utils'
import { SimpleEvents, StreamingViewerStatus } from 'common/types/eventService'
import * as React from 'react'
import 'common/frontend/components/InfoBox.scss'

function statusText(status: StreamingViewerStatus) {
    switch (status) {
        case 'pending': return 'pending'
        case 'accepted': return 'accepted'
        case 'declined': return 'unable to attend'
        default: 'unknown'
    }
}

export interface StreamingViewerProps {
    viewer: SimpleEvents.StreamingViewer | SimpleEvents.StreamingViewerData
    onRemove?: () => void
}

export default function StreamingViewer({ viewer, onRemove }: StreamingViewerProps) {
    return (
        <div
            className="info-box"
        >
            <div>
                <div><strong>{viewer.name}</strong></div>
                <div>{viewer.email}</div>
            </div>
            {false /* streaming viewer status not used any more */ && (
                <div
                    className={getBemClasses('info-box__status', viewer.status?.toLowerCase())}
                >
                    {statusText(viewer.status)}
                </div>
            )}
            {onRemove && (
                <button
                    className="action action--trash"
                    onClick={() => onRemove()}
                />
            )}
        </div>
    )
}