import { BDuration } from "@busby/esb";
import { Divider, Flex, Popover, Progress, RingProgress, Stack, Text } from "@mantine/core";
import { Project } from '@motion-canvas/core';
import StringField from '@rjsf/core/lib/components/fields/StringField';
import { FieldProps } from '@rjsf/utils';
import axios from 'axios';
import * as classnames from 'classnames';
import { useAddAction, useDeleteAction, useDeleteMultipleAction, useUpdateAction } from 'common/frontend/actions';
import Cover from 'common/frontend/components/Cover';
import { Form } from "common/frontend/components/Form";
import { BDurationSecondsNumberField } from 'common/frontend/components/customFields';
import { useEventPathTo } from "common/frontend/eventPaths";
import { formatMillisAsMinutesAndSeconds } from 'common/frontend/formatters';
import { useEvent, useEventId, useEventOrganisation, useIsAdmin, useVisual, useVisualState, useVisuals } from 'common/frontend/state';
import { calculateAdditionalRequiredMusicMilliseconds, calculateMusicDurationMilliseconds, eventMediaPath, getBemClasses, wesleyDebugNamespace } from 'common/frontend/utils';
import { SimpleEvents } from 'common/types/eventService';
import { getVisualImageAndVideoCounts } from 'common/universal/universalUtils';
import { generateKeyBetween } from 'fractional-indexing';
import { createDraft, finishDraft, produce } from 'immer';
import { JSONSchema7 } from 'json-schema';
import { debounce, filter, find, findIndex, isEmpty, map, orderBy, sumBy } from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useConfirmModal, useVisualTributeMode } from '../../hooks';
import Loading from '../Loading';
import MusicSelectionForVisual from '../MusicSelectionForVisual';
import { Toggle } from '../Toggle';
import { BDurationUpDownWidget, TextBlurWidget, TextareaBlurWidget, UpDownWidget, millisecondsToDuration } from '../customWidgets';
import { useVisualComponentUploadsContext, useVisualComponentsUploadStatus } from '../visualComponentUpload';
import { VisualComponentGrid } from './VisualComponentGrid';
import VisualPreview from './VisualPreview';
import { VisualTemplateCarouselWidget } from './VisualTemplateCarousel';
import { TemplateDescription, templates } from './templates/templates';
import { getVisualProject } from "./VisualUtils";


const debug = wesleyDebugNamespace.extend('visual-edit')
const slideDurationMin = 1
const slideDurationMax = 100;
const fadeDurationMin = 0;
const fadeDurationMax = 10;

function getSchemasForVisualEdit(
    visual: SimpleEvents.Visual,
    setAutoSlideDuration: (autoSlideDuration: boolean) => void,
    mode: "full" | "restricted"
): { schema: JSONSchema7, uiSchema: any } {
    const schema: JSONSchema7 = {
        type: 'object',
        properties: {
            name: {
                type: 'string',
                title: 'Name'
            }
        }
    }

    if (!visual.manuallyCreatedVisual && mode === "full") {
        schema.properties = {
            ...schema.properties,
            ...(visual.canSelectTemplate ? {
                template: {
                    type: 'object',
                    properties: {
                        id: {
                            type: "string"
                        }
                    }
                },
            } : {}),
            ...((visual.maxImages + visual.maxVideos) > 1 ? {
                fadeInDuration: {
                    type: "number",
                    minimum: fadeDurationMin,
                    maximum: fadeDurationMax
                },
                slideDuration: {
                    type: "number",
                    minimum: slideDurationMin,
                    maximum: slideDurationMax
                }
            } : {})
        }
    }

    const uiSchema = {
        name: {
            "ui:autofocus": true
        },
        template: {
            "ui:title": 'Template',
            "ui:field": StringField,
            "ui:widget": VisualTemplateCarouselWidget
        },
        fadeInDuration: {
            "ui:title": "Fade time (s)",
            "ui:options": {
                min: fadeDurationMin,
                max: fadeDurationMax
            },
            "ui:widget": UpDownWidget
        },
        slideDuration: {
            "ui:field": SlideTimeField,
            "ui:options": {
                "hideTitle": true,
                "supportsAutoSlideDuration": visual.hasMusic,
                autoSlideDuration: visual.autoSlideDuration,
                setAutoSlideDuration,
                min: slideDurationMin,
                max: slideDurationMax
            }
        }
    }
    return { schema, uiSchema }
}

const fields = {
    // Ideally this should be a custom field type, but if 'ui:field' is set it hides the label
    // (it's assuming we are including it in our field definition, but BDurationSecondsNumberField is just a thin wrapper around NumberField, which doesn't include the label)
    // ... so, a bit of a hack, we repurpose the "number" type instead ...
    // this works, so long as we don't need the number field for anything else in this form...
    NumberField: BDurationSecondsNumberField
}

interface VisualEditProps {
    visualId: string
}

export default function VisualEdit({ visualId }: VisualEditProps) {
    const eventId = useEventId()
    const event = useEvent();
    const organisation = useEventOrganisation()
    const visuals = useVisuals()
    const [visual, setVisual] = useVisualState(visualId)
    const [showInRoom, setShowInRoom] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const [isUpdatingTemplate, setIsUpdatingTemplate] = useState(false)
    const { doUpload } = useVisualComponentUploadsContext()
    const mode = useVisualTributeMode(event)
    const to = useEventPathTo()
    const isAdmin = useIsAdmin()
    const requestInProgress = visual?.requestStatus === "processing"
    const navigate = useNavigate()
    const [selectedComponents, setSelectedComponents] = useState<string[]>([])

    const { confirm, modal } = useConfirmModal({
        isPending,
        title: 'Are you sure you want to change template?',
        message: 'This will remove all your existing components and replace them with the new template.',
        confirmButton: 'Yes'
    })

    const roomAspectRatio = event?.room?.aspectRatio || '16/9'
    const templateAspectRatio = visual?.template?.aspectRatio || '16/9'

    // ensure we have template params
    // if we have empty object ({}), if we save that, it comes back as
    // undefined from server, and two sessions with the same visual open
    // get into a loop with each other :)

    if (visual && !visual.templateParams) visual.templateParams = { message: '' }

    const [add] = useAddAction()
    const [update] = useUpdateAction()
    const [deleteAction] = useDeleteAction()
    const [deleteMultipleAction] = useDeleteMultipleAction()

    async function save(changedVisual: SimpleEvents.Visual) {
        const {
            // leave out some stuff that we don't need to set here
            components,
            musicItems,
            ...updateData
        } = changedVisual

        const { resource: updatedVisual } = await update.visual(updateData)
        setVisual(updatedVisual)
    }

    async function updateFromForm(formData: any) {
        let updatedComponents = false;
        const draft = createDraft(visual)
        Object.assign(draft, formData)
        if (!draft.slideDuration) {
            draft.slideDuration = BDuration.fromFrames(slideDurationMin * 1000, "milli").toJson()
        }

        if (!draft.fadeInDuration) {
            draft.fadeInDuration = BDuration.fromFrames(fadeDurationMin * 1000, "milli").toJson()
        }

        let updatedVisual = finishDraft(draft)

        if (updatedVisual.template.id !== visual.template.id) {
            if (await confirm()) {
                updatedComponents = true
                
                const project: Project = getVisualProject(roomAspectRatio, updatedVisual.template.projectId)
                const templateDescription: TemplateDescription = project.variables.templateDescription as TemplateDescription

                const firstMediaSlide = find(templateDescription.slideTypes, v => !isEmpty(v.media))
                const firstTextSlide = find(templateDescription.slideTypes, v => isEmpty(v.media))

                updatedVisual = produce(updatedVisual, draft => {
                    for (let component of draft.components) {
                        const isTextOnly = component.type === "text"
                        const hasMedia = component.type === "image" || component.type === "video"
                        const hasMatchingSlideType = find(templateDescription.slideTypes, v => {
                            const slideIsTextOnly = isEmpty(v.media)
                            const slideIsMedia = !isEmpty(v.media)
                            return v.type === component.slideType && (
                                (slideIsTextOnly && isTextOnly) ||
                                (slideIsMedia && hasMedia)
                            )
                        })
                        if (isTextOnly) {
                            if (hasMatchingSlideType) {
                                component.crop = null
                            } else if (firstTextSlide) {
                                component.slideType = firstTextSlide.type
                                component.crop = null
                            }
                        } else {
                            if (hasMatchingSlideType) {
                                component.crop = null
                            } else {
                                component.slideType = firstMediaSlide.type
                                component.crop = null
                            }
                        }
                    }

                })
            }
        }

        if (updatedComponents) {
            // This can be a bit slow as it has to save each component, so we have a loader...
            setIsUpdatingTemplate(true)
            try {
                await Promise.all(updatedVisual.components.map(component => onComponentChange(component)))
            } finally {
                setIsUpdatingTemplate(false)
            }
        }

        // optimistic local update
        setVisual(updatedVisual)
        // save to database
        save(updatedVisual)
    }

    const onComponentsAdd = useCallback(async (components: { component: SimpleEvents.VisualComponentData, file: File }[]) => {
        debug('got new items to create component for %o', components)
        //For media items, S3 workflow deals with adding the component
        const mediaComponents = filter(components, ({ component }) => component.type === "image" || component.type === "video")
        const textComponents = filter(components, ({ component }) => component.type === "text")
        const draft = createDraft(visual)
        let lastOrder = orderBy(visual.components, ["order"], ["desc"])[0]?.order ?? null
        if (!isEmpty(textComponents)) {
            const addedComponents = await Promise.all(textComponents.map(async ({ component }) => {
                const order = generateKeyBetween(lastOrder, null)
                const { resource: addedComponent } = await add.visualComponent({ ...component, order }, visualId)
                lastOrder = order;
                return addedComponent
            }))

            debug('added visual components %o', { addedComponents })


            draft.components.push(...addedComponents)

            if (visual?.autoSlideDuration) {
                const slideDuration = calculateSlideDuration(draft)
                if (slideDuration !== null) {
                    draft.slideDuration = millisecondsToDuration(slideDuration)
                }
            }
        }



        for (const { component, file } of mediaComponents) {
            component.order = generateKeyBetween(lastOrder, null)
            lastOrder = component.order
            doUpload(visual.id, eventId, component, file)
        }

        const updatedVisual = finishDraft(draft)
        setVisual(updatedVisual)
        await save(updatedVisual)
    }, [visual])

    async function onComponentChange(component: SimpleEvents.VisualComponent) {
        debug('updating component %o', component)
        // optimistic local update
        updateComponent(component)
        const { resource: updatedComponent } = await update.visualComponent(component)
        debug('updated component %o', updatedComponent)
        updateComponent(updatedComponent)
    }

    async function onComponentRemove(id: string) {
        debug('remove component %s', id)
        const deletedComponent = visual.components.find(item => item.id === id)
        removeComponent(id) // optimistically remove it
        await deleteAction.visualComponent(id)
        if (deletedComponent.type === "image" || deletedComponent.type === "video") {
            // delete the asset files (TODO: maybe do on backend somewhere?)
            axios.delete(eventMediaPath(eventId, deletedComponent.location, deletedComponent.filename))
        }
        removeComponent(id) // just in case we might have reloaded it...
    }

    function updateComponent(component: SimpleEvents.VisualComponent) {
        setVisual(produce(draft => {
            const idx = draft.components.findIndex(item => item.id === component.id)
            if (idx !== -1) {
                draft.components[idx] = component
            }
        }))
    }

    function removeComponent(id: string) {

        const draft = createDraft(visual)
        const idx = draft.components.findIndex(item => item.id === id)
        if (idx !== -1) {
            draft.components.splice(idx, 1)
        }

        if (visual?.autoSlideDuration) {
            const slideDuration = calculateSlideDuration(draft)
            if (slideDuration !== null) {
                draft.slideDuration = millisecondsToDuration(slideDuration)
            }
        }

        const updatedVisual = finishDraft(draft)

        setVisual(updatedVisual)
        save(updatedVisual)
    }


    function removeComponents(ids: string[]) {

        const draft = createDraft(visual)
        for (const id of ids) {
            const idx = draft.components.findIndex(item => item.id === id)
            if (idx !== -1) {
                draft.components.splice(idx, 1)
            }
        }
        if (visual?.autoSlideDuration) {
            const slideDuration = calculateSlideDuration(draft)
            if (slideDuration !== null) {
                draft.slideDuration = millisecondsToDuration(slideDuration)
            }
        }

        const updatedVisual = finishDraft(draft)

        setVisual(updatedVisual)
        save(updatedVisual)
    }

    async function removeSelectedComponents() {

        const deletedComponents = visual.components.filter(item => selectedComponents.includes(item.id))
        const draft = createDraft(visual)
        for (const id of selectedComponents) {
            const idx = draft.components.findIndex(item => item.id === id)
            if (idx !== -1) {
                draft.components.splice(idx, 1)
            }
        }

        if (visual?.autoSlideDuration) {
            const slideDuration = calculateSlideDuration(draft)
            if (slideDuration !== null) {
                draft.slideDuration = millisecondsToDuration(slideDuration)
            }
        }

        const updatedVisual = finishDraft(draft)
        setVisual(updatedVisual)

        // removeComponents(selectedComponents)
        await deleteMultipleAction.visualComponent(selectedComponents)
        for (const deletedComponent of deletedComponents) {
            if (deletedComponent.type === "image" || deletedComponent.type === "video") {
                // delete the asset files (TODO: maybe do on backend somewhere?)
                axios.delete(eventMediaPath(eventId, deletedComponent.location, deletedComponent.filename))
            }
        }

        // removeComponents(selectedComponents)
        setSelectedComponents([])
    }

    function onComponentSelected(id: string, selected: boolean) {
        if (selected) {
            setSelectedComponents([...selectedComponents, id])
        } else {
            setSelectedComponents(filter(selectedComponents, v => v !== id))
        }
    }

    function setAutoSlideDuration(autoSlideDuration: boolean) {
        setVisual(produce(draft => {
            draft.autoSlideDuration = autoSlideDuration
        }))
    }

    const debouncedSetSlideDuration = debounce(setSlideDuration, 250)
    function setSlideDuration(ms: number) {
        const millisconds = Math.ceil(ms)

        const draft = createDraft(visual)
        const draftDuration = Math.ceil(draft.slideDuration?.duration as number)
        console.log("setSlideDuration start", ms, draft.slideDuration.duration)

        let proceed = false;
        if (!draft) return
        if (draftDuration !== millisconds) {
            draft.slideDuration = millisecondsToDuration(millisconds)
            proceed = true
        }
        const updatedVisual = finishDraft(draft)

        if (proceed) {
            console.log("setSlideDuration proceed", ms, updatedVisual.slideDuration.duration)
            setVisual(updatedVisual)
            save(updatedVisual)
        }
    }

    const additionalRequiredMusicMillis = useMemo(() => calculateAdditionalRequiredMusicMilliseconds(visual), [visual])

    const [musicDurationMillis, setMusicDurationMillis] = useState(null)

    useEffect(() => {
        if (visual) {
            if (visual.musicItems) {
                setMusicDurationMillis(calculateMusicDurationMilliseconds(visual.musicItems))
            } else {
                setMusicDurationMillis(0)
            }
        }
    }, [visual?.musicItems])

    const calculateSlideDuration = (visual) => {
        if (visual.components.length === 0 || visual.musicItems.length === 0) {
            // Can't do anything here... use a default kind of time
            return 10000
        } else {
            if (musicDurationMillis === 0 || musicDurationMillis === null) {
                return musicDurationMillis
            }
            // To auto calculate the slide duration we start with total music time
            let millisecondsToDivide = musicDurationMillis
            const fadeMs = BDuration.fromJson(visual.fadeInDuration).getMilliseconds()
            millisecondsToDivide -= (fadeMs * 2 * visual.components.length)

            let nonDurationSlideCount = 0
            for (const component of visual.components) {
                if (component.duration) {
                    // This component has a duration (i.e. a video)
                    // It plays out it's full duration, so we cannot change this slide time
                    // Remove it from the calculation
                    //Video fades whilst playing
                    millisecondsToDivide -= (BDuration.fromJson(component.duration).getMilliseconds() - fadeMs - fadeMs)
                } else {
                    // It does not have a duration, so is in the mix for having a slice of time allocated
                    nonDurationSlideCount += 1
                }
            }
            // Divvy up the time
            let millisecondsPerSlide = millisecondsToDivide / nonDurationSlideCount

            return millisecondsPerSlide
        }
    }

    useEffect(() => {
        if (visual?.autoSlideDuration) {
            const slideDuration = calculateSlideDuration(visual)
            if (slideDuration !== null) {
                debouncedSetSlideDuration(slideDuration)
            }
        }
    }, [visual?.autoSlideDuration, visual?.components, musicDurationMillis])

    const musicSetVisual = (visual) => {
        setVisual(visual)
    }

    if (!visual) {
        return (
            <Loading
                cover
                coverCenter
            />
        )
    }

    const { schema: visualEditSchema, uiSchema: visualEditUiSchema } = getSchemasForVisualEdit(visual, setAutoSlideDuration, mode)
    const {
        imageCount,
        videoCount,
        exceededImageCount,
        exceededVideoCount,
        maxTotal,
        typesCanStillAdd: addTypes,
    } = getVisualImageAndVideoCounts(visual)

    const uploadLabelParts = []
    const exceeded = []
    if (exceededImageCount > 0) {
        exceeded.push(`${exceededImageCount} image${maybeS(exceededImageCount)}`)
    }
    if (exceededVideoCount) {
        exceeded.push(`${exceededVideoCount} video${maybeS(exceededVideoCount)}`)
    }
    if (visual.maxImages > 0) {
        uploadLabelParts.push('image' + maybeS(visual.maxImages))
    }
    if (visual.maxVideos > 0) {
        uploadLabelParts.push('video' + maybeS(visual.maxVideos))
    }

    const addMultiple = maxTotal > 1

    let gridReadyOnly;

    if (visual.manuallyCreatedVisual) {
        gridReadyOnly = true
    } else if (isAdmin) {
        gridReadyOnly = false
    } else if (requestInProgress) {
        gridReadyOnly = true
    } else if (visual.requestStatus === "complete") {
        gridReadyOnly = true
    } else {
        gridReadyOnly = false
    }

    let showPreview = false;
    if (isAdmin) {
        showPreview = true;
    } else if (visual.requestStatus === "complete" || visual.requestStatus === "failed") {
        showPreview = true;
    } else if (mode === "full" && !visual.requestStatus) {
        showPreview = true;
    }

    let showSubmit = true;
    if (mode === "restricted" && (requestInProgress || visual.requestStatus === "complete")) {
        showSubmit = false;
    } else if (mode === "full") {
        if (visual.requestStatus === "processing") {
            showSubmit = false;
        }
    }

    let musicReadyOnly = true;
    if (isAdmin) {
        musicReadyOnly = false;
    } else if (visual.requestStatus === "complete" || visual.requestStatus === "failed") {
        musicReadyOnly = true;
    } else if (!visual.requestStatus) {
        musicReadyOnly = false;
    }

    const index = findIndex(visuals, v => v.id === visual.id)
    
    return (
        <div className={getBemClasses('visual-selection', templateAspectRatio.replace('/', '-'))}>
            <div>
                <Link to={to(`visuals/`)}>
                    Goto Visuals overview
                </Link>
                <h2>Visual {index + 1} of {visuals.length}</h2>
                <Description visual={visual} mode={mode} />
            </div>
            <div className="cols cols--spaced">
                {
                    (!requestInProgress || isAdmin) && <div style={{marginTop: 50}}>
                        <Form
                            theme="wesley"
                            jsonSchema={visualEditSchema}
                            uiSchema={visualEditUiSchema}
                            widgets={{
                                text: TextBlurWidget,
                                textarea: TextareaBlurWidget
                            }}
                            fields={fields}
                            value={visual}
                            onChange={data => updateFromForm(data.formData)}
                        />
                    </div>
                }
                <div>
                    {
                        showSubmit && <button disabled={exceeded.length > 0} onClick={() => navigate(to(`visuals/${visual.id}/${mode === "restricted" ? 'submit' : 'request-assistance'}`))} className='btn btn--long' style={{ marginLeft: "auto", display: "block", marginBottom: "10px" }}>{mode === "restricted" ? "Submit for compilation" : "Request assistance"}</button>
                    }
                    {
                        showPreview && <div
                            className="rounded-box pad"
                            style={{ marginBottom: 20 }}
                        >
                            <div className="cols cols--center">
                                <h3>Preview</h3>
                                {
                                    !visual.manuallyCreatedVisual && <Toggle
                                        checked={showInRoom}
                                        onChange={checked => setShowInRoom(checked)}
                                        label="Show in example room"
                                        className="cols__right"
                                    />
                                }
                            </div>
                            <VisualPreview
                                eventId={eventId}
                                visual={visual}
                                aspectRatio={event.room.aspectRatio}
                                showInRoom={showInRoom}
                                onShowInRoomChanged={showInRoom => setShowInRoom(showInRoom)}
                            />
                        </div>
                    }
                </div>
            </div>
            <div className="rounded-box pad relative">
                <div className="cols cols--center">
                    <h3>Upload {uploadLabelParts.join(' and ')}</h3>
                    {(visual.maxImages > 0 || visual.maxVideos > 0) && (
                        <div className="cols__right">
                            <div className="cols cols--spaced">
                                <Uploads visualId={visual.id} />
                                <button disabled={isEmpty(selectedComponents)} className="btn" onClick={removeSelectedComponents}>Delete</button>
                                {additionalRequiredMusicMillis > 5000 && (
                                    <div className={classnames([
                                        'pad',
                                        getBemClasses('rounded-box', { warning: true }),
                                    ])}>
                                        You need to add {formatMillisAsMinutesAndSeconds(additionalRequiredMusicMillis)} more music or reduce the slide time
                                    </div>
                                )}
                                {visual.maxImages > 0 && (
                                    <div className={classnames([
                                        'pad',
                                        getBemClasses('rounded-box', { warning: exceededImageCount > 0 }),
                                    ])}>
                                        {imageCount} / {visual.maxImages} image{maybeS(visual.maxImages)}
                                    </div>
                                )}
                                {visual.maxVideos > 0 && (
                                    <div className={classnames([
                                        'pad',
                                        getBemClasses('rounded-box', { warning: videoCount > visual.maxVideos }),
                                    ])}>
                                        {videoCount} / {visual.maxVideos} video{maybeS(visual.maxVideos)}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {exceeded.length > 0 && (
                    <div
                        className={classnames([
                            getBemClasses('rounded-box', 'warning'),
                            'pad',
                        ])}
                        style={{ marginBottom: 20 }}
                    >
                        You have uploaded too many files!&nbsp;
                        <strong>Please remove {exceeded.join(' and ')}</strong>
                    </div>
                )}
                <VisualComponentGrid
                    disableEdit={mode === "restricted"}
                    template={visual.template.projectId}
                    components={visual.components}
                    addTypes={addTypes}
                    addMultiple={addMultiple}
                    onAdd={onComponentsAdd}
                    onChange={onComponentChange}
                    onRemove={onComponentRemove}
                    aspectRatio={event.room.aspectRatio}
                    readOnly={gridReadyOnly}
                    selectedComponents={selectedComponents}
                    onComponentSelected={onComponentSelected}

                />
                <Cover loading center zTop enabled={isUpdatingTemplate}>
                    Updating template
                </Cover>
            </div>
            {visual.hasMusic && (
                <MusicSelectionForVisual
                    readOnly={musicReadyOnly}
                    visual={visual}
                    setVisual={musicSetVisual}
                />
            )}
            {modal}
        </div>
    )
}

function Description({ visual, mode }: { visual: SimpleEvents.Visual, mode: "full" | "restricted" }) {
    const music = 'Select the perfect soundtrack for your slideshow by selecting your preferred tracks from the tool below.'
    const order = 'Please upload your photos and videos in the sequence you prefer for the slideshow.'
    if (visual.requestStatus === "processing") {
        return <p>Your visual tribute is being compiled by the Wesley Visuals Team.</p>
    }
    if (mode === "full") {
        return <>
            <p>Add your own photos and videos to your schedule using our visual creation tool. {order}</p>
            <p>Choose a template, then set custom text captions and apply cropping and filters to your photos and videos.</p>
            {
                visual.hasMusic ? <p>{music}</p> : null
            }
        </>
    } else if (mode === "restricted") {
        if (visual.requestStatus === "complete") {
            return <p>Your visual tribute has been completed by the Wesley Visuals Team.</p>
        } else {
            return <>
                <p>Add your own photos and videos to your schedule using our visual upload tool. {order}</p>
                {
                    visual.hasMusic ? <p>{music}</p> : null
                }
            </>
        }
    } else {
        return null;
    }
}

function maybeS(count: number) {
    return count !== 1 ? 's' : ''
}

function SlideTimeField(props: FieldProps) {
    const { id, required, uiSchema, idSchema, formData } = props
    const options = uiSchema["ui:options"]
    const supportsAutoSlideDuration = options.supportsAutoSlideDuration as boolean
    const autoSlideDuration = options.autoSlideDuration as boolean
    const setAutoSlideDuration = options.setAutoSlideDuration as (value: boolean) => void
    return (
        <>
            <label
                className="control-label"
                htmlFor={String(id)}
            >
                <div className="cols">
                    <span>Slide time (s){required && <span className="required">*</span>}</span>
                    {supportsAutoSlideDuration && (
                        <Toggle
                            checked={autoSlideDuration}
                            onChange={value => setAutoSlideDuration(value)}
                            label="Auto"
                            className="cols__right"
                        />
                    )}
                </div>
            </label>
            <BDurationUpDownWidget
                {...props}
                id={idSchema.$id}
                value={formData}
                options={options}
                disabled={supportsAutoSlideDuration && autoSlideDuration}
                style={{ visibility: autoSlideDuration ? 'hidden' : 'visible' }}
            />
        </>
    )
}

function Uploads(props: { visualId: string }) {
    const uploads = useVisualComponentsUploadStatus(props.visualId)
    const { removeUpload, retryUpload } = useVisualComponentUploadsContext()
    const totalUploadProgress = uploads.length > 0 ? sumBy(uploads, "progress") / uploads.length : 0
    const [opened, setOpened] = useState(false);
    const anyFailed = filter(uploads, v => v.status === "failed").length > 0

    if (uploads.length === 0) {
        return null
    } else {
        return <Popover
            opened={opened}
            onClose={() => setOpened(false)}
            width={350}
            position="bottom"
            withArrow
            zIndex={1000}
        >
            <Popover.Target>
                <button className={classnames(["visual-selection__uploading", getBemClasses('rounded-box')])} onClick={() => setOpened(!opened)}>
                    Uploading <RingProgress sections={[{ value: totalUploadProgress, color: anyFailed ? "red" : "#8357e1" }]} thickness={3} roundCaps size={30} label={anyFailed && <Text color='red'>!</Text>} />
                </button>

            </Popover.Target>
            <Popover.Dropdown p={0} style={{ border: 0 }}>
                <div className='rounded-box pad' style={{ maxHeight: 450, overflowY: "scroll" }}>
                    {
                        map(uploads, ({ progress, status, uploadId, file }, idx) => {
                            let data = null;
                            if (status === "uploading") {
                                data = { color: "#8357e1", value: progress, label: `${progress}%` }
                            } else if (status === "failed") {
                                data = { color: "red", value: 100, label: "Upload failed" }
                            }

                            return [
                                <Stack gap={5} mb={10}>
                                    <Flex justify={"space-between"} gap={10} align={"center"}>
                                        <span style={{ overflowWrap: "anywhere" }}>{file?.name}</span>
                                        {
                                            status === "failed" && <div>
                                                <button onClick={() => retryUpload(uploadId)} className='action action--unloop' />
                                                <button onClick={() => removeUpload(uploadId)} className='action action--remove' />
                                            </div>
                                        }
                                    </Flex>
                                    {data ? <Progress.Root size={"xl"}>
                                        <Progress.Section value={data.value} color={data.color}>
                                            <Progress.Label>{data.label}</Progress.Label>
                                        </Progress.Section>
                                    </Progress.Root> : null}
                                </Stack>,
                                idx < uploads.length - 1 && <Divider mt={10} mb={10} />
                            ]
                        })
                    }
                </div>
            </Popover.Dropdown>
        </Popover>
    }
}