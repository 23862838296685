import * as React from 'react'
import Modal from '../Modal'
import { VisualTemplateCarouselWidget } from './VisualTemplateCarousel'
import StringField from '@rjsf/core/lib/components/fields/StringField'
import { JSONSchema7 } from 'json-schema'
import { useState } from 'react'
import { useVisualTemplates } from 'common/frontend/state'
import { TextBlurWidget, TextareaBlurWidget } from '../customWidgets'
import { Form } from 'common/frontend/components/Form'
import {ModalProps} from "@mantine/core"

type VisualAddModalProps = {
    onSubmit: (formData) => void
}

const schema: JSONSchema7 = {
    type: 'object',
    properties: {
        name: {
            type: 'string',
            title: 'Name'
        },
        template: {
            type: 'object',
            properties: {
                id: {
                    type: "string"
                }
            }
        }
    }
}

const uiSchema = {
    name: {
        "ui:autofocus": true
    },
    template: {
        "ui:title": 'Template',
        "ui:field": StringField,
        "ui:widget": VisualTemplateCarouselWidget
    }
}

export function VisualAddModal(props: VisualAddModalProps & Omit<ModalProps, 'className'>) {
    const { onClose, onSubmit } = props
    const options = useVisualTemplates()
    const [formData, setFormData] = useState({name: "New visual", template: options[0]})
    return <Modal {...props} className="visual-add-modal">
        <h3>Add visual</h3>
        <Form
            theme="wesley"
            jsonSchema={schema}
            uiSchema={uiSchema}
            widgets={{
                text: TextBlurWidget,
                textarea: TextareaBlurWidget
            }}
            value={formData}
            onChange={data => setFormData(data.formData)}
        />
        <div className="visual-add-modal__actions">
            <button
                className="btn"
                onClick={() => onSubmit(formData)}
            >
                Done
            </button>
            <button className="btn btn--secondary" onClick={onClose}>
                Cancel
            </button>
        </div>
    </Modal>
}
