import { Form } from 'common/frontend/components/Form'
import { JSONSchema7 } from 'json-schema'
import { SimpleEvents } from 'common/types/eventService'
import * as React from 'react'
import { useState } from 'react'
import { useStreamingViewers } from '../state'
import { toast } from 'react-toastify'
import 'common/frontend/components/InfoBox.scss'
import {validate} from "email-validator"
import { TextBlurWidget } from 'common/frontend/components/customWidgets'
import { stopAndPrevent } from '../utils'
const schema: JSONSchema7 = {
    type: 'object',
    required: ['name', 'email'],
    properties: {
        name: {
            type: 'string',
            title: 'Name'
        },
        email: {
            type: 'string',
            title: 'Email'
        }
    }
}

const uiSchema = {
    name: {
        'ui:autofocus': true,
    },
}

const initialInviteData = {
    name: '',
    email: ''
}

export interface StreamingViewerInviteProps {
    onInvite: (viewer: SimpleEvents.StreamingViewerData) => Promise<void>
    onDone: () => void
}

export default function StreamingViewerInvite({
    onInvite,
    onDone,
}: StreamingViewerInviteProps) {
    const savedStreamingViewers = useStreamingViewers()

    const [viewerData, setInviteData] = useState<SimpleEvents.StreamingViewerData>(initialInviteData)

    async function onAdd(viewerData: SimpleEvents.StreamingViewerData) {
        // Don't allow duplicate emails
        if (savedStreamingViewers.find(viewer => viewer.email === viewerData.email)) {
            toast(`You have already invited ${viewerData.email}`)
            return
        }
        await onInvite(viewerData)

        // That was done! Reset the form, so they can add another right away
        setInviteData(initialInviteData)

        // Focus the name field
        document.getElementById('root_name')?.focus()
    }

    const validateForm = (formData, errors) => {
        if(formData.email && !validate(formData.email)){
            errors.email.addError('Invalid email')
        }
        if(formData.name && !formData.name.trim()){
            errors.email.addError('Invalid name')
        }
        return errors;
    }

    return (
        <div className="add-items">
            <div className="cols cols--center">
                <h2>Invite viewer</h2>
            </div>
            <Form
                theme="wesley"
                jsonSchema={schema}
                uiSchema={uiSchema}
                value={viewerData}
                onChange={data => setInviteData(data.formData)}
                onSubmit={() => onAdd(viewerData)}
                customValidate={validateForm}
            >
                <div className="btn-bar">
                    <button className="btn">Send invitation</button>
                    <button className="btn btn--secondary" onClick={stopAndPrevent(() => onDone())}>Done</button>
                </div>
            </Form>
        </div>
    )
}