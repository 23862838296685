import { Form } from 'common/frontend/components/Form'
import { useRequestTrackAction } from 'common/frontend/actions'
import { TrackRequest, trackRequestFieldNames } from 'common/types/commonTypes'
import { JSONSchema7 } from 'json-schema'
import * as React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useRefreshTrackRequestJobs } from '../state'

const schema: JSONSchema7 = {
    type: 'object',
    required: ['track','artist'],
    properties: {
        track: {
            type: "string",
            title: trackRequestFieldNames.track
        },
        artist: {
            type: "string",
            title: trackRequestFieldNames.artist,
        },
        composer: {
            type: "string",
            title: trackRequestFieldNames.composer,
        },
        conductor: {
            type: "string",
            title: trackRequestFieldNames.conductor,
        },
        choir: {
            type: "string",
            title: trackRequestFieldNames.choir,
        },
        soloist: {
            type: "string",
            title: trackRequestFieldNames.soloist,
        },
        details: {
            type: "string",
            title: trackRequestFieldNames.details,
        }
    }
}

const uiSchema = {
    details: {
        "ui:widget": "textarea",
        "ui:options": {
            rows: 6,
        },
    }
}

const initialTrackRequest: TrackRequest = {
    type: 'missing',
    artist: '',
    composer: '',
    track: '',
    choir: '',
    conductor: '',
    soloist: '',
    details: ''
}

export default function MusicRequestPage() {
    const navigate = useNavigate()
    const [trackRequest, setTrackRequest] = useState(initialTrackRequest)
    const [requestTrack, isPending] = useRequestTrackAction()
    const refresh = useRefreshTrackRequestJobs()

    async function onSubmit() {
        await requestTrack({ trackRequest })
        toast('Your request was made')
        setTrackRequest(initialTrackRequest)
        navigate(-1) // go back, as we might have come from music page or visual edit page
        refresh()
    }

    return (
        <div className="music-request-page">
            <section className="section--grey cols container-padding">
                <div style={{ maxWidth: 600 }}>
                    <h3>Music request</h3>
                    <p>Can’t find the track you are looking for? Fill in as much information as you can below and we will try and source it for you.</p>
                    <Form
                        theme="wesley"
                        jsonSchema={schema}
                        uiSchema={uiSchema}
                        value={trackRequest}
                        onChange={data => setTrackRequest(data.formData)}
                        onSubmit={() => onSubmit()}
                    >
                        <div className="cols cols--spaced" style={{ alignItems: 'center' }}>
                            <button
                                type="submit"
                                className="btn btn--long"
                            >
                                Submit request
                            </button>
                            <button onClick={() => navigate(-1)} className="btn btn--secondary">
                                Cancel
                            </button>
                        </div>
                    </Form>
                </div>
            </section>
        </div>
    )
}