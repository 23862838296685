import { BDuration } from '@busby/esb'
import { TrackInfo } from 'common/types/commonTypes'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useAudioPlayer } from '../audio'
import { formatMillisAsMinutesAndSeconds } from '../formatters'
import { InOutMarkers } from '../model'
import { getBemClasses, wesleyDebugNamespace } from '../utils'
import Cover from './Cover'
import SliderBox from './SliderBox'
import './TrackEdit.scss'

const debug = wesleyDebugNamespace.extend('track-edit')

export interface TrackPreviewProps {
    id: string
    src: string
    track: TrackInfo
    markers?: InOutMarkers
    onMarkersChange?: (markers: InOutMarkers) => void
    onRequestClose?: () => void
}

export default function TrackEdit({ id, src, track, markers, onMarkersChange, onRequestClose }: TrackPreviewProps) {
    const {
        isActive,
        isPlaying,
        isLoading,
        position: audioPosition,
        duration: audioDuration,
        setPosition,
        playPause,
        setPositionToStart
    } = useAudioPlayer({ id, src, markers })

    // These don't get updated when someone in another session changes the markers
    // This is probably a good idea, so people don't clobber each others sessions..
    const [markersUpdate, setMarkersUpdate] = useState(markers ?? [null, null] as InOutMarkers)

    const trackDuration = BDuration.fromJson(track.duration).getFrames()

    const position = isActive ? audioPosition : (markers?.[0] || 0)
    const duration = isActive ? audioDuration : trackDuration

    const inTime = markersUpdate?.[0] ?? 0
    const outTime = markersUpdate?.[1] ?? duration

    useEffect(() => {
        if (audioDuration && trackDuration) {
            debug('WARNING audio duration does not match track duration: %sms vs %sms for %o', audioDuration, trackDuration, track)
        }
    }, [audioDuration, trackDuration])

    return (
        <div className="track-edit relative" style={{ width: 380 }}>
            <Cover center translucent loading enabled={isLoading} />
            {onRequestClose && (
                <div style={{ textAlign: 'right' }}>
                    <button
                        className="action action--close"
                        onClick={() => onRequestClose()}
                    />
                </div>
            )}
            <div className="cols cols--center cols--spaced-small">
                <div className="numeric" style={{ width: 42, textAlign: 'right' }}>{formatMillisAsMinutesAndSeconds(inTime)}</div>
                <div className="col-grow">
                    <SliderBox
                        value={position}
                        max={duration}
                        step={1}
                        onUpdate={position => setPosition(position)}
                        onChange={() => {}}
                        markers={markersUpdate}
                        onMarkersChange={onMarkersChange}
                        onMarkersUpdate={setMarkersUpdate}
                        style={{
                            '--height': '20px'
                        } as React.CSSProperties}
                    />
                </div>
                <div className="numeric" style={{ width: 42 }}>{formatMillisAsMinutesAndSeconds(outTime)}</div>
            </div>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {false && (
                    // could add a volume control...
                    <div style={{ width: 'fit-content', position: 'absolute', left: 0, textAlign: 'right' }}>
                        <SliderBox
                            min={0}
                            max={1}
                            value={5}
                            step={0.01}
                            sliderMode={1}
                            onUpdate={() => {}}
                            onChange={() => {}}
                            toggleAction="muted"
                            onToggle={() => {}}
                            style={{
                                border: 'none',
                                boxShadow: 'none',
                                '--padding': '9px',
                                '--handle__oversize': '3px',
                                '--track__color': 'var(--main-color)',
                                '--width': '120px'
                            } as React.CSSProperties}
                        />
                    </div>
                )}
                <div className="numeric" style={{ width: 42, position: 'absolute', right: 0 }}>
                    {isActive ? formatMillisAsMinutesAndSeconds(position) : '-:--'}
                </div>
                <button
                    className={getBemClasses('action', 'audio-previous')}
                    onClick={() => setPositionToStart()}
                    style={{
                        '--size': '26px'
                    } as React.CSSProperties}
                />
                <button
                    className={getBemClasses('action', isPlaying ? 'pause' : 'play')}
                    onClick={() => playPause()}
                    style={{
                        '--size': '40px'
                    } as React.CSSProperties}
                />
            </div>
        </div>
    )
}