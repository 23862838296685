import { useMusicSearchResults } from 'common/frontend/state'
import { wesleyDebugNamespace } from 'common/frontend/utils'
import * as React from 'react'
import DraggableNewTracks, { NewTrackTarget } from './DraggableNewTracks'
import { MusicSearchButtons } from './MusicSearchButtons'
import { Form } from 'common/frontend/components/Form'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
const debug = wesleyDebugNamespace.extend('music-search-results')

export interface MusicSearchResultsProps {
    target: NewTrackTarget
}

const schema = {
    type: "object",
    properties: {
        query: {
            type: "string"
        }
    }
}

const uiSchema = {
    query: {
        "ui:label": false,
        "ui:placeholder": "Search music",
        "ui:autofocus": true,
        "ui:options": {hideTitle: true},
        classNames: "search-input"
    }
}

export default function MusicSearch({ target }: MusicSearchResultsProps) {
    const [query, setQuery] = useState('')
    const { nextPage, prevPage, hasNext, hasPrev, allResults, results } = useMusicSearchResults({ query })
    const [queryFormData, setQueryFormData] = useState({ query: '' })

    const debouncedSetQuery = useCallback(debounce(setQuery, 300), [])

    useEffect(() => {
        debouncedSetQuery(queryFormData.query)
    }, [queryFormData])

    return (
        <div>
            <Form
                theme="wesley"
                jsonSchema={schema}
                uiSchema={uiSchema}
                value={queryFormData}
                onChange={data => setQueryFormData(data.formData)}
                children={[]}
            />
            {query && allResults?.length === 0 && (
                <p>No results found</p>
            )}
            {
                results && [
                    <DraggableNewTracks enableTrackRequest trackInfos={results} target={target} />,
                    <MusicSearchButtons {...{ hasNext, hasPrev, nextPage, prevPage }} />
                ]
            }
        </div>
    );
}
