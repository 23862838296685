import * as React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useEventPathTo } from './eventPaths'
import { useScreenSize } from './hooks'
import LandingPage from './pages/LandingPage'
import MusicPage from './pages/MusicPage'
import MissingMusicRequestPage from './pages/MissingMusicRequestPage'
import MusicRequestPage from './pages/MusicRequestPage'
import ReviewPage from './pages/ReviewPage'
import SchedulingPage from './pages/SchedulingPage'
import StreamingPage from './pages/StreamingPage'
import SubmittedPage from './pages/SubmittedPage'
import VisualEditPage from './pages/VisualEditPage'
import VisualsPage from './pages/VisualsPage'
import { useEvent, useIsEventSubmitted, useIsEventCutOffPassed, useIsAdmin } from './state'
import VisualRenderedPlayer from './components/visuals/VisualRenderedPlayer'
import VisualTributeRequestPage from './pages/VisualTributeRequestPage'
import { DateTime } from 'luxon'
import CutoffPage from './pages/CutOffPage'

function RedirectIfSubmitted({ children }) {
    const event = useEvent()
    const isSubmitted = useIsEventSubmitted()
    const to = useEventPathTo()
    if (event && isSubmitted) {
        return <Navigate to={to('submitted')} replace />
    }
    return children
}

function RedirectIfNotSubmitted({ children }) {
    const event = useEvent()
    const isSubmitted = useIsEventSubmitted()
    const to = useEventPathTo()
    if (event && !isSubmitted) {
        return <Navigate to={to('landing')} replace />
    }
    return children
}

function RedirectIfCutOffPassed({ children }) {
    const event = useEvent()
    const isSubmitted = useIsEventSubmitted()
    const cutOffPassed = useIsEventCutOffPassed()
    const to = useEventPathTo()
    const isAdmin = useIsAdmin()
    
    if (event && cutOffPassed && !isSubmitted && !isAdmin){
        return <Navigate to={to('locked')} replace />
    }
    return children
}

function RedirectIfCutOffNotPassed({ children }) {
    const event = useEvent()
    const cutOffPassed = useIsEventCutOffPassed()
    const isSubmitted = useIsEventSubmitted()
    const to = useEventPathTo()
    const isAdmin = useIsAdmin()
    
    if (event && (!cutOffPassed || isSubmitted || isAdmin)){
        return <Navigate to={to('landing')} replace />
    }
    return children
}

function RedirectIfSmallScreen({ children, to }) {
    const pathTo = useEventPathTo()
    const { isSmallScreen } = useScreenSize()
    if (isSmallScreen) {
        return <Navigate to={pathTo(to)} />
    }
    return children
}

export const eventRoutes = [
    {
        path: 'visual-render',
        children: [{
            path: ':visualId',
            element: <VisualRenderedPlayer />
        }]
    },
    {
        path: '',
        element: (
            <RedirectIfSubmitted>
                <RedirectIfCutOffPassed>
                    <Outlet />
                </RedirectIfCutOffPassed>
            </RedirectIfSubmitted>
        ),
        children: [
            {
                path: '',
                element: <Navigate to="landing" replace />,
            },
            {
                path: 'landing',
                element: <LandingPage />
            },
            {
                path: 'music',
                element: <MusicPage />
            },
            {
                path: 'music/request',
                element: <MissingMusicRequestPage />
            },
            {
                path: 'music/track/:trackId/request',
                element: <MusicRequestPage />
            },
            {
                path: 'visuals',
                children: [
                    {
                        path: '',
                        element: <VisualsPage />
                    },
                    {
                        path: ':visualId/submit',
                        element: <VisualTributeRequestPage />
                    },
                    {
                        path: ':visualId/request-assistance',
                        element: <VisualTributeRequestPage />
                    },
                    {
                        path: ':visualId',
                        element: (
                            <RedirectIfSmallScreen to="visuals">
                                <VisualEditPage />
                            </RedirectIfSmallScreen>
                        )
                    }
                ]
            },
            {
                path: 'scheduling',
                element: <SchedulingPage />
            },
            {
                path: 'webcast',
                element: <StreamingPage />
            },
            {
                path: 'review',
                element: <ReviewPage />
            },
        ]
    },
    {
        path: 'submitted',
        element: (
            <RedirectIfNotSubmitted>
                <SubmittedPage />
            </RedirectIfNotSubmitted>
        )
    },
    {
        path: 'locked',
        element: (
            <RedirectIfCutOffNotPassed>
                <CutoffPage />
            </RedirectIfCutOffNotPassed>
        )
    }
]
