import { Filters, ResourceOrderBy, ResourceProperties, ResourceQuery, SimpleResource } from '@busby/esb'
import { configState, useIsAdmin } from 'common/frontend/state'
import { wesleyDebugNamespace } from 'common/frontend/utils'
import { EventResource, EventServiceSubscriber, ProGetEventResult, SimpleEvents, simpleEvents } from 'common/types/eventService'
import { Events, events } from 'common/types/events'
import { OptionalIdDeep } from 'common/types/typeUtils'
import { fixEventDateString, resultOrThrow } from 'common/universal/universalUtils'
import { produce } from 'immer'
import { filter, find } from 'lodash'
import { atom, atomFamily, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil'

const debug = wesleyDebugNamespace.extend('state')

const organisationState = selectorFamily<SimpleEvents.ProfessionalOrganisation, { organisationId }>({
    key: 'ProOrganisationState',
    get: ({ organisationId }) => async ({ get }) => {
        const config = get(configState)
        if (!organisationId || !config) return
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        try {
            const { organisation } = await resultOrThrow(subscriber.control.proGetOrganisation({
                organisationId,
            }))
            return organisation
        } catch (error) {
            // It's ok to not have access
            return null
        }
    }
})

const selectedOrganisationIdState = atom<string | undefined>({
    key: 'ProSelectedOrganisationId',
    default: undefined
})

export function useOrganisation(organisationId: string) {
    return useRecoilValue(organisationState({ organisationId }))
}

export function useSelectedOrganisation() {
    const organisationId = useSelectedOrganisationId()
    return useRecoilValue(organisationState({ organisationId }))
}

export function useSetSelectedOrganisationId() {
    return useSetRecoilState(selectedOrganisationIdState)
}

export function useSelectedOrganisationId() {
    return useRecoilValue(selectedOrganisationIdState)
}

const eventDetailsState = atomFamily<ProGetEventResult, string | undefined>({
    key: 'ProEventDetailsState',
    effects: eventId => [
        ({ setSelf, getPromise }) => {
            setSelf(fetch())
            async function fetch() {
                const config = await getPromise(configState)
                if (!eventId) return
                const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                const result = await resultOrThrow(subscriber.control.proGetEvent({ eventId }))
                return produce(result, draft => {
                    draft.event.date = fixEventDateString(draft.event.date)
                    draft.event.cutOff = fixEventDateString(draft.event.cutOff)
                })
            }
        }
    ]
})

export function useProEventDetails(eventId: string | undefined) {
    return useRecoilValue(eventDetailsState(eventId))
}

export function useSetProEventDetails(eventId: string) {
    return useSetRecoilState(eventDetailsState(eventId))
}

const venuesState = atom<SimpleEvents.Venue[]>({
    key: 'ProVenues',
    effects: [
        ({ setSelf, getPromise }) => {
            setSelf(fetch())
            async function fetch() {
                const organisationId = await getPromise(selectedOrganisationIdState)
                if (!organisationId) return
                const config = await getPromise(configState)
                const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                const orderBy: ResourceOrderBy<Events.Venue> = {
                    field: 'name',
                    order: 'asc'
                }
                const { resources: venues } = await resultOrThrow(subscriber.control.proQueryResources({
                    organisationId,
                    resourceType: 'venue',
                    query: {},
                    orderBy: orderBy as any,
                }))
                debug('got venues %O', venues)
                return venues as SimpleEvents.Venue[]
            }
        }
    ]
})

export function useVenues() {
    return useRecoilValue(venuesState)
}

export type ResourcesStateParams = {
    organisationId: string
    resourceType: keyof events
    orderBy?: string
    query?: any // TODO: typed!
    filters?: any // TODO: typed!
}

const resourcesState = selectorFamily<SimpleResource<EventResource>[], ResourcesStateParams>({
    key: 'ProResourcesState',
    get: ({ organisationId, resourceType, orderBy = undefined, query = {}, filters = {} }) => async ({ get }) => {
        const config = get(configState)
        if (!config) return
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        const { resources } = await resultOrThrow(subscriber.control.proQueryResources({
            organisationId,
            resourceType,
            query,
            filters,
            orderBy: (orderBy ? { field: orderBy, order: 'asc' } : []) as any,
        }))
        return resources
    }
})

export interface UseProResourceOptions<RT extends keyof events> {
    orderBy?: string
    query?: ResourceQuery<events[RT], events, RT>
    filters?: Partial<Filters<events[RT], events>>
}

export function useProResource<RT extends keyof events>(organisationId: string, resourceType: RT, options: UseProResourceOptions<RT> = {}): simpleEvents[RT][] {
    return useRecoilValue(resourcesState({ organisationId, resourceType, ...options })) as simpleEvents[RT][]
}

export type ResourcesPropertiesStateParams = {
    resourceType: keyof events
}

const resourcePropertiesState = selectorFamily<ResourceProperties, ResourcesPropertiesStateParams>({
    key: 'ProResourcePropertiesState',
    get: ({ resourceType }) => async ({ get }) => {
        const config = get(configState)
        if (!config) return
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        const { properties } = await resultOrThrow(subscriber.control.proGetResourceProperties({
            resourceType,
        }))
        return properties
    }
})

export function useProResourceProperties<RT extends keyof events>(resourceType: RT): ResourceProperties {
    return useRecoilValue(resourcePropertiesState({ resourceType }))
}

export function useTopLevelOptions(organisationId: string) {
    return useProResource(organisationId, 'option', {
        query: {
            and: [
                { field: 'isTopLevel', comparator: 'eq', value: true },
                { field: 'deletedAt', comparator: 'eq', value: null },
            ]
        },
        filters: {
            resolveLinkTypes: [
                'option',
                'capability',
            ]
        }
    })
}

/**
 * Detirmine which organisation id we use when querying for resources
 */
export function useOrganisationIdForQuery(event?: OptionalIdDeep<SimpleEvents.Event>) {
    // Optimal!
    // The direct organiser of the event
    const eventOrganisationId = event?.organisation?.id
    const eventOrganisation = useOrganisation(eventOrganisationId)

    // Second best
    // The organisation that manages the venue the event is held in
    const eventVenueOrganisationId = event?.venue?.venueOrganisation?.id
    const eventVenueOrganisation = useOrganisation(eventVenueOrganisationId)

    if (eventOrganisation) return eventOrganisation.id
    if (eventVenueOrganisation) return eventVenueOrganisation.id

    // Otherwise nothing...
}

export const digitalSignageState = atom<any>({
    key: 'DigitalSignage',
    default: {}
})


export function useIsVenueUser() {
    const organisations = useUserOrganisations()
    return !!find(organisations, v => v.type === "venue")
}

const minEventDateState = atom<string>({
    key: 'MinEventDate',
    effects: [
        ({ setSelf, getPromise }) => {
            setSelf(fetch())
            async function fetch() {
                // const organisationId = await getPromise(selectedOrganisationIdState)
                // if (!organisationId) return
                const config = await getPromise(configState)
                const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)

                const { minDate } = await resultOrThrow(subscriber.control.proGetMinEventDate({}))

                return minDate
            }
        }
    ]
})

export function useMinEventDate() {
    return useRecoilValue(minEventDateState)
}

const userOrganisationsState = atom<SimpleEvents.ProfessionalOrganisation[]>({
    key: 'UserOrganisations',
    effects: [
        ({ setSelf, getPromise }) => {
            setSelf(fetch())
            async function fetch() {
                // const organisationId = await getPromise(selectedOrganisationIdState)
                // if (!organisationId) return
                const config = await getPromise(configState)
                const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
                
                const { organisations } = await resultOrThrow(subscriber.control.proQueryOrganisations({}))
                
                return organisations as SimpleEvents.ProfessionalOrganisation[]
            }
        }
    ]
})

export function useUserOrganisations() {
    const isAdmin = useIsAdmin()
    return !isAdmin ? useRecoilValue(userOrganisationsState) : undefined
}

export function useUserVenueOrganisations() {
    const isAdmin = useIsAdmin()
    const userOrganisations = useRecoilValue(userOrganisationsState)
    
    return !isAdmin ? filter(userOrganisations, v => v.type === "venue" ) : undefined
}