import axios from 'axios'
import { BDateTime, BDuration, BDurationJson } from '@busby/esb'
import { Size } from 'common/types/commonTypes'
import { Events } from 'common/types/events'
import { AdjustParams, ContentQuality, CropParams, SimpleEvents } from 'common/types/eventService'
import * as _ from 'lodash'
import { eventMediaPath } from '../utils'

export interface Position {
    left: number
    top: number
    width: number
    height: number
}

type DrawImageParams = {
    opacity?: number,
    position: Position
    filters?: AdjustParams
    crop?: CropParams
}


export interface DrawTemplateParams {
    backgroundImage: { location: string, filename: string }
    contentQuality: ContentQuality
    canvas: HTMLCanvasElement
    // templateConfig: Events.EventsVisualTemplateConfig
    templateParams?: Events.EventsVisualTemplateParams
    aspectRatio: "16/9" | "9/16"
}

export interface DrawComponentParams {
    eventId: string
    contentQuality: ContentQuality
    canvas: HTMLCanvasElement
    component: Omit<SimpleEvents.VisualComponent, 'order'>
    imagePosition?: Position
    aspectRatio: "16/9" | "9/16"
}



export function getScaledSize({ width, height }: Size, contentQuality: ContentQuality): Size {
    if (width && height && contentQuality === 'preview') {
        const ratio = width / height
        return {
            width: 800,
            height: 800 / ratio
        }
    }
    return { width, height }
}

export function createBlankImageDataURL(size: Size): string {
    const canvas = document.createElement('canvas')
    canvas.width = size.width
    canvas.height = size.height
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = 'rgba(0, 0, 0, 0)'
    ctx.fillRect(0, 0, size.width, size.height)
    return canvas.toDataURL()
}

export function parseAspectRatio(aspectRatio: string) {
    let result
    if (aspectRatio) {
        result = _.map(aspectRatio.split("/"), v => parseInt(v))
    }
    return result
}

export function getScaledWidth(aspectRatio: "16/9" | "9/16", height: number) {
    let width = 0;
    if (aspectRatio) {
        const [aspectRatioWidth, aspectRatioHeight] = parseAspectRatio(aspectRatio)
        width = height * (aspectRatioWidth / aspectRatioHeight)
    }
    return width
}

export function getScaledHeight(aspectRatio: "16/9" | "9/16", width: number) {
    let height = 0;
    if (aspectRatio) {
        const [aspectRatioWidth, aspectRatioHeight] = parseAspectRatio(aspectRatio)
        height = width * (aspectRatioHeight / aspectRatioWidth)
    }
    return height
}

const relativeBase = 120;
export function getRelativeWidth(aspectRatio: "16/9" | "9/16", width: number, canvas: HTMLCanvasElement) {
    let result = 0;
    if (aspectRatio) {
        const [aspectRatioWidth, aspectRatioHeight] = parseAspectRatio(aspectRatio)
        result = (width / (relativeBase * aspectRatioWidth) * canvas.width)
    }
    return result
}

export function getRelativeHeight(aspectRatio: "16/9" | "9/16", height: number, canvas: HTMLCanvasElement) {
    let result = 0;
    if (aspectRatio) {
        const [aspectRatioWidth, aspectRatioHeight] = parseAspectRatio(aspectRatio)
        result = (height / (relativeBase * aspectRatioHeight) * canvas.height)
    }
    return result
}


export type VisualComponentExtra = SimpleEvents.VisualComponent & {
    inTimecode: BDateTime
    duration: BDuration // TODO: this duration (maybe) conflicts with the one on visual component
    content: HTMLImageElement | HTMLVideoElement
}
