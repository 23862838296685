import * as classnames from 'classnames'
import { Updater } from 'common/frontend/model'
import { bem, getAspectRatioLabel, getBemClasses, isAspectRatioNumeric } from 'common/frontend/utils'
import { AdjustParams, AspectRatio, SimpleEvents } from 'common/types/eventService'
import { produce } from 'immer'
import * as React from 'react'
import { useState } from 'react'
import { useEventId } from '../../state'
import Modal from '../Modal'
import { VisualCropper } from './VisualCropper'
import {templates} from "./templates/templates"
import { TemplateDescription } from "./templates/templates"
import { Project } from '@motion-canvas/core'
import { filter, find, isEmpty, map } from 'lodash'
import { VisualPlayer } from './VisualPlayer'
import { BDuration } from "@busby/esb"
import { Form } from 'common/frontend/components/Form'
import {ModalProps} from "@mantine/core"
import { getVisualProject } from './VisualUtils'
// There is always a neutral value that leaves the image unchanged
// That is what the "defaultValue" here is
// See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/filter
const adjustDefaults: AdjustParams = {
    brightness: 1,
    saturate: 1,
    contrast: 1,
    sepia: 0,
    grayscale: 0
}

interface FilterPreset {
    label: string
    adjust: Partial<AdjustParams>
}

const filterPresets: FilterPreset[] = [
    {
        label: 'Original',
        adjust: {
            grayscale: 0,
            sepia: 0
        }
    },
    {
        label: 'B&W',
        adjust: {
            grayscale: 1,
            sepia: 0
        }
    },
    {
        label: 'Sepia',
        adjust: {
            grayscale: 0,
            sepia: 1
        }
    }
]

interface AdjustControlParams {
    label: string
    adjustKey: keyof AdjustParams
    min: number
    max: number
}

const adjustControls: AdjustControlParams[] = [
    {
        label: 'Brightness',
        adjustKey: 'brightness',
        min: 0.5,
        max: 1.5,
    },
    {
        label: 'Saturation',
        adjustKey: 'saturate',
        min: 0.5,
        max: 1.5,
    },
    {
        label: 'Contrast',
        adjustKey: 'contrast',
        min: 0.5,
        max: 1.5,
    }
]

export interface VisualEntryEditModalProps {
    component: SimpleEvents.VisualComponent
    onDone: (component: SimpleEvents.VisualComponent) => void
    template: string
    aspectRatio: "16/9" | "9/16"
}

export function VisualComponentEditModal({ component, onDone, template, aspectRatio, ...props }: VisualEntryEditModalProps & Omit<ModalProps, 'className'>) {

    function handleDone(event, updatedComponent: SimpleEvents.VisualComponent) {
        props.onClose()
        onDone(updatedComponent)
    }

    return (
        <Modal
            {...props}
            closeOnEscape={false}
            closeOnClickOutside={false}
            modalBodyClassName="visual-entry-edit-modal"
        >
            {component && (
                <VisualComponentEdit
                    aspectRatio={aspectRatio}
                    template={template}
                    component={component}
                    onCancel={event => props.onClose()}
                    onDone={(event, component) => handleDone(event, component)}
                />
            )}
        </Modal>
    )
}

type EditMode = "view" | "crop" | "adjust" | "filters" | "template" | "caption"

interface VisualEntryEditProps {
    component: SimpleEvents.VisualComponent
    onCancel?: (event: React.MouseEvent | React.KeyboardEvent) => void
    onDone?: (event: React.MouseEvent | React.KeyboardEvent, component: SimpleEvents.VisualComponent) => void
    template: string
    aspectRatio: "16/9" | "9/16"
}

export default function VisualComponentEdit({ component: savedComponent, onCancel, onDone, template, aspectRatio }: VisualEntryEditProps) {
    const eventId = useEventId()
    const project: Project = getVisualProject(aspectRatio, template)
    const templateDescription: TemplateDescription = project.variables.templateDescription as TemplateDescription
    const [component, setComponent] = useState(savedComponent)
    const hasMedia = component.type != "text" as string;

    const getModeButtons = () => {
        const slideTypes = filter(templateDescription.slideTypes, v => {
            return (hasMedia && !isEmpty(v.media)) || (!hasMedia && isEmpty(v.media))
        })
    
        let modeButtons = [] as { editMode: EditMode; label: string }[]

        const selectedSlideType = find(templateDescription.slideTypes, v => v.type === component.slideType)
        if(selectedSlideType.metadataForm){
            modeButtons.push( {
                editMode: 'caption',
                label: 'Caption'
            })
        }
        
        if(slideTypes.length > 1){
            modeButtons = [
                {
                    editMode: 'template',
                    label: 'Template'
                },
                ...modeButtons
            ]
        }
    
    
        if(hasMedia){
            modeButtons = [ {
                editMode: 'crop',
                label: 'Crop'
            },
            {
                editMode: 'adjust',
                label: 'Adjust'
            },
            {
                editMode: 'filters',
                label: 'Filters'
            },
            ...modeButtons]
        }

        return modeButtons
    }

    const [editMode, setEditMode] = useState<EditMode>(getModeButtons()[0].editMode)

    // we use the starting state of the saved component
    // if the underlying saved component changes while editing
    // we *don't* pull in those changes to avoid confusion
    // last person to save their edits will win in the case of concurrent updates

    function done(event) {
        onDone?.(event, component)
    }

    

    function setAdjust(adjust: Partial<AdjustParams>) {
        setComponent(produce(draft => {
            if (!draft.adjust) draft.adjust = { ...adjustDefaults }
            Object.assign(draft.adjust, adjust)
        }))
    }

    function setAspectRatio(aspectRatio: AspectRatio) {
        setComponent(produce(draft => {
            if (draft.crop) {
                draft.crop.aspectRatio = aspectRatio
            } else {
                draft.crop = {
                    top: 0,
                    left: 0,
                    width: draft.width,
                    height: draft.height,
                    aspectRatio
                }
            }
        }))
    }

    function resetCrop() {
        setComponent(produce(draft => {
            delete draft.crop
        }))
    }

    function setSlideType(slideType: string) {
        setComponent(produce(draft => {
            draft.slideType = slideType;
            delete draft.crop
        }))
    }

    function setCaptions(captions: any) {
        setComponent(produce(draft => {
            draft.captions = captions;
        }))
    }

    function setEntry(updater: Updater<SimpleEvents.VisualComponent>) {
        setComponent(produce(updater))
    }

    function isPresetActive(preset: FilterPreset) {
        return Object.keys(preset.adjust).every(key => (component.adjust || adjustDefaults)[key] === preset.adjust[key])
    }

    if (!component) return null

    const slideDuration = BDuration.fromJson({ duration: 50, frameRate: "ndf25" }).toJson()
    const fadeDuration = BDuration.fromJson({ duration: 0, frameRate: "ndf25" }).toJson()
    const slideDescription = find(templateDescription.slideTypes, { type: component.slideType })
    
    let width = 0;
    let height = 0;
    let freeformAspectRatio = false
    if(hasMedia){
        freeformAspectRatio = slideDescription.media[0].freeformAspectRatio
        if(!freeformAspectRatio){
            width = slideDescription.media[0].aspectRatio.width;
            height = slideDescription.media[0].aspectRatio.height;
        }
    }
    
    const mediaAspectRatio = `${width}/${height}`

    return (
        <div className="visual-entry-edit">
            <div className="visual-entry-edit__header cols cols--center">
                <h3>Edit visual</h3>
                {getModeButtons().map(modeButton => (
                    <button
                        key={modeButton.editMode}
                        className={getBemClasses('visual-entry-edit__edit-mode', { active: editMode === modeButton.editMode })}
                        onClick={() => setEditMode(modeButton.editMode)}
                    >
                        {modeButton.label}
                    </button>
                ))}
            </div>
            <div className="visual-entry-edit__body">
                {
                    (editMode !== "template") && [
                        <div className="visual-entry-edit__preview">
                            <div className="visual-entry-edit__preview__content">
                                {
                                    editMode === "caption" ?
                                        <VisualPlayer duration={component.duration} eventId={eventId} components={[component]} slideDuration={slideDuration} fadeDuration={fadeDuration} metadata={component.captions} template={template} contentQuality='preview' aspectRatio={aspectRatio}/> :
                                        <VisualCropper
                                            freeformAspectRatio={freeformAspectRatio}
                                            eventId={eventId}
                                            component={component}
                                            setComponent={setEntry}
                                            contentQuality={component.type === 'image' ? 'preview' : 'original'}
                                            disabled={editMode !== 'crop'}
                                            aspectRatio={!freeformAspectRatio ? mediaAspectRatio : null}
                                        />
                                }
                            </div>
                        </div>,
                        <div className="visual-entry-edit__controls">
                            {editMode === 'crop' && (
                                <div className="visual-entry-edit__button-grid">
                                    <button
                                        className={classnames([
                                            getBemClasses('btn', {
                                                secondary: true
                                            })
                                        ])}
                                        onClick={() => resetCrop()}
                                    >
                                        Reset
                                    </button>
                                </div>
                            )}

                            {editMode === 'adjust' && (
                                adjustControls.map(params => (
                                    <AdjustControl
                                        key={params.adjustKey}
                                        value={component.adjust?.[params.adjustKey]}
                                        onChange={value => setAdjust({ [params.adjustKey]: value })}
                                        {...params}
                                    />
                                ))
                            )}

                            {editMode === 'filters' && (
                                <div className="visual-entry-edit__button-grid">
                                    {filterPresets.map(preset => (
                                        <button
                                            key={preset.label}
                                            className={getBemClasses('btn', {
                                                secondary: true,
                                                active: isPresetActive(preset)
                                            })}
                                            onClick={() => setAdjust(preset.adjust)}
                                        >
                                            {preset.label}
                                        </button>
                                    ))}
                                </div>
                            )}

                            {
                                editMode === 'caption' ?
                                    component.slideType ?
                                        <Form widgets={{ TextWidget: TextAreaWidget }} theme="wesley" value={component.captions} onChange={({ formData }) => setCaptions(formData)} jsonSchema={slideDescription.metadataForm.schema} /> :
                                        <span>Please select a template</span> :
                                    null
                            }
                        </div>
                    ]
                }
                {
                    editMode === "template" &&
                    <div className="visual-entry-edit__templates" style={{gridTemplateRows: `repeat(auto-fill, ${aspectRatio === "16/9" ? 180 : 320}px)`, gridTemplateColumns: `repeat(auto-fill, ${aspectRatio === "16/9" ? 320 : 180}px)`}}>
                        {
                            map(templateDescription.slideTypes, v => {
                                if ((hasMedia && !isEmpty(v.media)) || (!hasMedia && isEmpty(v.media))) {
                                    const { captions, slideType } = component
                                    const templateComponent = { ...component, crop: undefined, slideType: v.type }
                                    const className = bem("visual-entry-edit__templates__template").mod({ selected: v.type === slideType })
                                    return <div className={className} onClick={() => setSlideType(v.type)}>
                                        <VisualPlayer duration={templateComponent.duration} eventId={eventId} components={[templateComponent]} slideDuration={slideDuration} fadeDuration={fadeDuration} metadata={captions} template={template} contentQuality='preview' aspectRatio={aspectRatio}/>
                                    </div>
                                }
                            })
                        }
                    </div>
                }
            </div>
            <div className="visual-entry-edit__actions">
                <button
                    className="btn"
                    onClick={done}
                >
                    Done
                </button>
                <button className="btn btn--secondary" onClick={event => onCancel?.(event)}>
                    Cancel
                </button>
            </div>
        </div>
    )
}

interface FilterControlProps extends AdjustControlParams {
    value: number | undefined
    onChange: (value: number) => void
}

function AdjustControl({
    value,
    adjustKey,
    min,
    max,
    label,
    onChange,
}: FilterControlProps) {
    const defaultValue = adjustDefaults[adjustKey]
    return (
        <div
            className="filter-control"
            key={adjustKey}
        >
            <label>
                {label}
                <div className="filter-control__control">
                    <input
                        type="range"
                        min={min}
                        step="0.01"
                        max={max}
                        value={value || defaultValue}
                        onChange={event => onChange(parseFloat(event.target.value))}
                    />
                    <button
                        className="action action--remove"
                        onClick={() => onChange(defaultValue)}
                    />
                </div>
            </label>
        </div>
    )
}

function TextAreaWidget(props) {
    return (
        <textarea
            value={props.value}
            required={props.required}
            onKeyDown={(event) => {
                if(event.key === "Enter"){
                    event.preventDefault()
                }
            }}
            onChange={(event) => {
                props.onChange(event.target.value)} 
            }/>
    );
}