import { useAction } from 'common/frontend/actions'
import { useConfig } from 'common/frontend/state'
import { EventServiceSubscriber, ProDeleteEventsParams, ProRefreshPinParams, ProSaveEventParams } from 'common/types/eventService'
import { resultOrThrow } from 'common/universal/universalUtils'

export function useSaveEventAction() {
    const config = useConfig()
    return useAction(async (params: ProSaveEventParams) => {
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        return await resultOrThrow(subscriber.control.proSaveEvent(params))
    })
}

export function useDeleteEventsAction() {
    const config = useConfig()
    return useAction(async (params: ProDeleteEventsParams) => {
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        return await resultOrThrow(subscriber.control.proDeleteEvents(params))
    })
}

export function useRefreshPinAction() {
    const config = useConfig()
    return useAction(async (params: ProRefreshPinParams) => {
        const subscriber = await EventServiceSubscriber.getConnected(config.services.eventService)
        return await resultOrThrow(subscriber.control.proRefreshPin(params))
    })
}
