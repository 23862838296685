import * as React from 'react'
import { useState } from 'react'
import LiveEventEdit, { LiveEventEditModal } from '../components/LiveEventEdit'
import { StoriesDefinition } from './StoriesPage'

export default {
    name: 'LiveEvents',
    stories: [
        {
            name: 'On the page',
            component: () => {
                return (
                    <div
                        className="rounded-box"
                        style={{
                            padding: 20,
                            maxWidth: 500
                        }}
                    >
                        <LiveEventEdit />
                    </div>
                )
            }
        },
        {
            name: 'In a modal',
            component: () => {
                const [open, setOpen] = useState(false)
                return (
                    <>
                        <button
                            className="btn"
                            onClick={() => setOpen(true)}
                        >
                            open modal
                        </button>
                        <LiveEventEditModal
                            opened={open}
                            onClose={() => setOpen(false)}
                        />
                    </>
                )
            }
        }
    ]
} as StoriesDefinition