import { BDuration, BDurationJson } from '@busby/esb'
import { UiSchema, WidgetProps } from '@rjsf/utils'
import StringField from '@rjsf/core/lib/components/fields/StringField'
import { JSONSchema7 } from 'json-schema'
import * as React from 'react'
import { useMemo, useRef, useState } from 'react'
import { Form } from 'common/frontend/components/Form'
import { SimpleEvents } from 'common/types/eventService'
import { wesleyDebugNamespace } from '../utils'
import Modal, { ModalProps } from './Modal'
import { LiveEventTypeSelect } from './Select'

import './LiveEventEdit.scss'
import { oneHour, oneMinute } from 'common/universal/universalUtils'

const debug = wesleyDebugNamespace.extend('live-event-edit')

const schema: JSONSchema7 = {
    type: "object",
    required: ['name' /*, 'type', 'duration' */],
    properties: {
        name: {
            type: "string"
        },
        /*
        type: {
            type: "object",
            properties: {
                id: {
                    type: "string"
                }
            }
        },
        duration: {
            type: 'object',
            properties: {
                hours: {
                    type: 'number'
                },
                minutes: {
                    type: 'number'
                }
            }
        }
        */
    }
}

const uiSchema: UiSchema = {
    name: {
        'ui:title': 'Note',
        'ui:autofocus': true
    },
    type: {
        "ui:title": 'Type',
        "ui:field": StringField,
        "ui:widget": (props: WidgetProps) => {
            const { id, title, required: requiredOption } = props.options
            const { value, onChange, required: requiredProp } = props
            const required = Boolean(requiredOption || requiredProp)
            return (
                <>
                    <label
                        className="control-label"
                        htmlFor={String(id)}
                    >
                            {title.toString()}{required && <span className="required">*</span>}
                    </label>
                    <LiveEventTypeSelect
                        value={value}
                        required={required}
                        onChange={onChange}
                    />
                </>
            )
        }
    },
    duration: {
        'ui:title': 'Duration',
        classNames: 'form-group--horizontal',
        hours: {
            'ui:title': 'Hours'
        },
        minutes: {
            'ui:title': 'Minutes'
        }
    }
}

export function durationToHoursAndMinutes(duration: BDurationJson) {
    const totalMinutes = BDuration.fromJson(duration).getMinutes()
    const hours = Math.floor(totalMinutes / 60) || undefined
    const minutes = (totalMinutes % 60) || undefined
    return { hours, minutes }
}

export function hoursAndMinutesToDuration({ hours = 0, minutes = 0 }: { hours: number, minutes: number }): BDurationJson {
    return BDuration.fromFrames((hours * oneHour) + (minutes * oneMinute), 'milli').toJson(true)
}

function formDataToEvent(data: any): SimpleEvents.LiveEvent | SimpleEvents.LiveEventData {
    return {
        ...data,
        duration: hoursAndMinutesToDuration(data.duration)
    }
}

function eventToFormData(event: SimpleEvents.LiveEvent | SimpleEvents.LiveEventData) {
    return {
        ...event,
        duration: durationToHoursAndMinutes(event.duration)
    }
}

export function LiveEventEditModal({ event, onSave, onCancel, ...props }: LiveEventEditProps & Omit<ModalProps, 'className'>) {
    async function handleSave(event: SimpleEvents.LiveEvent) {
        await onSave?.(event)
        props.onClose?.()
    }
    return (
        <Modal
            {...props}
        >
            <LiveEventEdit
                event={event}
                onSave={handleSave}
                onCancel={props.onClose}
            />
        </Modal>
    )
}

export interface LiveEventEditProps {
    event?: SimpleEvents.LiveEvent
    onSave?: (liveEvent: SimpleEvents.LiveEvent | SimpleEvents.LiveEventData) => Promise<void>
    onCancel?: (event: React.MouseEvent | React.KeyboardEvent) => void
}

export default function LiveEventEdit({ event, onSave, onCancel }: LiveEventEditProps) {
    const [editEvent, setEditEvent] = useState<SimpleEvents.LiveEvent | SimpleEvents.LiveEventData>(event || {
        name: '',
        // Default duration
        duration: BDuration.fromFrames(0, 'milli').toJson(true),
        type: undefined
    })

    const editFormEvent = useMemo(() => eventToFormData(editEvent), [editEvent])

    const isNew = Boolean(!event)

    return (
        <div className="live-event-edit">
            {isNew ? (
                <h3>Create note</h3>
            ) : (
                <h3>Edit note</h3>
            )}
            <Form
                theme="wesley"
                value={editFormEvent}
                onChange={data => setEditEvent(formDataToEvent(data.formData))}
                onSubmit={data => onSave?.(formDataToEvent(data.formData))}
                jsonSchema={schema}
                uiSchema={uiSchema}
            >
                <div className="cols cols--spaced cols--center">
                    <button
                        className="btn"
                        type="submit"
                    >
                        {isNew ? (
                            <h3>Add note</h3>
                        ) : (
                            <h3>Save note</h3>
                        )}
                    </button>
                    <a
                        onClick={onCancel}
                    >
                        cancel
                    </a>
                </div>
            </Form>
        </div>
    )
}
