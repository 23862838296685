import { ScheduleItemTagWithLoop, SimpleEvents } from "common/types/eventService"

/**
 * A type that mimics react's useState setter function when you call it with a function.
 * 
 * It takes a function that accepts the old state and returns a new state.
 * 
 * Works with immer + recoil nicely, e.g.:
 * 
 *   interface Something {
 *     name: string
 *   }
 * 
 *   // recoils setters accept a function, which gets passed the old value
 *   const setSomething = useSetRecoilState(somethingState)
 * 
 *   return <Foo setSomething={setSomething} />
 * 
 *   ------
 * 
 *   interface FooProps {
 *     setSomething: Updater<Something>
 *   }
 * 
 *   function Foo({ setSomething }: FooProps) {
 *     function someAction(blah) {
 *        // immers "produce" when used like this returns a function that accepts the old value
 *        setSomething(produce(draft => {
 *           draft.name = 'Peter'
 *        }))
 *     }
 *   }
 */
export type SetUsingUpdater<T> = (updater: Updater<T>) => void
export type Updater<T> = (old: T) => T

export interface ProgressItem {
    done: boolean
}

export interface Progress {
    music: ProgressItem
    visuals: ProgressItem
    scheduling: ProgressItem
    streaming: ProgressItem
    review: ProgressItem
}

export type InOutMarkers = [number, number]

export const ActionDisabled = Symbol('action-disabled')

export type OnLink = (() => void) | typeof ActionDisabled
export type OnRemove = (() => void) | typeof ActionDisabled
export type OnLoop = (() => void) | typeof ActionDisabled
export type OnMarkersChange = (markers: InOutMarkers) => void
export type OnTagChange = (tag: ScheduleItemTagWithLoop) => void
export type OnTrackRequestClick = (id: string) => void