import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import * as classnames from 'classnames'
import { SimpleEvents } from 'common/types/eventService'
import produce from 'immer'
import * as React from 'react'
import { useState } from 'react'
import { useEventId } from '../../state'
import { bem } from '../../utils'
import { VisualComponentImage } from './VisualComponentImage'
import Action from '../Action'
import Cover from '../Cover'
import { BlurInput } from '../customWidgets'
import Modal from '../Modal'
import VideoPreview from '../VideoPreview'
import { formatDurationAsMinutesAndSeconds } from 'common/frontend/formatters'
import { Project } from '@motion-canvas/core'
import { TemplateDescription, templates } from './templates/templates';
import { find, isEmpty, reduce } from 'lodash'
import { getVisualProject } from './VisualUtils'


export interface VisualComponentGridItemProps {
    component: SimpleEvents.VisualComponent
    number?: number
    onChange?: (component: SimpleEvents.VisualComponent) => void
    onEdit?: (id: string) => void
    onRemove?: (id: string) => void
    aspectRatio: "16/9" | "9/16"
    template: string
    disableEdit?: boolean
    readOnly?: boolean
    isSelected?: boolean
    onSelected?: (id: string, selected: boolean) => void
}

export default function VisualComponentGridItem({ number, component, onChange, onRemove, onEdit, onSelected, aspectRatio, template, disableEdit, readOnly, isSelected }: VisualComponentGridItemProps) {
    const { uploadStatus } = component
    const isTextOnly = component.type === "text"
    const [mediaLoaded, setMediaLoaded] = useState(false)
    const [mediaError, setMediaError] = useState(null)
    const [videoModalIsOpen, setVideoModalIsOpen] = useState(false)
    const eventId = useEventId()
    const {
        attributes,
        listeners,
        transform,
        transition,
        isDragging,
        setNodeRef,
    } = useSortable({
        id: component.id,
        data: {
            component
        }
    })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    function openVideoPreview() {
        if (component.type !== 'video') return
        setVideoModalIsOpen(true)
    }

    function setIncludeAudio(e) {
        e.stopPropagation()
        onChange({ ...component, includeAudio: !component.includeAudio })
    }

    const project: Project = getVisualProject(aspectRatio, template)
    const { slideTypes } = project.variables.templateDescription as TemplateDescription;
    const hasSlideType = find(slideTypes, { type: component.slideType })
    let ready
    let processing
    let error
    let errorText

    if (isTextOnly) {
        if (!hasSlideType) {
            ready = false
            error = true;
            errorText = "No text slide type available for chosen template"
        } else {
            ready = true
        }
    } else {
        if (!hasSlideType) {
            error = true;
            errorText = "Slide type not found"
        } else {
            error = uploadStatus === "failed"
            processing = uploadStatus === "processing"
            ready = uploadStatus === "ready"
            if (error) {
                errorText = "Processing failed"
            }
        }
    }
    return (
        <div
            className={classnames([
                bem('media-grid__resources__entry').className,
                isDragging && 'dragging'
            ])
            }
            ref={setNodeRef}
            {...attributes}
            style={style}
        >
            <>
                {
                    (processing || error) && <div className='rounded-box' style={{ aspectRatio: "16/9" }}>
                        <Cover center transparent loading={processing} enabled>
                            {
                                processing ? "Processing" : errorText
                            }
                        </Cover>
                    </div>
                }
                {
                    ready && <>
                        <Cover
                            enabled
                            center
                            transparent
                            style={{ cursor: 'grab' }}
                            {...listeners}
                        >
                            {component.type === 'video' && (
                                <button
                                    className="action action--play-overlay"
                                    style={{
                                        '--size': '80px'
                                    } as React.CSSProperties}
                                    onPointerDown={event => {
                                        event.stopPropagation()
                                        event.preventDefault()
                                        openVideoPreview()
                                    }}
                                />
                            )}
                        </Cover>
                        <VisualComponentImage
                            eventId={eventId}
                            component={component}
                            contentQuality="preview"
                            onLoad={() => setMediaLoaded(true)}
                            onError={err => setMediaError(err)}
                            template={template}
                            aspectRatio={aspectRatio}
                        />
                    </>
                }
            </>
            {typeof number === 'number' && (
                <div className={bem('media-grid__resources__entry__number').className}>
                    {number}
                </div>
            )}
            {
                !readOnly && <div className="actions">
                    {component.type === "video" && (
                        <Action
                            icon={
                                !component.hasAudio ? "Mute" :
                                    !component.includeAudio ? "VolumeOff" : "VolumeUp"}
                            disabled={!component.hasAudio}
                            title={!component.hasAudio ? "Video has no audio" : null}
                            circle
                            onClick={setIncludeAudio}
                        />
                    )}
                    {mediaLoaded && !disableEdit && (
                        <Action
                            icon="Edit"
                            circle
                            onClick={() => onEdit(component.id)}
                        />
                    )}
                    <button
                        className="action action--circle"
                        // onClick={() => onRemove(component.id)}
                        onClick={() => onSelected(component.id, !isSelected)}
                    >
                        <input className="action action--circle" type="checkbox" checked={isSelected} onChange={() => onSelected(component.id, !isSelected)} />
                    </button>
                </div>
            }
            {
                videoModalIsOpen && <Modal
                    opened={videoModalIsOpen}
                    onClose={() => setVideoModalIsOpen(false)}
                >
                    <div style={{width: "80vw", height: "80vh"}}>
                        <VideoPreview
                            aspectRatio={aspectRatio}
                            eventId={eventId}
                            component={component}
                            template={template}
                        />
                    </div>
                </Modal>
            }
        </div>
    )
}

export function VisualComponentGridItemOverlay({ number, component, aspectRatio, template }: VisualComponentGridItemProps) {
    const eventId = useEventId()
    return (
        <div
            className={classnames([
                bem('media-grid__resources__entry').className,
                'dragging-overlay'
            ])}
        >
            <VisualComponentImage
                eventId={eventId}
                component={component}
                contentQuality="preview"
                aspectRatio={aspectRatio}
                template={template}
            />
            {typeof number === 'number' && (
                <div className={bem('media-grid__resources__entry__number').className}>
                    {number}
                </div>
            )}
        </div>
    )
}