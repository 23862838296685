import { Tab } from '@headlessui/react'
import * as React from 'react'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Cover from './Cover'
import { NewTrackTarget } from './DraggableNewTracks'
import MusicRequests from './MusicRequests'
import MusicSearch from './MusicSearch'
import MusicUploaded from './MusicUploaded'

export interface MusicTabsProps {
    target: NewTrackTarget
}

export default function MusicTabs({ target }: MusicTabsProps) {
    return (
        <Tab.Group as="div" className="tabs rounded-box relative">
            <Tab.List className="tabs__tabs">
                <Tab as="div">Search</Tab>
                <Tab as="div">Uploaded</Tab>
                <Tab as="div">Requests</Tab>
            </Tab.List>
            <Tab.Panels className="tabs__panels">
                <Tab.Panel className="pad">
                    <ErrorBoundary
                        FallbackComponent={({ error }) => <div>error: {error.message}</div>}
                    >
                        <Suspense
                            fallback={<Cover center translucent loading />}
                        >
                            <MusicSearch
                                target={target}
                            />
                        </Suspense>
                    </ErrorBoundary>
                </Tab.Panel>
                <Tab.Panel className="pad">
                    <MusicUploaded target={target} />
                </Tab.Panel>
                <Tab.Panel className="pad">
                    <MusicRequests target={target} />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    )
}