import classNames from 'classnames'
import * as React from 'react'
import { useState } from 'react'
import { getBemClasses, stopAndPrevent } from '../utils'
import './SliderBox.scss'
import WesleySliderInput, { WesleySliderInputProps } from './WesleySliderInput'

export interface SliderBoxProps extends WesleySliderInputProps {
    className?: string
    value: number
    toggleAction?: string
    onToggle?: (value: boolean) => void
    toggleDefault?: boolean
    sliding?: boolean
    style?: React.CSSProperties
}

/**
 * A slider in a box!
 * 
 * Wraps a WesleySliderInput.
 * 
 * It's mostly to take care of the styling nicely.
 * Styling variants are handled directly through CSS variables.
 * Pass them in on the style prop, check the scss file for what to set.
 * 
 * Also has an option of a toggle action, that sits on the left.
 * Used for mute icon but could be used for other stuff too.
 * 
 */
export default function SliderBox({
    className,
    sliderWidth = '100%',
    toggleAction,
    toggleDefault = false,
    onToggle,
    style,
    ...props
}: SliderBoxProps) {
    const [enabled, setEnabled] = useState(toggleDefault)
    function toggle() {
        const newValue = !enabled
        setEnabled(newValue)
        onToggle?.(newValue)
    }
    return <div
        className={classNames([
            getBemClasses('slider-box', {
                'has-action': Boolean(toggleAction)
            }),
            className
        ])}
        style={{
            '--width': sliderWidth,
            ...style
        } as React.CSSProperties}
    >
        {toggleAction && (
            <button
                className={getBemClasses('action', {
                    [toggleAction]: true,
                    enabled
                })}
                onClick={stopAndPrevent(() => toggle())}
                style={{
                    '--size': 'calc(var(--height) * 0.7)'
                } as React.CSSProperties}
        />
        )}
        <WesleySliderInput
            {...props}
            sliderWidth="unset"
        />
    </div>
}