import * as React from 'react'
import { getBemClasses } from '../utils'
import './Cover.scss'
import { useSpinDelay } from 'spin-delay'

export interface CoverProps extends React.HTMLAttributes<HTMLDivElement>{
    enabled?: boolean
    center?: boolean
    translucent?: boolean
    transparent?: boolean
    loading?: boolean
    zTop?: boolean
    children?: React.ReactNode
}

/**
 * A component that fills the size of it's container (to cover it, hence the name)
 * 
 * It would commonly be used by setting `position: relative` on an outer container
 * so it stays contained within a given space, and doesn't cover the whole viewport
 */
export default function Cover({ 
    enabled = true,
    center = false,
    translucent = false,
    transparent = false,
    loading = false,
    zTop = false,
    children,
    ...attributes
}: CoverProps) {
    // spin delay avoids flashing the loading thing for a very short time
    const isEnabled = useSpinDelay(enabled)
    if (!isEnabled) return null
    return (
        <div
            className={getBemClasses('cover', {
                center,
                translucent,
                transparent,
                zTop,
            })}
            {...attributes}
        >
            {loading && <div className="loading-spinner" />}
            {children}
        </div>
    )
}