import { UiSchema } from '@rjsf/utils'
import { useLoginAction } from 'common/frontend/actions'
import AuthSection from 'common/frontend/components/AuthSection'
import Cover from 'common/frontend/components/Cover'
import { Form } from 'common/frontend/components/Form'
import { defaultBackgroundImages } from 'common/frontend/images'
import { useIsAdminPortal } from 'common/frontend/state'
import * as React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
const schema = {
    type: 'object',
    required: [
        'email',
        'password'
    ],
    properties: {
        email: {
            type: "string",
            format: "email",
            title: "Email"
        },
        password: {
            type: "string",
            title: "Password"
        }
    }
}

const uiSchema: UiSchema = {
    email: {
        "ui:autocomplete": "email"
    },
    password: {
        "ui:widget": "password",
        "ui:autocomplete": 'current-password',
        "ui:help": <div>Forgot your password? <Link to="/forgot-password">Reset here</Link></div>
    }
}

const initialData = {
    email: '',
    password: '',
}

function LoginForm() {
    const [data, setData] = useState(initialData)
    const admin = useIsAdminPortal()
    const [login, isPending] = useLoginAction({ admin })
    return (
        <>
            <Cover center translucent loading enabled={isPending} />
            <h1>Sign in</h1>
            <div className="form-input form-input--wesley-auth">
                <Form
                    theme="wesley-auth"
                    jsonSchema={schema}
                    uiSchema={uiSchema}
                    value={data}
                    onChange={data => {
                        console.log('data', data)
                        setData(data.formData)
                    }}
                >
                    <button
                        type="submit"
                        className="btn"
                        onClick={() => login(data)}
                    >
                        Log in
                    </button>
                </Form>
            </div>
        </>
    )
}

export default function LoginPage() {
    return (
        <AuthSection images={defaultBackgroundImages}>
            <LoginForm />
        </AuthSection>
    )
}