import { streamingViewersState } from 'common/frontend/state'
import * as React from 'react'
import { useRecoilState } from 'recoil'
import { useDeleteAction } from '../actions'
import { useConfirmModal } from '../hooks'
import StreamingViewer from './StreamingViewer'

export interface StreamingViewersProps {
    editable?: boolean
}

export default function StreamingViewers({ editable = false }: StreamingViewersProps) {
    const [viewers, setViewers] = useRecoilState(streamingViewersState)

    const [deleteAction, deleteIsPending] = useDeleteAction()

    const {
        modal,
        confirm
    } = useConfirmModal({
        isPending: deleteIsPending,
        title: 'Delete invite',
        message: 'Are you sure want to delete the invite?',
        confirmButton: 'Delete'
    })

    async function onRemove(id: string) {
        if (!id) return
        if (await confirm()) {
            await deleteAction.streamingViewer(id)
            setViewers(viewers => viewers.filter(viewer => viewer.id !== id))
        }       
    }
    
    return (
        <div className="info-box-list info-box-list--fixed-height">
            {viewers.map(viewer => (
                <StreamingViewer
                    key={viewer.id}
                    viewer={viewer}
                    onRemove={editable ? () => onRemove(viewer.id) : undefined}
                />
            ))}
            {modal}
        </div>
    )
}
