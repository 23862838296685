import { Form } from 'common/frontend/components/Form'
import { EventUserInfo } from 'common/types/eventService'
import { JSONSchema7 } from 'json-schema'
import * as React from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { validate } from "email-validator"
import { stopAndPrevent } from '../utils'
const schema: JSONSchema7 = {
    type: 'object',
    required: ['firstName', 'lastName', 'email'],
    properties: {
        firstName: {
            type: 'string',
            title: 'First name'
        },
        lastName: {
            type: 'string',
            title: 'Last name'
        },
        email: {
            type: 'string',
            format: 'email',
            title: 'Email'
        },
        phoneNumber: {
            type: 'string',
            title: 'Phone number'
        }
    }
}

const uiSchema = {
    firstName: {
        'ui:autofocus': true
    }
}

const initialData = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
}

export interface EventUserAddProps {
    onSave: (user: EventUserInfo) => Promise<void>
    onDone: () => void
    existingUserEmails: string[]
    addButtonText: string
}

export default function EventUserAdd({
    onSave,
    onDone,
    existingUserEmails,
    addButtonText = 'Add',
}: EventUserAddProps) {
    const [data, setData] = useState(initialData)

    async function onAdd(userData: EventUserInfo) {
        if (existingUserEmails.find(email => email === userData.email)) {
            toast(`${userData.email} is already added`)
            return
        }
        await onSave(userData)

        // Clear the fields, ready to add another
        setData(initialData)

        // Focus the first name
        document.getElementById('root_firstName')?.focus()
    }

    const validateForm = (formData, errors) => {
        if (formData.email && !validate(formData.email)) {
            errors.email.addError('Invalid email')
        }

        if (formData.firstName && !formData.firstName.trim()) {
            errors.firstName.addError('Invalid first name')
        }

        if (formData.lastName && !formData.lastName.trim()) {
            errors.lastName.addError('Invalid last name')
        }

        return errors;
    }

    return (
        <div className="add-items">
            <div className="cols cols--center">
                <h2>Add family user</h2>
            </div>
            <Form
                theme="wesley"
                jsonSchema={schema}
                uiSchema={uiSchema}
                value={data}
                onChange={data => setData(data.formData)}
                onSubmit={() => onAdd(data)}
                customValidate={validateForm}
            >
                <div className="btn-bar">
                    <button className="btn">{addButtonText}</button>
                    <button className="btn btn--secondary" onClick={stopAndPrevent(() => onDone())}>Done</button>
                </div>
            </Form>
        </div>
    )
}