import { useAddAction } from "common/frontend/actions"
import Modal from 'common/frontend/components/Modal'
import StreamingViewers from 'common/frontend/components/StreamingViewers'
import { streamingViewersState } from 'common/frontend/state'
import { wesleyDebugNamespace } from "common/frontend/utils"
import { SimpleEvents } from 'common/types/eventService'
import { produce } from 'immer'
import * as React from 'react'
import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import StreamingViewerInvite from './StreamingViewerInvite'
import Action from "./Action"
import { toast } from "react-toastify"

const debug = wesleyDebugNamespace.extend('streaming')

export default function Streaming() {
    const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false)
    const setStreamingViewers = useSetRecoilState(streamingViewersState)
    const [add, addIsPending] = useAddAction()
    const [isInviting, setIsInviting] = useState(false)

    function openInviteDialog() {
        setInviteModalIsOpen(true)
    }

    function closeInviteDialog() {
        setInviteModalIsOpen(false)
    }

    async function onInvite(viewer: SimpleEvents.StreamingViewerData) {
        setIsInviting(true)
        debug('adding streaming viewer %O', viewer)
        try {
            const { resource: streamingViewer } = await add.streamingViewer({
                ...viewer,
                status: 'pending'
            })
            debug('added streaming viewer %o', streamingViewer)
            setStreamingViewers(produce(draft => {
                draft.push(streamingViewer)
            }))
            toast("Viewer was invited")
        } finally {
            setIsInviting(false)
        }
    }

    return (
        <div className="manage-users">
            <h2>Webcast</h2>
            <p>It’s easy to invite your guests to view a live stream of the event. You can add as many people as you like.</p>
            <div className="rounded-box pad">
                <div className="manage-users__header">
                    <h4>Invited viewers</h4>
                    <Action onClick={() => openInviteDialog()} icon="PlusSquare" size={40}/>
                </div>
                <StreamingViewers editable/>
            </div>
            <Modal
                opened={inviteModalIsOpen}
                onClose={() => closeInviteDialog()}
                isPending={addIsPending || isInviting}
            >
                <StreamingViewerInvite
                    onInvite={viewer => onInvite(viewer)}
                    onDone={() => closeInviteDialog()}
                />
            </Modal>
        </div>
    )
}