import * as React from 'react'
import AuthSection from '../components/AuthSection'
import { defaultBackgroundImages } from '../images'

export default function LoggingOut() {
    return (
        <AuthSection images={defaultBackgroundImages}>
            <h1>Logging out</h1>
        </AuthSection>
    )
}