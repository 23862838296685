import { BDateTime, BDuration } from '@busby/esb'
import { SimpleEvents } from "common/types/eventService"
import { sortBy } from "lodash"
import * as React from "react"
import { Fragment, useMemo } from "react"
import { formatSectionDuration, formatTime } from '../formatters'
import { useSectionTimes } from '../hooks'
import { InOutMarkers } from '../model'
import { useEvent, useEventId, useLiveEvent, useSections, useVisual } from "../state"
import { getBemClasses } from "../utils"
import FoldableSection from "./FoldableSection"
import LiveEventBox from "./LiveEventBox"
import './Scheduler.scss'
import TrackBox from "./TrackBox"
import VisualBox from "./visuals/VisualBox"
import { calculateVisualDuration } from 'common/universal/universalUtils'

export interface SchedulerViewOnlyProps {
    types?: SimpleEvents.ScheduleItem['type'][]
    includeTimings?: boolean
}

export default function SchedulerViewOnly({
    types = ['audio', 'visual', 'liveEvent'],
    includeTimings = false
}: SchedulerViewOnlyProps) {
    const sections = useSections()
    const event = useEvent()
    const sectionTimes = useSectionTimes(event)

    return (
        <div className={getBemClasses('scheduler', 'view-only')}>
            {sections.map((section, index) => {

                let totalDuration = BDuration.zero("milli")
                for (let item of section.items) {
                    if (item.type === "visual") {
                        const visualDuration = calculateVisualDuration(item.visual)
                        if(visualDuration){
                            totalDuration = totalDuration.addDuration(visualDuration)
                        }
                    } else if (item.type === "audio") {
                        totalDuration = totalDuration.addDuration(BDuration.fromJson((item as any).trackInfo.duration))
                    }
                }


                return <Fragment key={section.id}>
                    {includeTimings && (
                        <div className="scheduler__section-time">
                            {formatTime(sectionTimes[index])}
                        </div>
                    )}
                    <FoldableSection
                        key={section.id}
                        title={section.name}
                        section
                        sectionDuration={BDuration.fromJson(totalDuration)}
                    >
                        <ScheduleItems
                            types={types}
                            items={section.items}
                        />
                    </FoldableSection>
                </Fragment>
            })
            }

        </div>
    )
}

interface ScheduleItemsProps {
    items: SimpleEvents.ScheduleItem[]
    types: SimpleEvents.ScheduleItem['type'][]
}

function ScheduleItems({ items, types }: ScheduleItemsProps) {
    const sortedItems = useMemo(() => sortBy(items, 'order'), [items])
    return (
        <>
            {sortedItems.map((item, itemIndex, items) => {
                if (!types.includes(item.type)) return
                return (
                    <ScheduleItem
                        key={item.id}
                        item={item}
                        nextItem={items[itemIndex + 1]}
                    />
                )
            })}
        </>
    )
}

export interface ScheduleItemProps {
    item: SimpleEvents.ScheduleItem
    nextItem?: SimpleEvents.ScheduleItem
}

export function ScheduleItem({ item, nextItem }: ScheduleItemProps) {
    // Always use the fresh one...
    const liveEvent = useLiveEvent(item.liveEvent?.id)
    const visual = useVisual(item.visual?.id)
    const eventId = useEventId()
    if (item.type === 'audio') {
        const linkedToNext = item.linkedToNext && nextItem?.type === 'audio'
        const markers = [item.inTimecode, item.outTimecode].map(duration => {
            if (!duration) return null
            if (duration.frameRate !== 'milli') throw new Error('in/out timecodes must be in milli framerate')
            return BDateTime.fromJson(duration)?.getFrames()
        }) as InOutMarkers
        return (
            <TrackBox
                id={item.id}
                key={item.id}
                markers={markers}
                track={item.trackInfo}
                linkedToNext={linkedToNext}
                tag={item.tag}
                showTag
            />
        )
    }
    if (item.type === 'liveEvent') {
        return (
            <LiveEventBox
                key={item.id}
                liveEvent={liveEvent}
            />
        )
    }
    if (item.type === 'visual') {
        return (
            <VisualBox
                key={item.id}
                eventId={eventId}
                visual={visual}
            />
        )
    }

}