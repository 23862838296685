
import { ResourceQuery, ResourceTypeFields } from '@busby/esb'
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel, Row, RowData, RowSelectionState, SortingState, useReactTable
} from '@tanstack/react-table'
import Cover from 'common/frontend/components/Cover'
import { formatDate, formatTime } from 'common/frontend/formatters'
import { eventStatusLabel, getBemClasses, wesleyDebugNamespace } from 'common/frontend/utils'
import { Events, events } from 'common/types/events'
import { SimpleEvents } from 'common/types/eventService'
import * as React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useInfiniteEventsQuery, useInfiniteProResourceQuery } from '../queries'
import { useSelectedOrganisation, useUserOrganisations } from '../state'
import './EventGrid.scss'
import { EventFiltersProps } from './EventFilters'
import { useEventSource } from 'common/frontend/state'
import { getEventSource } from 'common/universal/universalUtils'
import { map } from 'lodash'
declare module '@tanstack/table-core' {
    interface ColumnMeta<TData extends RowData, TValue> {
        className?: string
    }
}

const debug = wesleyDebugNamespace.extend('event-grid')

type QueryField = keyof Events.Event | ResourceTypeFields<Events.Event, false>

const orderByMapping: Record<string, QueryField> = {
    name: 'name',
    date: 'date',
    venue: 'venue.name',
    room: 'room.name',
    organisation: 'organisation.name',
}

const queryFields: QueryField[] = [
    'name',
    'bookingId',
    'venue.name',
    'room.name',
]

export interface EventGridProps extends EventFiltersProps {
    onSelectedChanged?: (events: SimpleEvents.Event[]) => void
}

export default function EventGrid({ organisationId, venueId, queryString = '', status, after, before, onSelectedChanged }: EventGridProps) {
    const organisation = useSelectedOrganisation()
    const navigate = useNavigate()
    const [sorting, setSorting] = useState<SortingState>([])
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

    const {
        events,
        hasNextPage,
        isLoading,
        isFetching,
        fetchNextPage
    } = useInfiniteEventsQuery({
        // This is to filter for events viewable by this org
        // organisationIds: map(organisations, "id"),
        organisationId: organisation?.id,
        orderBy: sorting.map(sort => ({
            field: orderByMapping[sort.id] as any,
            order: sort.desc ? 'desc' : 'asc'
        })),
        query: buildQuery({
            // This is to filter on event.organisation field
            organisationId,
            venueId,
            status,
            queryString,
            after,
            before,
        }),
    })

    const {
        getRowModel,
        getFlatHeaders,
    } = useReactTable<SimpleEvents.Event>({
        data: events,
        columns: [
            {
                id: 'select',
                cell: cell => {
                    const event = cell.row.original;
                    const eventSource = getEventSource(event.bookingId)
                    return <input
                        disabled={event.status !== "notSubmitted" || eventSource === "oldSystem"}
                        type="checkbox"
                        checked={cell.row.getIsSelected()}
                        onChange={cell.row.getToggleSelectedHandler()}
                    />
                }
            },
            {
                header: 'Name',
                accessorKey: 'name'
            },
            {
                header: 'Booking ID',
                accessorKey: 'bookingId',
                enableSorting: false,
                meta: {
                    className: 'nowrap'
                },
            },
            {
                header: 'Status',
                accessorKey: 'status',
                enableSorting: false,
                cell: cell => eventStatusLabel(cell.getValue())
            },
            {
                id: 'date',
                header: 'Date',
                accessorKey: 'date',
                sortingFn: 'datetime',
                cell: cell => cell.getValue() ? formatDate(cell.getValue()) : '',
                meta: {
                    className: 'numeric'
                }
            },
            {
                id: 'startTime',
                header: 'Start time',
                accessorKey: 'date',
                enableSorting: false,
                cell: cell => cell.getValue() ? formatTime(cell.getValue()) : '',
                meta: {
                    className: 'numeric'
                }
            },
            {
                id: 'organisation',
                header: 'Organisation',
                accessorFn: row => row.organisation?.name,
            },
            {
                id: 'venue',
                header: 'Venue',
                accessorFn: row => row.venue?.name,
            },
            {
                id: 'room',
                header: 'Room',
                accessorFn: row => row.room?.name
            },
        ],
        state: {
            sorting,
            rowSelection,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onRowSelectionChange: setRowSelection,
        getRowId: row => row.id
    })

    useEffect(() => {
        if (!onSelectedChanged) return
        const selectedEvents = events.filter(event => rowSelection[event.id])
        onSelectedChanged(selectedEvents)
    }, [rowSelection])

    const fetchMoreOnBottomReached = useCallback(
        (el?: HTMLDivElement | null) => {
            if (el) {
                const { scrollHeight, scrollTop, clientHeight } = el
                if (
                    scrollHeight - scrollTop - clientHeight < 300 &&
                    !isFetching &&
                    hasNextPage
                ) {
                    fetchNextPage()
                }
            }
        },
        [fetchNextPage, isFetching, hasNextPage]
    )

    const containerRef = useRef(null)

    // checks on mount to see if we already need to load more
    useEffect(() => {
        fetchMoreOnBottomReached(containerRef.current)
    }, [fetchMoreOnBottomReached])

    function onClickRow(event: MouseEvent, row: Row<SimpleEvents.Event>) {
        const tagName = (event.target as Element).tagName.toLowerCase()
        if (tagName === 'input') {
            // this is a checkbox, let it do it's thing
            return
        }
        navigate(`/events/${row.original.id}/edit`)
    }

    return (
        <div
            className="event-grid"
            onScroll={event => fetchMoreOnBottomReached(event.target as HTMLDivElement)}
            ref={containerRef}
        >
            <Cover center translucent loading enabled={isLoading} />
            <table>
                <thead>
                    <tr>
                        {getFlatHeaders().map(header => (
                            <th
                                key={header.id}
                                onClick={header.column.getToggleSortingHandler()}
                                style={header.column.getCanSort() ? { cursor: 'pointer' } : {}}
                            >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {header.column.getCanSort() && (
                                    <span className={getBemClasses('sort-direction', header.column.getIsSorted() as string)} />
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {getRowModel().rows.map(row => (
                        <tr
                            key={row.id}
                            onClick={event => onClickRow(event as unknown as MouseEvent, row)}
                        >
                            {row.getVisibleCells().map(cell => (
                                <td
                                    key={cell.id}
                                    className={cell.column.columnDef.meta?.className}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}


function buildQuery({ organisationId, venueId, queryString, status, before, after }: EventFiltersProps): ResourceQuery<Events.Event, events, 'event'> {
    const queries: ResourceQuery<Events.Event, events, 'event'>[] = []

    if (organisationId) {
        queries.push({
            // eventService will also filter it by accessible orgs, so can't break access here!
            field: 'organisation._resourceId',
            comparator: 'eq',
            value: organisationId,
        })
    }

    if (venueId) {
        queries.push({
            field: 'venue._resourceId',
            comparator: 'eq',
            value: venueId,
        })
    }

    if (after) {
        queries.push({
            field: 'date',
            comparator: 'gte',
            value: after,
        })
    }

    if (before) {
        queries.push({
            field: 'date',
            comparator: 'lt',
            value: before,
        })
    }

    if (status) {
        queries.push({
            field: 'status',
            comparator: 'eq',
            value: status,
        })
    }

    if (queryString) {
        queries.push({
            or: queryFields.map(field => ({
                field,
                comparator: 'ilike',
                value: `%${queryString}%`
            }))
        })
    }
    if (queries.length > 1) {
        return {
            and: queries,
        }
    } else if (queries.length === 1) {
        return queries[0]
    }
    return {}
}