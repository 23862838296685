import * as React from 'react'
import { useEffect, useState } from 'react'
import DevInspect from '../components/dev/DevInspect'
import SliderBox, { SliderBoxProps } from '../components/SliderBox'
import { StoriesDefinition } from './StoriesPage'

const stories: StoriesDefinition = {
    name: 'SliderBox',
    stories: [
        {
            name: 'Various',
            component() {
                return (
                    <div>
                        <SliderBoxStory
                            title="Default"
                        />

                        <SliderBoxStory
                            title="With styles"
                            style={{
                                '--height': '40px',
                                '--padding': '18px',
                                '--handle__oversize': '10px',
                                '--track__color': 'red',
                                '--handle__color': 'blue'
                            } as React.CSSProperties}
                        />

                        <SliderBoxStory
                            title="With toggle action.."
                            toggleAction="muted"
                            style={{
                                '--height': '40px',
                                '--padding': '15px',
                                '--track__color': 'black',
                                '--handle__color': 'black'
                            } as React.CSSProperties}
                        />

                        <SliderBoxStory
                            title="With in/out markers..."
                            enableMarkers
                            style={{
                                '--height': '25px',
                                '--padding': '8px',
                                '--handle__oversize': '4px',
                            } as React.CSSProperties}
                        />
                    </div>
                )
            },
        }
    ]
}

function SliderBoxStory({ title, enableMarkers, ...props }: { title: string, enableMarkers?: boolean } & Partial<SliderBoxProps>) {
    const max = 40000
    const [value, setValue] = useState(Math.floor(max / 3))
    const [inMarker, setInMarker] = useState(0)
    const [outMarker, setOutMarker] = useState(max)
    const inspectValue = enableMarkers ? { value, inMarker, outMarker } : { value }
    const [isPlaying, setIsPlaying] = useState(false)
    useEffect(() => {
        if (isPlaying) {
            let last = Date.now()
            const interval = setInterval(() => {
                const now = Date.now()
                setValue(prev => {
                    const next = prev + (now - last)
                    if (next >= max) {
                        clearInterval(interval)
                        setIsPlaying(false)
                    }
                    return next
                })
                last = now
            }, 100)
            return () => {
                clearInterval(interval)
            }
        }
    }, [isPlaying])
    return (
        <div>
            <h4>{title}</h4>
            <SliderBox
                value={value}
                {...(enableMarkers ? {
                    markers: [inMarker, outMarker]
                } : {})}
                max={max}
                onChange={value => setValue(value)}
                onUpdate={value => setValue(value)}
                onMarkersChange={markers => {
                    setInMarker(markers[0])
                    setOutMarker(markers[1])
                }}
                onMarkersUpdate={markers => {
                    setInMarker(markers[0])
                    setOutMarker(markers[1])
                }}
                {...props}
            />
            <DevInspect value={inspectValue} />
            <button onClick={() => setIsPlaying(value => !value)}>play/pause</button>
            {enableMarkers && (
                    <button onClick={() => setInMarker(2000)}>set inMarker to 2000</button>
            )}
            <button onClick={() => setValue(5000)}>set value to 5000</button>
            {enableMarkers && (
                    <button onClick={() => setOutMarker(32000)}>set outMarker to 3000</button>
            )}
        </div>
    )
}

export default stories