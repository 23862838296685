// DO NOT EDIT THIS FILE
// This file was generated from iconsGenerator.ts

import Account from 'common/assets/icons/Account.svg'
import ArrowClockwise from 'common/assets/icons/ArrowClockwise.svg'
import BrokenLink from 'common/assets/icons/BrokenLink.svg'
import CalendarWeek from 'common/assets/icons/CalendarWeek.svg'
import Copy from 'common/assets/icons/Copy.svg'
import DefaultMusic from 'common/assets/icons/DefaultMusic.svg'
import Display from 'common/assets/icons/Display.svg'
import Edit from 'common/assets/icons/Edit.svg'
import Info from 'common/assets/icons/Info.svg'
import Live from 'common/assets/icons/Live.svg'
import Mute from 'common/assets/icons/Mute.svg'
import FacebookSquare from 'common/assets/icons/FacebookSquare.svg'
import TwitterSquare from 'common/assets/icons/TwitterSquare.svg'
import LinkedinSquare from 'common/assets/icons/LinkedinSquare.svg'
import Plus from 'common/assets/icons/Plus.svg'
import PlusSquare from 'common/assets/icons/PlusSquare.svg'
import Home from 'common/assets/icons/Home.svg'
import Tag from 'common/assets/icons/Tag.svg'
import Question from 'common/assets/icons/Question.svg'
import VolumeUp from 'common/assets/icons/VolumeUp.svg'
import VolumeOff from 'common/assets/icons/VolumeOff.svg'

export default {
  Account,
  ArrowClockwise,
  BrokenLink,
  CalendarWeek,
  Copy,
  DefaultMusic,
  Display,
  Edit,
  Info,
  Live,
  Mute,
  FacebookSquare,
  TwitterSquare,
  LinkedinSquare,
  Plus,
  Home,
  Tag,
  PlusSquare,
  Question,
  VolumeUp,
  VolumeOff
}
