import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AudioProvider } from 'common/frontend/audio'
import AppLoader from 'common/frontend/components/AppLoader'
import GeneralError from 'common/frontend/components/GeneralError'
import { LastSavedProvider, SetupEventSync, useEventId, useSetEventBasePath } from 'common/frontend/state'
import * as React from 'react'
import { Suspense, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { RouterProvider } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RecoilRoot } from 'recoil'
import router from './router'
import { useProEventDetails, useSelectedOrganisationId, useSetSelectedOrganisationId } from './state'
import { compact } from 'lodash'
import { VisualComponentUploadProvider } from 'common/frontend/components/visualComponentUpload'
import { Modal } from '@mantine/core'

const queryClient = new QueryClient()

function SelectEvent() {
    const eventId = useEventId()
    const setEventBasePath = useSetEventBasePath()
    useEffect(() => {
        if (eventId) {
            setEventBasePath(`/events/${eventId}/view`)
        } else {
            setEventBasePath('/')
        }
    }, [eventId])
    return null
}

export function App() {
    return (
        <LastSavedProvider>
            <AudioProvider>
                <RecoilRoot>
                    <ErrorBoundary
                        FallbackComponent={GeneralError}
                    >
                        <Suspense
                            fallback={(
                                <AppLoader />
                            )}
                        >
                            <QueryClientProvider client={queryClient}>
                                <VisualComponentUploadProvider>
                                    <SetupEventSync />
                                    <SelectEvent />
                                    <RouterProvider router={router} />
                                    <ToastContainer
                                        position="top-center"
                                        hideProgressBar
                                        theme='dark'
                                        transition={Slide}
                                    />
                                </VisualComponentUploadProvider>
                            </QueryClientProvider>
                        </Suspense>
                    </ErrorBoundary>
                </RecoilRoot>
            </AudioProvider>
        </LastSavedProvider>
    )
}
