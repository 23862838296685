import { BDateTime, BDuration, BDurationJson } from '@busby/esb'
import { SimpleEvents } from 'common/types/eventService'
import * as React from 'react'
import { useRef, useState, useMemo, useCallback, useEffect } from 'react'
import VideoControls from './VideoControls'
import { VisualPlayer, VisualPlayerApi } from './visuals/VisualPlayer'
import screenfull from 'screenfull'
import { mergeRefs } from 'react-merge-refs'
import { usePauseAudio } from '../audio'
export interface PreviewProps {
    eventId: string
    component: SimpleEvents.VisualComponent
    template: string
    aspectRatio: "16/9" | "9/16"
}

export default function VideoPreview({ eventId, component, template, aspectRatio }: PreviewProps) {
    const [hasAutoPlayed, setHasAutoPlayed] = useState(false)
    const fullscreenRef = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState<number>(0)
    
    // const videoRef = useRef<VisualVideoApi>()
    const player = useRef<VisualPlayerApi>(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const pauseMusic = usePauseAudio()

    function setPositionMillis(millis: number) {
        console.log("setPositionMillis")
        if (!position === undefined) return
        player.current.setPosition(BDateTime.fromJson({ time: millis / (1000 / 30), frameRate: "ndf30" }))
    }

    async function playPause() {
        // if (!duration) {
        //     // await player.current.cue(visual)
        // }
        if (player.current.playing.current) {
            player.current.pause()
        } else {
            pauseMusic()
            player.current.play()
        }
    }
    const onPositionChange = (bDateTime: BDateTime) => {
        setPosition(Math.round(bDateTime.getFrames()))
    }

    const onRequestFullscreen = () => {
        screenfull.request(fullscreenRef.current)
    }
    
    const fadeDuration = useMemo<BDurationJson>(() => ({duration: 25, frameRate: 'ndf25'}), [])
    const components = useMemo(() => ([component]), [])
    return (
        <div className="video-preview" ref={fullscreenRef}>
             <VisualPlayer
                aspectRatio={aspectRatio}
                template={template}
                eventId={eventId}
                components={components}
                onPositionChange={useCallback((position) => setPosition(position.getMillis()), [])}
                fadeDuration={fadeDuration}
                slideDuration={fadeDuration}
                ref={player}
                onPlay={useCallback(() => setIsPlaying(true), [])}
                onPause={useCallback(() => setIsPlaying(false), [])}
                // onDurationChange={useCallback((duration) => setDuration(duration.getMilliseconds()), [])}
                contentQuality='preview'
                duration={component.duration}
            />
            <VideoControls
                duration={BDuration.fromJson(component.duration).getMilliseconds()}
                position={position}
                isPlaying={isPlaying}
                // onVolumeChange={value => player.current?.setVolume(value)}
                // onMuted={muted => player.current?.setMuted(muted)}
                onPositionChange={position => setPositionMillis(position)}
                onRequestFullscreen={screenfull.isEnabled ? onRequestFullscreen : null}
                playPause={playPause}
            />
        </div>
    )
}