import * as React from 'react'
import { cloneElement, ReactElement, useMemo } from 'react'
import { RecoilRoot } from 'recoil'
import { getBemClasses } from '../../../../../common/BEM/utils'
import { Link, useSearchParams } from 'react-router-dom'
import './StoriesPage.scss'
import SearchableRouteProvider from '../components/search/SearchableRouteProvider'

const entries: StoriesDefinition[] = []

// Wildcard import for *.stories.js in this dir
// See https://webpack.js.org/guides/dependency-management/#context-module-api  
importAll(require.context('./', true, /\.stories.tsx$/))

function importAll(r) {
    r.keys().forEach(key => {
        const def = r(key).default as StoriesDefinition
        if (!def.hidden) {
            entries.push(def)
        }
    })
    entries.sort((a, b) => a.name.localeCompare(b.name))
}

export interface StoryParent {
    name: string
    children: Story[]
}

export interface Story {
    name: string
    component: () => ReactElement
}

export type StoryDefinition = Story | StoryParent

export interface StoriesDefinition {
    name: string
    hidden?: boolean
    stories: StoryDefinition[]
}

export interface SelectedStory {
    entryIndex: number | undefined
    storyIndex: number | undefined
    childIndex: number | undefined
}

export default function StoriesPage() {
    const [searchParams, setSearchParams] = useSearchParams()

    function setSelected(params: any) {
        setSearchParams(new URLSearchParams(params))
    }

    const { entryIndex, storyIndex, childIndex } = useMemo(() => {
        const params = {}
        const keys = ['entryIndex', 'storyIndex', 'childIndex']
        for (const key of keys) {
            const val = searchParams.get(key)
            if (val) params[key] = parseInt(val)
        }
        return params as SelectedStory
    }, [searchParams])

    const selectedStory = useMemo(() => {
        const story = entries[entryIndex]?.stories?.[storyIndex]
        if (story && childIndex !== null && 'children' in story) {
            return story.children[childIndex]
        }
        return story
    }, [entryIndex, storyIndex, childIndex, entries])

    function isSelected(params) {
        return (
            params.entryIndex === entryIndex &&
            params.storyIndex === storyIndex &&
            (params.childIndex === childIndex || !('childIndex' in params))
        )
    }

    const component = selectedStory && 'component' in selectedStory && selectedStory.component && (
        <selectedStory.component/>
    )

    return (
        // Give it separate recoil state context
        <RecoilRoot>
            <div className="stories">
                <div className="stories__nav">
                    <div style={{ marginBottom: 10 }}>
                        <Link to="/">&larr; Home</Link>
                    </div>
                    {entries.map((entry, entryIndex) => (
                        <div
                            key={entry.name}
                            className="stories__nav__entry"
                        >
                            <div className="stories__nav__entry__name">
                                {entry.name}
                            </div>
                            <div className="stories__nav__entry__stories">
                                {entry.stories.map((story, storyIndex) => (
                                    <div
                                        key={story.name}
                                        className={getBemClasses('stories__nav__entry__stories__story', { selected: isSelected({ entryIndex, storyIndex }) })}
                                    >
                                        {'component' in story ? (
                                            <a
                                                onClick={() => setSelected({ entryIndex, storyIndex })}
                                            >
                                                {story.name}
                                            </a>
                                        ) : (
                                            <a
                                                onClick={() => setSelected({ entryIndex, storyIndex, childIndex: 0 })}
                                            >
                                                {story.name}
                                            </a>

                                        )}
                                        {'children' in story && story.children.length > 0 && (
                                            story.children.map((story, childIndex) => (
                                                <div
                                                    key={childIndex}
                                                    className={getBemClasses('stories__nav__entry__stories__story__child', { selected: isSelected({ entryIndex, storyIndex, childIndex }) })}
                                                >
                                                    <a
                                                        key={story.name}
                                                        onClick={() => setSelected({ entryIndex, storyIndex, childIndex })}
                                                    >
                                                        &rarr; {story.name}
                                                    </a>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="stories__story">
                    {component}
                </div>
            </div>
        </RecoilRoot>
    )
}