import Account from 'common/assets/icons/Account.svg'
import BrokenLink from 'common/assets/icons/BrokenLink.svg'
import Live from 'common/assets/icons/Live.svg'
import * as React from 'react'
import Action from '../components/Action'
import Icon from '../components/Icon'
import { StoriesDefinition } from './StoriesPage'

const stories: StoriesDefinition = {
    name: 'Icons',
    stories: [
        {
            name: 'Default',
            component: () => {
                const width = 30
                const viewBox = `0 0 ${width} ${width}`
                return (
                    <div className="rounded-box pad">
                        <h3>Hello 👋</h3>
                        <Icon name="BrokenLink" fill="pink" size={30} />
                        <Account fill="purple" style={{ width }} />
                        <Account fill="red" width={width} />
                        <BrokenLink fill="green" width={width} />
                        <Live fill="brown" width={width} />
                        <div style={{ width, display: 'inline-block' }}>
                            <Account fill="grey" />
                        </div>
                        <h3>Actions with icons</h3>
                        <Action icon="DefaultMusic" />
                        <Action icon="BrokenLink" iconColor="red"/>
                        <Action icon="Account" iconColor="blue" size={50} />
                    </div>
                )
            }
        }
    ]
}

export default stories