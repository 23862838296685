import * as React from 'react'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useEventListener } from './hooks'
import { InOutMarkers } from './model'
import { wesleyDebugNamespace } from './utils'

const debug = wesleyDebugNamespace.extend('audio')

const Context = createContext<AudioContext>(null)

interface AudioContext {
    audio: HTMLAudioElement
    activeId: string
    segment: number,
    setSegment: (segment: number) => void
    setSrc: (src: string) => void
    setActiveId: (id: string) => void
    setMarkers: (markers: InOutMarkers) => void
    isLoading: boolean
    isPlaying: boolean
    duration: number
    position: number
    reset: (id?: string) => void
}

/**
 * We keep one shared audio element in a context, as only one thing can
 * be playing at a time, and the thing playing is not always directly connected
 * to the element, it might relate to multiple elements (e.g. a track box, and an in/out editing box)
 * 
 * We use the concept of an "id" to track the logical thing that some audio belongs to.
 * 
 * Anything wanting to use audio should call useAudioPlayer()
 */
export function AudioProvider({ children }: { children: ReactNode }) {
    const [audio] = useState<HTMLAudioElement>(() => new Audio())
    const [activeId, setActiveId] = useState(null)
    const [segment, setSegment] = useState(0)
    const [src, setSrc] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const [isPlaying, setIsPlaying] = useState(!audio.paused)
    const [position, setPosition] = useState(0)
    const [duration, setDuration] = useState(null)
    const [markers, setMarkers] = useState<InOutMarkers>([null, null])

    // Check what all the events are:
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement#events

    // Fired when playback has stopped because of a temporary lack of data.
    useEventListener('waiting', () => {
        setIsPlaying(false)
        setIsLoading(true)
    }, audio)

    // The playing event is fired after playback is first started, and whenever
    // it is restarted. For example it is fired when playback resumes after having
    // been paused or delayed due to lack of data.
    useEventListener('playing', () => {
        setIsPlaying(true)
        setIsLoading(false)
    }, audio)

    useEventListener('loadedmetadata', () => {
        setDuration(Math.round(audio.duration * 1000))
    }, audio)

    useEventListener('play', () => {
        setIsPlaying(true)
        setIsLoading(false)
    }, audio)

    useEffect(() => {
        const [inMarker] = markers ?? [null, null]
        audio.src = `${src}?position=${segment}`
        audio.currentTime = inMarker ? inMarker / 1000 : 0
    },[segment])

    // The pause event is sent when a request to pause an activity is handled
    // and the activity has entered its paused state, most commonly after the
    // media has been paused through a call to the element's pause() method.
    useEventListener('pause', () => {
        setIsPlaying(false)
    }, audio)

    useEventListener('timeupdate', () => {
        const position = Math.round(audio.currentTime * 1000)
        
        setPosition(position)
        const [inMarker, outMarker] = markers ?? [null, null]
        if (inMarker > 0 && position < inMarker) {
            // our position is before the in marker... naughty!
            audio.currentTime = inMarker / 1000
        } else if (outMarker > 0 && position >= outMarker) {
            // reached the end
            if (!audio.paused) {
                audio.pause()
            }
            setSegment(segment+1)
        }
    }, audio)

    useEventListener('ended', () => {
        // audio.currentTime = markers?.[0] || 0
        setSegment(segment+1)
    }, audio)

    function reset(id?: string) {
        // id is optional, and means "only reset if we pass in the active one"
        if (id && id !== activeId) return
        setActiveId(null)
        audio.pause()
        audio.src = ''
    }

    return (
        <Context.Provider value={{
            audio,
            activeId,
            setActiveId,
            setMarkers,
            setSrc,
            segment,
            setSegment,
            isLoading,
            isPlaying,
            duration,
            position,
            reset
        }}>
            {children}
        </Context.Provider>
    )
}

function useAudioContext() {
    const ctx = useContext(Context)
    if (!ctx) throw new Error('Missing an audio context, include <AudioProvider> in your root!')
    return ctx
}

export interface AudioPlayerParams {
    id: string
    src: string
    markers?: InOutMarkers
    preload?: boolean
}

export interface AudioPlayer {
    isActive: boolean
    isLoading: boolean
    isPlaying: boolean
    duration: number
    position: number
    playPause: () => void
    setPosition: (position: number) => void
    stop: () => void,
    setPositionToStart: () => void
}

/**
 * Use this to consume audio somewhere, you can use it multiple times with the
 * same params, and it'll be fine! (make the params consistent though...)
 * 
 * Only one id is active at a time.
 */
export function useAudioPlayer({ id, src, markers, preload }: AudioPlayerParams): AudioPlayer {
    const {
        audio,
        activeId,
        setActiveId,
        setMarkers,
        isLoading,
        isPlaying,
        duration,
        position,
        setSegment,
        segment,
        setSrc
    } = useAudioContext()

    if (!id) throw new Error('must provide id to useAudioPlayer') // will be confusing and mix up src's otherwise..

    const isActive = activeId === id

    useEffect(() => {
        if(preload){
            const audio = new Audio()
            audio.src = `${src}?position=${segment}`
        }
    }, [])

    useEffect(() => {
        if (id === activeId) {
            setMarkers(markers)
        }
    }, [id, activeId, markers])

    function playPause() {
        if (activeId !== id) {
            // changing it up!
            audio.pause()
            setActiveId(id)
            setSegment(0)
            setMarkers(markers)
            
            audio.src = `${src}?position=${segment}`
            setSrc(src);
        
            setPositionToStart()
            swallowPromiseError(audio.play())
        } else {
            if (audio.paused) {
                swallowPromiseError(audio.play())
            } else {
                audio.pause()
            }
        }
    }

    function setPosition(position: number) {
        if (!isActive) return
        const [inMarker, outMarker] = markers ?? [null, null]
        if (inMarker && position < inMarker) position = inMarker
        if (outMarker && position > outMarker) position = outMarker
        if (outMarker && position >= outMarker) audio.pause()
        if (Number.isNaN(position)) {
            debug('got NaN when setting position to %s with markers %o', position, markers)
            return
        }
        audio.currentTime = position / 1000
    }

    function setPositionToStart() {
        if (!isActive) return
        setPosition(markers?.[0] || 0)
    }

    function stop() {
        if (!isActive) return
        const [inMarker] = markers ?? [null, null]
        audio.pause()
        audio.currentTime = inMarker ? inMarker / 1000 : 0
    }

    if (isActive) {
        return {
            isActive,
            isLoading,
            isPlaying,
            duration,
            position,
            playPause,
            setPosition,
            setPositionToStart,
            stop,
        }
    } else {
        return {
            isActive,
            isLoading: false,
            isPlaying: false,
            duration: 0,
            position: 0,
            playPause,
            setPosition: () => {},
            setPositionToStart: () => {},
            stop: () => {},
        }
    }
}

export function useResetAudio() {
    const { reset } = useAudioContext()
    return reset
}

export function usePauseAudio() {
    const { audio } = useAudioContext()
    return () => {
        if (!audio.paused) {
            audio.pause()
        }
    }
}

/**
 * In newer browser audio.play() returns a promise, if we click pause before it's
 * loaded, we get a promise exception... this can be used to swallow that error.
 */
function swallowPromiseError(promise?: Promise<any>) {
    if (!promise) return
    promise.catch(() => {})
}