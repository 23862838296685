import { closestCorners, DndContext, useSensor, useSensors } from '@dnd-kit/core'
import * as React from 'react'
import { NoButtonsPointerSensor, wesleyDebugNamespace } from '../utils'

const debug = wesleyDebugNamespace.extend('scheduler-context')

export default function SchedulerContext({ children }) {
    const sensors = useSensors(useSensor(NoButtonsPointerSensor))

    return (
        <DndContext
            autoScroll={false}
            sensors={sensors}
            collisionDetection={closestCorners}
        >
            {children}
        </DndContext>
    )
}