import * as React from 'react'
import ProgressSteps from '../components/ProgressSteps'
import { StoriesDefinition } from './StoriesPage'
import Steps, { StepsProps } from '../components/Steps'

const stories: StoriesDefinition = {
    name: 'Steps',
    stories: [
        {
            name: 'Default',
            component: () => {
                return (
                    <div>
                        <h3><code>headerOnly=false</code></h3>
                        <div style={{ border: '1px dotted #f00', margin: '60px 0', padding: 20 }}>
                            <StepsStory headerOnly={false} />
                        </div>

                        <h3><code>headerOnly=true</code></h3>
                        <div style={{ border: '1px dotted #f00', margin: '60px 0', padding: 20 }}>
                            <StepsStory headerOnly={true} />
                        </div>
                    </div>
                )
            }
        },
    ]
}

function StepsStory(props: Omit<StepsProps, 'children'>) {
    return (
        <Steps {...props}>
            <Steps.Step
                done={false}
                to="#"
                title="A nice step here"
            >
                yay
            </Steps.Step>
            <Steps.Step
                done={false}
                to="#"
                title="Another step"
            >
                Something here
            </Steps.Step>
            <Steps.Step
                done={false}
                to="#"
                title="Finally"
            >
                All good things come in threes
            </Steps.Step>
        </Steps>
    )
}

export default stories