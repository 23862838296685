import ProgressSteps from 'common/frontend/components/ProgressSteps'
import VisualsOverview from 'common/frontend/components/visuals/VisualsOverview'
import { usePageTitle, useScreenSize } from 'common/frontend/hooks'
import { wesleyDebugNamespace } from 'common/frontend/utils'
import * as React from 'react'
import VisualBox from '../components/visuals/VisualBox'
import { useEventId, useVisuals } from '../state'

const debug = wesleyDebugNamespace.extend('visual-selection-page')

export default function VisualsPage() {
    usePageTitle('Visuals')
    const { isSmallScreen } = useScreenSize()
    return (
        <div className="visual-selection-page">
            <section className="progress-navigation">
                <ProgressSteps headerOnly />
            </section>
            <section className="section--grey container-padding">
                {isSmallScreen ? (
                    <SmallScreenVisualsList />
                ) : (
                    <>
                        <div className="cols cols--spaced">
                            <div>
                                <h2>Visuals</h2>
                                <p>Add your own photos and videos to your schedule using our visual creation tool.</p>
                                <p>Choose a template, then set custom text captions and apply cropping and filters to your photos and videos.</p>
                            </div>
                        </div>
                        <VisualsOverview />
                    </>
                )}
            </section>
        </div>
    )
}

function SmallScreenVisualsList() {
    const eventId = useEventId()
    const visuals = useVisuals()
    return (
        <div>
            {visuals.map(visual => (
                <VisualBox
                    key={visual.id}
                    eventId={eventId}
                    visual={visual}
                />
            ))}
        </div>
    )
}