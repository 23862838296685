import { Player, PlayerSettings, Project, Stage, StageSettings } from '@motion-canvas/core'
import { SimpleEvents } from 'common/types/eventService'
import { memoize } from 'lodash'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import { getScaledHeight, getScaledWidth } from '../CanvasImageHelper'
import { getVisualProject } from './VisualUtils'
import { SlideParams, TemplateParams } from "./templates/templates"


export interface VisualComponentImageProps extends React.HTMLAttributes<HTMLImageElement> {
    eventId: string
    component: SimpleEvents.VisualComponent
    contentQuality?: "preview" | "original"
    aspectRatio: "16/9" | "9/16"
    width?: number
    height?: number
    objectFit?: "fit" | "fill"
    background?: string
    template: string
    onReady?: () => void
}

export function VisualComponentImage({
    eventId,
    component,
    contentQuality,
    aspectRatio,
    width,
    height,
    objectFit,
    background,
    template,
    onReady,
    ...attributes
}: VisualComponentImageProps) {
    const [image, setImage] = useState(null)

    useEffect(() => {
        onReady?.()
    }, [image])

    useAsyncEffect(async () => {
        if (component) {
            setImage(await generateComponentImageCached({ eventId, component, contentQuality, aspectRatio, width, height, objectFit, background, template }))
        } else {
            setImage(null)
        }
    }, [component, contentQuality])

    return (
        <img
            src={image}
            {...attributes}
        />
    )
}

export const generateComponentImageCached = memoize(generateComponentImage, JSON.stringify)

export interface GenerateComponentCoverImageParams {
    eventId: string
    component: Omit<SimpleEvents.VisualComponent, 'order'>
    contentQuality?: "preview" | "original"
    aspectRatio: "16/9" | "9/16"
    width?: number
    height?: number
    objectFit?: "fit" | "fill"
    background?: string
    template: string
}

export async function generateComponentImage(props: GenerateComponentCoverImageParams): Promise<string> {

    try {
        const { eventId, component, objectFit = "fill", aspectRatio, background, contentQuality, template } = props;
        let width = 0;
        let height = 0;

        if (props.width) {
            width = props.width
            height = getScaledHeight(aspectRatio, width)
        } else if (props.height) {
            height = props.height
            width = getScaledWidth(aspectRatio, height)
        } else {
            if (aspectRatio === "16/9") {
                width = 300;
                height = getScaledHeight(aspectRatio, width)
            }else{
                width = getScaledWidth(aspectRatio, height)
                height = 300
            }
        }

        const stage = new Stage()
        let project: Project = getVisualProject(aspectRatio, template)

        const defaultSettings = project.meta.getFullRenderingSettings();
        const player = new Player(project);

        const slides = []
        if (component) {
            const { adjust, crop, type, filename, captions = {} } = component

            const slide: SlideParams = {
                captions,
                type: component.slideType,
                userMedia: [{ adjust, crop, type, filename, contentQuality }]
            }
            slides.push(slide)
        }
        const templateParams: TemplateParams = {
            eventId,
            slides,
            slideDuration: 2,
            fadeDuration: 0
        }
        const variables = {
            ...project.variables,
            templateParams
        }
        player.setVariables(variables);

        const resolutionScale = height / defaultSettings.size.height;
        const settings: PlayerSettings & StageSettings = {
            ...defaultSettings,
            resolutionScale,
            background: background || "#FFFFFFFF",
            fps: 25
        };


        stage.configure(settings);
        await player.configure(settings);
        player.activate()

        return new Promise(resolve => {
            const onRender = async () => {
                const { firstFrame, lastFrame } = player.playback.currentScene
                const position = (lastFrame - firstFrame) / 2

                await player.playback.seek(position);
                await stage.render(
                    player.playback.currentScene!,
                    player.playback.previousScene,
                );

                player.onRender.unsubscribe(onRender);
                const blob: Blob = await new Promise(resolve => {
                    stage.finalBuffer.toBlob((blob) => {
                        resolve(blob)
                    }, "image/png");
                })
                resolve(URL.createObjectURL(blob))
            }
            player.onRender.subscribe(onRender);
        })
    } catch (e) {
        console.error(e)
    }
}
