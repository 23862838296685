import * as React from 'react'
import Cover from './Cover'
import Logo from './Logo'

export default function AppLoader() {
    return (
        <div className="app-loader">
            <Cover center>
                <Logo />
                <div className="loading-spinner" style={{ marginTop: 20 }} />
            </Cover>
        </div>
    )
}