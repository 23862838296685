import { Form } from 'common/frontend/components/Form'
import { UploadedAudioAsset } from 'common/types/commonTypes'
import { JSONSchema7 } from 'json-schema'
import * as React from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useConfig, useEventId } from '../state'
import { axios, eventMediaUploadPath, wesleyDebugNamespace } from '../utils'
import Cover from './Cover'
import MediaDropzone from './MediaDropzone'
import './MusicUpload.scss'
import ProgressBar from './ProgressBar'
import { DefaultTrackImage } from './TrackBox'
import { AxiosProgressEvent } from 'axios'

const schema: JSONSchema7 = {
    type: 'object',
    required: [
        'name',
    ],
    properties: {
        name: {
            title: 'Name',
            type: 'string'
        }
    }
}

const debug = wesleyDebugNamespace.extend('music-upload')

export interface MusicUploadProps {
    onDone?: (asset: UploadedAudioAsset) => void
    onCancel?: () => void
}

export default function MusicUpload({ onDone, onCancel }: MusicUploadProps) {
    const eventId = useEventId()
    const [isUploading, setIsUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const config = useConfig()
    const [file, setFile] = useState(null)
    const [formData, setFormData] = useState(null)

    function doUpload() {
        upload(file, formData.name)
    }

    async function onDrop(acceptedFiles: File[]) {
        if (acceptedFiles[0]) {
            setFile(acceptedFiles[0])
        }
    }

    async function upload(file: File, name: string) {
        try {
            setIsUploading(true)
            const formData = new FormData()
            formData.append('name', name)
            formData.append('resource', file)
            const uploadedAsset = (await axios.post(
                // TODO: the 'visuals' bit is a location, which I *think* we'll rename to something not specific to visuals... TBC
                // ... but maybe it'll be an "audio" thing at some point or "music", etc...
                eventMediaUploadPath(eventId, config.locations.media),
                formData,
                {
                    onUploadProgress(event: AxiosProgressEvent) {
                        const percentage = Math.round((event.loaded * 100) / event.total)
                        setUploadProgress(percentage)
                    },
                    timeout: 5 * 60 * 1000
                }
            )).data as UploadedAudioAsset
            
            // TODO: what to do with it now? something with the MAM service...?
            onDone?.(uploadedAsset)
        } catch (err) {
            toast('Sorry, something went wrong')
        } finally {
            setIsUploading(false)
        }
    }

    return (
        <div className="music-upload relative">
            <h3>Upload a track</h3>
            {isUploading ? (
                <div className="music-upload__uploading">
                    <Cover center transparent loading>
                        {uploadProgress === 100 ? (
                            <div>Processing...</div>
                        ) : (
                            <div>Uploading...</div>
                        )}
                        <ProgressBar
                            loaded={uploadProgress}
                            total={100}
                        />
                    </Cover>
                </div>
            ) : (
                file ? (
                    <div>
                        <div className="info-box" style={{ padding: 5, marginBottom: 20 }}>
                            <DefaultTrackImage />
                            {file.path}
                        </div>
                        <Form
                            theme="wesley"
                            jsonSchema={schema}
                            value={formData}
                            onChange={data => setFormData(data.formData)}
                            onSubmit={() => doUpload()}
                        >
                            <div className="cols cols--spaced cols--center" style={{ marginTop: 20 }}>
                                <button className="btn" >Upload</button>
                                <a onClick={() => onCancel?.()}>Cancel</a>
                            </div>
                        </Form>
                    </div>
                ) : (
                    <MediaDropzone types={['audio']} multiple={false} onDrop={onDrop} />
                )
            )}
            {!file && (
                <div className="cols cols--spaced cols--center" style={{ marginTop: 20 }}>
                    <a onClick={() => onCancel?.()}>Cancel</a>
                </div>
            )}
        </div>
    )
}