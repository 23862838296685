import * as React from 'react'
import { usePageTitle } from "common/frontend/hooks"
import { useParams } from 'react-router'
import { useEventId, useVisual } from 'common/frontend/state'
import Loading from '../Loading'
import "./VisualRenderedPlayer.scss"
import { find } from 'lodash'
import { eventRenderedVisualsPath } from 'common/frontend/utils'

export default function VisualRenderedPlayer() {
    usePageTitle('Visual Tribute Preview')
    const eventId = useEventId()
    const { visualId } = useParams()
    const visual = useVisual(visualId)
    const instance = find(visual?.render.instances, { type: "renderedOutput" })

    if (!visual || !instance) {
        return (
            <Loading
                cover
                coverCenter
            />
        )
    }

    const { location, filename } = instance.fileMetadata.location
    const src = eventRenderedVisualsPath(eventId, location, filename)
    return (
        <div className="visual-rendered-player pad">
            <h3>{visual.name}</h3>
            {
                visual.template.projectId === "singleImage" ?
                    <div className="visual-rendered-player__image">
                        <img src={src} />
                    </div> :
                    <video controlsList="nodownload" controls src={src} />
            }
        </div>
    )
}
