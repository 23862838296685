import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { walkChildren, mapChildren, ChildMapper } from '../../utils'
import { IndexSearchContext, SearchContext, SearchQueryState, searchQueryState } from './Search'
import { SearchHighlight } from './SearchHighlight'

/**
 * This is used to mark text as searchable
 * 
 * It's then responsible for indexing or highlighting, depending on the context.
 * 
 */
export default function SearchableContent({ children }: { children: React.ReactNode }) {
    const query = useRecoilValue(searchQueryState)

    function index(context: IndexSearchContext): ChildMapper {
        return child => {
            if (typeof child === 'string') {
                context.content.push(child)
            }
            return child
        }
    }

    return (
        <SearchContext.Consumer>
            {(context) => {
                if (context.mode === 'default' && query.queryString) {
                    // Do the highlighting thing!
                    return (
                        <HighlightedContent query={query}>
                            {children}
                        </HighlightedContent>
                    )

                } else if (context.mode === 'index') {
                    // Extract indexable content from nodes
                    walkChildren(children, index(context))
                }

                return children
            }}
        </SearchContext.Consumer>
    )
}

export function HighlightedContent({ query, children }: { query: SearchQueryState, children: React.ReactNode }) {
    const { queryString } = query

    /**
     * Turn a plain string into highlighted syntax.
     */
    function highlightText(content: string): React.ReactNode {
        // TODO: this only looks at the search query as a whole, should probably break it into words
        // TODO: this only gives us the first entry, consider what to do here
        // TODO: need to highlight based on query result, not just the query string...
        const idx = content.toLowerCase().indexOf(queryString.toLowerCase())
        if (idx === -1) return content
        const parts = []
        if (idx > 0) {
            parts.push(content.substring(0, idx))
        }
        parts.push(
            <SearchHighlight
                content={content.substring(idx, idx + queryString.length)}
            />
        )
        if ((idx + queryString.length) < content.length) {
            parts.push(content.substring(idx + queryString.length, content.length))
        }
        return parts
    }

    function highlightChild(child: React.ReactNode) {
        // At the very bottom layer, the child nodes are just strings
        if (typeof child === 'string') {
            return highlightText(child)
        }
        return child
    }

    return <>{mapChildren(children, highlightChild)}</>
}