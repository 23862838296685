import * as React from 'react'
import { useParams } from 'react-router-dom'
import EventEdit from '../components/EventEdit'

export default function EventEditPage() {
    const { eventId } = useParams()
    return (
        <div className="edit-event-page">
            <section className="container-padding">
                <EventEdit eventId={eventId} />
            </section>
        </div>
    )
}