import * as React from 'react'
import { CSSProperties, HTMLAttributes } from 'react'
import { getBemClasses } from '../utils'
import Icon, { IconName } from './Icon'

export interface ActionProps extends HTMLAttributes<HTMLButtonElement> {
    icon?: IconName
    iconColor?: string
    size?: number
    disabled?: boolean
    circle?: boolean
    text?: string
}

export default function Action({ icon, iconColor, size, circle, disabled, text, style, ...attributes }: ActionProps) {
    return (
        <button
            className={getBemClasses('action', { circle, disabled })}
            style={size ? {
                '--size': `${size}px`,
                ...style
            } as CSSProperties : {}}
            {...attributes}
        >  
            {icon && <Icon name={icon} fill={iconColor} />}
            {
                text && <span>{text}</span>
            }
        </button>
    )
}