import * as React from 'react'

import MusicSelection from 'common/frontend/components/MusicSelection'
import ProgressSteps from 'common/frontend/components/ProgressSteps'
import SchedulerViewOnly from 'common/frontend/components/SchedulerViewOnly'
import { usePageTitle, useScreenSize } from 'common/frontend/hooks'
import { useEventSource } from '../state'

export default function MusicPage() {
    usePageTitle('Music')
    const { isSmallScreen } = useScreenSize()
    return (
        <div className="music-selection-page">
            <section className="progress-navigation">
                <ProgressSteps headerOnly/>
            </section>
            <section className="section--grey container-padding">
                {isSmallScreen ? (
                    <SchedulerViewOnly
                        types={['audio']}
                    />
                ) : (
                    <MusicSelection />
                )}
            </section>
        </div>
    )
}