import { BDateTime, BDuration, BDurationJson, FrameRate, Frtype } from "@busby/esb";
import { AudioManager, Player, PlayerSettings, PlayerState, Project, Stage, StageSettings } from '@motion-canvas/core';
import { SimpleEvents } from 'common/types/eventService';
import { filter, find, isEmpty, isEqual, map, sortBy } from 'lodash';
import * as React from 'react';
import { RefObject, forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import screenfull from 'screenfull';
import { useAsyncEffect } from 'use-async-effect';
import { VisualAudioManager } from "./VisualAudioManager";
import "./VisualPlayer.scss";
import { SlideParams, TemplateParams, loadTemplateFonts, templates } from "./templates/templates";
import { useVisualPlayerAudio } from "./useVisualPlayerAudio";
import * as MultitrackImported from "wavesurfer-multitrack"
import MultitrackDefault from "wavesurfer-multitrack"
import { getVisualProject } from "./VisualUtils";
const Multitrack: typeof MultitrackDefault = MultitrackImported as any

enum State {
    Initial = 'initial',
    Mounted = 'mounted',
    Loading = 'loading',
    Ready = 'ready',
    Error = 'error',
}

type VisualPlayerProps = {
    eventId: string
    template: string
    slideDuration: BDurationJson
    fadeDuration: BDurationJson
    components: SimpleEvents.VisualComponent[]
    metadata?: any
    onReady?: () => void
    onPlay?: () => void
    onPause?: () => void
    onEnd?: () => void
    onPositionChange?: (position: BDateTime) => void
    // onDurationChange?: (duration: BDuration) => void
    contentQuality: "preview" | "original"
    musicItems?: SimpleEvents.VisualMusicItem[]
    frameRate?: Frtype
    enableMusic?: boolean
    duration?: BDurationJson
    aspectRatio: "16/9" | "9/16"
}

export type VisualPlayerApi = {
    play: () => void
    cue: (visual: SimpleEvents.Visual) => Promise<BDuration>
    pause: () => void
    setPosition: (position: BDateTime) => void
    playing: RefObject<boolean>
    requestFullscreen: () => void
    canvas: RefObject<HTMLCanvasElement>
    setVolume: (volume: number) => void
    setMuted: (muted: boolean) => void
    getAudioManager: () => AudioManager
    getStage: () => Stage
    getPlayer: () => Player
    getDucks: () => any
}

export const VisualPlayer = memo(forwardRef((props: VisualPlayerProps, forwardRef) => {
    const { onReady, onPause, onPlay, onPositionChange, eventId, slideDuration, fadeDuration, components, metadata, template, musicItems, frameRate = "ndf30", enableMusic, contentQuality, duration, aspectRatio } = props;
    const playing = useRef<boolean>(false)
    const abortController = useRef<AbortController>();
    const player = useRef<Player>();
    const state = useRef<State>(State.Initial)
    const defaultSettings = useRef<PlayerSettings & StageSettings>()
    const stage = useRef<Stage>(new Stage());
    const canvas = useRef<HTMLCanvasElement>(stage.current.finalBuffer);
    const project = useRef<Project>()
    const loadingRenderResolve = useRef<(value) => void>()
    const audio = useVisualPlayerAudio({ musicItems, duration, fadeDuration, slideDuration, components, getPosition: () => BDateTime.fromJson({ time: player.current.playback.frame, frameRate }) })
    const prevVariables = useRef<any>()
    const { width = 0, height = 0, ref: detectRef } = useResizeDetector<HTMLDivElement>({ refreshMode: 'debounce', refreshRate: 0 });


    useImperativeHandle(forwardRef, () => ({
        play: () => setPlaying(true),
        cue,
        pause: () => setPlaying(false),
        playing,
        setPosition,
        requestFullscreen: () => {
            if (!detectRef.current) return
            screenfull.request(detectRef.current)
        },
        canvas,
        //@ts-ignore
        setVolume: (volume: number) => {
            // audio.setVolume(volume)
        },
        setMuted: (muted: boolean) => {
            // console.log("set muted", muted)
            // player.current?.toggleAudio(!muted)
        },
        getAudioManager: () => {
            //@ts-ignore
            return player.current.audio
        },
        getStage: () => stage.current,
        getPlayer: () => player.current,
        getDucks: () => audio.getDucks()
    }))

    const setPosition = (position: BDateTime) => {
        player.current?.requestSeek(position.getFrames())
        audio.setPosition()
    }

    const setState = (newState: State) => {
        state.current = newState;
        setPlaying(playing.current);
    }

    const setPlaying = (value: boolean) => {
        if (state.current === State.Ready && value) {
            player.current?.togglePlayback(true);
            audio.play()
        } else {
            player.current?.togglePlayback(false);
            playing.current = false;
            audio.pause()
            onPause?.()
        }
        updateClass();
    }

    const updateClass = () => {
        canvas.current.className = `canvas state-${state.current}`;
    }

    useAsyncEffect(async () => {
        if (state.current === State.Ready) {
            updateSettings()
        }

        if (width && height && (state.current === State.Mounted)) {
            await cue()
            onReady?.()
        }
    }, [width, height])

    useEffect(() => {
        setPlaying(false)
    }, [musicItems])

    // useEffect(() => {
    //     const currentPlayer: any = player.current
    //     if (currentPlayer) {
    //         currentPlayer.audio.multitrack = vtAudio
    //         // currentPlayer.audio.duration = BDuration.fromJson(duration).getMilliseconds()/1000
    //     }
    // }, [vtAudio, duration])

    useAsyncEffect(async () => {
        if (state.current === State.Ready) {
            updateSettings()
        }
        if (width && height) {
            await cue()
            onReady?.()
        }
    }, [template])

    useAsyncEffect(async () => {
        detectRef.current.prepend(canvas.current)
        setState(State.Mounted)

        return () => {
            if (player.current) {
                player.current.togglePlayback(false);
            }
        }
    }, [])

    useEffect(() => {
        return () => {
            if (player.current) {
                player.current.togglePlayback(false);
            }
        }
    }, [])

    useEffect(() => {
        if (state.current === State.Ready) {
            const newVariables = getVariables();
            if ((!prevVariables.current || !isEqual(newVariables, prevVariables.current))) {
                setVariables(newVariables, player.current)
                //@ts-ignore - This is dodgy as it's calling a private function, but it works. Hopefully motion-canvas exposes this soon...
                player.current?.requestRecalculation()
                player.current?.playback.reload()
                render()
            }
        }
    }, [metadata, fadeDuration, slideDuration, components])

    const setVariables = (variables: any, player: Player) => {
        console.log("set variables", variables)
        player?.setVariables(variables)
        prevVariables.current = variables;
    }

    const getVariables = () => {
        const readyComponents = filter(components, v => {
            return !v.uploadStatus || v.uploadStatus === "ready"
        })
        const mediaSlides = map(sortBy(readyComponents, "order"), (component) => {
            const { adjust, crop, type, captions = {}, filename } = component

            const slide: SlideParams = {
                captions,
                type: component.slideType,
            }

            const { slideTypes } = project.current.variables.templateDescription as any
            const slideType = find(slideTypes, { type: component.slideType })

            if (slideType?.media) {
                slide.userMedia = [{ adjust, crop, type, filename, muted: !component.includeAudio, contentQuality }]
            }

            return slide;
        })
        const templateParams: TemplateParams = {
            slideDuration: BDuration.fromJson(slideDuration).getMilliseconds() / 1000,
            fadeDuration: BDuration.fromJson(fadeDuration).getMilliseconds() / 1000,
            eventId,
            slides: [
                // title,
                ...mediaSlides
            ]
        }
        return {
            ...project.current.variables,
            templateParams
        }
    }

    const cue = async () => {
        // const release = await VisualPlayerLock.get()
        const promise = new Promise((resolve) => {
            loadingRenderResolve.current = resolve
        })
        try {
            abortController.current?.abort();
            abortController.current = new AbortController();


            try {
                setState(State.Loading);
                //To be replaces with dynamic import from S3 when we switch to Vite...
                project.current = getVisualProject(aspectRatio, template)
                await loadTemplateFonts(project.current)

                // if (module) {
                //     project.current = require(`./templates/${template}Project`).default;
                // } else {
                //     throw new Error(`Module not found`)
                // }
            } catch (e) {
                console.error(e);
                setState(State.Error);
                return;
            }

            defaultSettings.current = project.current.meta.getFullRenderingSettings();

            const newPlayer = new Player(project.current);
            
            setVariables(getVariables(), newPlayer);

            if (player.current) {
                player.current.onRender.unsubscribe(render);
                // player.current.onDurationChanged.unsubscribe(onDurationChange)
                player.current.onStateChanged.unsubscribe(onPlayerStateChange)
                player.current.onRender.unsubscribe(render);
                player.current.onFrameChanged.unsubscribe(onFrameChange)
            }

            player.current?.togglePlayback(false);
            player.current?.deactivate();
            player.current = newPlayer;
            
            updateSettings();
            player.current.toggleAudio(true)
            player.current.toggleLoop(false)

            player.current.onRender.subscribe(render);
            player.current.onStateChanged.subscribe(onPlayerStateChange)
            // player.current.onDurationChanged.subscribe(onDurationChange, false)
            player.current.onFrameChanged.subscribe(onFrameChange)
            player.current.togglePlayback(playing.current);


            player.current.activate()
            if (!isEmpty(musicItems) && enableMusic) {
                //@ts-ignore
                // player.current.audio = new VisualAudioManager(player.current.logger)
            }
            await promise
            setState(State.Ready);
        } catch (e) {
            console.error(e)
        } finally {
            // release()
        }
    }

    // const onDurationChange = (dur) => {
    //     const currentDuration = BDuration.fromJson(duration)
    //     if (dur !== currentDuration.getFrames()) {
    //         const duration = BDuration.fromFrames(dur, frameRate)
    //         props.onDurationChange?.(duration)
    //         setDuration(duration.toJson())
    //     }
    // }

    const onPlayerStateChange = (playerState: PlayerState) => {
        if (state.current === State.Ready) {
            if (playerState.paused && playing.current) {
                onPause?.()
            } else if (!playerState.paused && !playing.current) {
                onPlay?.()
            }
        }
        playing.current = !playerState.paused;
    }

    const onFrameChange = (frames: number) => {
        if (state.current === State.Ready) {
            onPositionChange?.(BDateTime.fromJson({ time: frames, frameRate }))
        }
    }

    const updateSettings = () => {
        const resolutionScale = height / defaultSettings.current.size.height;

        const settings: PlayerSettings & StageSettings = {
            ...defaultSettings.current,
            resolutionScale,
            background: "#000000",
            fps: FrameRate[frameRate]().getFramesPerSecond()
        };

        stage.current.configure(settings);
        player.current.configure(settings);
    }

    const render = async () => {
        if (player.current) {
            loadingRenderResolve.current?.(null)
            try {
                await stage.current.render(
                    player.current.playback.currentScene,
                    player.current.playback.previousScene,
                );
            } catch (e) {
                console.error(e)
            }

        }
    };

    return [<div ref={detectRef} className="visual-player" style={{aspectRatio}}>

    </div>,
    <div style={{ position: "absolute", zIndex: 2, top: 0 }} className="vt-debug"></div>
    ]
}))