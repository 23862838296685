import { Combobox } from '@headlessui/react'
import * as React from 'react'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { bem } from '../../utils'
import { searchQueryState, searchResultsState } from './Search'
import './SearchDropdown.scss'
import { SearchResult } from './searchModel'
import { getRoutePath, Highlight } from './SearchResults'

export interface SearchDropdownProps {
    initialQueryString?: string
    onSelect?: (selected: SearchResult) => void
}

export default function SearchDropdown({ initialQueryString, onSelect }: SearchDropdownProps) {
    const [queryString, setQueryString] = useState(initialQueryString)

    // This is what triggers the search to actually be done
    const results = useRecoilValue(searchResultsState({ queryString }))

    // Once we've selected our result, we set the state to say so, which triggers the highlighting to be done
    const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState)

    const inputRef = useRef<HTMLInputElement>(null)

    const navigate = useNavigate()

    function sanitizeQuery(value: string) {
        return value.replace(/[\^]/gi, '')
    }

    function handleSelectResult(result: SearchResult) {
        setSearchQuery({ queryString, result })
        onSelect?.(result)
        navigate(getRoutePath(result))
    }
    const block = bem('search-dropdown')

    function close() {
        setQueryString('')
        inputRef.current.value = ''
    }

    return (
        <Combobox
            value={searchQuery.result}
            onChange={handleSelectResult}
            as="div"
            className={block.mod({
                'with-results': results.length > 0
            })}
        >
            <Combobox.Input
                ref={inputRef}
                value={queryString}
                onChange={event => setQueryString(sanitizeQuery(event.target.value))}
                className={block.el('input')}
            />
            {results.length > 0 && (
                <Combobox.Options
                    className={block.el('options')}
                >
                    <div className={block.el('options', 'header')}>
                        <div>Results</div>
                        <button
                            className="action action--close"
                            onClick={() => close()}
                        />
                    </div>
                    <div className={block.el('options', 'results')}>
                        {results.map(result => (
                            <Combobox.Option
                                key={result.entry.id}
                                value={result}
                                className={block.el('options', 'results', 'option')}
                            >
                                <div className={block.el('options', 'results', 'option', 'title')}>
                                    {result.highlights.title ? <Highlight highlight={result.highlights.title} /> : result.entry.title}
                                </div>
                                <div className={block.el('options', 'results', 'option', 'preview')}>
                                    {result.highlights.content.length === 0 && (
                                        <span>
                                            {result.entry.content.slice(0, 20)}
                                        </span>
                                    )}
                                    {result.highlights.content.map((highlight, index) => (
                                        <span
                                            key={index}
                                            className={block.el('options', 'results', 'option', 'preview', 'highlight')}
                                        >
                                            <Highlight highlight={highlight} />
                                        </span>
                                    ))}
                                </div>
                            </Combobox.Option>
                        ))}
                    </div>
                </Combobox.Options>
            )}
        </Combobox>
    )
}