import * as React from 'react'
import { walkChildren } from '../../utils'
import { SearchHighlight } from './SearchHighlight'

export interface SearchHighlightExistsProps {
    children: React.ReactNode
    onExists: () => void
}

/**
 * Walks through all the child nodes to see if any of them is a SearchHighlight
 * 
 * Then let's you know via the onExists callback that a highlight exists
 * 
 * This was created so accordians could be opened if they have highlighted text inside.
 */
export default function SearchHighlightExists({ children, onExists }: SearchHighlightExistsProps) {
    walkChildren(children, child => {
        if (React.isValidElement(child)) {
            // TODO: does this comparison always work?
            if (child.type === SearchHighlight) {
                onExists();
            }
        }
    })
    return <>{children}</>
}