import { BDuration, EsbResult } from '@busby/esb'
import { SimpleEvents } from '../types/eventService'
import { entries, find, isString, values } from 'lodash'
import { DateTime } from 'luxon'
import { createElement } from 'react'
import { EventSource } from '../../common/types/commonTypes'
// in millis
export const oneSecond = 1000
export const oneMinute = oneSecond * 60
export const oneHour = oneMinute * 60
export const oneDay = oneHour * 24
export const oneWeek = oneDay * 7

export function stripTrailingSlash(str: string) {
    if (!str) return str
    return str.replace(/\/$/, '')
}



/**
 * Replaces new lines with react <br> elements.
 */
export function nl2br(text: string) {
    const re = /(\r\n|\r|\n)/g
    return text
        .split(re)
        .map((line, index) => line.match(re) ? createElement('br', { key: index }) : line)
}

export async function resultOrThrow<T>(resultPromise: Promise<EsbResult<T>>, errorMessage?: string): Promise<T> {
    const result = await resultPromise
    if (result.isError()) {
        throw new Error(errorMessage || result.getErrorResult().reason)
    }
    return result.getResult()
}

/**
 *  Parses a date string
 *
 *  If no timezone/offset is specified it assumes UTC.
 *
 *  (The default js date parse assumes local time)
 *
 * @param dateString with or without timezone info
 * @returns a JS Date
 */
export function parseDateString(dateString: string): DateTime {
    // Luxon docs:
    // https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#static-method-fromISO
    return DateTime.fromISO(dateString, {
        // use this zone if no offset is specified in the input string itself.
        // Will also convert the time to this zone
        zone: 'utc'
    })
}

/**
 *  Give it a date string, and get back another with fixed timezone info :)
 */
export function fixEventDateString(dateString: string) {
    return parseDateString(dateString).toISO()
}

export function createElements(text) {

    const jsonify = (almostJson) => {
        try {
            return JSON.parse(almostJson);
        } catch (e) {
            almostJson = almostJson.replace(/([a-zA-Z0-9_$]+\s*):/g, '"$1":').replace(/'([^']+?)'([\s,\]\}])/g, '"$1"$2');
            return JSON.parse(almostJson);
        }
    };

    const chars = {
        '[': ']',
        '{': '}'
    };

    const any = (iteree, iterator) => {
        let result;
        for (let i = 0; i < iteree.length; i++) {
            result = iterator(iteree[i], i, iteree);
            if (result) {
                break;
            }
        }
        return result;
    };

    const extract = (str) => {
        let startIndex = str.search(/[\{\[]/);
        if (startIndex === -1) {
            return null;
        }

        let openingChar = str[startIndex];
        let closingChar = chars[openingChar];
        let endIndex = -1;
        let count = 0;

        str = str.substring(startIndex);
        any(str, (letter, i) => {
            if (letter === openingChar) {
                count++;
            } else if (letter === closingChar) {
                count--;
            }

            if (!count) {
                endIndex = i;
                return true;
            }
        });

        if (endIndex === -1) {
            return null;
        }

        let obj = str.substring(0, endIndex + 1);
        return obj;
    };

    const result = []
    for (let el of text) {
        if (isString(el)) {
            let match;
            while ((match = extract(el)) !== null) {
                try {
                    const { element, props, children }  = jsonify(match);
                    result.push(el.slice(0, el.indexOf(match)))
                    result.push(createElement(element, props, children))
                    el = el.slice(el.indexOf(match));
                    el = el.replace(match, '');
                } catch (e) {
                    // Do nothing
                }
            }

            result.push(el)

        } else {
            result.push(el);
        }
    }

    return result;
}

export function getVisualImageAndVideoCounts(visual: SimpleEvents.Visual): {
    imageCount: number
    videoCount: number
    exceededImageCount: number
    exceededVideoCount: number
    maxTotal: number
    typesCanStillAdd: ('image' | 'video')[]
} {
    const imageCount = visual.components?.filter(component => component.type === 'image').length
    const videoCount = visual.components?.filter(component => component.type === 'video').length
    const typesCanStillAdd = []
    let exceededImageCount = 0
    let exceededVideoCount = 0
    let maxTotal = 0
    if (visual.maxImages > 0) {
        maxTotal += visual.maxImages
        if (imageCount < visual.maxImages) {
            typesCanStillAdd.push('image')
        } else if (imageCount > visual.maxImages) {
            exceededImageCount = imageCount - visual.maxImages
        }
    }
    if (visual.maxVideos > 0) {
        maxTotal += visual.maxVideos
        if (videoCount < visual.maxVideos) {
            typesCanStillAdd.push('video')
        } else if (videoCount > visual.maxVideos) {
            exceededVideoCount = videoCount - visual.maxVideos
        }
    }
    return {
        imageCount,
        videoCount,
        exceededImageCount,
        exceededVideoCount,
        typesCanStillAdd,
        maxTotal
    }
}

export const getEventSource = (bookingId:string) : EventSource => {
    let result : EventSource;
    const isNewBookingId = bookingId.match(/^[A-Z2-9]{3}-[A-Z2-9]{3}-[A-Z2-9]{3}$/)
   
    if(isNewBookingId){
        result = "newSystem"
    }else {
        result = "oldSystem"
    }
    return result
}

export const MUSIC_GAP_MS = 1000

export const calculateVisualDuration = (visual: SimpleEvents.Visual): BDuration => {
    // const { template: { projectId }, slideDuration, fadeInDuration, components } = visual;
    // return (await getVisualDuration({ template: projectId, eventId, slideDuration, fadeDuration: fadeInDuration, components }))
    let totalMs = 0
    if(visual.manuallyCreatedVisual){
        const { duration } = find(visual.manuallyCreatedVisual.instances, v => v.type === "manuallyCreatedVisual").technicalMetadata
        return BDuration.fromJson(duration)
    }else if (visual.template.type !== "singleImage") {
        const fadeMs = BDuration.fromJson(visual.fadeInDuration).getMilliseconds()
        const slideMs = BDuration.fromJson(visual.slideDuration).getMilliseconds()
        for (const component of visual.components) {
            if (component.duration) {
                totalMs += BDuration.fromJson(component.duration).getMilliseconds()
            } else {
                totalMs += slideMs
                totalMs += fadeMs * 2
            }
        }
    }

    return BDuration.zero("milli").addFrames(totalMs)
}

export const isEventCutOffPassed = (event: SimpleEvents.Event) => {
    return DateTime.fromISO(event.cutOff, {zone: "UTC"}).diffNow().milliseconds < 0
}