import Cover from 'common/frontend/components/Cover'
import { pickKeys, wesleyDebugNamespace } from 'common/frontend/utils'
import { EventUserInfo, SimpleEvents, proUpdatableEventFields } from 'common/types/eventService'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSaveEventAction } from '../actions'
import { EventParams } from '../model'
import { useProEventDetails, useProResource, useSelectedOrganisationId, useSetProEventDetails } from '../state'
import EventForm from './EventForm'
import { toast } from 'react-toastify'
import { fixEventDateString } from 'common/universal/universalUtils'
import { useRefetchEvent } from 'common/frontend/state'
import { OptionalIdDeep } from 'common/types/typeUtils'

const debug = wesleyDebugNamespace.extend('event-edit')

export interface EventEditProps {
    eventId: string
}

export default function EventEdit({ eventId }: EventEditProps) {
    const organisationId = useSelectedOrganisationId()
    const formRef = useRef(null)

    const savedDetails = useProEventDetails(eventId)
    const setSavedDetails = useSetProEventDetails(eventId)
    const refetchEvent = useRefetchEvent()

    const [eventUserInfos, setEventUserInfos] = useState<EventUserInfo[]>([])
    const [event, setEvent] = useState<OptionalIdDeep<SimpleEvents.Event>>(null)
    const [removeEventUsersById, setRemoveEventUsersById] = useState<string[]>([])

    useEffect(() => {
        if (savedDetails) {
            // This overwrites local storage, we assume the server has the most up to date info
            // The local storage only works for creating new info...
            debug('using saved details', savedDetails)
            setEvent(savedDetails.event)
            setEventUserInfos(savedDetails.eventUserInfos)
        }
    }, [savedDetails])

    async function onChange({ event: incomingEvent, eventUserInfos, removeEventUsersById }: EventParams) {

        try {

            // we *don't* update our local eventUserInfos here, but wait until they're saved...
            // this is as you add them from inside the modal, and it seems to make sense...
            let save = true;
            if (event?.venue?.id !== incomingEvent?.venue?.id) {
                incomingEvent.room = {} as any
                save = false
            }
            if(!incomingEvent?.room?.id){
                save = false
            }
            setEvent(incomingEvent)
            setRemoveEventUsersById(removeEventUsersById || [])

            if (save) {
                // Don't save if client-side validation fails
                if (formRef.current && !formRef.current.checkValidity()) {
                    toast('Error saving', { type: "error" })
                    return
                }

                const { event: savedEvent, eventUserInfos: savedEventUserInfos } = await saveEvent({
                    organisationId,
                    event: 'id' in incomingEvent ? pickKeys(incomingEvent, 'id', ...proUpdatableEventFields) : pickKeys(incomingEvent, ...proUpdatableEventFields),
                    eventUserInfos,
                    removeEventUsersById
                })
                savedEvent.date = fixEventDateString(savedEvent.date)

                setRemoveEventUsersById([])

                // Update the saved details (which will in turn up date our local ones)
                setSavedDetails({
                    event: savedEvent,
                    eventUserInfos: savedEventUserInfos,
                })

                refetchEvent() // this is refetching for the client version of the event

                toast('Saved', {
                    autoClose: 500,
                    closeButton: false,
                    position: 'bottom-center',
                    className: 'small-toast'
                })
            }
        } catch (e) {
            refetchEvent() // this is refetching for the client version of the event

            toast.error(e.message || "Error saving", {
                autoClose: 5000,
                closeButton: false,
                position: 'top-center',
                // className: 'small-toast'
            })
        }
    }

    const [saveEvent, saveEventIsPending] = useSaveEventAction()

    if (!event) return null

    return (
        <div className="event-edit relative">
            <Cover center translucent loading enabled={saveEventIsPending} />
            <div style={{ maxWidth: 500, marginBottom: 40 }}>
                <h2>{event.name}</h2>
                <p>Enter in the details of the event below.</p>
            </div>
            <Link to={`/events/${eventId}/view`}>
                <button className="btn">View as user</button>
            </Link>
            <EventForm
                ref={formRef}
                event={event}
                eventUserInfos={eventUserInfos}
                removeEventUsersById={removeEventUsersById}
                liveValidate={true}
                onChange={onChange}
            />
        </div>
    )
}