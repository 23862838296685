import * as React from 'react'
import { Link } from 'react-router-dom'
// import * as logoImage from 'common/assets/wesleylogo.svg'
import * as logoImage from 'common/assets/wesley-media-logo.png'
import './Logo.scss'

export default function Logo() {
    return (
        <img src={logoImage} />
    )
}

export function LogoLink() {
    return (
        <Link to="/" className="logo">
            <Logo />
        </Link>
    )
}